import React from 'react';

interface Props {
  className?: string;
}
export default function TramIcon({ className }: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24px" viewBox="0 0 24 24" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4113 5.41581L10.8273 6.99977H2.8673C2.36964 6.99977 1.94772 7.3657 1.87735 7.85835L1.07071 13.5048C1.0262 13.8164 1.13099 14.1308 1.35355 14.3533L2.70711 15.7069C2.89464 15.8944 3.149 15.9998 3.41421 15.9998H4.75C4.88807 15.9998 5 16.1117 5 16.2498V16.4998C5 16.7759 5.22386 16.9998 5.5 16.9998H9.5C9.77614 16.9998 10 16.7759 10 16.4998V16.2498C10 16.1117 10.1119 15.9998 10.25 15.9998H13.75C13.8881 15.9998 14 16.1117 14 16.2498V16.4998C14 16.7759 14.2239 16.9998 14.5 16.9998H18.5C18.7761 16.9998 19 16.7759 19 16.4998V16.2498C19 16.1117 19.1119 15.9998 19.25 15.9998H20.5858C20.851 15.9998 21.1054 15.8944 21.2929 15.7069L22.6464 14.3533C22.869 14.1308 22.9738 13.8164 22.9293 13.5048L22.1227 7.85835C22.0523 7.3657 21.6304 6.99977 21.1327 6.99977H12.2415L13.4725 5.76874C13.6678 5.57344 13.6678 5.25677 13.4724 5.06153L11.5816 3.1718C11.4839 3.07422 11.3257 3.07425 11.2281 3.17186L10.8745 3.52546C10.7768 3.62309 10.7768 3.78138 10.8745 3.87901L12.4113 5.41581ZM15 8.99977H9V11.9998H15V8.99977ZM16 8.99977H19.5C19.7761 8.99977 20 9.22363 20 9.49977V11.9998H16V8.99977ZM8 8.99977H4.5C4.22386 8.99977 4 9.22363 4 9.49977V11.9998H8V8.99977Z"
        fill="#e5e8ff"
      />
      <path
        d="M1 17.25C1 17.1119 1.11193 17 1.25 17H22.75C22.8881 17 23 17.1119 23 17.25V17.75C23 17.8881 22.8881 18 22.75 18H1.25C1.11193 18 1 17.8881 1 17.75V17.25Z"
        fill="#e5e8ff"
      />
    </svg>
  );
}
