import React from 'react';

interface Props {
  className?: string;
}
export default function Work({ className = '' }: Props) {
  return (
    <svg className={className} viewBox="0 0 121 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M80.4605 65.6048C84.1678 62.2734 86.5035 58.3706 88.4633 53.7867C88.0911 53.2321 74.4472 47.8181 72.3646 46.6214C67.9481 44.0828 62.5602 41.9239 58.6853 38.6185C55.5214 35.9199 50.6695 31.6263 50.4033 32.0116C50.2023 32.3019 49.188 33.5712 49.1601 33.9229C48.5776 41.5126 50.6881 49.0427 53.2211 56.221C53.9785 58.3688 57.9781 74.9867 59.7089 74.4451C62.3275 73.6244 76.4423 69.2173 80.4605 65.6048Z"
        fill="#9DA6D9"
      />
      <path
        d="M61.379 45.3987C61.4962 45.5345 61.6172 45.6983 61.5855 45.8733C61.5595 46.0203 61.4348 46.1245 61.3175 46.2157C59.3876 47.7139 57.305 49.013 55.1088 50.085C52.976 46.7554 51.2005 43.197 49.8195 39.4915C49.4268 38.4399 48.0087 34.4851 49.4343 33.7053C50.6291 33.052 52.265 36.3499 52.8885 37.0664C55.4997 40.0647 58.792 42.3799 61.379 45.3987Z"
        fill="#DBA9A2"
      />
      <path
        d="M29.7975 29.46C27.8024 31.952 26.0827 34.6618 24.6738 37.5261C28.8297 41.172 34.6643 42.0318 40.1491 42.7372C40.5045 42.7837 40.8935 42.8228 41.1969 42.6311C41.4165 42.4934 41.5523 42.2589 41.677 42.0337C42.8272 39.953 43.7913 37.768 44.5488 35.5142C44.7758 34.8386 44.921 33.949 44.3384 33.5395C44.3384 33.5395 42.0288 33.5265 41.2825 33.4446C39.9276 33.2976 38.5839 33.0482 37.268 32.7002C34.6346 32.0041 32.1034 30.9116 29.7975 29.46Z"
        fill="#CCBFB5"
      />
      <path
        d="M3.93457 50.1202C5.00472 53.4646 6.43406 56.6918 8.19096 59.7329C10.5862 60.0269 13.0113 59.677 15.3842 59.2322C18.8775 58.5771 22.5346 57.6056 24.9969 55.041C25.237 54.7897 25.4733 54.5087 25.5348 54.1662C25.6055 53.768 25.4268 53.3734 25.25 53.0105C23.8262 50.0718 22.2592 47.2001 20.5581 44.4121C20.9787 45.1026 17.9637 47.3303 17.5394 47.6337C16.2645 48.5475 14.8519 49.2678 13.3705 49.7796C10.3517 50.82 7.01659 50.9893 3.93457 50.1202Z"
        fill="#E2D1CA"
      />
      <path
        d="M16.0732 25.8609C11.2157 24.1487 6.00456 22.4923 1 23.7058C1.02792 21.2565 1.30709 18.811 1.83192 16.4195C1.88962 16.1608 1.95476 15.889 2.13901 15.6992C2.34001 15.4908 2.64151 15.42 2.92626 15.3642C7.00212 14.5602 11.2715 14.8022 15.2413 16.0175C16.2035 16.3115 17.2718 16.6205 18.1558 17.1118C19.2911 17.7427 19.226 17.7632 18.7681 19.0232C17.9325 21.3235 16.974 23.5866 16.0732 25.8609Z"
        fill="#CCBFB5"
      />
      <path
        d="M95.0235 105.2C99.0714 104.13 101.111 103.298 109.028 100.362C109.267 100.274 119.006 96.321 119.162 96.12C119.324 95.9116 119.35 95.6287 119.324 95.3663C119.214 94.3278 118.334 93.4977 117.351 93.1422C116.369 92.7868 115.293 92.8165 114.251 92.8724C111.517 93.0157 108.788 93.3042 106.084 93.7322C103.808 94.0933 100.953 94.3147 99.7302 92.3587C99.5106 92.0088 99.3543 91.5956 99.0156 91.3593C98.6285 91.0876 98.1092 91.1229 97.6439 91.2048C95.5223 91.5808 91.5729 90.935 91.3496 93.2427C91.3496 93.2427 92.9446 98.9247 93.2963 100.157C93.7858 101.876 94.186 103.432 95.0235 105.2Z"
        fill="#F24949"
      />
      <path
        d="M26.3916 101.678C27.7949 105.298 29.6895 108.726 32.0103 111.838C32.202 112.095 32.4068 112.357 32.6915 112.502C32.9967 112.657 33.3541 112.655 33.6946 112.649C39.1105 112.567 44.5282 112.486 49.9441 112.404C50.817 110.678 49.49 108.54 47.7685 107.66C46.0469 106.779 44.0388 106.722 42.1572 106.277C40.9437 105.99 39.7694 105.538 38.6769 104.937C37.9287 104.526 37.2978 102.994 37.2978 102.994L40.5696 100.563C40.7074 99.9602 38.8072 99.5489 38.4498 99.0445C37.8059 98.1326 37.1619 97.2225 36.5161 96.3105C34.8485 93.9525 33.9143 95.8918 32.31 97.0364C30.2665 98.4936 28.2844 100.027 26.3916 101.678Z"
        fill="#F24949"
      />
      <path
        d="M33.2812 95.0104C34.7701 98.2673 36.3056 100.914 38.9577 103.385C39.2499 103.657 61.8495 91.5673 66.5953 81.6103C67.9484 78.7721 67.0457 65.7014 66.5953 62.3477C66.5953 62.3477 81.5774 69.9783 82.2288 72.3047C83.9429 78.4241 90.5871 95.9261 90.976 95.8479C94.1641 95.2021 98.7928 93.0562 101.584 91.3812C101.858 91.2174 95.0742 66.2709 90.4177 61.9755C87.5255 59.3085 80.3825 54.8287 76.8315 53.1351C70.8592 50.2839 62.3855 45.2254 61.7564 45.5976C59.2551 47.079 54.9745 49.6939 52.4731 51.1754C51.9632 51.4769 51.4179 51.8212 51.2187 52.3795C51.0457 52.8653 51.1778 53.4031 51.3118 53.9019C52.5439 58.4579 54.498 77.3316 54.498 77.3316C47.798 83.1922 40.9119 88.6826 33.2812 95.0104Z"
        fill="#4A5296"
      />
      <path
        d="M75.3669 33.5587C74.8867 32.3061 74.0194 31.1746 72.8395 30.5344C72.4151 30.3036 71.801 30.1807 71.5218 30.5753C71.425 30.713 71.3953 30.8861 71.3766 31.0517C71.2054 32.6058 71.8215 34.1375 71.8754 35.7008C71.8829 35.8944 71.8829 36.0991 71.9852 36.2648C72.0634 36.3913 75.473 37.6085 75.5977 37.6885C76.2044 38.0793 75.6479 34.292 75.3669 33.5587Z"
        fill="#F24949"
      />
      <path
        d="M74.8252 30.3816C75.3277 30.1731 75.8693 30.0447 76.409 30.0801C76.9208 30.1136 77.4103 30.2922 77.8886 30.4821C79.9842 31.314 81.9868 32.4009 83.8405 33.7148C81.5234 34.1838 79.1132 34.7682 77.3247 36.3706C75.7576 37.7721 74.8531 39.7895 73.9933 41.7437C74.0677 41.5762 70.928 39.3075 70.6339 38.9911C69.8113 38.1071 70.2096 37.0723 70.5409 36.0077C71.2555 33.7074 72.5621 31.3214 74.8252 30.3816Z"
        fill="#CCBFB5"
      />
      <path
        d="M66.9994 28.5369C70.2136 29.0338 75.3521 30.1114 75.3745 30.3366C75.7765 34.5185 75.6909 37.0776 75.3819 41.267C75.3614 41.5461 75.4694 42.3092 75.3354 42.5549L66.7389 44.2652C66.0261 42.2478 66.9008 37.6006 66.8133 35.4845C66.7835 34.7996 66.4746 28.4569 66.9994 28.5369Z"
        fill="#E2D1CA"
      />
      <path
        d="M64.4323 31.4866C66.9467 31.2595 70.481 32.0915 72.9935 32.6498C73.4476 36.1803 72.3142 39.7518 71.0058 43.0628C70.9258 43.2656 70.8383 43.4741 70.6876 43.6304C70.4922 43.8333 70.2111 43.9301 69.9394 44.0082C67.8643 44.6094 62.9453 44.3525 60.9893 43.1651C60.9893 43.1651 63.6414 36.5116 64.4323 31.4866Z"
        fill="#CCBFB5"
      />
      <path
        d="M52.6092 14.6942C53.5769 14.5639 55.1663 13.678 55.4455 14.175C55.7247 14.6719 54.8667 15.5261 54.716 15.6322C53.75 16.3283 53.4448 16.4083 52.5347 16.7563C52.7543 17.525 53.0447 18.275 53.402 18.9897C52.1085 19.5741 50.5843 19.2242 49.2889 18.6417C49.0339 18.5263 48.7045 18.4127 48.5017 18.6044C48.2839 18.8092 48.3788 19.615 48.5445 19.8644C49.4732 21.2714 51.1966 21.5432 52.8567 21.8428C52.3374 24.4707 51.1668 26.2164 50.3833 28.4256C48.8348 28.4907 47.2845 28.5559 45.736 28.621C45.563 28.6284 45.3787 28.634 45.2242 28.5559C45.0418 28.4647 44.932 28.2748 44.8427 28.0924C44.0983 26.5998 43.8898 24.8634 42.989 23.4582C42.533 22.7454 41.1856 22.4812 40.9157 21.679C40.8059 21.3515 40.3779 19.9817 40.5435 19.6783C40.8915 19.0418 42.6317 18.476 43.3352 18.6547C43.7465 17.1397 43.056 13.1644 48.6859 12.7922C49.5495 12.7345 49.8938 13.8735 50.4949 14.3071C51.0719 14.7296 51.9243 14.7873 52.6092 14.6942Z"
        fill="#F24949"
      />
      <path
        d="M49.1938 15.8965C49.3521 15.8965 49.4805 15.7681 49.4805 15.6099C49.4805 15.4516 49.3521 15.3232 49.1938 15.3232C49.0355 15.3232 48.9072 15.4516 48.9072 15.6099C48.9072 15.7681 49.0355 15.8965 49.1938 15.8965Z"
        fill="#4A5296"
      />
      <path
        d="M47.2063 15.1821C47.1932 15.2212 47.7516 16.7343 48.6338 17.1791C49.1009 17.4155 49.7672 17.3057 50.0147 16.8441C50.1617 16.5687 50.1338 16.2337 50.0817 15.9266C50.0073 15.4966 49.4899 14.1901 49.3056 14.0617C49.2014 13.991 47.2193 15.143 47.2063 15.1821Z"
        fill="#4A5296"
      />
      <path
        d="M51.2944 12.9207C51.3465 12.891 51.4061 12.8612 51.4638 12.8798C51.5066 12.8928 51.5382 12.9319 51.5643 12.9691C51.9309 13.4679 52.1933 14.1379 52.2622 14.7521C52.2678 14.8005 50.5258 14.8303 49.9209 13.7136C49.9228 13.7099 50.7621 13.2222 51.2944 12.9207Z"
        fill="#4A5296"
      />
      <path
        d="M49.2549 14.6865C49.4484 14.5637 50.4888 14.0183 50.6824 13.8955"
        stroke="#4A5296"
        stroke-width="0.555682"
        stroke-miterlimit="10"
      />
      <path
        d="M44.4623 21.4964C44.9425 21.4648 45.4022 21.3457 45.8414 21.1391C45.5511 20.3556 45.3091 19.5572 45.1156 18.7438C44.9369 17.2791 45.0672 16.3858 46.074 15.3305C47.8365 13.4862 51.3503 13.6183 50.5482 10.1455C48.9681 3.30583 38.6221 9.23909 36.7349 12.7324C35.3968 15.2096 35.3428 19.0081 36.2976 21.6453C37.0011 23.5902 41.3635 26.5364 43.8891 25.081C43.8891 25.081 43.7085 23.9755 43.005 22.9407C42.5826 22.3191 41.505 21.9636 41.4678 21.2154C41.4212 20.2551 41.9516 18.3884 43.4722 19.036C43.9337 19.2315 44.252 21.0758 44.4623 21.4964Z"
        fill="#4A5296"
      />
      <path
        d="M50.8685 28.5686C51.1514 30.3274 50.7383 31.2412 51.0863 32.987C51.4399 34.7662 63.1668 65.002 66.7811 73.3286C62.0353 76.7717 51.3878 80.9686 46.4018 78.819C45.9068 78.6049 40.4146 56.0035 39.9493 47.7698C39.4524 38.9612 43.519 31.4069 43.519 31.4069C43.7311 30.3777 43.4352 28.4756 43.8484 27.6064C43.8502 27.6046 49.3889 28.5724 50.8685 28.5686Z"
        fill="#9DA6D9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.426 101.31C26.5375 101.258 26.6695 101.307 26.721 101.418C28.3851 105.022 30.0619 108.639 32.5767 111.659C32.8191 111.95 33.0494 112.202 33.3391 112.327C33.6051 112.442 33.9123 112.448 34.2626 112.45C34.2626 112.45 34.2626 112.45 34.2626 112.45L49.7416 112.528C49.8643 112.529 49.9633 112.629 49.9627 112.752C49.9621 112.875 49.8621 112.974 49.7393 112.973L34.2603 112.895L34.2531 112.895C33.9201 112.893 33.5231 112.891 33.1627 112.735C32.7638 112.563 32.4729 112.23 32.2351 111.944C29.6766 108.871 27.9773 105.199 26.3174 101.605C26.2659 101.493 26.3146 101.361 26.426 101.31Z"
        fill="#9DA6D9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M119.365 96.0264C119.415 96.1383 119.366 96.2699 119.254 96.3204C111.158 99.9753 103.684 102.866 95.1303 105.276C95.0121 105.31 94.8893 105.241 94.856 105.123C94.8228 105.005 94.8915 104.882 95.0097 104.848C103.537 102.446 110.99 99.5634 119.071 95.9153C119.183 95.8647 119.314 95.9145 119.365 96.0264Z"
        fill="#9DA6D9"
      />
      <path
        d="M52.677 69.5764C53.9333 69.5354 55.1765 69.8072 56.403 70.0789C56.684 70.1403 56.9762 70.2092 57.1977 70.3934C57.4415 70.5981 57.5606 70.9127 57.6611 71.2142C58.2157 72.8948 58.5117 74.661 58.534 76.4309C58.5359 76.6356 58.534 76.8459 58.4577 77.0358C58.2157 77.6276 57.4266 77.7114 56.7883 77.702C56.5668 77.6983 56.31 77.6778 56.1797 77.4973C56.0959 77.3819 56.0903 77.2293 56.0848 77.086C56.0364 75.6474 55.8093 74.2143 55.411 72.8315C55.3515 72.6268 55.2826 72.4146 55.1281 72.2694C54.9513 72.1038 54.6964 72.0573 54.4563 72.02C53.5034 71.8749 52.5449 71.7669 51.5846 71.6943C51.4059 71.6813 51.2142 71.672 51.056 71.7595C50.8327 71.886 50.7545 72.1633 50.7024 72.4127C50.4809 73.4866 50.9425 76.4179 49.8742 77.032C48.8022 77.6499 47.8456 76.4383 47.6595 75.5618C47.0099 72.4779 49.7644 69.6713 52.677 69.5764Z"
        fill="#DBA9A2"
      />
      <path
        d="M58.855 75.94C54.6061 77.5964 50.0798 74.4288 45.5368 74.0361C41.1651 73.6583 36.7337 76.1187 34.5376 79.9191C32.3415 83.7195 32.3806 88.6496 34.4222 92.5356C36.4639 96.4217 40.3834 99.204 44.6789 100.099C46.7596 100.533 48.9967 100.533 50.9304 99.6507C55.4362 97.596 57.1223 91.4469 61.7863 89.7868C66.91 87.9629 72.2663 92.6008 77.6803 92.0945C80.4515 91.8359 83.0478 90.1236 84.3748 87.6762C85.7929 85.0595 85.2941 79.5878 85.1099 76.6156C85.1099 76.6156 101.73 69.3293 109.057 66.2547C109.479 66.0779 107.294 59.3276 106.079 59.8022C97.317 63.2248 89.7739 65.9774 81.4416 69.8876C81.3206 69.9453 78.8342 68.4155 78.4973 68.2833C77.4048 67.8534 76.2603 67.565 75.0971 67.4086C72.7148 67.0904 70.2451 67.3379 67.9801 68.1419C63.7014 69.6643 62.9607 74.3394 58.855 75.94Z"
        fill="#DBA9A2"
      />
      <path
        d="M58.6851 38.6184C55.3555 38.6836 54.6595 40.6955 56.4517 42.3407C57.4828 43.2861 59.7199 43.0982 61.058 43.5039C62.2994 43.8798 65.3665 44.7601 65.3851 44.574C65.4894 43.5058 65.7201 40.5466 64.8361 39.8356C64.8361 39.8356 61.058 38.5719 58.6851 38.6184Z"
        fill="#F24949"
      />
      <path
        d="M63.7106 38.5872C63.5618 38.7659 63.197 44.8052 63.3608 44.8369C64.9762 45.1384 68.5235 45.8009 70.1613 45.9442C72.7576 46.1713 74.5852 45.9908 76.1541 45.7823C79.841 45.2891 80.0904 37.7199 75.8098 38.7138C71.6949 39.6648 63.8372 38.4365 63.7106 38.5872Z"
        fill="#4A5296"
      />
      <path
        d="M51.6098 65.3112C52.5422 66.0259 53.4058 66.8597 53.9716 67.8889C54.5373 68.9181 55.038 70.5912 54.6993 71.7154C54.3605 72.8395 54.0162 73.7012 53.0242 73.7626C52.0323 73.824 51.2134 72.9735 50.6662 71.7154C50.1209 70.4572 48.3361 69.0018 46.5773 67.4757C46.354 67.2822 46.1269 67.0719 46.0357 66.7927C45.7212 65.8361 47.2641 63.8707 48.2002 63.6772C49.3448 63.4408 50.7779 64.6729 51.6098 65.3112Z"
        fill="#F24949"
      />
      <path
        d="M29.0171 42.5863C27.1951 43.9357 25.3731 45.3669 24.1205 47.2559C23.3984 48.3447 22.8717 49.6028 22.8587 50.9111C22.8308 53.5912 24.7831 55.9548 26.9978 57.4642C34.0254 62.2547 44.4607 68.804 44.8199 68.4894C45.8919 67.5496 48.8567 64.3782 49.4727 63.0922C49.6254 62.7739 33.2754 50.9056 33.1377 50.4961C32.9069 49.8168 43.707 43.5578 45.0991 41.9684C46.4912 40.379 47.2952 38.7692 46.6234 36.7666C46.1432 35.3373 44.6952 34.0754 43.1635 33.9321C41.6746 33.7925 40.4016 34.9874 39.1919 35.7281C35.7004 37.8609 32.3039 40.152 29.0171 42.5863Z"
        fill="#4A5296"
      />
    </svg>
  );
}
