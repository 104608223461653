import fetch from 'cross-fetch';
import { buildDepartureQuery } from './queries/departures';
import { formatDepartures, formatFirstDepartures } from '../utils/departures';

const clientIdentifier = 'ruter-stop-signs';

const JOURNEY_PLANNER_DEFAULT = 'https://api.entur.io/journey-planner/v3/graphql';
const JOURNEY_PLANNER_URL =
  window && window._env_ ? window._env_.JOURNEY_PLANNER || JOURNEY_PLANNER_DEFAULT : JOURNEY_PLANNER_DEFAULT;

const fetchGraphQL = async (query: string): Promise<Record<string, Entur.Board>> => {
  return fetch(JOURNEY_PLANNER_URL, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'ET-Client-Name': clientIdentifier,
    },
    body: JSON.stringify({
      query,
      variables: {},
    }),
  })
    .then((response) => response.json())
    .then((json) => {
      return json.data;
    });
};

export const getAggregatedDepartures = async (
  screenConfig: DPI.ScreenConfig
): Promise<[DPI.BoardDeparture[], DPI.FirstEstimatedCall[]]> => {
  const query = buildDepartureQuery(screenConfig);

  const response = await fetchGraphQL(query);

  const departures = getDepartures(response, screenConfig);
  const firstDepartures = formatFirstDepartures(response);

  return [departures, firstDepartures];
};

export const getDepartures = (response: Record<string, Entur.Board>, screenConfig: DPI.ScreenConfig) => {
  const numberOfDeparturesList = screenConfig.boards.map((bv) => bv.numberOfDepartures);
  const groupedSituationBoardsList = screenConfig.boards.map(
    (bv) => bv.situationsInfo && bv.situationsInfo.groupByBoard
  );

  return formatDepartures(response, numberOfDeparturesList, groupedSituationBoardsList);
};
