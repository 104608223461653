import React, { useMemo } from 'react';
import DepartureLine from '../../Departures/Line';
import DepartureHeader from '../DepartureHeader';

import './styles.scss';

export const getDepartureKey = (departure: DPI.EstimatedCall, index: number) => {
  return `${departure.serviceJourneyId}-${departure.quayId || index}`;
};

interface Props {
  estimatedCalls?: DPI.EstimatedCall[];
  screenConfig: DPI.ScreenConfig;
  index: number;
  hideTrack: boolean;
  allDeparturesTramOrMetro: boolean;
  currentSituation?: DPI.Situation;
  boardConfig: DPI.BoardConfig;
}

const DepartureBoard = ({
  estimatedCalls = [],
  screenConfig,
  index,
  hideTrack,
  allDeparturesTramOrMetro,
  currentSituation,
  boardConfig,
}: Props) => {
  const hasTopHeader = !screenConfig || !screenConfig.header || !screenConfig.header.label;
  const boardArrowTracks = boardConfig.publicCodeArrowAngles || [];
  const hasDelayOrCancelation = estimatedCalls.some((departure) => departure.delayed || departure.cancellation);
  const showPlannedTime = boardConfig.hideDelay !== true && hasDelayOrCancelation;

  const departuresString = useMemo(() => {
    return estimatedCalls
      .map((d) => d.serviceJourneyId)
      .sort((a, b) => a.localeCompare(b))
      .join(',');
  }, [estimatedCalls]);

  return (
    <>
      <DepartureHeader
        screenConfig={screenConfig}
        departures={estimatedCalls}
        index={index}
        hideTrack={hideTrack}
        hasTopHeader={hasTopHeader}
        showPlannedTime={showPlannedTime}
      />
      <div className="departure-board">
        {estimatedCalls.map((departure, i) => {
          return (
            <DepartureLine
              key={getDepartureKey(departure, i)}
              showPlannedTime={showPlannedTime}
              departure={departure}
              publicCodeArrowAngles={boardArrowTracks}
              hideTrack={hideTrack}
              allDeparturesTramOrMetro={allDeparturesTramOrMetro}
              blinkKey={departuresString}
              activeSituation={currentSituation}
            />
          );
        })}
      </div>
    </>
  );
};

export default DepartureBoard;
