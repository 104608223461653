import React from 'react';

interface Props {
  className?: string;
}
export default function Deviations({ className = '' }: Props) {
  return (
    <svg className={className} viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.0674 70.9427C18.0674 70.9427 15.9447 64.0155 17.3184 60.7858C17.819 59.6093 18.6191 58.5863 19.3608 57.5414C21.3775 54.7099 23.0655 51.5167 23.4053 48.0567C23.5149 46.9241 23.4235 45.6381 22.5832 44.8708C21.4287 43.8149 19.58 44.3082 18.177 45.0023C11.5348 48.2759 6.71208 54.666 4.4834 61.7248"
        fill="#DBA9A2"
      />
      <path
        d="M58.0039 37.2125C57.5143 35.9118 55.629 32.9451 54.2845 32.5944C49.1476 31.2572 49.597 37.2819 44.4856 38.1332C44.4856 38.1332 40.6238 26.4454 40.6494 26.6281C40.6859 26.8692 20.5218 32.5615 11.249 35.1482C11.249 35.1482 16.3604 54.7497 20.1966 66.6786C29.7142 63.7119 40.5069 60.2702 50.0244 57.3035C50.0244 57.3035 46.3343 44.3808 46.6303 44.2676C47.7519 43.8401 48.9978 43.7488 50.1706 44.0118C52.0156 44.4283 53.7657 45.6961 55.6217 45.3235C57.2001 45.0092 58.3473 43.5076 58.6542 41.9293C58.9574 40.3546 58.5665 38.7214 58.0039 37.2125Z"
        fill="#4A5296"
      />
      <path
        d="M70.8942 46.5439C70.8942 46.5439 68.3075 54.1544 66.9959 57.5668C71.3802 59.1634 74.3432 54.5087 77.668 58.3815C78.5741 59.4374 78.4316 62.9485 78.0918 64.2967C77.6972 65.8568 77.0834 67.4241 75.9325 68.5458C74.7816 69.6711 72.9841 70.2484 71.508 69.609C69.7726 68.8563 69.0565 66.814 67.7777 65.422C67.0361 64.6145 66.0715 64.0154 65.0229 63.7011L64.537 65.4622C64.537 65.4622 61.0552 77.2705 61.1392 77.1025C61.2488 76.8833 83.5393 83.5583 92.6367 86.7077C92.6367 86.7077 100.927 68.4471 104.566 56.4561C95.0517 53.4748 80.4081 49.5253 70.8942 46.5439Z"
        fill="#4A5296"
      />
      <path
        d="M50.6137 40.165C50.3981 40.165 50.2264 40.0773 50.1387 39.7996C50.0474 39.5183 50.2008 39.2151 50.4749 39.1128C50.5077 39.1018 50.537 39.0908 50.5699 39.0872C50.632 39.0689 50.7562 39.036 50.9243 38.9885C54.1796 38.0642 54.2088 38.0971 54.4865 38.4149C54.6838 38.6415 54.6619 38.9885 54.4317 39.1895C54.3075 39.2991 54.1467 39.3393 53.9969 39.3174C53.7229 39.3539 52.7912 39.5987 51.2238 40.0408C51.1033 40.0737 50.9864 40.1065 50.8804 40.1321C50.8731 40.1358 50.8658 40.1394 50.8585 40.1431V40.1394C50.7672 40.154 50.6868 40.165 50.6137 40.165Z"
        fill="#CCBFB5"
      />
      <path
        d="M40.0106 43.1569C39.7768 43.1569 39.5612 43.0071 39.4882 42.7696C39.3968 42.481 39.5612 42.1741 39.8499 42.0827C41.2967 41.6333 42.0201 41.3922 42.4512 41.2461C43.1125 41.0232 43.2002 40.994 44.1611 40.793C44.457 40.7309 44.7493 40.9209 44.8114 41.2168C44.8735 41.5128 44.6835 41.8051 44.3876 41.8672C43.4888 42.0572 43.4377 42.0754 42.802 42.2873C42.3672 42.4335 41.6365 42.6783 40.175 43.1313C40.1202 43.1496 40.0654 43.1569 40.0106 43.1569Z"
        fill="#CCBFB5"
      />
      <path
        d="M29.2764 46.4638C29.0462 46.4638 28.8343 46.3176 28.7576 46.0874C28.6626 45.7988 28.816 45.4883 29.1047 45.3933L32.9409 44.1145C33.2296 44.0195 33.5401 44.173 33.6351 44.4616C33.7301 44.7502 33.5767 45.0608 33.288 45.1558L29.4518 46.4345C29.3933 46.4565 29.3348 46.4638 29.2764 46.4638Z"
        fill="#CCBFB5"
      />
      <path
        d="M19.5582 49.1233C19.328 49.1233 19.1125 48.9735 19.0357 48.7433C18.9444 48.4547 19.1015 48.1478 19.3901 48.0528C20.3876 47.7349 20.8479 47.5741 21.2936 47.417C21.7467 47.2599 22.2143 47.0955 23.2227 46.774C23.5114 46.6827 23.8183 46.8398 23.9133 47.1284C24.0046 47.417 23.8475 47.7239 23.5589 47.8189C22.5614 48.1368 22.1011 48.2976 21.6553 48.4547C21.2023 48.6118 20.7346 48.7762 19.7263 49.0977C19.6715 49.1123 19.613 49.1233 19.5582 49.1233Z"
        fill="#CCBFB5"
      />
      <path
        d="M84.324 67.4423C84.2838 67.4423 84.2437 67.4386 84.2035 67.4277L80.3672 66.5764C80.0713 66.5106 79.8849 66.2183 79.9507 65.9224C80.0165 65.6264 80.3088 65.4401 80.6047 65.5059L84.4409 66.3572C84.7369 66.4229 84.9232 66.7152 84.8575 67.0111C84.8027 67.2705 84.5761 67.4423 84.324 67.4423Z"
        fill="#CCBFB5"
      />
      <path
        d="M93.696 69.9998C93.6412 69.9998 93.5827 69.9925 93.5279 69.9743C92.293 69.5724 91.566 69.3678 90.2507 69.134C89.9511 69.0828 89.7538 68.7978 89.8049 68.4982C89.8561 68.1986 90.1447 68.0013 90.4407 68.0525C91.8181 68.2973 92.578 68.5092 93.8677 68.9294C94.1563 69.0243 94.3135 69.3312 94.2185 69.6199C94.1417 69.8537 93.9298 69.9998 93.696 69.9998Z"
        fill="#CCBFB5"
      />
      <path
        d="M107.547 10.0119C107.343 9.67575 80.5657 16.5847 80.5657 16.5847C82.3925 22.7957 85.8159 30.0371 81.5558 35.576C78.0703 40.1064 70.4562 40.6362 69.1994 44.0961C67.4968 48.7836 74.5482 49.602 77.2957 49.2075C83.262 48.3562 87.5221 42.8174 93.4884 42.8174C99.4547 42.8174 101.512 50.1209 101.585 53.895C101.76 62.9814 101.395 68.8272 94.3397 76.9052C92.1914 79.3677 85.392 83.7228 85.8195 86.7041C86.7841 93.445 97.6571 86.5068 99.0272 85.4254C106.17 79.7916 116.071 69.2327 117.774 52.1888C118.11 48.8238 117.467 26.453 107.547 10.0119Z"
        fill="#DBA9A2"
      />
      <path
        d="M32.7873 77.307C32.4256 73.8617 33.7592 70.5004 34.6141 67.1427C35.4508 63.8728 37.0145 59.2437 35.111 56.456C31.4574 51.1108 28.2934 56.0285 26.558 59.81C24.585 64.1103 21.0484 74.3512 17.6396 69.6637L4.48304 61.7281C2.25071 68.7868 2.448 76.4593 4.10672 83.6752C5.76545 90.891 9.53229 101.87 12.9557 108.435C15.3524 113.028 39.678 106.057 44.9135 98.6366C43.5033 96.1667 33.0029 79.353 32.7873 77.307Z"
        fill="#DBA9A2"
      />
    </svg>
  );
}
