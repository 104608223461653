import dayjs from 'dayjs';

const calculateSecondsIn = (minutes = 0, seconds = 0) => minutes * 60 + seconds;

export const getETAText = (etaDiffInSeconds: number | null, departureTime: string, realtime: boolean) => {
  try {
    if (typeof etaDiffInSeconds !== 'number') {
      return '';
    }

    if (!realtime) {
      return dayjs(departureTime).format('HH:mm');
    }

    if (0 > etaDiffInSeconds) {
      return 'nå';
    }

    if (etaDiffInSeconds >= 0 && etaDiffInSeconds <= 45) {
      return 'nå';
    }

    const showMinutesThreshold = calculateSecondsIn(14, 46);

    if (etaDiffInSeconds >= showMinutesThreshold) {
      return dayjs(departureTime).format('HH:mm');
    }

    if (etaDiffInSeconds < showMinutesThreshold) {
      const minutes = Math.floor(etaDiffInSeconds / 60);
      const restSeconds = etaDiffInSeconds % 60;
      if (restSeconds > 45) {
        return `${minutes + 1} min`;
      } else {
        return `${minutes} min`;
      }
    }

    return `${etaDiffInSeconds} min`;
  } catch (error) {
    console.error('Unable to parse ETA test', error);
    return '';
  }
};

export const getSecondsToDeparture = (isoString: string | null, nowDate = new Date().toISOString()) => {
  if (!isoString) {
    return null;
  }
  try {
    const date = dayjs(isoString);
    const now = dayjs(nowDate);
    return date.diff(now, 'seconds');
  } catch (error) {
    return null;
  }
};
