import React from 'react';

import AddFavourite from './AddFavourite';
import Celebrate from './Celebrate';
import EasyTicket from './EasyTicket';
import EasyTravel from './EasyTravel';
import HighFive from './HighFive';
import OpenDoor from './OpenDoor';
import RushHour from './RushHour';
import Tram from './Tram';
import Whoops from './Whoops';
import Bus from './Bus';
import Cycling from './Cycling';
import FromAToB from './FromAToB';
import Home from './Home';
import OtherPlaces from './OtherPlaces';
import Scooter from './Scooter';
import TravelCompanion from './TravelCompanion';
import Work from './Work';
import CantFindAnything from './CantFindAnything';
import Deviations from './Deviations';
import Garden from './Garden';
import Micromobility from './Micromobility';
import Planning from './Planning';
import TaDa from './TaDa';
import WearAMask from './WearAMask';

export const SUPPORTED_ILLUSTRATIONS: Record<DPI.SupportedIllustrations, React.FC<{ className?: string; style?: CSSStyleDeclaration }>> = {
  ILLUSTRATION_ADD_FAVOURITE: AddFavourite,
  ILLUSTRATION_BUS: Bus,
  ILLUSTRATION_CELEBRATE: Celebrate,
  ILLUSTRATION_CYCLING: Cycling,
  ILLUSTRATION_DEVIATIONS: Deviations,
  ILLUSTRATION_EASY_TICKET: EasyTicket,
  ILLUSTRATION_EASY_TRAVEL: EasyTravel,
  ILLUSTRATION_FROM_A_TO_B: FromAToB,
  ILLUSTRATION_GARDEN: Garden,
  ILLUSTRATION_HIGH_FIVE: HighFive,
  ILLUSTRATION_HOME: Home,
  ILLUSTRATION_MICROMOBILITY: Micromobility,
  ILLUSTRATION_OPEN_DOOR: OpenDoor,
  ILLUSTRATION_OTHER_PLACES: OtherPlaces,
  ILLUSTRATION_PLANNING: Planning,
  ILLUSTRATION_RUSH_HOUR: RushHour,
  ILLUSTRATION_SCOOTER: Scooter,
  ILLUSTRATION_TA_DA: TaDa,
  ILLUSTRATION_TRAM: Tram,
  ILLUSTRATION_TRAVEL_COMPANION: TravelCompanion,
  ILLUSTRATION_CANT_FIND_ANYTHING: CantFindAnything,
  ILLUSTRATION_WEAR_MASK: WearAMask,
  ILLUSTRATION_WHOOPS: Whoops,
  ILLUSTRATION_WORK: Work,
};

export function isSupportedIllustration(name: DPI.SupportedIllustrations) {
  return Object.keys(SUPPORTED_ILLUSTRATIONS).includes(name);
}

export function Illustration({ name, className, style }: Props) {
  const Component = SUPPORTED_ILLUSTRATIONS[name];
  const emptyStyle = {} as CSSStyleDeclaration;

  if (!Component) {
    return null;
  }

  return <Component className={className} style={style || emptyStyle} />;
}

interface Props {
  name: DPI.SupportedIllustrations;
  className?: string;
  style?: CSSStyleDeclaration;
}