import React from 'react';

interface Props {
  className?: string;
}
export default function Scooter({ className = '' }: Props) {
  return (
    <svg className={className} viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.115 100.946C8.71121 99.5466 6.21843 96.5266 4.48162 93.2817C0.767654 86.35 -0.0232123 77.9063 2.34163 70.4047C2.76808 69.0517 3.34185 67.66 4.46999 66.8032C6.30372 65.4075 8.95932 65.8883 11.0295 66.904C18.2559 70.4513 21.5163 78.798 24.1486 86.4081C22.1598 79.9339 21.8148 72.9673 23.1445 66.3302C23.726 63.4343 24.6875 60.484 26.7538 58.3712C28.8202 56.2583 32.2356 55.2387 34.8292 56.6576C36.3993 57.5183 37.4383 59.1116 38.1865 60.7399C39.7992 64.2368 40.373 68.2027 39.8147 72.0136C41.4934 68.1058 49.2586 60.5538 53.4378 61.3253C57.617 62.0968 59.8578 65.2292 62.1839 68.7842C64.5099 72.3431 64.13 81.9304 65.4287 85.9817C66.9135 80.3603 68.6659 74.3203 73.287 70.7885C77.6949 67.4196 84.1575 67.2258 88.9725 69.9822C93.7875 72.7386 96.8463 78.1545 97.1448 83.6944C98.7886 77.2783 100.708 70.4629 105.697 66.1092C108.539 63.6281 113.032 62.2441 116.017 64.5547C118.266 66.2915 118.812 69.4278 118.948 72.2695C119.429 82.3647 116.564 92.4947 111.152 101.031L12.115 100.946Z"
        fill="#9DA6D9"
      />
      <path
        d="M91.3222 99.8642C91.1672 99.8642 91.0198 99.7672 90.9578 99.6122L73.1556 52.5013C73.078 52.2998 73.1827 52.0788 73.3804 52.0012C73.582 51.9237 73.803 52.0245 73.8805 52.2261L91.6866 99.3369C91.7642 99.5385 91.6595 99.7595 91.4618 99.837C91.4153 99.8564 91.3688 99.8642 91.3222 99.8642Z"
        fill="#4A5296"
      />
      <path
        d="M30.8017 98.6704C30.5885 98.6704 30.4141 98.496 30.4141 98.2827C30.4141 98.0695 30.5885 97.8951 30.8017 97.8951L77.7614 97.7865C79.723 97.7749 80.5992 96.3482 81.8165 94.3749C82.6578 93.0142 83.6076 91.4712 85.135 90.1027C87.4378 88.0402 90.7874 87.4626 94.0904 88.5675C94.292 88.6334 94.4045 88.8544 94.3347 89.056C94.2688 89.2576 94.0478 89.37 93.8462 89.3002C90.8107 88.2884 87.748 88.804 85.6507 90.6804C84.2085 91.9713 83.2858 93.4639 82.4756 94.782C81.2311 96.8018 80.1534 98.5464 77.7614 98.5619L30.8017 98.6704Z"
        fill="#4A5296"
      />
      <path
        d="M39.6363 98.24C39.6286 98.24 39.6169 98.24 39.6092 98.24C39.396 98.2245 39.2331 98.0384 39.2487 97.8252C39.2525 97.7942 39.4347 94.7393 37.3258 92.6148C35.7673 91.0408 33.352 90.343 30.1498 90.5407C29.9405 90.5523 29.7505 90.3895 29.7389 90.1763C29.7272 89.963 29.8901 89.777 30.1033 89.7653C33.542 89.556 36.1588 90.3313 37.884 92.0759C40.2411 94.4563 40.0318 97.7438 40.024 97.8795C40.0085 98.0849 39.8379 98.24 39.6363 98.24Z"
        fill="#4A5296"
      />
      <path
        d="M31.0611 105.788C27.0642 105.788 23.8115 102.536 23.8115 98.5387C23.8115 94.5417 27.0642 91.2891 31.0611 91.2891C35.0581 91.2891 38.3107 94.5417 38.3107 98.5387C38.3069 102.536 35.0581 105.788 31.0611 105.788ZM31.0611 92.0644C27.4906 92.0644 24.5869 94.9681 24.5869 98.5387C24.5869 102.109 27.4906 105.013 31.0611 105.013C34.6317 105.013 37.5354 102.109 37.5354 98.5387C37.5354 94.9681 34.6278 92.0644 31.0611 92.0644Z"
        fill="#4A5296"
      />
      <path
        d="M90.9693 105.529C86.9724 105.529 83.7197 102.276 83.7197 98.2789C83.7197 94.2819 86.9724 91.0293 90.9693 91.0293C94.9663 91.0293 98.2189 94.2819 98.2189 98.2789C98.2151 102.28 94.9663 105.529 90.9693 105.529ZM90.9693 91.8085C87.3988 91.8085 84.4951 94.7123 84.4951 98.2828C84.4951 101.853 87.3988 104.757 90.9693 104.757C94.5399 104.757 97.4436 101.853 97.4436 98.2828C97.4436 94.7123 94.536 91.8085 90.9693 91.8085Z"
        fill="#4A5296"
      />
      <path
        d="M45.808 68.1832C45.7925 68.5554 41.3769 85.6637 41.6211 85.9467C41.908 86.2801 42.3732 86.3848 42.8074 86.4701C45.1218 86.9198 48.9405 87.5943 49.1537 87.478C49.398 87.3462 59.6831 60.6894 60.7066 47.0392C60.7066 46.2406 50.5998 46.3491 49.1692 47.1283C45.9825 48.8574 46.1531 60.4645 45.808 68.1832Z"
        fill="#F24949"
      />
      <path
        d="M59.4234 71.1603C59.6172 74.0563 60.8694 87.4623 61.1175 87.4739C63.3932 87.6057 65.8899 87.9275 68.0725 87.1754C68.1345 87.156 69.5147 76.4599 68.0415 68.1364C66.1612 57.5373 60.9857 48.2989 60.7648 46.88C60.7299 46.6668 52.5537 51.7958 52.7475 55.1802C52.8871 57.6187 59.3381 69.9159 59.4234 71.1603Z"
        fill="#F24949"
      />
      <path
        d="M58.4352 29.1012C58.4391 28.4654 56.9349 27.7017 56.3999 27.3605C54.9384 26.4301 53.1628 26.1587 51.4415 25.9687C50.732 25.8912 50.0032 25.8253 49.3131 26.0191C47.8128 26.4378 46.9095 27.9226 46.1613 29.2873C45.8046 29.9425 45.4363 30.6364 45.4441 31.3807C45.4441 31.6211 42.5714 32.6368 40.9315 36.5912C40.3228 38.0605 40.1019 41.0223 40.5671 42.5459C41.0982 44.2905 42.8272 45.3333 44.4206 46.2172C44.5369 46.2793 44.6687 46.3452 44.7928 46.3103C44.9091 46.2754 44.9827 46.163 45.0487 46.0583C47.0491 42.7669 49.0534 39.4755 51.0538 36.1841C52.4688 33.8541 53.9188 31.4854 55.9657 29.6827"
        fill="#9DA6D9"
      />
      <path
        d="M40.9308 97.8834C41.0859 98.0269 57.7833 97.8524 57.7833 97.8524C58.3066 97.0305 58.0973 96.2087 57.508 95.4372C56.9187 94.6657 56.0155 94.1927 55.1044 93.8516C53.1815 93.1344 49.8397 92.8785 48.5061 91.5681C48.3123 91.3782 48.8395 88.6411 49.1535 87.4781C44.3502 86.7066 41.6209 85.9468 41.6209 85.9468C40.3493 90.8974 40.7835 97.7477 40.9308 97.8834Z"
        fill="#E2D1CA"
      />
      <path
        d="M62.0602 97.8098C62.2153 97.9533 77.6992 97.8215 77.6992 97.8215C78.2226 96.9996 78.0132 96.1777 77.424 95.4062C76.8347 94.6347 75.9314 94.1618 75.0203 93.8206C73.0975 93.1034 69.7557 92.8475 68.422 91.5372C68.2282 91.3472 68.0731 87.1758 68.0731 87.1758C63.7001 87.9473 61.1182 87.4743 61.1182 87.4743C61.2112 92.5529 61.9129 97.6703 62.0602 97.8098Z"
        fill="#E2D1CA"
      />
      <path
        d="M69.542 45.1782C71.9766 44.682 75.0509 45.7132 77.1095 47.1088C78.0089 47.7214 78.0671 49.3845 77.7957 50.218C77.2723 51.8385 76.1248 52.8969 74.5818 51.699C74.388 51.5478 75.0936 50.1676 74.6671 49.8536C73.3684 48.896 72.5659 48.5045 70.1003 49.4233C69.9529 49.4775 70.2902 49.2139 70.1623 49.1248C70.0537 49.0511 70.0111 48.9154 69.9801 48.7914C69.7746 47.9656 69.8134 47.0817 70.0925 46.2792"
        fill="#DBA9A2"
      />
      <path
        d="M61.9005 48.7837C61.8501 48.9697 61.792 49.1675 61.6447 49.2993C61.4663 49.4582 61.2027 49.4699 60.9623 49.4699C56.2404 49.4854 51.5185 48.3688 47.3005 46.2405C47.0834 46.1319 46.8547 46.0079 46.7306 45.7985C46.5639 45.5194 46.6182 45.1666 46.6841 44.8448C47.1726 42.3521 47.6611 39.8476 48.5372 37.4634C49.4134 35.0792 50.7005 32.8035 52.573 31.09C53.6972 30.0626 55.0502 29.2407 56.5467 28.9616C57.8687 28.7174 59.493 28.9306 60.5941 29.7641C61.54 30.4813 61.7222 31.8614 61.9975 32.9508C62.831 36.2383 63.1256 39.6616 62.8736 43.0421C62.7263 44.9844 62.4045 46.9073 61.9005 48.7837Z"
        fill="#565EA3"
      />
      <path
        d="M66.9526 18.8356C67.348 20.2467 68.4335 21.4485 69.7982 21.9796C70.1044 22.0998 70.4223 22.189 70.7402 22.282C71.2442 22.4332 71.2093 23.1582 70.6937 23.2629C70.2518 23.352 69.7943 23.3637 69.3523 23.2978C69.1585 24.3367 68.9569 25.3951 68.4607 26.3294C67.8481 27.4847 66.8169 28.3725 65.6733 29.0044C64.5296 29.6363 63.2696 30.024 62.0097 30.3652C61.7189 30.4427 61.4282 30.5202 61.1297 30.5474C59.8968 30.6559 58.7687 29.9077 57.7568 29.1944C58.9354 28.4151 59.3192 26.6551 59.5751 25.2633C59.8309 23.8715 60.0403 22.7317 60.6063 21.4369C61.0482 20.4251 61.8081 19.5373 62.785 19.0255C63.7659 18.5138 64.9638 18.4052 65.9873 18.8201"
        fill="#DBA9A2"
      />
      <path
        d="M56.4346 21.1461C56.4927 23.4256 57.2371 25.7749 59.2297 26.8798C60.637 25.3214 62.0365 23.0883 62.0443 20.9871C62.0443 20.7584 62.0365 20.5219 61.9474 20.3125C61.7458 19.8279 61.203 19.5915 60.699 19.4519C60.009 19.2581 59.2801 19.1534 58.5746 19.2813C57.869 19.4131 57.1905 19.8086 56.8378 20.4288"
        fill="#F24949"
      />
      <path
        d="M61.4361 29.3297C60.7073 28.1046 60.5018 26.2554 60.622 24.9683L61.3934 25.0419C61.2888 26.1701 61.4632 27.8681 62.099 28.9381L61.4361 29.3297Z"
        fill="#4A5296"
      />
      <path
        d="M61.0639 25.1973C58.5866 25.3368 58.2726 22.2625 58.2649 21.3515C58.2649 21.3515 55.9814 21.8012 55.9814 21.2856C55.9814 20.0838 56.0629 18.2423 57.2685 16.8467C58.2959 15.6565 59.897 15.1913 62.9907 15.0129C68.6198 14.6912 68.8951 20.4831 68.6043 20.5219C67.1272 20.7351 62.8628 21.0879 62.8628 21.0879C62.4479 24.5654 61.7695 25.1546 61.0639 25.1973Z"
        fill="#4A5296"
      />
      <path
        d="M72.9927 52.7033C71.8995 52.7033 71.0078 51.8155 71.0078 50.7223C71.0078 49.629 71.8956 48.7412 72.9927 48.7412C74.0899 48.7412 74.9738 49.629 74.9738 50.7223C74.9738 51.8155 74.086 52.7033 72.9927 52.7033ZM72.9927 49.5166C72.3259 49.5166 71.7832 50.0593 71.7832 50.7223C71.7832 51.3852 72.3259 51.9279 72.9927 51.9279C73.6595 51.9279 74.1984 51.3852 74.1984 50.7223C74.1984 50.0593 73.6595 49.5166 72.9927 49.5166Z"
        fill="#4A5296"
      />
      <path
        d="M57 28.884C57.3257 28.4381 59.0702 26.7362 59.2292 26.8796C60.5473 28.0698 63.2068 29.481 64.7924 30.0625C64.9009 30.1013 63.1215 33.792 62.1561 35.5598"
        fill="#565EA3"
      />
      <path
        d="M46.068 46.1165C45.8586 46.1165 45.7191 46.0971 45.6687 46.07L45.374 45.9033L45.4903 45.5738C45.5679 45.3761 45.7927 45.2753 45.9943 45.3567C46.0021 45.3606 46.0137 45.3644 46.0214 45.3683C46.8162 45.4187 52.7438 44.7054 56.388 39.9059C57.8999 37.9132 60.9006 32.8811 56.0623 29.1478C55.8918 29.016 55.8608 28.7717 55.9926 28.605C56.1244 28.4345 56.3686 28.4034 56.5353 28.5353C60.2726 31.4196 60.447 35.843 57.0044 40.375C53.4261 45.0892 47.6652 46.1126 46.068 46.1165Z"
        fill="#9DA6D9"
      />
      <path
        d="M69.3519 44.5C69.3519 44.5 68.8751 43.3718 67.5453 43.3253C66.2621 43.2788 51.5846 44.5038 46.8743 44.7481C50.4487 42.9415 55.0776 40.5146 57.9192 36.0485C59.2684 33.9279 57.9192 29.551 54.7597 30.1985C53.2128 30.5125 51.286 31.4972 49.8788 32.1717C48.1032 33.0246 46.378 33.99 44.7226 35.06C41.6522 37.041 38.702 39.5338 37.2986 42.9143C36.8101 44.0929 36.5232 45.3916 36.7364 46.6477C36.969 48.024 39.0354 51.4627 44.7536 53.277C47.921 54.2811 51.3442 54.0252 54.655 53.719C58.6597 53.3468 65.1262 51.8853 68.6851 50.005C68.6851 50.005 69.5419 50.1446 70.5925 50.1136C70.9995 50.1019 71.2981 46.1476 71.2554 45.0543C71.2515 44.8838 70.4646 44.686 69.3519 44.5Z"
        fill="#4A5296"
      />
      <path
        d="M66.2855 22.3556C66.4054 22.3556 66.5026 22.2584 66.5026 22.1385C66.5026 22.0186 66.4054 21.9214 66.2855 21.9214C66.1656 21.9214 66.0684 22.0186 66.0684 22.1385C66.0684 22.2584 66.1656 22.3556 66.2855 22.3556Z"
        fill="#4A5296"
      />
      <path
        d="M68.8752 25.5269C68.8636 25.5269 68.8481 25.5269 68.8365 25.5231C68.0185 25.3602 67.2237 25.1858 66.4561 24.8873C65.5838 24.5461 64.8783 24.0887 64.3627 23.5188C64.2929 23.4412 64.2967 23.3172 64.3782 23.2435C64.4596 23.1737 64.5798 23.1776 64.6534 23.259C65.1264 23.7824 65.7816 24.2088 66.5996 24.5267C67.34 24.8136 68.1193 24.9803 68.9179 25.1431C69.0226 25.1625 69.0923 25.2672 69.0691 25.3719C69.0458 25.4649 68.9644 25.5269 68.8752 25.5269Z"
        fill="#4A5296"
      />
      <path
        d="M54.9032 21.3901C53.934 20.7892 52.3406 20.3278 51.2822 20.2348C49.2469 20.0565 47.1922 20.5605 45.343 21.4754C44.5327 21.739 43.7264 22.0414 42.889 22.1306C41.629 22.2662 40.3846 21.9057 39.1711 21.5413C37.9616 21.173 36.7249 20.7931 35.461 20.8822C34.2011 20.9714 32.8868 21.6305 32.3092 22.8555C31.8905 23.7433 31.9176 24.8211 32.2666 25.7321C32.6116 26.6393 33.2551 27.3914 33.9917 27.9613C36.5465 29.9345 40.2256 29.7368 42.9743 27.9845C44.4087 27.0696 47.01 24.0147 51.2318 23.1967C51.856 23.0765 52.5034 23.0339 53.1315 23.1075C54.7907 23.3052 57.2448 24.0922 57.6324 23.879C58.3109 23.5107 56.2678 22.243 54.9032 21.3901Z"
        fill="#F24949"
      />
      <path
        d="M48.7236 89.7651C48.7158 89.7651 48.708 89.7651 48.7003 89.7651C47.735 89.707 46.758 89.6101 45.8004 89.4744C45.5872 89.4434 45.4399 89.2495 45.4709 89.0363C45.5019 88.8231 45.6996 88.6757 45.909 88.7068C46.8472 88.8386 47.8009 88.9355 48.7468 88.9936C48.96 89.0053 49.1229 89.1914 49.1112 89.4046C49.0996 89.6101 48.929 89.7651 48.7236 89.7651Z"
        fill="#DBA9A2"
      />
      <path
        d="M46.4906 92.5642C46.32 92.5642 46.1611 92.4518 46.1146 92.2773C46.0603 92.0719 46.1805 91.8587 46.3898 91.8044C47.045 91.6299 47.6924 91.3973 48.3127 91.1182C48.5065 91.029 48.7392 91.1182 48.8244 91.312C48.9136 91.5059 48.8244 91.7385 48.6306 91.8238C47.9716 92.1184 47.2854 92.3626 46.5914 92.5487C46.5604 92.5604 46.5255 92.5642 46.4906 92.5642Z"
        fill="#DBA9A2"
      />
      <path
        d="M49.5843 94.5106C49.5416 94.5106 49.499 94.5028 49.4563 94.4873C49.2547 94.4175 49.1501 94.1927 49.2198 93.9911C49.4253 93.4095 49.6967 92.8474 50.0262 92.324C50.1425 92.1418 50.379 92.0876 50.5612 92.2039C50.7434 92.3163 50.7977 92.5567 50.6814 92.7389C50.3829 93.2118 50.1348 93.7197 49.9487 94.2469C49.8905 94.4098 49.7432 94.5106 49.5843 94.5106Z"
        fill="#DBA9A2"
      />
      <path
        d="M66.4097 89.7614C66.2934 89.7614 66.1771 89.7576 66.0608 89.7498C65.8476 89.7343 65.6847 89.5482 65.7002 89.335C65.7157 89.1218 65.8941 88.9667 66.1151 88.9745C66.8129 89.0249 67.5223 88.9008 68.162 88.6217C68.3597 88.5364 68.5846 88.6255 68.6737 88.8233C68.759 89.021 68.6699 89.2497 68.4721 89.335C67.8247 89.6141 67.1191 89.7614 66.4097 89.7614Z"
        fill="#DBA9A2"
      />
      <path
        d="M66.6492 92.5874C66.5058 92.5874 66.3662 92.506 66.3003 92.3703C66.2072 92.1765 66.2887 91.9439 66.4786 91.8508C67.1144 91.5446 67.7696 91.2693 68.4325 91.029C68.6341 90.9553 68.8551 91.06 68.9288 91.2616C69.0024 91.4632 68.8977 91.6841 68.6961 91.7578C68.0604 91.9865 67.4284 92.2502 66.8159 92.5448C66.7655 92.5758 66.7074 92.5874 66.6492 92.5874Z"
        fill="#DBA9A2"
      />
      <path
        d="M69.4378 94.212C69.3525 94.212 69.2672 94.1848 69.1935 94.1267C69.0268 93.991 68.9997 93.7468 69.1354 93.5801L70.248 92.2038C70.3837 92.0371 70.628 92.01 70.7947 92.1457C70.9614 92.2813 70.9885 92.5256 70.8528 92.6923L69.7402 94.0685C69.6626 94.1616 69.5502 94.212 69.4378 94.212Z"
        fill="#DBA9A2"
      />
    </svg>
  );
}
