type Interval = number;

export enum ContentTypes {
  situation = 'situation',
  survey = 'survey',
}

export type CampaignContainer = {
  eventTimestamp: string;
  traceId: string;
  ref: string;
  content: CampaignContent[];
};

export enum CampaignType {
  VIDEO = 'VIDEO',
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  HTML = 'HTML',
}

export type CampaignContent = VideoCampaign | ImageCampaign | TextCampaign | HTMLCampaign | TextCampaignInternal;

export type VideoCampaign = {
  type: CampaignType.VIDEO;
  duration: Interval;
  src: string;
};

export type ImageCampaign = {
  type: CampaignType.IMAGE;
  duration: Interval;
  src: string;
};

export type TextCampaign = {
  type: CampaignType.TEXT;
  duration: Interval;
  colorScheme: ColorScheme;
  icon: string;
  iconAnchor: 'LEFT' | 'RIGHT';
  message: TextCampaignMessage;
};

export type HTMLCampaign = {
  type: CampaignType.HTML;
  duration: Interval;
  src: string;
};

export type TextCampaignMessage = Record<'no' | 'en', TextMessage>;

export type TextMessage = {
  title: string;
  paragraphs: string[];
};

export enum ColorScheme {
  INFO = 'INFO',
  ANNOUNCEMENT = 'ANNOUNCEMENT',
  DEVIATION = 'DEVIATION',
}

export function isVideoCampaign(data: CampaignContent): data is VideoCampaign {
  return data.type === CampaignType.VIDEO;
}

export function isImageCampaign(data: CampaignContent): data is ImageCampaign {
  return data.type === CampaignType.IMAGE;
}

export function isTextCampaign(data: CampaignContent): data is TextCampaign {
  return data.type === CampaignType.TEXT;
}

export function isHtmlCampaign(data: CampaignContent): data is HTMLCampaign {
  return data.type === CampaignType.HTML;
}

/* Sepparate languages */
export type TextCampaignInternal = {
  type: CampaignType.TEXT;
  duration: Interval;
  colorScheme: ColorScheme;
  icon: string;
  iconAnchor: 'LEFT' | 'RIGHT';
  message: TextMessage;
};

export function isInternalTextCampaign(data: CampaignContent): data is TextCampaignInternal {
  return data.type === CampaignType.TEXT;
}
