import React from 'react';

interface Props {
  className?: string;
  style?: any;
}

const UArrowIcon = ({ className, style }: Props) => {
  return (
    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={className} style={style}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM8 10C8 8.34315 9.34315 7 11 7C12.6569 7 14 8.34315 14 10L14 15.3787L12.0607 13.4393C11.8654 13.2441 11.5488 13.2441 11.3536 13.4393L10.6464 14.1464C10.4512 14.3417 10.4512 14.6583 10.6464 14.8536L14.6464 18.8536C14.8417 19.0488 15.1583 19.0488 15.3536 18.8536L19.3536 14.8536C19.5488 14.6583 19.5488 14.3417 19.3536 14.1464L18.6464 13.4393C18.4512 13.2441 18.1346 13.2441 17.9393 13.4393L16 15.3787L16 10C16 7.23858 13.7614 5 11 5C8.23858 5 6 7.23858 6 10V16.5C6 16.7761 6.22386 17 6.5 17H7.5C7.77614 17 8 16.7761 8 16.5V10Z"
        fill="#e5e8ff"
      />
    </svg>
  );
};

export default UArrowIcon;
