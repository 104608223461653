import React from 'react';

import BusShelterDepartures from '../BusShelterDepartures';
import BusShelterCarousel from '../BusShelterCarousel';

import { CampaignContent } from '~/types/Campaign';

import './styles.scss';

interface Props {
  departures: DPI.EstimatedCall[];
  activePlaylistItem?: DPI.Situation | CampaignContent;
  activeIndex?: number;
  activeSituationIndex: number;
  boardConfig: DPI.BoardConfig;
  allDeparturesTramOrMetro: boolean;
  playlistCount: number;
  situationCount: number;
}

function BusShelterDisplay(props: Props) {
  const {
    departures,
    activePlaylistItem,
    activeIndex,
    boardConfig,
    allDeparturesTramOrMetro,
    playlistCount,
    situationCount,
    activeSituationIndex,
  } = props;

  return (
    <div className="bus-shelter-display">
      <BusShelterDepartures
        departures={departures}
        activeSituation={activePlaylistItem}
        boardConfig={boardConfig}
        allDeparturesTramOrMetro={allDeparturesTramOrMetro}
      />
      <BusShelterCarousel
        currentPlaylistItem={activePlaylistItem}
        activeIndex={activeIndex}
        playlistCount={playlistCount}
        situationCount={situationCount}
        activeSituationIndex={activeSituationIndex}
      />
    </div>
  );
}

export default BusShelterDisplay;
