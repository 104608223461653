type MockResponse = {
  departures: DPI.BoardDeparture[];
  firstDepartures: DPI.FirstEstimatedCall[];
};

export const fetchMockData = async (screenConfigId: string): Promise<MockResponse> => {
  const departuresData = await import(`./data/${screenConfigId}.json`).then((module) => module.default);

  if (departuresData) {
    const [departures, firstDepartures] = departuresData.data;
    return {
      departures,
      firstDepartures,
    };
  }

  console.log('Unable to find departures or config for screen ' + screenConfigId);
  return {
    departures: [],
    firstDepartures: [],
  };
};

export const fetchMockConfig = async (screenConfigId: string): Promise<[DPI.ScreenConfig, DPI.PTAColor[], DPI.EnturAuthority]> => {
  const ptaData = await import(`./mock-pta.json`).then((module) => module.default) as { colors: DPI.PTAColor[], enturAuthority: DPI.EnturAuthority };
  const data = await import(`./configs/${screenConfigId}.json`).then((module) => module.default);

  return [data.config, ptaData.colors, ptaData.enturAuthority];
};
