import React from 'react';

interface Props {
  className?: string;
}
export default function HighFive({ className = '' }: Props) {
  const clipId = `clip_${Math.random()}`;
  return (
    <svg className={className} viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath={`url(#${clipId})`}>
        <path
          d="M88.2622 58.482C84.192 53.5561 81.4649 47.6815 78.7861 41.8776C77.9155 39.995 75.2182 35.6421 75.1773 35.2738C74.8052 31.9253 76.5687 29.9944 76.6655 25.9726C76.6766 25.5187 76.3381 24.8564 74.8052 24.8564C73.6891 24.8564 72.852 26.0247 72.1562 27.7138C71.2522 29.9051 70.3407 32.1374 70.1435 34.4999C69.9947 36.3118 70.3034 38.3357 70.7127 40.1104C71.5684 43.8308 72.9487 47.1979 74.422 50.7211C76.3492 55.3345 78.4029 59.9107 80.9812 64.193C81.517 65.0784 82.0825 65.9676 82.86 66.6522"
          fill="#565EA3"
        />
        <path
          d="M79.2656 62.8053C81.0812 66.3286 84.411 71.4777 86.7066 74.7108C87.0563 75.2056 84.8128 80.7975 83.7302 85.1281C82.614 89.5927 89.1509 106.279 89.6829 106.335C97.4215 107.172 113.866 103.73 113.866 101.498C113.866 95.311 109.71 90.5972 108.326 84.57C107.493 80.95 106.485 77.304 104.502 74.1602C103.452 72.4971 102.176 72.7799 100.848 71.3624C96.8188 67.069 91.5692 62.1393 88.1985 57.2246"
          fill="#9DA6D9"
        />
        <path
          d="M101.009 61.6446C99.4349 59.3714 92.6636 57.9688 92.6636 57.9688C90.8034 59.4569 89.1069 61.3544 86.6253 62.1059C86.0784 62.2696 86.0189 63.0398 86.536 63.2779C86.9229 63.4564 87.3322 63.5755 87.7563 63.6313C87.6335 64.2563 87.6373 64.8925 87.734 65.5213C88.5153 65.7445 89.3971 65.5734 90.156 65.2386C91.0452 64.8479 91.834 64.2563 92.7046 63.8285C92.8459 63.7615 92.991 63.6946 93.1473 63.6871C93.4821 63.676 93.776 63.9513 93.8728 64.2675C93.9695 64.5875 93.9063 64.9335 93.7984 65.246C93.4486 66.2542 92.6413 67.0616 91.6963 67.5564C90.8071 68.0215 89.8026 68.2298 88.7981 68.3079C88.8315 68.3637 88.8613 68.4196 88.8948 68.4716C90.0593 70.2426 91.7075 71.3587 93.4077 71.3587C94.1518 71.3587 94.1369 74.2272 94.8959 74.3351C97.5002 74.7071 99.5353 72.925 100.849 71.3587C101.012 71.1653 99.9706 69.6808 100.105 69.4985C101.742 67.2774 102.582 63.9178 101.009 61.6446Z"
          fill="#565EA3"
        />
        <path
          d="M92.1128 58.9994C94.4456 59.4086 96.5699 60.8782 97.7754 62.9133C97.8126 62.9728 97.8498 63.0398 97.913 63.0696C97.9875 63.1031 98.0767 63.0807 98.1586 63.0658C98.858 62.9282 99.6096 63.4379 99.7882 64.1299C99.9667 64.8219 99.5984 65.5995 98.9734 65.9492C98.8878 65.9976 98.7874 66.0459 98.7502 66.139C98.7167 66.2208 98.739 66.3138 98.765 66.3994C99.0961 67.5304 99.4756 68.6503 99.9035 69.7478C100.681 69.2753 104.562 66.9017 103.445 61.693C103.103 60.0969 100.469 57.6005 98.6088 56.8564C92.5742 54.4418 91.9491 57.6935 91.8338 58.0767C91.7185 58.4599 91.871 58.9287 92.2282 59.1073"
          fill="#F24949"
        />
        <path
          d="M112.731 79.6963C117.586 83.64 119.431 89.6188 119.711 90.7275C121.679 98.5219 113.122 98.8939 109.401 98.5219C105.528 98.1349 82.3012 94.6377 81.4976 94.4294C78.9267 93.7634 75.7346 96.9295 73.0856 97.1118C72.7098 97.1379 72.308 97.1155 72.0067 96.8923C71.3928 96.4384 71.5453 95.486 71.8206 94.7716C72.881 92.0259 74.9347 89.563 77.6953 88.5399C79.8271 87.7474 82.171 87.859 84.4405 87.9818C92.8487 88.432 100.993 88.7742 109.405 89.2244C108.94 88.6589 108.743 88.2013 108.278 87.6358C110.287 85.5895 111.682 82.948 112.247 80.1353"
          fill="#565EA3"
        />
        <path
          d="M96.5885 56.8266C96.0751 55.9969 95.8928 54.9701 96.0862 54.0139C96.1234 53.8205 96.1792 53.627 96.2946 53.4633C96.5327 53.121 96.9754 52.9871 97.3884 52.935C97.753 52.8903 98.1436 52.8941 98.4673 53.0727C98.9361 53.3331 99.1482 53.9209 99.1035 54.4567C99.0552 54.9924 98.7947 55.4835 98.5083 55.9374C98.2218 56.3876 97.8944 56.8266 97.4628 57.1354C97.526 57.0908 97.5893 57.0461 97.6488 56.9977"
          fill="#F24949"
        />
        <path
          d="M100.678 58.527C100.86 57.9913 101.042 57.4518 101.221 56.9161C101.344 56.5477 101.474 56.1757 101.705 55.8632C101.939 55.5544 102.296 55.3126 102.683 55.32C103.003 55.3274 103.293 55.4986 103.539 55.7032C104.037 56.1236 104.398 56.7114 104.547 57.3476C104.621 57.6676 104.636 58.021 104.473 58.3038C104.354 58.5084 104.16 58.6498 103.963 58.7763C103.271 59.2265 102.504 59.565 101.705 59.7697C101.675 59.7771 101.638 59.7771 101.626 59.7473C101.615 59.7176 101.679 59.7064 101.667 59.7362"
          fill="#F24949"
        />
        <path
          d="M102.85 62.5931C103.49 62.3699 104.155 62.221 104.829 62.1466C105.093 62.1169 105.387 62.1057 105.606 62.2582C105.837 62.4219 105.923 62.7233 105.979 63.0023C106.168 63.9138 106.243 64.9407 105.707 65.6997C105.562 65.9043 105.365 66.0866 105.119 66.1387C104.952 66.1759 104.777 66.1461 104.609 66.1015C103.969 65.9341 103.389 65.5397 102.995 65.0039"
          fill="#F24949"
        />
        <path
          d="M115.726 82.5237C109.774 75.4548 105.138 73.1146 100.844 71.3623L96.0078 82.8958L109.401 89.2206C111.95 86.8469 116.106 82.9776 115.726 82.5237Z"
          fill="#9DA6D9"
        />
        <path
          d="M90.6138 62.4336C90.7165 62.4336 90.7998 62.3503 90.7998 62.2475C90.7998 62.1448 90.7165 62.0615 90.6138 62.0615C90.511 62.0615 90.4277 62.1448 90.4277 62.2475C90.4277 62.3503 90.511 62.4336 90.6138 62.4336Z"
          fill="#E5E8FF"
        />
        <path
          d="M62.0177 40.6091C62.9887 38.9088 63.9895 37.1044 63.9858 35.1474C63.9821 34.0834 63.6844 33.0454 63.3831 32.0259C62.9403 30.5154 62.4976 29.0049 62.0586 27.4907C61.8837 26.8917 61.7089 26.2778 61.7721 25.6602C61.8354 25.0426 62.2 24.4101 62.7952 24.2241C63.2752 24.0753 63.8109 24.2427 64.2165 24.5441C64.622 24.8454 64.9196 25.2621 65.2098 25.6714C66.7129 27.7883 68.242 29.9722 68.8931 32.4836C69.0717 33.1681 69.1795 33.8787 69.1237 34.5856C69.0642 35.2962 68.8373 35.9808 68.6103 36.6579C67.6728 39.4557 66.7352 42.2572 65.7976 45.055C64.1197 44.1175 62.4455 43.1799 60.7676 42.2461"
          fill="#DBA9A2"
        />
        <path
          d="M40.1755 72.058C48.7735 62.8722 53.465 49.1846 61.784 39.7383C62.1188 39.3588 65.8244 42.205 66.9927 42.7147C65.1994 57.1538 57.8738 68.505 49.8785 80.6635C49.8785 80.6635 52.8958 104.285 52.1108 104.474C39.8332 107.451 31.6817 106.558 25.5318 103.689C24.2631 103.098 22.9163 102.283 22.5479 100.933C22.3954 100.375 22.4326 99.7867 22.4735 99.21C23.0018 91.9997 24.2742 84.849 26.261 77.8992C26.5661 76.8314 22.7116 73.6727 23.4632 72.8542C26.287 69.7848 36.3286 74.9563 39.5058 72.2552C39.6323 72.1473 39.796 72.0283 39.9411 72.099C40.09 72.1734 39.9077 72.4673 39.8295 72.3222"
          fill="#F24949"
        />
        <path
          d="M39.4566 75.0827C39.4566 85.5 33.541 86.9584 28.5556 90.6677C26.993 91.8322 25.289 92.8033 23.5181 93.6218C23.0791 93.8227 23.1274 86.2925 22.3424 90.7124C22.2122 91.4379 21.7137 95.7239 21.6467 96.4568C21.5053 97.9971 21.39 99.6564 22.1787 100.988C22.5694 101.651 23.1572 102.175 23.745 102.67C26.3308 104.854 29.4969 106.863 32.8527 106.416C35.6654 106.044 37.9758 104.013 39.7579 101.807C44.6317 95.7834 46.9012 87.7211 45.8855 80.0421C45.5656 77.6126 44.8885 75.1422 43.3184 73.2559C42.485 72.2551 41.3019 71.4143 39.9998 71.4813C38.7013 71.5483 37.4698 72.8802 37.8977 74.1079"
          fill="#9DA6D9"
        />
        <path
          d="M44.025 62.7532C43.9357 62.6304 43.7571 62.6304 43.6046 62.6453C41.5211 62.8276 39.4042 62.5895 37.41 61.957L36.9003 61.5962C35.2782 60.9488 33.4254 61.864 32.1902 63.0992C30.2481 65.0488 29.2213 67.8689 29.4519 70.6109C29.5301 71.5372 29.757 72.4748 30.3076 73.2226C31.0331 74.2048 32.7259 74.2904 33.8718 74.7108C35.5684 75.3284 36.7998 76.2436 38.5745 76.5859C38.9577 73.8663 41.283 71.66 41.4356 68.9143C39.9474 68.892 38.3662 68.6948 37.2091 67.7573C36.9189 67.5229 36.6547 67.2289 36.558 66.8681C36.4613 66.5072 36.5766 66.0719 36.9003 65.8821C37.1086 65.7594 37.3691 65.7594 37.6109 65.778C39.1363 65.8896 40.8142 66.5779 42.0866 65.7333C41.9229 65.3166 41.7592 64.9037 41.5918 64.487C42.2987 64.0926 43.0019 63.6982 43.7088 63.3039C43.9245 63.1885 44.1664 62.9504 44.025 62.7532Z"
          fill="#DBA9A2"
        />
        <path
          d="M35.8923 60.8036C33.5038 62.4331 32.7597 64.2934 32.7188 66.4661C34.4488 65.0672 36.0523 63.5232 37.6558 61.9792C37.6819 61.9569 37.7079 61.9272 37.7042 61.8937C37.7042 61.8676 37.6819 61.8453 37.6632 61.8267C37.1982 61.3356 36.6029 60.971 35.9556 60.7812"
          fill="#9DA6D9"
        />
        <path
          d="M40.2011 72.4785C37.9688 73.9667 35.5393 71.3177 34.0102 69.517C33.5452 68.9701 33.0987 68.3785 32.9164 67.6828C32.6151 66.5444 33.0652 65.3352 33.6865 64.3344L36.1086 60.945C36.209 59.6206 34.8994 58.2663 33.5973 57.9984C32.2951 57.7306 30.8739 58.4561 30.2674 59.6392C29.8135 60.5209 29.3708 61.3617 28.6676 62.0612C28.0017 62.7272 26.8074 62.8053 25.6913 63.9214C24.5454 65.0673 24.2031 66.5258 23.0869 68.0139C22.1122 69.3124 19.7013 71.2619 19.3665 72.8506C19.3218 73.0701 25.3676 72.8654 27.1794 73.2226C29.1922 73.6207 31.6068 74.3387 33.534 75.0456C35.4612 75.7525 37.5968 78.4313 39.0849 78.0592C39.8402 77.8695 40.8559 72.0432 40.2011 72.4785Z"
          fill="#565EA3"
        />
        <path
          d="M38.668 88.4097C38.2401 88.1158 37.6597 88.3204 37.1872 88.5325C34.7912 89.6114 32.3357 90.761 29.7091 90.8429L27.075 91.0475C26.3234 92.2343 25.97 93.6704 26.0891 95.0731C28.991 95.62 32.0455 95.3261 34.7912 94.236C35.833 93.823 36.8412 93.2872 37.6448 92.5059C38.4485 91.7246 39.0288 90.6755 39.0809 89.5593C39.107 89.1352 39.0214 88.6515 38.668 88.4097Z"
          fill="#DBA9A2"
        />
        <path
          d="M29.4115 73.9668C23.3769 72.2963 17.0632 70.9235 10.8054 70.6816C7.73601 70.5626 4.30202 70.7672 2.18135 72.9883C0.0755679 75.1946 -0.724333 78.8034 0.763854 82.8959C4.80428 94.009 26.7476 95.698 27.1792 95.1735C28.4851 93.5811 28.9873 92.3533 29.4115 90.3369C26.063 86.6164 11.6574 81.5491 9.69298 80.2916L27.1792 85.5002"
          fill="#F24949"
        />
        <path
          d="M38.5268 64.6655C38.6296 64.6655 38.7129 64.5822 38.7129 64.4795C38.7129 64.3767 38.6296 64.2935 38.5268 64.2935C38.4241 64.2935 38.3408 64.3767 38.3408 64.4795C38.3408 64.5822 38.4241 64.6655 38.5268 64.6655Z"
          fill="#4A5296"
        />
        <path
          d="M88.4588 80.7453C88.2318 81.188 93.8944 84.3988 96.521 84.9606C96.7443 85.009 96.9824 85.0536 97.1944 84.9718C97.5218 84.849 97.6781 84.4807 97.7897 84.1495C98.6826 81.5341 99.0026 78.8665 99.7281 76.1989C100.561 73.137 100.573 72.9323 100.844 71.3623C97.9869 71.273 97.7488 71.3251 94.8915 71.3623"
          fill="#565EA3"
        />
        <path
          d="M74.3808 20.2805C74.351 20.2805 74.3175 20.2731 74.2878 20.2582C74.1985 20.2061 74.165 20.0945 74.2171 20.0052C74.7714 19.0193 75.4076 18.0706 76.1034 17.1776C76.1666 17.0958 76.2857 17.0809 76.3638 17.1442C76.4456 17.2074 76.4605 17.3227 76.3973 17.4046C75.7127 18.2789 75.0914 19.2165 74.5445 20.1838C74.5073 20.2433 74.444 20.2805 74.3808 20.2805Z"
          fill="#4A5296"
        />
        <path
          d="M68.4845 18.3718C68.3915 18.3718 68.3097 18.3011 68.2985 18.2044C68.1757 16.865 67.9227 15.5294 67.5507 14.2384C67.5209 14.1379 67.5804 14.0375 67.6772 14.0077C67.7776 13.9779 67.8781 14.0375 67.9078 14.1342C68.2873 15.4512 68.544 16.8092 68.6668 18.1709C68.678 18.2714 68.5998 18.3644 68.4994 18.3718C68.4957 18.3718 68.4882 18.3718 68.4845 18.3718Z"
          fill="#4A5296"
        />
        <path
          d="M62.5795 21.0728C62.5423 21.0728 62.5088 21.0617 62.4754 21.0431C61.7015 20.5222 60.8942 20.0385 60.0682 19.5995C59.9789 19.5512 59.9417 19.4395 59.9901 19.3465C60.0384 19.2572 60.1501 19.22 60.2431 19.2684C61.0765 19.7111 61.8987 20.206 62.6837 20.7305C62.7693 20.7864 62.7916 20.9017 62.7358 20.9873C62.6986 21.0468 62.6391 21.0728 62.5795 21.0728Z"
          fill="#4A5296"
        />
      </g>
      <defs>
        <clipPath id={clipId}>
          <rect width="120" height="120" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
