import React, { useMemo } from 'react';

import NoConfigFound from '../NoConfigFound';
import BusShelterHeader from '../BusShelter/BusShelterHeader';
import { BusShelterNoDepartures } from '../BusShelter/BusShelterNoDepartures';
import BusShelterDisplay from '../BusShelter/BusShelterDisplay';
import BusShelterGroup from '../BusShelter/BusShelterGroup';
import { NoDeparturesFallback } from '../Metro/NoDeparturesFallback';
import MetroDisplay from '../Metro/MetroDisplay';

import { getSituationsForBoard, shouldGroupSituations } from '~/utils/situations';
import { getBoardByIndex } from '~/utils/config';
import { isMinutesBefore } from '~/utils/dates';
import usePlaylist from '~/hooks/usePlaylist';
import { isOfflineMode, useStore, useTemplate } from '~/hooks/useStore';

import './styles.scss';

function Display() {
  const { screenConfig, departureDate } = useStore();
  const template = useTemplate();
  const offlineMode = isOfflineMode();

  const { activePlaylistItem, activeIndex, playlistCount, situationCount, activeSituationIndex } = usePlaylist();

  const noDepartures = departureDate.departures.every((departureList) =>
    departureList.entries.every((departure) => departure.isEmpty)
  );

  const isDataOld = offlineMode && isMinutesBefore(departureDate.fetchDate, 10);

  if (!screenConfig) {
    return <NoConfigFound />;
  }

  const allDeparturesTramOrMetro = departureDate.departures.every((dps) =>
    dps.entries.filter((dps) => !dps.isEmpty).every((d) => d.transportMode === 'metro' || d.transportMode === 'tram')
  );

  if (template === 'BUS_SHELTER_DEFAULT') {
    const situationsByBoard = departureDate.departures.map((d) => getSituationsForBoard(d.entries));
    const groupBoardSituations = shouldGroupSituations(situationsByBoard);

    // Data is too old, do not show any departures
    if (isDataOld) {
      return <BusShelterNoDepartures screenConfig={screenConfig} firstDepartures={[]} unableToFetch={offlineMode} />;
    }

    if (noDepartures) {
      return (
        <BusShelterNoDepartures
          screenConfig={screenConfig}
          firstDepartures={departureDate.firstDepartures}
          unableToFetch={offlineMode}
        />
      );
    }

    if (groupBoardSituations) {
      return (
        <BusShelterGroup
          activePlaylistItem={activePlaylistItem}
          activeIndex={activeIndex}
          departures={departureDate.departures}
          screenConfig={screenConfig}
          allDeparturesTramOrMetro={allDeparturesTramOrMetro}
          playlistCount={playlistCount}
          situationCount={situationCount}
          activeSituationIndex={activeSituationIndex}
        />
      );
    }
    return (
      <div className="busShelterBoards">
        {departureDate.departures.map((boardDepartures, index) => {
          const boardConfig = getBoardByIndex(screenConfig, index);
          const hideClock = departureDate.departures.length > 1 && index > 0;
          const largeHeader = boardConfig?.header?.large ?? false;

          if (!boardConfig) {
            return;
          }

          return (
            <div
              className="busShelterBoard"
              key={`busShelterBoard-${index}`}
              style={{ paddingTop: index > 0 ? '1.75rem' : 0 }}
            >
              <BusShelterHeader
                boardConfig={boardConfig}
                hideClock={hideClock}
                largeHeader={largeHeader}
                departures={boardDepartures}
              />
              <BusShelterDisplay
                departures={boardDepartures.entries}
                boardConfig={boardConfig}
                allDeparturesTramOrMetro={allDeparturesTramOrMetro}
                activePlaylistItem={activePlaylistItem}
                activeIndex={activeIndex}
                playlistCount={playlistCount}
                situationCount={situationCount}
                activeSituationIndex={activeSituationIndex}
              />
            </div>
          );
        })}
      </div>
    );
  } else {
    // Data is too old, do not show any departures
    if (isDataOld) {
      return <NoDeparturesFallback screenConfig={screenConfig} firstDepartures={[]} unableToFetch={offlineMode} />;
    }

    if (noDepartures) {
      return (
        <NoDeparturesFallback
          screenConfig={screenConfig}
          firstDepartures={departureDate.firstDepartures}
          unableToFetch={offlineMode}
        />
      );
    }

    return (
      <MetroDisplay
        departures={departureDate.departures}
        screenConfig={screenConfig}
        allDeparturesTramOrMetro={allDeparturesTramOrMetro}
        activePlaylistItem={activePlaylistItem}
        situationCount={situationCount}
        activeSituationIndex={activeSituationIndex}
      />
    );
  }
}

export default Display;
