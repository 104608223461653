import React from 'react';
import cx from 'classnames';

import Clock from '../../Clock';
import MetroIcon from '../../Icons/MetroIcon';
import BusIcon from '../../Icons/BusIcon';
import TramIcon from '../../Icons/TramIcon';
import BoatIcon from '../../Icons/BoatIcon';

import { getTrackColumnLabels } from '~/utils/columns';

import './styles.scss';

export const getHeaderIcon = (stopPlaceType: DPI.IconType, className: string, key: any) => {
  const iconClassName = stopPlaceType + ' ' + className;
  switch (stopPlaceType) {
    case 'metro':
      return <MetroIcon className={iconClassName} key={key} />;
    case 'bus':
      return <BusIcon className={iconClassName} key={key} />;
    case 'tram':
      return <TramIcon className={iconClassName} key={key} />;
    case 'water':
      return <BoatIcon className={iconClassName} key={key} />;
    default:
      return null;
  }
};

const emptyDepartures = (): DPI.BoardDeparture => ({ entries: [], name: '', zones: [] });

interface Props {
  boardConfig: DPI.BoardConfig;
  hideClock: boolean;
  largeHeader: boolean;
  departures?: DPI.BoardDeparture;
  hideColumns?: boolean;
  fullBottomBoarder?: boolean;
}

function BusShelterHeader(props: Props) {
  const {
    boardConfig,
    hideClock,
    largeHeader,
    departures = emptyDepartures(),
    hideColumns = false,
    fullBottomBoarder = false,
  } = props;
  const { header } = boardConfig;
  const transportMode = departures.entries?.[0]?.transportMode ?? 'bus';
  const hasDelayOrCancelation = departures.entries.some((departure) => departure.delayed || departure.cancellation);
  const showPlannedTime = boardConfig.hideDelay !== true && hasDelayOrCancelation;
  const [norwegianTrackLabel, englishTrackLabel] = getTrackColumnLabels(transportMode);

  const overrideHeader = header?.override ?? false;
  const norwegianTitle = overrideHeader ? header?.label?.no ?? '' : departures.name;
  const englishTitle = overrideHeader ? header?.label?.en ?? '' : '';
  const stopPlaceTypeIcons = header?.stopPlaceTypeIcons ?? [];
  const showTracks = !boardConfig?.hideTracks;

  return (
    <div
      className={cx('busShelterHeader', {
        '-large': largeHeader,
        '-fullBottomBoarder': fullBottomBoarder,
      })}
    >
      <div className="left">
        <div className={cx('stopPlaceInfo', { 'with-tracks': showTracks, 'with-delay': showPlannedTime })}>
          <div className="stopPlaceIconHolder">
            {stopPlaceTypeIcons.map((spt, index) => (
              <div className={cx('stopPlaceTypeIcon', { '-large': largeHeader })} key={`${spt}-${index}-icon`}>
                {getHeaderIcon(spt, cx('stopPlaceIcon', { '-large': largeHeader }), `${spt}-${index}`)}
              </div>
            ))}
          </div>
          <div
            className={cx('titleHolder', `length-${stopPlaceTypeIcons.length}-icons`, {
              '-large': largeHeader,
            })}
          >
            <span className="norwegian">{norwegianTitle}</span>
            <span className={cx('english')}>{englishTitle}</span>
          </div>
        </div>
        {!hideColumns && (
          <>
            {showPlannedTime && (
              <div
                className={cx('plannedTitles', {
                  '-large': largeHeader,
                  '-largeShowingTracks': largeHeader && showTracks,
                })}
              >
                <span className="norwegian">Rutetid</span>
                <span className="english">Schedule</span>
              </div>
            )}
            <div
              className={cx('departureTitles', {
                '-large': largeHeader,
                '-largeShowingTracks': largeHeader && showTracks,
              })}
            >
              <span className="norwegian">Forventet</span>
              <span className="english">Expected</span>
            </div>
            {showTracks && (
              <div className={cx('trackTitles', { '-large': largeHeader })}>
                <div className="flex">
                  <span className="norwegian">{norwegianTrackLabel}</span>
                  <span className="english">{englishTrackLabel}</span>
                </div>
              </div>
            )}
            <div className="situationSpace"> </div>
          </>
        )}
      </div>
      <div className="right">
        {!hideClock && (
          <div className="clockHeader">
            <Clock sizeVariant={largeHeader ? 'shelter-medium' : 'xsmall'} className="content" />
          </div>
        )}
      </div>
    </div>
  );
}

export default BusShelterHeader;
