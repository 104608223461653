import React from 'react';

interface Props {
  className?: string;
}
export default function MetroIcon({ className }: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={className}>
      <path
        d="M16 9.94507C16.2761 9.94507 16.5 9.72121 16.5 9.44507V8.57007C16.5 8.29393 16.2761 8.07007 16 8.07007H8C7.72386 8.07007 7.5 8.29393 7.5 8.57007V9.44507C7.5 9.72121 7.72386 9.94507 8 9.94507H11.07V17.5001C11.07 17.7762 11.2939 18.0001 11.57 18.0001H12.445C12.7211 18.0001 12.945 17.7762 12.945 17.5001V9.94507H16Z"
        fill="#e5e8ff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 20.5C16.6944 20.5 20.5 16.6944 20.5 12C20.5 7.30558 16.6944 3.5 12 3.5C7.30558 3.5 3.5 7.30558 3.5 12C3.5 16.6944 7.30558 20.5 12 20.5ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        fill="#e5e8ff"
      />
    </svg>
  );
}
