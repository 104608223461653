import { getLangString, LANGUAGES } from './languages';

const isServerityIncluded = (severity: string, included: string[] = []) => {
  if (!included.length) {
    return true;
  }
  return included.includes(severity);
};

export const getUniqueSituations = (
  situations: DPI.Situation[] = [],
  showOnlySeverities: string[] = [],
  serviceJourneyId?: string
) => {
  const uniqueSituationNumbers = new Set();
  return situations
    .filter(
      (situation) =>
        situation &&
        ['incident', 'general'].includes(situation.reportType) &&
        isServerityIncluded(situation.severity, showOnlySeverities)
    )
    .filter((situation) => {
      return getSituationText(situation) !== null;
    })
    .filter((situation) => (serviceJourneyId ? situation.serviceJourneyId === serviceJourneyId : true))
    .filter((current) => {
      const { situationNumber } = current;
      if (!uniqueSituationNumbers.has(situationNumber)) {
        uniqueSituationNumbers.add(situationNumber);
        return true;
      }
      return false;
    });
};

export const getSituationText = (situation?: DPI.Situation | Entur.Situation): string[] | null => {
  if (!situation) {
    return null;
  }

  const { summary, description } = situation;
  const summaryNorwegian = getLangString(summary, LANGUAGES.no) || '';
  const descriptionNorwegian = getLangString(description, LANGUAGES.no) || '';

  if (!summaryNorwegian && !descriptionNorwegian) {
    return null;
  }

  return [summaryNorwegian, descriptionNorwegian];
};

export const getSituationTextSize = (title?: string, body?: string, showFullVersion?: boolean) => {
  if (body) {
    return getSituationIncludingBodyTextSize(title, body, showFullVersion);
  }
  const UPPER_THRESHOLD_LIMITS = {
    large: showFullVersion ? 160 : 50,
    medium: showFullVersion ? 300 : 110,
    small: Number.MAX_SAFE_INTEGER, // We believe 160 is the upper limit
  };

  const titleLength = typeof title === 'string' ? title.length : 0;
  const size = Object.entries(UPPER_THRESHOLD_LIMITS).find(([_, max]) => titleLength <= max);
  if (size) {
    return size[0];
  }
  return 'small';
};

export const getSituationIncludingBodyTextSize = (title?: string, body?: string, showFullVersion?: boolean) => {
  const titleLength = typeof title === 'string' ? title.length : 0;
  const bodyLength = typeof body === 'string' ? body.length : 0;
  const textLength = titleLength + bodyLength;

  const UPPER_THRESHOLD_LIMITS = {
    large: showFullVersion ? 330 : 130,
    medium: showFullVersion ? 400 : 200,
    small: Number.MAX_SAFE_INTEGER, // We believe 160 is the upper limit
  };

  const size = Object.entries(UPPER_THRESHOLD_LIMITS).find(([_, max]) => textLength <= max);
  if (size) {
    return size[0];
  }
  return 'small';
};

export const getSituationsForBoard = (departures: DPI.EstimatedCall[]): DPI.Situation[] => {
  const situations = departures
    .map((d) => d.situations)
    .reduce((tail, item) => [...tail, ...(item || [])], []) as DPI.Situation[];
  return getUniqueSituations(situations).sort((a, b) => b.situationNumber.localeCompare(a.situationNumber));
};

export const getSituationsForAllBoards = (departures: DPI.BoardDeparture[]) => {
  const allDepartures = departures.reduce<DPI.EstimatedCall[]>((tail, item) => [...tail, ...(item.entries || [])], []);
  const situations = allDepartures.map((d) => d.situations).reduce((tail, item) => [...tail, ...(item || [])], []);
  return getUniqueSituations(situations).sort((a, b) => b.situationNumber.localeCompare(a.situationNumber));
};

export const shouldGroupSituations = (situationsByBoard: DPI.Situation[][] = []) => {
  const situationIds = situationsByBoard.map((b) =>
    b
      .map((s) => s.situationNumber)
      .slice()
      .sort()
      .join(',')
  );
  if (situationIds.length > 1) {
    return situationIds.every((value, index, ids) => value === ids[0]);
  }
  return false;
};
