import React from 'react';

interface Props {
  className?: string;
}
export default function BoatIcon({ className }: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" width="24px" viewBox="0 0 24 24" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99001 15C7.04001 15 6.01001 15.44 5.10001 15.83C4.52379 16.0792 4.00215 16.3041 3.22819 16.4198C2.88015 16.4718 2.62037 16.1297 2.73188 15.7959L3.8859 12.3416C3.95405 12.1376 4.14504 12 4.36014 12H5.63986C5.85496 12 6.04596 11.8624 6.11411 11.6584L7.44182 7.68321C7.57811 7.27516 7.9601 7.00001 8.39031 7.00001H12V6.30903C12 6.11964 12.107 5.94651 12.2764 5.86181L15.2764 4.36181C15.6089 4.19559 16 4.43733 16 4.80903V6.00001H17.2793C17.7097 6.00001 18.0918 6.27544 18.2279 6.68378L19.7721 11.3162C19.9082 11.7246 20.2903 12 20.7208 12H22.3056C22.6471 12 22.8881 12.3347 22.7798 12.6586L22.0836 14.7402C22.0207 14.9284 21.8519 15.0604 21.655 15.0851C20.5216 15.2275 19.7773 15.5371 19.1 15.83L19.0946 15.8323C18.2569 16.1913 17.5367 16.5 15.99 16.5C14.4433 16.5 13.7231 16.1913 12.8854 15.8323L12.88 15.83C11.98 15.44 10.95 15 8.99001 15ZM17.3063 12C17.6476 12 17.8886 11.6657 17.7806 11.3419L17.114 9.34189C17.0459 9.13772 16.8548 9.00001 16.6396 9.00001H15V12H17.3063ZM14 9.00001H9.36039C9.14517 9.00001 8.95411 9.13772 8.88605 9.34189L8.21938 11.3419C8.11146 11.6657 8.35245 12 8.69372 12H14V9.00001Z"
        fill="#e5e8ff"
      />
      <path
        d="M19.0946 17.8323L19.1 17.83C19.5097 17.6525 19.9427 17.4703 20.4754 17.3216C20.8393 17.2201 21.1449 17.5735 21.0249 17.9318L20.6051 19.1857C20.5576 19.3277 20.4487 19.4405 20.31 19.4969C20.2343 19.5276 20.1581 19.5592 20.0811 19.5911C20.0181 19.6173 19.9545 19.6436 19.89 19.67C18.98 20.06 17.95 20.5 15.99 20.5C14.04 20.5 13.01 20.06 12.1 19.67C11.26 19.31 10.53 19 8.99 19C7.45332 19 6.73311 19.3087 5.89543 19.6677L5.89 19.67C4.98 20.06 3.95 20.5 2 20.5C1.93829 20.5 1.87785 20.4996 1.8186 20.4987C1.49366 20.4941 1.27988 20.1719 1.38311 19.8638L1.7257 18.8412C1.79398 18.6374 1.98502 18.5015 2.19979 18.4931C3.21976 18.4531 4.14341 18.24 5.1 17.83C6.01 17.44 7.04 17 8.99 17C10.95 17 11.98 17.44 12.88 17.83C13.72 18.19 14.45 18.5 15.99 18.5C17.5367 18.5 18.2569 18.1913 19.0946 17.8323Z"
        fill="#e5e8ff"
      />
    </svg>
  );
}
