export const LEGAL_MEDIA_TYPES = ['IMAGE'];

export const isLegalMediaType = (type: string) => {
  if (!type) {
    return false;
  }
  return LEGAL_MEDIA_TYPES.includes(type);
};

// located at public/mediaContent
const MEDIA_CONTENT_PATH = './mediaContent/';

export const MEDIA_SOURCES_MAP = Object.freeze({
  MAP_TOYEN_WESTBOUND: `${MEDIA_CONTENT_PATH}img/toyen_westbound.svg`,
  MAP_TOYEN_EASTBOUND: `${MEDIA_CONTENT_PATH}img/toyen_eastbound.svg`,
  MAP_JERNBANETORGET_EAST: `${MEDIA_CONTENT_PATH}img/jernbanetorget_east.svg`,
  MAP_JERNBANETORGET_WEST: `${MEDIA_CONTENT_PATH}img/jernbanetorget_west.svg`,
});

export const isLegalMediaSrc = (src: string) => {
  if (!src) {
    return false;
  }
  return Object.keys(MEDIA_SOURCES_MAP).includes(src);
};
