export const LANGUAGES: Record<string, DPI.Langauges> = Object.freeze({
  no: 'no',
  en: 'en',
});

export const getLangString = (
  entries: DPI.SituationText[] = [],
  preferredLanguage: DPI.Langauges = LANGUAGES.no
): string | null => {
  for (let i = 0; i < entries.length; i++) {
    if (entries[i].language === preferredLanguage) {
      return entries[i].value;
    }
  }
  if (entries.length > 0) {
    return entries[0].value;
  }
  return null;
};
