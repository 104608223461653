import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import DepartureHeader from '../DepartureHeader';
import LineNumber from '../../Departures/LineNumber';

import { UNABLE_TO_FETCH_DEPARTURES_TEXTS, NO_DEPARTURES_TEXTS, NO_DEPARTURE_SOON } from '~/texts/fallback';

import './styles.scss';

interface UnableToShowDeparturesProps {
  text: DPI.MessageText;
}
const UnableToShowDepartures = ({ text }: UnableToShowDeparturesProps) => {
  const [showEnglishText, setShowEnglishText] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowEnglishText(!showEnglishText);
    }, 10 * 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [showEnglishText]);

  const strings = showEnglishText ? text.en : text.no;

  return (
    <div className={cx('unableToFetchDepartures')}>
      <span className="title">{strings.title}</span>
      <div className="body">
        <div className="weAreSorry">{strings.body}</div>
      </div>
    </div>
  );
};

interface NoDeparturesSoonProps {
  firstDeparture: DPI.FirstEstimatedCall;
  isLast: boolean;
}
const NoDeparturesSoon = ({ firstDeparture, isLast }: NoDeparturesSoonProps) => {
  const [showEnglishText, setShowEnglishText] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowEnglishText(!showEnglishText);
    }, 10 * 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [showEnglishText]);

  const strings = showEnglishText ? NO_DEPARTURE_SOON.en : NO_DEPARTURE_SOON.no;
  return (
    <div className="noDeparturesSoon">
      <div className="firstDeparture">
        <div className="titles">{strings.nextDeparture}</div>
        <div className="destinationWrapper">
          <span className="destination">
            <LineNumber
              lineNumber={firstDeparture.lineNumber}
              transportMode={firstDeparture.transportMode}
              className="nextDepartureLineNumber"
            />
            {firstDeparture.frontText}
          </span>
          <span className="departure">
            - {strings.departureDay(firstDeparture.departureTime)},{' '}
            {strings.getDepartureTime(firstDeparture.departureTime)}
          </span>
        </div>
      </div>
      {isLast && <div className="moreInfoGuidance">{strings.moreInfo}</div>}
    </div>
  );
};

interface NoDeparturesFallbackProps {
  screenConfig: DPI.ScreenConfig;
  firstDepartures: DPI.FirstEstimatedCall[];
  unableToFetch: boolean;
}
export const NoDeparturesFallback = ({
  screenConfig,
  firstDepartures = [],
  unableToFetch,
}: NoDeparturesFallbackProps) => {
  const boardConfigs = screenConfig ? screenConfig.boards : [];
  const padding = typeof screenConfig.padding === 'string' ? screenConfig.padding : '0';

  if (boardConfigs.length > 1) {
    return (
      <div className="noDeparturesFallback -full" style={{ padding }}>
        <UnableToShowDepartures text={unableToFetch ? UNABLE_TO_FETCH_DEPARTURES_TEXTS : NO_DEPARTURES_TEXTS} />
      </div>
    );
  }

  return (
    <div className="noDeparturesFallback" style={{ padding }}>
      {boardConfigs.map((boardConfig, index) => {
        const numberOfDepartures = boardConfig.numberOfDepartures || 4;
        const firstDeparture = firstDepartures.length ? firstDepartures[0] : null;
        return (
          <div key={`board-${index}`} style={{ paddingTop: index > 0 ? '1.75rem' : 0 }}>
            <DepartureHeader
              screenConfig={screenConfig}
              departures={[]}
              index={0}
              hideTrack={screenConfig.hideTracks}
              isFallBackHeader
            />
            <div
              className={cx('fallbackBody', { '-center': !firstDeparture })}
              style={{
                height: `${7.78125 * numberOfDepartures}rem`,
              }}
            >
              {firstDeparture ? (
                <NoDeparturesSoon firstDeparture={firstDeparture} isLast={index === boardConfigs.length - 1} />
              ) : (
                <UnableToShowDepartures text={unableToFetch ? UNABLE_TO_FETCH_DEPARTURES_TEXTS : NO_DEPARTURES_TEXTS} />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
