import { getSecondsToDeparture } from './eta';
import { getSituationText, getUniqueSituations } from './situations';
import { ContentTypes } from '../types/Campaign';
import { getSituationSeveritiesForBoard } from './config';

export const sortDeparturesAsc = (estimatedCalls: DPI.EstimatedCall[]) =>
  estimatedCalls.slice().sort((a, b) => new Date(a.departureTime).getTime() - new Date(b.departureTime).getTime());

export const fillWithEmptyDepartures = (number: number) =>
  new Array(number).fill({
    frontText: '',
    isEmpty: true,
  });

export const groupEstimatedCallsByDestination = (estimatedCalls: DPI.EstimatedCall[] = []) => {
  const uniqueEstimatedCalls: Record<string, DPI.EstimatedCall[]> = {};
  estimatedCalls.forEach((departure) => {
    const destination = `${departure.lineNumber}-${departure.frontText}`;
    uniqueEstimatedCalls[destination] = [...(uniqueEstimatedCalls[destination] || []), departure];
  });

  Object.keys(uniqueEstimatedCalls).forEach((group) => {
    uniqueEstimatedCalls[group] = sortDeparturesAsc(uniqueEstimatedCalls[group]);
  });

  return uniqueEstimatedCalls;
};

export const formatFirstDepartures = (response: Record<string, Entur.Board>) => {
  return Object.values(response)
    .reduce<Entur.Board[]>((tail, current) => {
      const values = Array.isArray(current) ? current : [current];
      return [...tail, ...values];
    }, [])
    .filter((item) => item !== null)
    .map(({ firstDeparture }) => firstDeparture.map((departure) => formatFirstDeparture(departure)))
    .reduce<DPI.FirstEstimatedCall[]>((tail, current) => [...tail, ...current], [])
    .sort((a, b) => Date.parse(a.departureTime) - Date.parse(b.departureTime));
};

export const formatFirstDeparture = (firstDeparture: Entur.FirstEstimatedCall): DPI.FirstEstimatedCall => ({
  lineNumber: firstDeparture?.serviceJourney?.line?.publicCode ?? '',
  authority: firstDeparture?.serviceJourney?.line?.authority?.id || '',
  frontText: firstDeparture?.destinationDisplay?.frontText ?? '',
  cancellation: firstDeparture?.cancellation || false,
  departureTime: (firstDeparture.actualDepartureTime ||
    firstDeparture.expectedDepartureTime ||
    firstDeparture.aimedDepartureTime)!,
  transportMode: (firstDeparture?.serviceJourney?.line?.transportMode ?? 'bus') as DPI.TransportType,
});

export const formatDepartures = (
  response: Record<string, Entur.Board> = {},
  numberOfDeparturesList: number[] = [],
  groupedSituationBoardsList: boolean[] = []
) => {
  const departures: DPI.BoardDeparture[] = [];
  Object.entries(response).forEach(([key, board], index) => {
    const estimatedCalls: Entur.EstimatedCall[] = Array.isArray(board)
      ? board.map((d) => (d ? d.estimatedCalls || [] : [])).reduce((tail, current) => [...current, ...tail], [])
      : board
      ? board.estimatedCalls || []
      : [];

    const numberOfDepartures = numberOfDeparturesList[index] ? numberOfDeparturesList[index] : 5;

    const allowedMultipleSituationsOccurrences = groupedSituationBoardsList[index]
      ? groupedSituationBoardsList[index]
      : false;

    const formatedEstimatedCalls = formatEstimatedCalls(estimatedCalls, allowedMultipleSituationsOccurrences);

    const relevantEstimatedCalls = getRelevantEstimatedCalls(formatedEstimatedCalls, numberOfDepartures);

    const emptyDeparturesCount = numberOfDepartures - relevantEstimatedCalls.length;
    const emptyDepartures = fillWithEmptyDepartures(emptyDeparturesCount);

    departures.push({
      name: getBoardName(Array.isArray(board) ? board : [board]),
      zones: getBoardZones(Array.isArray(board) ? board : [board]),
      entries: [...relevantEstimatedCalls, ...emptyDepartures],
    });
  });

  return departures;
};

const getBoardName = (entries: Entur.Board[]) => {
  // Join all unique names
  return entries
    .map((e) => e.name)
    .filter((name, index, arr) => name && arr.indexOf(name) === index)
    .join(', ');
};

const getBoardZones = (entries: Entur.Board[]) => {
  // Unique zone ids
  const zones = entries.reduce<Entur.TariffZone[]>((sum, ent) => {
    return [...sum, { id: ent?.stopPlace?.id, name: ent?.stopPlace?.id }, ...(ent?.tariffZones || [])];
  }, []);

  const filtered = zones
    .filter((zone) => zone && zone.id)
    .filter((zone, index, arr) => arr.findIndex((it) => it.id === zone.id) === index);

  return filtered;
};

const getRelevantEstimatedCalls = (formattedEstimatedCalls: DPI.EstimatedCall[], numberOfDepartures: number) => {
  if (numberOfDepartures > 6) {
    const estimatedCallsGroups = groupEstimatedCallsByDestination(formattedEstimatedCalls);
    const relevantEstimatedCalls = findRelevantCallsFromGroup(estimatedCallsGroups, numberOfDepartures);
    return relevantEstimatedCalls;
  }

  const sortedFormattedEstimatedCalls = sortDeparturesAsc(formattedEstimatedCalls);
  return sortedFormattedEstimatedCalls.slice(0, numberOfDepartures);
};

export const formatEstimatedCalls = (
  estimatedCalls: Entur.EstimatedCall[] = [],
  allowMultipleSituationOccurrences = false
): DPI.EstimatedCall[] => {
  const situationsSet = new Set();
  const duplicateServiceJourneyIdsAndQuays = new Set();

  return estimatedCalls
    .map((ec, i) => {
      const serviceJourneyId = ec?.serviceJourney?.id;
      const quayId = ec?.quay?.id ?? '';
      const uniqueIdentifier = `${serviceJourneyId}-${quayId}`;

      if (duplicateServiceJourneyIdsAndQuays.has(uniqueIdentifier)) {
        return null;
      } else {
        duplicateServiceJourneyIdsAndQuays.add(uniqueIdentifier);
      }

      let affectedBySituations = false;
      let affectedBySituationIds = new Set<string>();

      const delayed = (function () {
        if (!ec.realtime) return false;
        if (!ec.actualDepartureTime && !ec.expectedDepartureTime) return false;

        const plannedTime = new Date(ec.aimedDepartureTime!);
        const departureTime = new Date((ec.actualDepartureTime || ec.expectedDepartureTime)!);

        return departureTime.getTime() - plannedTime.getTime() > 3 * 60 * 1000;
      })();

      return {
        serviceJourneyId,
        lineNumber: ec?.serviceJourney?.line?.publicCode ?? '',
        authority: ec?.serviceJourney?.line?.authority?.id,
        frontText: ec?.destinationDisplay?.frontText ?? '',
        cancellation: ec?.cancellation || false,
        eta: getSecondsToDeparture((ec.actualDepartureTime || ec.expectedDepartureTime || ec.aimedDepartureTime)!),
        delayed: delayed,
        realtime: ec.realtime,
        departureTime: ec.actualDepartureTime || ec.expectedDepartureTime || ec.aimedDepartureTime,
        plannedTime: ec.aimedDepartureTime,
        quayPublicCode: ec?.quay?.publicCode ?? '',
        quayId,
        transportMode: (ec?.serviceJourney?.line?.transportMode ?? '') as DPI.TransportType,
        situations: filterSituationsByContent(ec?.situations ?? [])
          .filter(({ situationNumber }) => {
            affectedBySituations = true;
            affectedBySituationIds.add(situationNumber);
            if (situationsSet.has(situationNumber)) {
              return allowMultipleSituationOccurrences;
            }
            situationsSet.add(situationNumber);
            return true;
          })
          .map((situation) => ({
            ...situation,
            serviceJourneyId,
            type: ContentTypes.situation,
          })),
        affectedBySituations,
        affectedBySituationIds: Array.from(affectedBySituationIds),
      } as DPI.EstimatedCall;
    })
    .filter<DPI.EstimatedCall>((value: DPI.EstimatedCall | null): value is DPI.EstimatedCall => {
      return value !== null;
    });
};

export const formatMockEstimatedCalls = (
  estimatedCalls: Entur.EstimatedCall[] = [],
  fetchTimestamp: string,
  allowMultipleSituationOccurences = false
) => {
  const situationsSet = new Set();
  return estimatedCalls.map((ec) => {
    let affectedBySituations = false;
    const situations = ec?.situations ?? [];
    const serviceJourneyId = ec?.serviceJourney?.id;
    return {
      lineNumber: ec?.serviceJourney?.line?.publicCode ?? '',
      frontText: ec?.destinationDisplay?.frontText ?? '',
      cancellation: ec?.cancellation || false,
      eta: getSecondsToDeparture(ec.expectedDepartureTime, fetchTimestamp),
      realtime: ec.realtime,
      departureTime: ec.expectedDepartureTime,
      quayPublicCode: ec?.quay?.publicCode ?? '',
      transportMode: ec?.serviceJourney?.line?.transportMode ?? '',
      situations: situations
        .filter(({ situationNumber }) => {
          affectedBySituations = true;
          if (situationsSet.has(situationNumber)) {
            return allowMultipleSituationOccurences;
          }
          situationsSet.add(situationNumber);
          return true;
        })
        .map((situation) => ({
          ...situation,
          serviceJourneyId,
          type: ContentTypes.situation,
        })),
      affectedBySituations,
      serviceJourneyId,
    };
  });
};

export const pluckCallsFromGroups = (
  group: Record<string, DPI.EstimatedCall[]>,
  prioritizedKeys: string[],
  startingIndex = 0,
  maxCount = 0
) => {
  if (!group || !Array.isArray(prioritizedKeys) || !maxCount) {
    return [];
  }

  const result: DPI.EstimatedCall[] = [];

  let valuesIndex = startingIndex;
  let keyStartIndex = 0;
  let keyEndIndex = prioritizedKeys.length - 1;
  let keyIndex = 0;
  let noMoreEntries = 0;

  while (result.length < maxCount) {
    // if (noMoreEntries === prioritizedKeys) {
    //   return result;
    // }

    const currentKey = prioritizedKeys[keyIndex];
    const currentEntries = group[currentKey];

    if (Array.isArray(currentEntries) && currentEntries[valuesIndex]) {
      result.push(currentEntries[valuesIndex]);
    } else {
      noMoreEntries++;
    }

    if (keyIndex === keyEndIndex) {
      keyIndex = keyStartIndex;
      valuesIndex++;
    } else {
      keyIndex++;
    }
  }
  return result;
};

export const findRelevantCallsFromGroup = (
  groupOfDepartures: Record<string, DPI.EstimatedCall[]>,
  numberOfDepartures = 5
) => {
  if (!groupOfDepartures || !Object.keys(groupOfDepartures).length || numberOfDepartures === 0) {
    return [];
  }

  let result: (DPI.EstimatedCall & { belongsToGroup?: string })[] = [];

  // find 1st departure for each unique
  for (let i = 0; i < Object.keys(groupOfDepartures).length; i++) {
    const values = Object.values(groupOfDepartures)[i];
    if (values.length) {
      result.push({
        ...values[0],
        belongsToGroup: Object.keys(groupOfDepartures)[i],
      });
    }
  }

  // Sort list
  result = result.sort((a, b) => Date.parse(a.departureTime) - Date.parse(b.departureTime));

  if (numberOfDepartures <= result.length) {
    return result.slice(0, numberOfDepartures);
  }

  const pluckPriority = result.map((item) => item.belongsToGroup as string);

  const foundEntries = Object.values(groupOfDepartures).reduce((total, item) => total + item.length, 0);

  const maxCount = foundEntries - result.length;
  const additionalCalls = pluckCallsFromGroups(groupOfDepartures, pluckPriority, 1, maxCount);

  return result.concat(additionalCalls).slice(0, numberOfDepartures);
};

// Only show situations with at least description or summary
export const filterSituationsByContent = (situations: Entur.Situation[] = []) => {
  return situations.filter((situation) => {
    const situationText = getSituationText(situation);
    if (situationText === null) {
      return false;
    }
    const [title, body] = situationText;
    return !!(title || body);
  });
};

export const getVisibleDeparturesAndSituation = (
  departures: DPI.EstimatedCall[],
  boardConfig: DPI.BoardConfig,
  screenConfig: DPI.ScreenConfig,
  boardIndex: number,
  displaySituationsInBoard: boolean,
  decreaseNumberOfDepartures = 0
) => {
  if (!departures || !Array.isArray(departures)) {
    return {
      departures: [],
      situations: [],
    };
  }

  const SITUATION_DEPARTURE_SPACE = 2;
  const numberOfDepartures = boardConfig ? boardConfig.numberOfDepartures - decreaseNumberOfDepartures : 0;
  // First of all: Do not show situations for departures that are not rendered
  const situationsSeverityFilters = getSituationSeveritiesForBoard(screenConfig, boardIndex);
  // check if any departures line are affected by situations
  const wantedDepartures = departures.slice(0, numberOfDepartures);

  if (!displaySituationsInBoard) {
    return {
      departures: wantedDepartures,
      situations: [],
    };
  }

  const situations = getUniqueSituations(
    wantedDepartures.map((departure) => departure.situations).reduce((list, current = []) => [...list, ...current], []),
    situationsSeverityFilters
  );

  if (situations.length) {
    // all departures are affected, remove two departures to check if remaining departures lines are affected by departures:
    const departureRemainder = departures.slice(0, numberOfDepartures - SITUATION_DEPARTURE_SPACE);
    const situationsForRemainder = getUniqueSituations(
      departureRemainder
        .map((departure) => departure.situations)
        .reduce((list, current = []) => [...list, ...current], []),
      situationsSeverityFilters
    );

    // return n - 2 departures and their situations
    if (situationsForRemainder.length) {
      return {
        departures: departureRemainder,
        situations: situationsForRemainder,
      };
    }
  }

  // either there is no space to show the situations for departure n-1 or n2, or there are no situations
  return {
    departures: wantedDepartures,
    situations: [],
  };
};

const pickFromHighestDepartureCnt = (listItems: DPI.BoardDeparture[], deductedIndices: Record<string, number>) => {
  if (Array.isArray(listItems)) {
    // Will this ever be hit?! -> || listItems.every((list) => Array.isArray(list))) {
    let highestDepartureCnt = -1;
    let highestDepartureIdx = -1;
    listItems.forEach((listItem, index) => {
      const alreadyDeducted = deductedIndices[index] || 0;
      const currentDepartureCnt = listItem.entries.length - alreadyDeducted;
      if (currentDepartureCnt >= highestDepartureCnt) {
        highestDepartureCnt = currentDepartureCnt;
        highestDepartureIdx = index;
      }
    });
    return highestDepartureIdx;
  }
  return -1;
};

export const getBalancedDepartureDecreaseMap = (departures: DPI.BoardDeparture[], removeDeparturesCount: number) => {
  let deductedIndices: Record<string, number> = {};
  for (let i = 0; i < removeDeparturesCount; i++) {
    const highestDepartureIdx = pickFromHighestDepartureCnt(departures, deductedIndices);
    if (highestDepartureIdx > -1) {
      deductedIndices = {
        ...deductedIndices,
        [highestDepartureIdx]: (deductedIndices[highestDepartureIdx] || 0) + 1,
      };
    }
  }
  return deductedIndices;
};
