import React from 'react';
import cx from 'classnames';
import { getBusModifier } from '~/utils/lineColors';

import './styles.scss';
import { useColors, useEnturAuthority } from '~/hooks/useStore';
import { findColorMatch } from '~/utils/colors';

interface Props {
  lineNumber: string;
  transportMode: DPI.TransportType;
  authority?: string;
  isPlaceHolder?: boolean;
  className?: string;
  busShelter?: boolean;
  allDeparturesTramOrMetro?: boolean;
  realtime?: boolean;
  delayed?: boolean;
  cancellation?: boolean;
}

const LineNumber = ({
  lineNumber = '',
  transportMode,
  authority,
  isPlaceHolder = false,
  className,
  busShelter = false,
  allDeparturesTramOrMetro = false,
  realtime = false,
  delayed = false,
  cancellation = false
}: Props) => {
  const colors = useColors();
  const enturAuthority = useEnturAuthority();
  const color = (authority === enturAuthority) ? findColorMatch(transportMode, lineNumber, colors) : undefined;

  const classNames = cx(
    'line-number',
    'other',
    {
      '-hidden': isPlaceHolder,
      '-busShelter': busShelter,
      '-short': allDeparturesTramOrMetro,
      '-xlong': !allDeparturesTramOrMetro && lineNumber.length > 4,
      '-long':
        !allDeparturesTramOrMetro && lineNumber.length === 4,
      '-md':
          !allDeparturesTramOrMetro && lineNumber.length === 3,
    },
    className
  );

  const colorStyle = {
    backgroundColor: color
  }

  return (
    <div style={colorStyle} className={classNames}>
      <span className="line-text">{isPlaceHolder ? '-' : lineNumber}</span>
      {realtime && !cancellation && <span className={cx("realtime", { 'delayed': delayed })}></span>}
    </div>
  );
};

export default LineNumber;
