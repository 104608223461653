import { getQueryParam } from './url';

const CLIENT_ID_KEY = 'DPI:STOPSIGN:ClientId';

export const generateShortUID = () => {
  const firstPart = (Math.random() * 46656) | 0;
  const secondPart = (Math.random() * 46656) | 0;
  const first = ('000' + firstPart.toString(36)).slice(-3);
  const second = ('000' + secondPart.toString(36)).slice(-3);
  return first + second;
};

export const createClientId = () => {
  const time = new Date().getTime();
  const shortUID = generateShortUID();
  return `DPI-${time}-${shortUID}`;
};

export const getClientId = () => {
  try {
    const urlClientId = getQueryParam('clientId');

    if (urlClientId) {
      return urlClientId;
    }

    const persistedClientId = localStorage.getItem(CLIENT_ID_KEY);

    if (persistedClientId) {
      return persistedClientId;
    }

    const generatedClientId = createClientId();
    localStorage.setItem(CLIENT_ID_KEY, generatedClientId);

    return generatedClientId;
  } catch (e) {
    console.error(e);
    return 'INVALID_CLIENT_ID';
  }
};
