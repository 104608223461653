import { useMemo, useEffect, useRef } from 'react';
import { CampaignContent, isVideoCampaign } from '../types/Campaign';
import { useStore, useActivePlalistItem, useIgnoreCampaigns, getPlaylist } from './useStore';

export default function usePlaylist() {
  const storeState = useStore();
  const { activePlaylistIndex, setNextActivePlaylistIndex, situations } = storeState;
  const playlist = getPlaylist(storeState);
  const activeContent = useActivePlalistItem();

  const timer = useRef<number>();
  const interval = useRef<any>();

  function getDuration(item: CampaignContent | DPI.Situation) {
    const duration = item?.hasOwnProperty('duration') ? (item as CampaignContent).duration * 1000 : 10000; // 10sec default
    if (situations.length && !isVideoCampaign(item as CampaignContent)) {
      // Situations should not have to wait for campaigns
      // Videos get to play the full length
      return Math.min(duration, 10000);
    }
    return duration;
  }

  function loop() {
    if (timer.current !== undefined && Date.now() < timer.current) {
      return;
    }

    let nextPlaylistIndex = setNextActivePlaylistIndex();

    const nextItem = playlist[nextPlaylistIndex];
    const duration = getDuration(nextItem);

    timer.current = Date.now() + duration;
  }

  useEffect(() => {
    interval.current = setInterval(loop, 2000);

    return () => {
      clearInterval(interval.current);
    };
  }, [playlist]);


  const activeSituationIndex = useMemo(() => {
    if (activeContent?.type === 'situation') {
      return situations.findIndex((sit) => sit.situationNumber === activeContent.situationNumber);
    }
    return 0;
  }, [activeContent, situations]);

  return {
    activePlaylistItem: activeContent,
    situationCount: situations.length,
    playlistCount: playlist.length,
    activeIndex: activePlaylistIndex,
    activeSituationIndex,
  };
}
