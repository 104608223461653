import React from 'react';
import cx from 'classnames';
import SituationLineEntry from '../SituationLineEntry';

import './styles.scss';

interface Props {
  numberOfSituations: number;
  situation: DPI.Situation;
  activeIndex: number;
  includeBody?: boolean;
}
const SituationsFooter = ({ numberOfSituations, situation, activeIndex, includeBody }: Props) => {
  if (!situation) {
    return null;
  }

  return (
    <div className={cx('situationsFooter', { '-large': includeBody })}>
      <SituationLineEntry
        situation={situation}
        index={activeIndex}
        numberOfSituations={numberOfSituations}
        includeBody={includeBody}
      />
    </div>
  );
};

export default SituationsFooter;
