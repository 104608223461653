import React from 'react';
import cx from 'classnames';
import dayjs from 'dayjs';

import LineNumber from '../LineNumber';
import Track from '../Track';
import AffectedBySituation from '../AffectedBySituation';
import DepartureTime from '../Time';

import { getAngleForPublicCode } from '~/utils/publicCodeArrows';
import { getDestinationAndViaText } from '~/utils/destinations';
import { CampaignContent } from '~/types/Campaign';

import './styles.scss';
import { useTemplate } from '~/hooks/useStore';

function isSituation(item?: any): item is DPI.Situation {
  return item && item.situationNumber !== undefined;
}

interface Props {
  departure: DPI.EstimatedCall;
  allDeparturesTramOrMetro?: boolean;
  activeSituation?: DPI.Situation | CampaignContent;
  publicCodeArrowAngles: DPI.PublicCodeArrowAngle[];
  hideTrack?: boolean;
  blinkKey: string;
  showPlannedTime: boolean;
}
const DepartureLine = ({
  departure,
  allDeparturesTramOrMetro,
  activeSituation,
  publicCodeArrowAngles,
  hideTrack,
  blinkKey,
  showPlannedTime,
}: Props) => {
  const {
    lineNumber,
    authority,
    eta,
    frontText,
    departureTime,
    plannedTime,
    cancellation,
    delayed,
    realtime,
    isEmpty,
    affectedBySituations,
    affectedBySituationIds = [],
    quayPublicCode,
  } = departure;
  const template = useTemplate();

  const showTracks = hideTrack !== true;

  const activeSituationNumber = isSituation(activeSituation) ? activeSituation.situationNumber : null;
  const isAffectedByCurrentSituation =
    (activeSituationNumber && affectedBySituationIds.includes(activeSituationNumber)) || false;

  if (isEmpty) {
    return <LineNumber lineNumber={''} transportMode={''} isPlaceHolder={isEmpty} />;
  }

  const { destination, viaText } = getDestinationAndViaText(frontText);

  const trackArrowAngle = getAngleForPublicCode(publicCodeArrowAngles, quayPublicCode);

  return (
    <div className="departure-line">
      <div className={cx('line-destination', { '-with-tracks': showTracks, '-with-delay': showPlannedTime })}>
        <LineNumber
          lineNumber={lineNumber}
          transportMode={departure.transportMode}
          realtime={realtime}
          authority={authority}
          delayed={delayed}
          busShelter
          allDeparturesTramOrMetro={allDeparturesTramOrMetro}
        />
        <div className={cx('front-text', { '-smaller': showTracks, '-cancellation': cancellation })}>
          <span className="mainText">{destination}</span> <span className="viaText">{viaText}</span>
        </div>
      </div>
      {showPlannedTime && (
        <span className="planned">{delayed || cancellation ? dayjs(plannedTime).format('HH:mm') : ' '}</span>
      )}
      <DepartureTime eta={eta} departureTime={departureTime} realtime={realtime} cancellation={cancellation} />
      {showTracks && (
        <Track
          publicCode={quayPublicCode}
          trackArrowAngle={trackArrowAngle}
          transportMode={departure.transportMode}
        />
      )}
      <AffectedBySituation
        off={!affectedBySituations}
        keyRef={`${blinkKey}-${activeSituationNumber}`}
        active={isAffectedByCurrentSituation}
        hideArrow={template === 'METRO_DEFAULT'}
      />
    </div>
  );
};

export default DepartureLine;
