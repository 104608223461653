import React from 'react';

interface Props {
  className?: string;
}
export default function Whoops({ className = '' }: Props) {
  return (
    <svg className={className} viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M50.2187 11.6189C50.2269 10.808 49.5921 5.66824 49.3177 5.59862C48.3922 5.36518 47.442 5.26279 46.4919 5.29555C45.6687 5.32422 44.8209 5.45118 44.0346 5.17269C43.6783 5.04983 43.3015 4.77543 43.2565 4.33722C43.191 3.68604 43.8503 3.29288 44.3868 3.12906C47.6837 2.12568 51.5293 2.09291 54.8671 2.75228C55.0268 2.78504 55.5756 8.06817 55.6002 10.3452"
        fill="#DBA9A2"
      />
      <path
        d="M29.304 67.579C29.2548 67.0097 36.1843 55.2721 39.3296 50.6074L39.6695 50.8368C36.1065 56.1281 29.8446 66.9401 29.7135 67.579H29.304Z"
        fill="#9DA6D9"
      />
      <path
        d="M51.9606 52.1513C51.9606 52.1513 47.3123 39.0172 46.3171 31.2031C45.3096 23.3071 47.8406 14.6001 48.7211 7.43311C51.7108 6.76964 55.9905 7.29796 55.9168 8.18257C55.3271 15.2718 54.1312 21.9105 55.1674 29.9294C55.9619 36.0767 60.7863 46.8518 60.7863 46.8518"
        fill="#F24949"
      />
      <path
        d="M44.3461 39.5253C41.9667 40.1151 39.3128 39.468 37.4699 37.8462C36.3764 36.8838 35.5491 35.606 34.3 34.8483C33.4154 34.3118 32.3792 34.0825 31.4455 33.632C29.8032 32.8375 28.4968 31.273 28.251 29.4628C28.0053 27.6526 28.9636 25.6827 30.6387 24.966C31.0032 24.8104 31.3922 24.7121 31.7322 24.5114C32.5963 24.0036 32.9731 22.9797 33.4768 22.1156C34.3369 20.6371 35.758 19.4617 37.4125 19.0235C39.0671 18.5853 40.9387 18.9252 42.2575 20.0187C43.2404 20.8337 43.8875 22.0132 44.9277 22.7545C45.5297 23.1804 46.2546 23.4548 46.7461 24.0036C47.471 24.8104 47.5201 25.994 47.7536 27.0547C47.9419 27.9106 48.2696 28.7338 48.7201 29.4833C49.1419 30.1877 49.6743 30.8307 49.9938 31.5843C50.5917 33.0095 50.346 34.6927 49.6129 36.0524C48.8798 37.4121 47.7085 38.4892 46.4594 39.3984"
        fill="#565EA3"
      />
      <path
        d="M12.8812 62.0624C10.3912 60.67 7.7947 59.4618 5.12447 58.4543C4.88284 58.3642 4.60844 58.2741 4.375 58.3765C4.09651 58.4994 3.98184 58.8434 4.00231 59.1465C4.04327 59.7976 4.53063 60.326 4.99751 60.7806C7.78651 63.4795 11.0465 65.691 14.589 67.2841C14.6955 67.3333 14.8143 67.3824 14.929 67.3537C15.0354 67.3292 15.1174 67.2391 15.1911 67.1572C16.3869 65.7688 17.3699 64.2003 18.0988 62.5211C16.7473 62.1484 15.4491 61.571 14.2696 60.8133"
        fill="#DBA9A2"
      />
      <path
        d="M42.7904 44.9186C42.5405 45.3405 42.5119 45.8606 42.516 46.352C42.561 50.2018 44.2483 53.8426 46.1896 57.1681C47.8687 60.0513 49.8182 62.869 52.4925 64.8675C52.7177 65.0355 52.9594 65.2034 53.2378 65.2443C53.4672 65.2771 53.7006 65.2238 53.9259 65.1624C57.194 64.286 60.1551 62.4144 62.5468 60.0226C64.9385 57.6268 66.7815 54.7231 68.1821 51.6393C68.3296 51.3116 68.4729 50.9348 68.3296 50.6072C68.2477 50.4188 68.0838 50.2837 67.9241 50.1567C64.6027 47.5069 60.6957 45.623 57.5831 42.7276C56.7804 41.9781 56.0105 41.1508 55.0112 40.7003C53.5696 40.0491 51.8864 40.2867 50.3506 40.6757C48.9335 41.032 47.3814 41.4293 46.1323 42.2156C45.0961 42.8668 43.4129 43.8702 42.7904 44.9186Z"
        fill="#F24949"
      />
      <path
        d="M39.5383 74.32L39.2598 74.021C39.4441 73.849 39.612 73.6443 39.7676 73.4477C43.9286 68.1973 46.865 63.2951 48.7489 58.4624C51.329 51.8442 52.3079 46.6061 51.8287 41.9701C51.7714 41.409 51.1284 40.7374 50.5755 40.6554L50.6328 40.25C51.3659 40.3565 52.1563 41.1838 52.2341 41.925C52.7215 46.6266 51.7345 51.9261 49.1298 58.6058C47.2295 63.4753 44.2726 68.4144 40.087 73.6975C39.9273 73.8982 39.743 74.1275 39.5383 74.32Z"
        fill="#9DA6D9"
      />
      <path
        d="M96.3182 91.4266C96.3182 91.4266 100.442 90.0424 102.281 89.3175C101.188 86.1435 100.835 85.7258 99.7379 82.5477C101.72 84.6855 103.776 85.5497 106.618 86.2049C109.46 86.8602 112.393 86.1312 115.047 84.9231C117.225 83.932 116.734 82.3921 111.398 81.4051C107.437 80.672 102.908 78.5178 100.283 75.4626C98.3741 76.2858 93.8855 73.206 93.8486 74.4715C93.6725 80.0822 95.0158 86.295 96.3182 91.4266Z"
        fill="#9DA6D9"
      />
      <path
        d="M102.13 42.7733C98.2675 42.032 76.7664 50.1246 68.5428 53.8555L61.3389 66.4982C72.888 68.1282 86.5218 59.4827 95.9249 55.7927C94.168 62.5502 93.5536 68.7712 92.194 74.9594C95.9618 76.7491 98.7098 76.532 102.445 76.5361C104.37 73.7307 106.176 67.3582 107.896 61.5304C109.542 55.9606 113.548 41.9951 102.13 42.7733Z"
        fill="#565EA3"
      />
      <path
        d="M112.417 18.106C112.417 18.106 110.271 14.3218 109.206 12.659C106.298 14.3382 105.954 14.7682 103.051 16.4473C104.771 14.0924 105.225 11.9096 105.328 8.99769C105.43 6.08583 104.148 3.34598 102.457 0.970618C101.068 -0.978814 99.6514 -0.200679 99.7005 5.22579C99.7374 9.25161 98.4924 14.1129 95.9941 17.2705C97.1654 18.9865 99.6022 23.3522 100.72 22.7543C104.201 20.8786 107.74 19.3878 112.417 18.106Z"
        fill="#9DA6D9"
      />
      <path
        d="M58.853 70.6794C64.4679 71.4043 70.0377 67.9805 73.9447 63.6885C77.8518 59.3965 80.3091 53.9004 82.0578 48.3019C82.6803 46.3033 83.2332 44.2515 84.2734 42.4413C85.2645 40.713 86.6611 39.2755 88.0413 37.8626C92.4971 33.2921 96.9529 28.7175 101.409 24.147C101.695 23.8521 101.994 23.5408 102.125 23.1436C102.293 22.6276 98.6239 16.6933 96.1912 15.0837C95.8513 14.8585 79.49 25.0439 74.4362 33.3208C70.7585 39.3493 66.9948 47.6425 62.3997 52.9257C56.8544 59.2982 48.9707 61.1985 48.9707 61.1985C48.9707 61.1985 52.7795 69.8972 58.853 70.6794Z"
        fill="#565EA3"
      />
      <path
        d="M52.3983 107.943C49.0155 109.692 45.2518 110.61 41.4717 111.031C44.9446 112.555 48.8762 113.763 52.4802 112.584C56.6576 111.22 59.2254 107.034 60.7817 102.922C60.5155 109.156 56.8706 114.685 53.1437 119.685C55.5477 120.537 58.349 119.583 60.1101 117.744C61.8711 115.901 62.6984 113.321 62.8704 110.778C63.0424 108.234 62.6124 105.691 62.1619 103.185C64.4881 109.578 71.5363 113.89 78.2856 113.051C79.3218 112.924 80.3907 112.661 81.177 111.973C74.2926 110.675 66.671 105.421 62.1537 100.064L61.1462 95.7432C60.708 95.7636 60.2698 95.78 59.8316 95.8005C60.1305 98.9294 60.2042 100.518 60.2042 100.518C58.5456 102.447 54.745 106.899 52.3983 107.943Z"
        fill="#DBA9A2"
      />
      <path
        d="M51.0633 28.1436C51.3622 28.0331 51.7185 27.9061 51.9765 28.0904C52.3124 28.3279 52.1813 28.8685 51.911 29.1798C51.6407 29.4911 51.268 29.7327 51.1411 30.1217C52.8612 31.3053 53.5779 33.7503 52.7629 35.6711C52.341 36.6663 51.5711 37.5058 51.2967 38.5543C51.0182 39.6191 51.2967 40.7412 51.5711 41.8061C50.195 42.5719 48.6715 43.0715 47.1111 43.2722C46.931 43.2968 46.7426 43.3132 46.5787 43.2436C46.419 43.178 46.3003 43.0388 46.1938 42.9036C45.1699 41.634 44.2812 40.2539 43.5563 38.7959C43.3433 38.3659 42.8437 37.1045 42.4546 36.826C42.172 36.6253 41.0949 36.6007 40.7345 35.8308C40.4274 35.1755 40.4765 34.1476 41.1195 33.8158C41.2465 33.7503 41.3939 33.6971 41.4799 33.5783C41.5577 33.4636 41.5618 33.3203 41.57 33.181C41.656 31.1866 42.8847 29.2085 44.6621 28.2788C45.6696 27.7546 46.419 28.2788 47.4593 28.4631C48.6551 28.6761 49.9165 28.5696 51.0633 28.1436Z"
        fill="#DBA9A2"
      />
      <path
        d="M48.0573 43.2642C41.9141 40.9585 33.4078 39.0705 26.8879 38.3292C25.5855 38.1818 24.2422 38.0671 22.9849 38.4398C20.843 39.0705 19.3441 40.9749 18.1933 42.8874C15.4698 47.4129 13.9053 52.524 12.3654 57.5778C12.1033 58.442 11.8371 59.3634 12.0747 60.2358C12.3163 61.1163 13.0248 61.7797 13.7538 62.3244C14.8432 63.1394 16.1005 63.8193 17.4602 63.9176C17.7878 63.9421 18.1441 63.9257 18.4103 63.7292C18.6356 63.5653 18.7625 63.3073 18.8772 63.0575C21.2321 58.057 23.2716 52.909 24.9794 47.6545C25.049 47.4457 25.1187 47.2286 25.2702 47.073C25.4913 46.8395 25.8395 46.7863 26.1589 46.774C27.277 46.729 28.3704 47.0771 29.4353 47.4211C35.2467 49.3173 41.0049 51.3855 46.6894 53.6339C48.3972 50.8449 48.7822 47.286 47.7132 44.198"
        fill="#F24949"
      />
      <path
        d="M50.1001 33.206C49.8175 33.4435 49.7561 33.9104 49.9691 34.2135C50.182 34.5165 50.6653 34.5984 50.9438 34.3568C51.0298 34.279 51.0953 34.1807 51.1445 34.0742C51.3083 33.7302 50.5711 32.8087 50.1001 33.206Z"
        fill="#565EA3"
      />
      <path
        d="M46.1198 33.3823C46.2917 33.3823 46.4311 33.2429 46.4311 33.071C46.4311 32.8991 46.2917 32.7598 46.1198 32.7598C45.9479 32.7598 45.8086 32.8991 45.8086 33.071C45.8086 33.2429 45.9479 33.3823 46.1198 33.3823Z"
        fill="#565EA3"
      />
      <path
        d="M25.2701 74.455C25.0121 75.0243 24.7541 75.6468 24.881 76.2611C25.0408 77.027 25.7411 77.5389 26.4005 77.9607C28.8045 79.5006 31.3396 80.8398 33.9647 81.9538C34.3088 82.1012 34.6855 82.2446 35.0541 82.175C35.5087 82.0849 35.8282 81.6958 36.1067 81.3272C37.7612 79.1198 39.8048 75.4461 40.9884 72.952C41.0458 72.8291 34.0876 69.4299 30.9054 67.5788C30.2543 67.202 29.7546 66.686 29.0912 67.1651C28.526 67.5706 28.0919 68.8607 27.7601 69.4709C26.8714 71.1009 26.0401 72.7636 25.2701 74.455Z"
        fill="#9DA6D9"
      />
      <path
        d="M70.0905 95.2973C69.9308 95.2973 69.7793 95.2031 69.7137 95.0434C69.6277 94.8345 69.726 94.5929 69.9349 94.5069C73.5962 92.9875 76.5736 89.9896 78.134 86.3611C77.5524 85.9925 77.0651 85.5461 76.6965 85.0382C75.9224 83.9775 75.689 82.6301 76.0903 81.5162C76.3729 80.7298 76.9381 80.1851 77.6097 80.0582C78.5804 79.8739 79.4855 80.6315 79.7885 81.4711C80.0834 82.2943 79.9523 83.1707 79.7844 83.9202C79.6247 84.6246 79.4199 85.3208 79.166 85.9925C79.4076 86.1072 79.637 86.2013 79.85 86.2751C82.3482 87.1515 85.2518 86.5986 87.2504 84.8703C87.4224 84.7229 87.6804 84.7393 87.8279 84.9113C87.9753 85.0833 87.9548 85.3413 87.7869 85.4887C85.5672 87.4054 82.3441 88.0156 79.5797 87.045C79.3298 86.959 79.0882 86.8607 78.8589 86.7501C77.2043 90.5343 74.0877 93.6673 70.2543 95.2564C70.197 95.2891 70.1437 95.2973 70.0905 95.2973ZM77.9046 80.8568C77.8555 80.8568 77.8063 80.8609 77.7572 80.8691C77.3067 80.9551 76.9954 81.4056 76.8562 81.7987C76.549 82.6588 76.7374 83.7154 77.3558 84.5591C77.6671 84.985 78.048 85.3208 78.4411 85.587C78.6623 84.9891 78.8425 84.3707 78.9817 83.7482C79.1251 83.1134 79.2397 82.3803 79.0145 81.7578C78.8466 81.2991 78.392 80.8568 77.9046 80.8568Z"
        fill="#565EA3"
      />
    </svg>
  );
}
