import React from 'react';

interface Props {
  className?: string;
}
export default function Cycling({ className = '' }: Props) {
  return (
    <svg className={className} viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.0214 119.785C35.6312 119.785 45.0427 110.039 45.0427 98.0175C45.0427 85.9959 35.6312 76.2505 24.0214 76.2505C12.4116 76.2505 3 85.9959 3 98.0175C3 110.039 12.4116 119.785 24.0214 119.785Z"
        fill="#9DA6D9"
      />
      <path
        d="M96.2333 120C107.843 120 117.255 110.254 117.255 98.2329C117.255 86.2113 107.843 76.4658 96.2333 76.4658C84.6235 76.4658 75.2119 86.2113 75.2119 98.2329C75.2119 110.254 84.6235 120 96.2333 120Z"
        fill="#9DA6D9"
      />
      <path
        d="M13.2818 70.1162C12.9705 70.1162 12.7129 69.8663 12.7052 69.5511C12.6976 69.2321 12.9512 68.9707 13.2703 68.963C16.9756 68.8862 25.0705 68.8593 34.4416 68.8247C50.7005 68.767 70.9378 68.6978 77.2108 68.3327C80.1051 68.1635 82.8918 68.1559 85.5824 68.1482C88.0193 68.1405 90.3217 68.1328 92.6626 68.0175C92.9816 68.006 93.2507 68.2481 93.266 68.5633C93.2814 68.8785 93.0354 69.1514 92.7202 69.1668C90.3525 69.2859 88.0347 69.2936 85.5863 69.3013C82.911 69.309 80.1397 69.3167 77.2761 69.4819C70.9724 69.8471 50.7159 69.9201 34.4416 69.9778C25.0744 70.0124 16.9833 70.0393 13.2895 70.1162C13.2895 70.1162 13.2857 70.1162 13.2818 70.1162Z"
        fill="#4A5296"
      />
      <path
        d="M96.3065 99.9628C96.2988 99.9628 96.295 99.9628 96.2873 99.9628C95.9683 99.9551 95.7184 99.6861 95.73 99.3709C95.8222 96.2306 94.5269 82.8659 93.4891 72.1265C92.6704 63.6934 92.2129 58.8887 92.2744 58.2084C92.3782 57.0745 93.9888 56.9668 96.0259 56.8285C97.2828 56.7439 98.8472 56.6401 99.4469 56.2404C100.335 55.6523 100.527 52.2429 99.9273 51.0513C99.1317 49.4792 96.9561 49.0949 95.1457 48.9373C92.1784 48.6759 89.1649 48.5567 86.1898 48.576H86.186C85.8708 48.576 85.6133 48.3184 85.6094 48.0033C85.6056 47.6842 85.8631 47.4267 86.1821 47.4228C89.1956 47.4036 92.2475 47.5266 95.2495 47.788C97.3559 47.9725 99.9081 48.4491 100.961 50.5286C101.688 51.9623 101.672 56.1481 100.093 57.1975C99.2393 57.7625 97.648 57.8701 96.1105 57.9739C95.2226 58.0354 93.7543 58.1315 93.4276 58.3698C93.4083 59.2539 94.008 65.4576 94.646 72.0112C95.6915 82.7813 96.9907 96.1844 96.8946 99.4016C96.8754 99.713 96.6179 99.9628 96.3065 99.9628Z"
        fill="#4A5296"
      />
      <path
        d="M24.0333 98.4445C23.9295 98.4445 23.818 98.406 23.695 98.3138L23.2568 97.9832L23.5682 97.522C25.7437 94.2855 32.1781 86.9286 37.8553 80.4404C38.4819 79.7255 39.1045 79.0144 39.7119 78.3187C42.5101 74.9746 45.2122 71.7459 47.3801 69.1514L48.3141 69.824C47.5531 71.0463 44.432 74.6633 40.5767 79.0797C39.2391 80.6787 37.8169 82.3777 36.3947 84.0727C33.4274 87.6167 30.4561 91.1606 28.2229 93.8128C27.1083 95.1388 26.1742 96.2458 25.5208 97.0184C25.1941 97.4066 24.9327 97.7103 24.7559 97.914C24.5214 98.1869 24.3023 98.4445 24.0333 98.4445Z"
        fill="#4A5296"
      />
      <path
        d="M24.0444 98.4251C23.9868 98.4251 23.9253 98.4174 23.8676 98.3981C23.5986 98.3097 23.4141 98.0291 23.4602 97.7486C23.5524 97.1566 23.5524 97.1566 30.8286 97.0913C34.8261 97.0567 39.9191 97.0336 44.8467 97.0144C50.7738 96.9913 56.8853 96.9683 58.2729 96.9183C68.1244 77.4229 80.3859 73.779 93.1355 71.5305C93.4469 71.4766 93.7467 71.6842 93.8043 71.9994C93.8582 72.3146 93.6506 72.6105 93.3354 72.6682C80.8241 74.8745 68.7932 78.453 59.1569 97.7255C58.9763 98.0906 58.9763 98.0906 54.4791 98.1252C51.9499 98.1445 48.5098 98.156 44.8506 98.1714C36.7211 98.2021 25.6819 98.2482 24.2866 98.3789C24.2097 98.4058 24.129 98.4251 24.0444 98.4251Z"
        fill="#4A5296"
      />
      <path
        d="M47.3256 70.3889C47.0066 70.3889 46.749 70.1313 46.749 69.8123V61.2216C46.749 60.9025 47.0066 60.645 47.3256 60.645C47.6446 60.645 47.9021 60.9025 47.9021 61.2216V69.8123C47.9021 70.1313 47.6446 70.3889 47.3256 70.3889Z"
        fill="#4A5296"
      />
      <path
        d="M54.2558 92.3907C52.4877 95.089 49.9893 99.1903 49.9893 102.265C49.9893 103.418 67.8511 109.188 68.0548 109.184C68.616 109.169 69.5923 108.415 69.6423 106.789C69.6615 106.228 69.4962 105.675 69.2464 105.171C68.4469 103.576 67.213 102.208 65.7101 101.243C64.5878 100.524 63.3193 100.028 62.3046 99.1672C61.2898 98.3062 60.5518 96.9071 60.9862 95.6502C58.8414 94.524 56.6927 93.4016 54.5479 92.2754"
        fill="#4A5296"
      />
      <path
        d="M82.2772 56.9127C81.6853 50.3515 60.145 50.8166 57.612 50.0978C47.5337 47.2342 38.0743 42.6909 38.0743 42.6909C34.9993 49.6096 35.5144 57.0665 40.615 60.4105C43.9437 62.5938 57.1623 64.8347 68.8242 65.3689"
        fill="#F24949"
      />
      <path
        d="M68.0554 64.216C62.3475 74.0828 58.5191 79.4141 52.8112 89.2771C52.5959 89.6538 52.3691 90.0612 52.4191 90.4917C52.4652 90.903 52.7497 91.2413 53.0303 91.5411C55.0674 93.7051 57.735 95.2656 60.6178 95.9806C60.9637 96.0651 61.3366 96.1382 61.671 96.0229C61.99 95.9114 62.2283 95.6462 62.4474 95.3925C69.3469 87.4244 75.101 78.4647 79.479 68.8746C81.274 64.9386 83.3419 60.5913 82.3118 56.3901"
        fill="#F24949"
      />
      <path
        d="M63.8273 20.0132C63.3161 18.6179 61.1905 16.6192 59.5992 16.5539C49.9899 16.1695 42.6637 22.8883 39.9962 28.0851C37.3286 33.2818 35.9334 37.5099 34.9993 43.0756C34.9186 43.5484 50.3474 49.9021 57.6774 47.6881C58.3308 47.4921 59.9836 42.6912 60.7523 38.8475C62.1515 31.8596 66.3142 26.8358 63.8273 20.0132Z"
        fill="#9DA6D9"
      />
      <path
        d="M80.2132 44.0211C88.0429 44.2286 90.1031 47.2344 90.3491 48.4567C90.7335 50.3786 90.2261 51.6547 89.9647 51.9161C89.196 52.6848 85.7366 53.0692 84.9679 51.9161C83.4304 49.6099 80.1363 49.6099 79.971 48.8411C79.6904 47.5381 78.5104 44.8552 79.4444 43.9058"
        fill="#DBA9A2"
      />
      <path
        d="M72.3986 6.67161C72.3602 6.18345 72.2064 5.36089 71.4146 5.17255C70.9649 5.06493 70.5575 5.73758 70.4191 6.3718C70.2807 7.00601 70.4114 7.74785 70.7304 8.22063C70.8534 8.40513 71.0072 8.55503 71.1802 8.62422C71.5222 8.75875 71.9066 8.53966 72.1334 8.13607C72.3563 7.74016 72.4371 7.19051 72.3986 6.67161Z"
        fill="#4A5296"
      />
      <path
        d="M68.835 3.56573C67.9624 2.60096 66.5172 2.30883 65.2372 2.55099C63.0002 2.9738 61.2359 4.8457 60.4364 6.97897C59.6408 9.10839 59.683 11.4646 59.9982 13.7209C60.1289 14.6472 57.6728 16.5575 57.6728 16.5575C57.2884 16.5575 59.5946 21.5544 62.6696 21.5544C63.1885 21.5544 66.4057 17.1072 66.8977 16.9419C67.4051 16.7728 69.2155 16.1732 69.5883 15.7888C71.2296 14.1091 71.5102 10.7919 71.5986 8.83933C71.6025 8.75477 72.2444 8.98924 73.0708 8.92774C74.3392 8.83933 74.2162 8.40499 74.1854 7.84381C74.1124 7.55553 72.6172 7.56706 71.6025 6.78294C70.0726 5.59907 68.835 3.56573 68.835 3.56573Z"
        fill="#DBA9A2"
      />
      <path
        d="M80.8088 43.8286C80.7973 43.6671 80.7819 43.498 80.6935 43.3635C80.5167 43.0906 80.1361 43.056 79.8094 43.0483C74.9509 42.9714 70.0502 42.8946 65.2724 43.7901C62.6318 44.286 60.0488 45.074 57.4081 45.5583C55.8783 45.8389 54.337 46.0118 52.7918 46.1886C52.296 46.2463 51.754 46.2924 51.3274 46.031C50.6086 45.589 54.9213 30.8983 55.4594 29.1225C55.9975 27.3467 56.8739 22.8341 52.6842 21.5465C47.6873 20.009 44.4125 29.7567 42.7789 34.4883C41.2606 38.8971 40.3266 43.6441 41.1722 48.2335C41.4182 49.5596 41.8141 50.8703 42.5137 52.0234C44.1165 54.6641 47.1723 56.1785 50.2396 56.5552C53.3069 56.9319 56.4088 56.3092 59.3877 55.4905C61.84 54.8178 64.2538 54.0106 66.6216 53.0766C70.7767 51.443 74.9509 49.3905 79.4174 49.4289C79.698 49.4327 80.0054 49.4327 80.2207 49.2559C80.4206 49.0907 80.4859 48.8177 80.532 48.5641C80.828 47.0073 80.9203 45.4122 80.8088 43.8286Z"
        fill="#4A5296"
      />
      <path
        d="M59.7833 107.654C59.7141 107.654 59.6449 107.642 59.5757 107.615C57.5078 106.824 55.3822 106.109 53.2566 105.482C52.953 105.394 52.7762 105.071 52.8646 104.767C52.953 104.464 53.2759 104.287 53.5795 104.379C55.7359 105.013 57.8922 105.744 59.987 106.543C60.283 106.658 60.4329 106.989 60.3214 107.289C60.233 107.512 60.0139 107.654 59.7833 107.654Z"
        fill="#4A5296"
      />
      <path
        d="M63.516 7.66313L63.4814 7.27876C65.0651 7.14422 66.6756 6.99048 68.2669 6.8252L68.3053 7.20957C66.714 7.37485 65.1035 7.5286 63.516 7.66313Z"
        fill="#4A5296"
      />
      <path
        d="M63.5196 7.65924C63.1775 7.24027 62.5202 7.13264 62.0474 7.39786C61.5746 7.66308 61.3286 8.2627 61.4555 8.78929C61.5823 9.31588 62.0551 9.73485 62.5894 9.81557C61.9436 11.28 61.0749 13.0827 59.9833 14.2512C59.9295 14.3089 56.9891 10.2845 57.0659 8.18967C57.1428 6.09484 58.0422 4.08072 59.3568 2.45098C59.8257 1.87057 60.3562 1.32476 60.9865 0.925016C62.2012 0.15627 63.6964 -0.0243856 65.1301 0.00252053C66.4715 0.0255829 67.8284 0.21777 69.0622 0.748205C69.7041 1.02495 70.3307 1.41317 70.7112 2.00126C71.0917 2.58935 71.1801 3.40807 70.7765 3.98078C70.4806 4.39591 69.9809 4.62269 69.4773 4.71109C68.9738 4.7995 68.4588 4.76875 67.9514 4.77644C67.1711 4.78797 66.3755 4.8879 65.6644 5.20693C64.6688 5.65281 63.877 6.52149 63.5234 7.55161"
        fill="#F24949"
      />
      <path
        d="M70.2308 6.98635C70.1386 6.50589 69.8849 5.70255 69.0123 5.6103C68.5165 5.55649 68.1475 6.26758 68.0629 6.91717C67.9784 7.56676 68.1975 8.28554 68.5934 8.72372C68.7471 8.89285 68.9278 9.02353 69.12 9.0735C69.5043 9.16959 69.8926 8.90438 70.0963 8.48157C70.3 8.05491 70.3307 7.49757 70.2308 6.98635Z"
        fill="#4A5296"
      />
      <path
        d="M71.4068 11.4874C71.403 11.4874 71.3953 11.4874 71.3914 11.4874C70.6265 11.4298 69.7579 11.3644 68.9545 11.1031C68.0512 10.8109 67.3325 10.2843 66.9289 9.61938C66.8751 9.52713 66.902 9.41182 66.9942 9.35416C67.0826 9.30035 67.2018 9.32726 67.2594 9.41951C67.6131 10.0038 68.2588 10.4727 69.0775 10.7379C69.8386 10.9839 70.6458 11.0454 71.426 11.1031C71.5337 11.1108 71.6105 11.203 71.6028 11.3106C71.5913 11.4106 71.5068 11.4874 71.4068 11.4874Z"
        fill="#4A5296"
      />
      <path
        d="M69.8505 6.933L69.7236 6.57169C70.1657 6.41409 71.165 6.20269 71.6993 6.17578L71.7147 6.56015C71.2227 6.57937 70.2618 6.78693 69.8505 6.933Z"
        fill="#4A5296"
      />
      <path
        d="M58.2071 9.79243C55.8778 9.46571 53.4024 10.615 52.1493 12.6099C50.9309 12.0372 49.6086 11.6182 48.2633 11.6682C46.918 11.7181 45.5458 12.2947 44.7694 13.394C42.3786 11.8834 39.0461 12.0372 36.809 13.7669C36.4246 13.2749 35.7789 13.0519 35.1524 13.025C34.5297 12.9981 33.9108 13.1365 33.2958 13.2326C32.1927 13.4055 30.29 15.3005 30.29 15.3005C31.8852 15.6272 36.2863 16.2537 37.0166 14.797C38.531 16.2115 40.7719 16.8034 42.7899 16.3191C43.8892 16.0539 44.9962 15.3927 45.3344 14.3127C46.3184 15.3274 47.7906 15.8502 49.1935 15.6772C50.5965 15.5042 51.8995 14.6471 52.6067 13.4248C55.1744 14.9892 57.9688 16.4844 59.979 14.2435"
        fill="#F24949"
      />
      <path
        d="M58.8722 9.15442C58.826 8.54711 58.4301 8.0013 57.9266 7.65537C57.4231 7.30943 56.8196 7.14031 56.22 7.025C54.0906 6.61372 51.8381 6.85972 49.8471 7.71687C49.6357 7.80912 49.4166 7.90905 49.186 7.88984C49.0284 7.8783 48.8823 7.80912 48.7362 7.74377C47.0066 6.97887 45.027 6.80975 43.1897 7.25178L43.1359 7.30943C43.1244 7.2979 43.1129 7.28637 43.0975 7.27484C43.1282 7.26715 43.159 7.25946 43.1859 7.25562L43.2551 7.17875C43.1936 7.20181 43.1321 7.22871 43.0706 7.25178C41.4524 5.69891 38.9655 5.11466 36.8322 5.80269C36.5478 5.89494 36.2672 6.00641 35.9712 6.02947C35.706 6.05253 35.3946 6.09866 35.1871 5.93338C33.073 4.25367 29.998 4.69954 29.998 5.02241C29.998 5.40679 30.9321 6.90584 32.0621 7.50546C33.1922 8.10508 34.6874 7.79759 35.5791 6.87893C37.2665 8.84308 40.3569 9.4158 42.6439 8.21271C43.3704 9.1967 44.4697 9.9078 45.6728 10.1423C46.9643 10.3959 48.3519 10.1115 49.4358 9.36583C49.5319 9.30049 49.628 9.2313 49.7433 9.21592C49.8586 9.20439 49.9739 9.25052 50.0815 9.29664C52.6184 10.3844 55.5089 10.6266 58.1918 9.97698C58.3917 9.92701 58.6031 9.86936 58.7338 9.71177C58.8606 9.56186 58.8875 9.35045 58.8722 9.15442Z"
        fill="#F24949"
      />
      <path
        d="M25.8666 57.7702C25.8512 55.168 26.3125 52.5619 27.2196 50.125C29.2798 50.1711 31.3324 50.4863 33.3119 51.0667C33.0621 55.66 32.8122 60.2532 32.5624 64.8465"
        fill="#DBA9A2"
      />
      <path
        d="M13.7431 60.2645C12.3133 58.8001 11.214 56.8551 10.5951 54.6911C10.5067 54.3836 10.4298 54.0531 10.4837 53.7379C10.5375 53.4342 10.7104 53.1844 10.8834 52.9537C13.3165 49.7019 16.5491 49.2215 20.7772 48.0684C23.241 53.6149 24.3211 57.7622 25.3897 63.9507"
        fill="#DBA9A2"
      />
      <path
        d="M18.0254 59.2539C18.698 56.644 20.716 54.3262 23.3336 53.6805C25.9512 53.0347 29.0223 54.3531 30.0332 56.8515C30.6059 58.266 30.4906 59.9381 29.7257 61.2603C28.9608 62.5826 27.5732 63.5204 26.0626 63.7318C24.6059 63.9356 23.1068 63.4858 21.8614 62.7056C20.6161 61.9214 19.5936 60.826 18.6904 59.6652C18.6212 59.5729 18.6442 59.3692 18.7365 59.4307"
        fill="#E2D1CA"
      />
      <path
        d="M12.7052 58.0662C12.5438 58.1623 12.7359 69.5897 12.7359 69.5897C19.6354 69.3706 26.5349 69.1554 33.4344 68.9363C33.5267 65.3193 33.569 61.7024 33.5613 58.0854C29.2409 58.047 12.8551 57.9739 12.7052 58.0662Z"
        fill="#4A5296"
      />
    </svg>
  );
}
