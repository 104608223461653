import lz from 'lz-string';

export const compressConfig = (config: DPI.ScreenConfig) => {
  try {
    return lz.compressToEncodedURIComponent(JSON.stringify(config));
  } catch (error) {
    console.log('Unable to compress config', config, error);
  }
};

export const decompressConfig = (encodedURIComponent: string) => {
  try {
    return JSON.parse(lz.decompressFromEncodedURIComponent(encodedURIComponent)) as DPI.ScreenConfig;
  } catch (error) {
    console.log('Unable to decompress config', encodedURIComponent, error);
    throw error;
  }
};
