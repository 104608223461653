
export function findColorMatch(vehicleType: DPI.TransportType, code: string, colors: DPI.PTAColor[]) {
  // Find first match ignore base untill we have testet specific once
  const firstMatch = colors
    .filter((c) => c.type !== 'base')
    .find((test) => {
      try {
        if (test.vehicle !== vehicleType) return false;
        if (test.type === 'exact') {
          return test.selector.includes(code);
        }
        if (test.type === 'range') {
          return Number(code) <= Number(test.selector[1]) && Number(code) >= Number(test.selector[0]);
        }
      } catch (ex) {
        console.log(ex);
      }
      return false;
    });

  if (firstMatch) {
    return firstMatch.color;
  }

  // Test base type
  return colors.filter((c) => c.type === 'base').find((test) => test.vehicle === vehicleType)?.color;
}
