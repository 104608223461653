import { init } from '@sentry/browser';
import { config } from './config';

export const isDevelopment = () => import.meta.env.DEV;

export const initSentry = () => {
  console.info(`Application v.${__APP_VERSION__}`);

  if (!isDevelopment()) {
    init({
      dsn: 'https://bcc2ae892e234da7b6b3b4d76329cbb0@sentry.io/1476262',
      maxBreadcrumbs: 50,
      //debug: isDevelopment,
      release: __APP_VERSION__,
      environment: config.environment,
    });
  }
};
