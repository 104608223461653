import React from 'react';

interface Props {
  className?: string;
}
export default function WearAMask({ className = '' }: Props) {
  return (
    <svg className={className} viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M51.5117 26.5301C48.9045 26.6628 46.2746 27.5684 44.4026 29.3874C42.6329 31.1078 41.5415 36.2691 40.632 38.5618C39.7529 38.3799 36.763 38.4481 36.5887 41.2182C36.5129 42.4043 38.0324 45.2616 40.0522 45.6671C40.2986 51.8326 40.0712 51.9766 41.352 58.0132C45.6796 58.5172 52.387 59.1122 56.7412 59.2941C56.9079 59.3016 56.3584 52.1243 56.3584 52.1243C56.6578 50.6578 61.0726 49.4944 61.5917 48.0885C62.0578 46.8266 63.2098 42.2376 63.3614 39.619C63.6191 35.2194 60.7618 28.1217 59.3559 27.451C57.4233 26.5339 53.6186 26.424 51.5117 26.5301Z"
        fill="#DBA9A2"
      />
      <path
        d="M45.9876 50.1728C45.9194 50.1728 45.8549 50.1538 45.7905 50.1197C44.3505 49.2519 43.0848 48.2402 41.8646 47.2663C41.2583 46.7812 40.6292 46.281 39.9964 45.8111C39.8296 45.686 39.7918 45.4473 39.9168 45.2806C40.0419 45.1138 40.2806 45.0759 40.4473 45.201C41.0915 45.6785 41.7244 46.1825 42.3345 46.6713C43.5358 47.6338 44.7825 48.6267 46.1808 49.4679C46.3589 49.5778 46.4158 49.809 46.3097 49.9871C46.2415 50.1084 46.1164 50.1728 45.9876 50.1728Z"
        fill="#F24949"
      />
      <path
        d="M45.9675 41.2786C45.9447 41.2786 45.922 41.2786 45.903 41.271C44.6487 41.0512 43.3376 40.4942 42.0681 39.9598C41.0752 39.5392 40.1354 39.1413 39.3396 38.9594C39.135 38.9139 39.0062 38.7093 39.0554 38.5047C39.1009 38.3 39.3055 38.1712 39.5102 38.2205C40.3704 38.4175 41.3405 38.8268 42.3674 39.2626C43.599 39.7817 44.8685 40.3236 46.0395 40.5283C46.2441 40.5662 46.3843 40.7594 46.3464 40.9679C46.3085 41.1497 46.1494 41.2786 45.9675 41.2786Z"
        fill="#F24949"
      />
      <path
        d="M82.1126 32.7372C82.0975 32.9987 82.0823 33.2677 82.1808 33.5065C82.3968 34.037 83.0373 34.2227 83.5981 34.3364C85.94 34.7949 88.3236 35.0412 90.7072 35.0715C91.124 35.0753 91.5636 35.0715 91.9312 34.8707C92.5527 34.5334 92.7725 33.768 92.9278 33.0783C93.9245 28.5877 94.5801 22.589 94.5876 17.9885C94.5914 13.9489 86.6373 13.282 84.6554 16.1392C82.0027 19.9591 82.3893 28.2808 82.1126 32.7372Z"
        fill="#DBA9A2"
      />
      <path
        d="M56.1084 57.9033C65.0554 61.7875 72.2744 65.5467 81.5814 68.4722C81.8845 68.5669 78.7961 36.432 82.1119 32.7373C82.3317 32.491 92.7263 32.3394 93.0522 32.472C97.6564 34.3516 103.424 82.8647 97.232 89.4546C92.4572 94.5363 70.7245 86.5556 65.0592 79.6739"
        fill="#9DA6D9"
      />
      <path
        d="M56.8629 57.7481C62.5661 62.269 73.1464 70.4277 76.61 76.8282C78.7359 80.7579 76.9851 88.8977 78.5767 93.07C80.7822 98.8451 79.9485 98.7049 80.6193 104.851C80.6572 105.193 80.6875 105.553 80.5397 105.859C80.3805 106.193 80.0395 106.398 79.7174 106.576C72.1801 110.79 63.6423 112.969 55.0667 113.958C46.4911 114.947 37.8245 114.78 29.1921 114.556C28.8662 114.549 28.5137 114.53 28.2561 114.333C28.0173 114.151 27.8998 113.855 27.8013 113.571C26.4447 109.649 26.1908 105.446 25.9899 101.305C25.7739 96.8822 25.6072 92.4068 26.5091 88.0716C28.2636 79.64 24.1748 81.2429 29.8742 74.7857C31.0754 73.429 39.2191 63.6445 40.2195 62.1287C41.0759 60.8327 44.1681 58.2635 45.1837 57.21C45.1875 57.2062 56.7796 57.6761 56.8629 57.7481Z"
        fill="#565EA3"
      />
      <path
        d="M59.6175 40.8773C59.5076 40.8773 59.3977 40.8281 59.3219 40.7371C57.8554 38.9333 56.5556 36.9779 55.4642 34.924C55.3657 34.7383 55.4377 34.511 55.6196 34.4124C55.8053 34.3139 56.0326 34.3859 56.1312 34.5678C57.1998 36.58 58.4731 38.4937 59.9093 40.2596C60.0419 40.4226 60.0154 40.6613 59.8562 40.7939C59.788 40.847 59.7046 40.8773 59.6175 40.8773Z"
        fill="#4A5296"
      />
      <path
        d="M45.6992 50.0247C48.7005 51.4496 54.2597 52.5864 57.8635 52.81C58.4736 52.8479 59.0951 52.8744 59.6938 52.7456C60.6904 52.5334 61.5544 51.9157 62.2707 51.1881C63.8206 49.6117 64.7604 47.4744 65.0029 45.2765C65.2416 43.0786 64.7983 40.8276 63.8547 38.8268C63.794 38.7017 63.7296 38.5729 63.6159 38.4895C63.3772 38.319 63.0475 38.4289 62.7785 38.5426C59.4323 39.9257 55.8209 40.5245 52.2171 40.6647C50.4133 40.7367 45.1649 40.4752 45.1649 40.4752C44.8466 41.0588 45.5022 49.9338 45.6992 50.0247Z"
        fill="#F24949"
      />
      <path
        d="M50.8721 38.0195C51.2048 38.0195 51.4746 37.7497 51.4746 37.417C51.4746 37.0842 51.2048 36.8145 50.8721 36.8145C50.5393 36.8145 50.2695 37.0842 50.2695 37.417C50.2695 37.7497 50.5393 38.0195 50.8721 38.0195Z"
        fill="#4A5296"
      />
      <path
        d="M59.2646 36.6064C59.5974 36.6064 59.8672 36.3367 59.8672 36.0039C59.8672 35.6711 59.5974 35.4014 59.2646 35.4014C58.9319 35.4014 58.6621 35.6711 58.6621 36.0039C58.6621 36.3367 58.9319 36.6064 59.2646 36.6064Z"
        fill="#4A5296"
      />
      <path
        d="M61.5273 92.747C63.3577 91.8489 76.2154 85.1491 76.3708 84.6375C77.4243 81.2118 76.3139 79.7528 77.2462 76.2892C77.3977 75.717 78.0874 74.9212 78.6672 74.8037C79.5161 74.6332 80.0163 75.6109 80.1868 76.4597C80.6984 78.9911 80.6756 80.6206 80.49 83.1975C82.3885 82.9284 84.2908 82.6556 86.1894 82.3865C86.7805 82.3031 87.402 82.2198 87.9628 82.4282C89.2096 82.8905 89.5468 84.4518 89.8348 85.7516C90.3237 87.9722 91.2028 90.3028 90.4449 92.4438C89.5809 94.8805 86.951 96.1348 84.5674 97.1352C79.0196 99.4658 73.4756 101.796 67.9278 104.127C66.8781 102.179 65.8284 100.231 64.7787 98.2796"
        fill="#DBA9A2"
      />
      <path
        d="M95.4423 95.4078C95.3379 95.2876 95.3179 95.1143 95.4008 94.967C95.5049 94.7863 95.7375 94.7248 95.9182 94.8289C95.9286 94.835 96.8577 95.3732 97.7562 95.6971C97.9527 95.7673 98.0557 95.9841 97.9825 96.1831C97.9123 96.3796 97.6955 96.4826 97.499 96.4123C96.5296 96.0646 95.5749 95.5085 95.5336 95.4841C95.4998 95.4683 95.4696 95.4393 95.4423 95.4078Z"
        fill="#4A5296"
      />
      <path
        d="M95.4009 99.9316C95.391 99.9201 95.381 99.9087 95.374 99.8948L94.1051 98.0405C93.9856 97.8682 94.0318 97.6324 94.204 97.5129C94.3763 97.3934 94.6121 97.4396 94.7316 97.6119L96.0005 99.4661C96.12 99.6384 96.0738 99.8742 95.9016 99.9937C95.7403 100.109 95.5276 100.077 95.4009 99.9316Z"
        fill="#4A5296"
      />
      <path
        d="M71.7154 78.1177C71.6968 78.1047 71.6812 78.0939 71.6647 78.0778L69.7746 76.2103C69.6262 76.0653 69.6239 75.8235 69.7689 75.6751C69.9139 75.5268 70.1557 75.5244 70.3041 75.6694L72.1943 77.5369C72.3426 77.6819 72.3449 77.9237 72.1999 78.0721C72.071 78.2039 71.8646 78.2217 71.7154 78.1177Z"
        fill="#E5E8FF"
      />
      <path
        d="M67.5239 81.9706C67.3902 81.8774 67.3265 81.7037 67.3815 81.5387C67.4466 81.3393 67.6643 81.2324 67.8616 81.3006C68.3627 81.4698 68.8848 81.5889 69.4089 81.6587C69.6155 81.6872 69.7608 81.8763 69.7354 82.085C69.7069 82.2915 69.5179 82.4368 69.3092 82.4114C68.7378 82.3365 68.1698 82.2038 67.6227 82.021C67.5851 82.0087 67.5519 81.9901 67.5239 81.9706Z"
        fill="#E5E8FF"
      />
      <path
        d="M12.1013 101.33C11.241 103.835 10.6158 106.552 11.2751 109.114C11.7337 110.891 12.7872 112.475 14.0718 113.79C17.812 117.61 23.4167 119.167 28.7637 118.985C34.1106 118.804 39.2681 117.053 44.2361 115.071C51.8227 112.043 68.0758 106.897 75.0978 102.721C75.4199 102.531 72.0851 91.3032 69.6902 87.5061C56.9916 91.2767 43.41 96.1803 31.7195 100.205C31.7195 100.205 37.1081 95.411 40.9166 91.7921C44.725 88.1731 48.0332 83.9137 49.9166 79.0101C50.7579 76.8236 51.3073 74.3756 50.576 72.1473C48.2075 64.917 37.7031 66.429 33.4816 70.8892C28.3089 76.3575 23.0794 82.3486 18.7783 88.5331C16.0196 92.4969 13.6701 96.7601 12.1013 101.33Z"
        fill="#9DA6D9"
      />
      <path
        d="M62.0278 29.0653C61.4707 26.8864 59.9625 25.022 58.1056 23.7525C56.2488 22.483 54.0547 21.7706 51.8416 21.3803C49.981 21.0506 48.0635 20.9407 46.2104 21.2855C44.2892 21.6455 42.4816 22.4906 40.8256 23.5289C36.5548 26.2156 33.2163 30.2098 30.719 34.5942C28.2217 38.9787 26.524 43.7686 25.012 48.585C24.2314 51.0785 23.4886 53.6099 23.2992 56.2133C23.2537 56.8385 23.2423 57.4903 23.4924 58.0663C23.7425 58.6499 24.2314 59.0971 24.7278 59.4988C29.8929 63.7051 40.9885 66.1607 48.325 64.9064C50.6934 64.5009 48.5713 60.9729 47.8589 59.26C46.2597 55.4288 44.5089 51.6621 42.6218 47.9673C42.1481 47.0351 41.6479 46.0915 40.8862 45.3753C39.6167 44.1778 37.5931 43.5526 37.1384 41.8662C36.7329 40.3542 38.2373 38.6793 39.7835 38.9218C40.1624 38.9824 40.5451 39.134 40.9165 39.0393C41.4091 38.9142 41.6934 38.414 41.9207 37.9631C42.7279 36.3677 43.5237 34.7647 44.3384 33.1732C45.0167 31.8468 45.4677 31.8734 46.8736 31.8961C50.197 31.9529 53.5431 31.5437 56.7717 30.7517C57.2265 30.6418 62.0884 29.3003 62.0278 29.0653Z"
        fill="#4A5296"
      />
      <path
        d="M78.796 17.5527C78.7202 17.5527 78.6444 17.53 78.58 17.4845C78.4095 17.3633 78.364 17.1283 78.4853 16.9578C78.5611 16.8517 78.6937 16.647 78.8908 16.3439C80.0541 14.5552 83.1426 9.80701 89.4294 1.39434C89.5544 1.2276 89.7931 1.1935 89.9599 1.31855C90.1266 1.4436 90.1607 1.68234 90.0357 1.84908C83.7641 10.2428 80.687 14.9759 79.5274 16.7569C79.3228 17.0715 79.1864 17.2837 79.1106 17.3936C79.0348 17.4959 78.9173 17.5527 78.796 17.5527Z"
        fill="#4A5296"
      />
      <path
        d="M99.7635 18.4092C99.6233 18.4092 99.4868 18.3296 99.4224 18.197C96.8835 13.0016 92.9689 6.52913 89.6796 2.08405C89.5546 1.91731 89.5887 1.67858 89.7592 1.55352C89.9259 1.42847 90.1647 1.46637 90.2897 1.6331C93.6055 6.10849 97.5504 12.6302 100.105 17.8635C100.195 18.053 100.12 18.2803 99.9302 18.3713C99.8772 18.3978 99.8203 18.4092 99.7635 18.4092Z"
        fill="#4A5296"
      />
      <path
        d="M99.7635 18.4088C99.7597 18.4088 99.7559 18.4088 99.7521 18.4088L79.0387 17.7911C78.8303 17.7835 78.6636 17.6092 78.6711 17.4008C78.6787 17.1962 78.8455 17.0332 79.0501 17.0332C79.0539 17.0332 79.0577 17.0332 79.0615 17.0332L99.7748 17.6509C99.9832 17.6585 100.15 17.8328 100.142 18.0412C100.139 18.2458 99.9681 18.4088 99.7635 18.4088Z"
        fill="#4A5296"
      />
      <path
        d="M108.2 1.7579H66.3448C66.1363 1.7579 65.9658 1.58737 65.9658 1.37895C65.9658 1.17053 66.1363 1 66.3448 1H108.2C108.408 1 108.579 1.17053 108.579 1.37895C108.579 1.58737 108.408 1.7579 108.2 1.7579Z"
        fill="#4A5296"
      />
      <path
        d="M87.2764 19.4547C87.2348 20.1065 87.3106 20.7886 87.6706 21.3532C87.9737 21.8269 88.4739 22.1717 89.0082 22.4256C90.4558 23.1153 91.5434 23.0888 94.143 22.6833C96.7426 22.2778 97.1443 18.4088 95.4125 16.677C93.6807 14.9452 92.468 14.8012 90.9636 14.57C89.4137 14.3313 87.6933 14.9224 86.2646 15.7523C86.2684 15.7523 87.4242 17.1393 87.2764 19.4547Z"
        fill="#DBA9A2"
      />
      <path
        d="M48.1556 35.4093C48.0109 35.5379 47.8596 35.7121 47.9244 35.8849C47.9527 35.9562 48.0147 36.0154 48.0876 36.0515C48.3449 36.1894 48.6882 36.1205 48.9504 35.9891C50.8264 35.0268 51.9471 35.4472 52.9917 35.0658C53.0744 35.0373 53.232 34.1806 52.7649 33.9871C52.2979 33.7937 49.3137 34.3846 48.1556 35.4093Z"
        fill="#565EA3"
      />
      <path
        d="M62.6364 32.882C62.8089 32.9572 63.0009 33.072 63.001 33.2545C63.0022 33.3307 62.9657 33.4037 62.9151 33.4641C62.7339 33.6772 62.4069 33.7223 62.1287 33.684C60.1344 33.3852 59.2842 34.1435 58.2253 34.1201C58.1419 34.117 57.7099 33.3662 58.0607 33.0341C58.4116 32.702 61.263 32.2964 62.6364 32.882Z"
        fill="#565EA3"
      />
    </svg>
  );
}
