import React, { useEffect } from 'react';

import useMQTT from './hooks/useMQTT';
import useConfig from './hooks/useConfig';
import useDepartures from './hooks/useDepartures';

import Display from './components/Display';
import OutOfService from './components/OutOfService';

function App() {
  const { screenConfig, mqttConfig, outOfService, isSnapshotTest } = useConfig();
  const { tarrifZones } = useDepartures();
  const { mqttConnect, mqttDisconnect } = useMQTT();

  useEffect(() => {
    if (!screenConfig || !mqttConfig) {
      return;
    }

    // No need for mqtt when testing
    if (isSnapshotTest) {
      return;
    }
    mqttConnect(screenConfig, mqttConfig, outOfService);

    return () => {
      mqttDisconnect();
    };
  }, [screenConfig, mqttConfig, outOfService, isSnapshotTest]);

  return (
    <div className="app">
      {outOfService ? (
        <OutOfService config={screenConfig?.outOfService} />
      ) : (
        <Display />
      )}
    </div>
  );
}

export default App;
