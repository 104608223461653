export const DEFAULT_TIME_RANGE = 7200; // i.e. 2 hours
export const MIN_TIME_RANGE = 3600; // i.e 1 hour
export const MAX_TIME_RANGE = 14400; // i.e. 4 hours

export const getLegalTimeRange = (requestedTimeRange?: number) => {
  if (typeof requestedTimeRange !== 'number') {
    return DEFAULT_TIME_RANGE;
  }

  if (requestedTimeRange < MIN_TIME_RANGE) {
    return MIN_TIME_RANGE;
  }

  if (requestedTimeRange > MAX_TIME_RANGE) {
    return MAX_TIME_RANGE;
  }

  return requestedTimeRange;
};
