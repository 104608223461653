import React, { useMemo } from 'react';

import TopHeader from '../TopHeader';
import DepartureBoard from '../DepartureBoard';
import SituationsFooter from '../SituationsFooter';
import MultiMediaContentWrapper from '../MultimediaContent';

import { getBalancedDepartureDecreaseMap } from '~/utils/departures';
import { getBoardByIndex } from '~/utils/config';
import { CampaignContent } from '~/types/Campaign';

import './styles.scss';

interface Props {
  departures: DPI.BoardDeparture[];
  allDeparturesTramOrMetro: boolean;
  screenConfig: DPI.ScreenConfig;
  activePlaylistItem?: DPI.Situation | CampaignContent;
  activeSituationIndex: number;
  situationCount: number;
}

const MetroDisplay = ({
  departures,
  allDeparturesTramOrMetro,
  screenConfig,
  activePlaylistItem,
  activeSituationIndex,
  situationCount,
}: Props) => {
  const hideTrack = useMemo(() => {
    if (screenConfig && (screenConfig.hideTracks || screenConfig.boards.every((board) => board.hideTracks === true))) {
      return true;
    }
    return screenConfig && departures.every((ec) => ec.entries.every((departure) => !departure.quayPublicCode));
  }, [departures, screenConfig]);

  const showFullVersionOfSituation = screenConfig.situationsInfo ? screenConfig.situationsInfo.showFullVersion : false;
  const removeDeparturesCount = showFullVersionOfSituation ? 4 : 2;
  const bddMap = activePlaylistItem ? getBalancedDepartureDecreaseMap(departures, removeDeparturesCount) : {};

  const padding = typeof screenConfig.padding === 'string' ? screenConfig.padding : '0';

  return (
    <div className="metro-display" style={{ padding }}>
      <MultiMediaContentWrapper screenConfig={screenConfig} placementFilter="TOP" />
      <TopHeader screenConfig={screenConfig} />
      {departures.map((departure, index) => {
        const estimatedCalls = departure.entries;
        const maxDepartures = estimatedCalls.length - (bddMap[index] || 0);
        const boardDepartures = estimatedCalls.slice(0, maxDepartures);
        const boardConfig = getBoardByIndex(screenConfig, index);
        if (boardConfig) {
          return (
            <DepartureBoard
              key={index}
              estimatedCalls={boardDepartures}
              screenConfig={screenConfig}
              index={index}
              hideTrack={hideTrack}
              allDeparturesTramOrMetro={allDeparturesTramOrMetro}
              currentSituation={activePlaylistItem as DPI.Situation}
              boardConfig={boardConfig}
            />
          );
        }
        return null;
      })}
      <SituationsFooter
        numberOfSituations={situationCount}
        situation={activePlaylistItem as DPI.Situation}
        activeIndex={activeSituationIndex}
        includeBody={showFullVersionOfSituation}
      />
      <MultiMediaContentWrapper screenConfig={screenConfig} placementFilter="BOTTOM" />
    </div>
  );
};

export default MetroDisplay;
