import React from 'react';

import BusShelterDepartures from '../BusShelterDepartures';
import BusShelterCarousel from '../BusShelterCarousel';
import BusShelterHeader from '../BusShelterHeader';

import { getBoardByIndex } from '~/utils/config';
import { CampaignContent } from '~/types/Campaign';

import './styles.scss';

interface Props {
  departures: DPI.BoardDeparture[];
  activePlaylistItem: DPI.Situation | CampaignContent;
  activeIndex: number;
  activeSituationIndex: number;
  screenConfig: DPI.ScreenConfig;
  allDeparturesTramOrMetro?: boolean;
  playlistCount: number;
  situationCount: number;
}

function BusShelterGroup(props: Props) {
  const {
    departures,
    activePlaylistItem,
    activeIndex,
    activeSituationIndex,
    screenConfig,
    allDeparturesTramOrMetro,
    playlistCount,
    situationCount,
  } = props;

  return (
    <div className="busShelterGroup">
      {departures.map((boardDepartures, index) => {
        const boardConfig = getBoardByIndex(screenConfig, index);
        const hideClock = departures.length > 1 && index > 0;
        const largeHeader = boardConfig?.header?.large ?? false;

        if (!boardConfig) {
          return;
        }

        return (
          <div
            className="busShelterBoard"
            key={`busShelterBoard-${index}`}
            style={{ paddingTop: index > 0 ? '1.75rem' : 0 }}
          >
            <BusShelterHeader
              boardConfig={boardConfig}
              hideClock={hideClock}
              largeHeader={largeHeader}
              departures={boardDepartures}
            />
            <div className="departuresAndSituations">
              <BusShelterDepartures
                departures={boardDepartures.entries}
                activeSituation={activePlaylistItem}
                boardConfig={boardConfig}
                allDeparturesTramOrMetro={allDeparturesTramOrMetro}
              />
              {index === 0 && (
                <BusShelterCarousel
                  currentPlaylistItem={activePlaylistItem}
                  activeIndex={activeIndex}
                  fullHeight
                  largeHeader={largeHeader}
                  playlistCount={playlistCount}
                  situationCount={situationCount}
                  activeSituationIndex={activeSituationIndex}
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default BusShelterGroup;
