export const isMinutesBefore = (date: Date, minutes = 0) => {
  try {
    const minutesInMs = 60 * minutes * 1000;
    if (!date) {
      return false;
    }

    let prevDate: Date;

    if (typeof date === 'object') {
      prevDate = date;
    } else if (typeof date === 'string') {
      prevDate = new Date(date);
    } else {
      return false;
    }

    const minTreshold = Date.now() - minutesInMs;
    return prevDate.getTime() < minTreshold;
  } catch (error) {
    console.error('isMinutesBefore', error);
  }
};
