import React from 'react';

interface Props {
  className?: string;
}
const SurveyArrowIcon = ({ className }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122 142" className={className}>
      <path
        d="M119.39 4.90517C118.785 1.92839 115.882 0.0054121 112.905 0.61007C109.928 1.21473 108.005 4.11805 108.61 7.09483L119.39 4.90517ZM1.61091 96.6109C-0.536971 98.7588 -0.536971 102.241 1.61091 104.389L36.6127 139.391C38.7606 141.539 42.243 141.539 44.3909 139.391C46.5388 137.243 46.5388 133.761 44.3909 131.613L13.2782 100.5L44.3909 69.3873C46.5388 67.2394 46.5388 63.757 44.3909 61.6091C42.243 59.4612 38.7606 59.4612 36.6127 61.6091L1.61091 96.6109ZM89.5 89L86.2937 84.5312L89.5 89ZM108.61 7.09483C115.093 39.0096 105.658 70.6376 86.2937 84.5312L92.7063 93.4688C116.796 76.1844 126.394 39.3892 119.39 4.90517L108.61 7.09483ZM86.2937 84.5312C77.4347 90.8875 65.8836 93.6491 51.8957 94.6886C37.8033 95.7359 22.3563 95 5.5 95V106C21.61 106 38.0844 106.745 52.711 105.658C67.4422 104.564 81.4079 101.575 92.7063 93.4688L86.2937 84.5312Z"
        fill="#e5e8ff"
      />
    </svg>
  );
};

export default SurveyArrowIcon;
