import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import BusShelterHeader from '../BusShelterHeader';
import LineNumber from '../../Departures/LineNumber';
import { UNABLE_TO_FETCH_DEPARTURES_TEXTS, NO_DEPARTURES_TEXTS, NO_DEPARTURE_SOON } from '~/texts/fallback';

import './styles.scss';

interface Props {
  template: DPI.TemplateType;
  text: DPI.MessageText;
}

const UnableToShowDepartures = ({ template, text }: Props) => {
  const isBusShelterTemplate = template === 'BUS_SHELTER_DEFAULT';
  const [showEnglishText, setShowEnglishText] = useState(false);
  useEffect(() => {
    let isMounted = true;
    let loop = setTimeout(() => {
      if (isMounted) {
        setShowEnglishText(!showEnglishText);
      }
    }, 10 * 1000);
    return () => {
      clearTimeout(loop);
      isMounted = false;
    };
  }, [showEnglishText]);

  const strings = showEnglishText ? text.en : text.no;

  return (
    <div
      className={cx('unableToFetchDepartures', {
        '-busShelter': isBusShelterTemplate,
      })}
    >
      <span className="title">{strings.title}</span>
      <div className="body">
        <div className="weAreSorry">{strings.body}</div>
      </div>
    </div>
  );
};

interface NoDeparturesSoonProps {
  firstDeparture: DPI.FirstEstimatedCall;
  isLast: boolean;
}
const NoDeparturesSoon = ({ firstDeparture, isLast }: NoDeparturesSoonProps) => {
  const [showEnglishText, setShowEnglishText] = useState(false);
  useEffect(() => {
    let isMounted = true;
    let loop = setTimeout(() => {
      if (isMounted) {
        setShowEnglishText(!showEnglishText);
      }
    }, 10 * 1000);
    return () => {
      clearTimeout(loop);
      isMounted = false;
    };
  }, [showEnglishText]);

  const strings = showEnglishText ? NO_DEPARTURE_SOON.en : NO_DEPARTURE_SOON.no;
  return (
    <div className="noDeparturesSoon">
      <div className="firstDeparture">
        <div className="titles">{strings.nextDeparture}</div>
        <div className="destinationWrapper">
          <span className="destination">
            <LineNumber
              lineNumber={firstDeparture.lineNumber}
              busShelter
              transportMode={firstDeparture.transportMode}
              className="nextDepartureLineNumber"
            />
            {firstDeparture.frontText}
          </span>
          <span className="departure">
            - {strings.departureDay(firstDeparture.departureTime)},{' '}
            {strings.getDepartureTime(firstDeparture.departureTime)}
          </span>
        </div>
      </div>
      {isLast && <div className="moreInfoGuidance">{strings.moreInfo}</div>}
    </div>
  );
};

interface BusShelterNoDeparturesProps {
  screenConfig: DPI.ScreenConfig;
  firstDepartures: DPI.FirstEstimatedCall[];
  unableToFetch: boolean;
}
export const BusShelterNoDepartures = ({
  screenConfig,
  firstDepartures = [],
  unableToFetch,
}: BusShelterNoDeparturesProps) => {
  const boardConfigs = screenConfig ? screenConfig.boards : [];

  return (
    <div className="busShelterNoDepartures">
      {boardConfigs.map((boardConfig, index) => {
        const largeHeader = boardConfig?.header?.large ?? false;
        const numberOfDepartures = boardConfig.numberOfDepartures || 4;
        const firstDeparture = firstDepartures.length ? firstDepartures[0] : null;
        return (
          <div key={`board-${index}`} style={{ paddingTop: index > 0 ? '1.75rem' : 0 }}>
            <BusShelterHeader
              largeHeader={largeHeader}
              boardConfig={boardConfig}
              hideClock={index > 0}
              fullBottomBoarder
              hideColumns
            />
            <div
              className={cx('fallbackBody', { '-center': !firstDeparture })}
              style={{
                height: `${8.395833333333334 * numberOfDepartures}rem`,
              }}
            >
              {firstDeparture ? (
                <NoDeparturesSoon firstDeparture={firstDeparture} isLast={index === boardConfigs.length - 1} />
              ) : (
                <UnableToShowDepartures
                  template={screenConfig.template}
                  text={unableToFetch ? UNABLE_TO_FETCH_DEPARTURES_TEXTS : NO_DEPARTURES_TEXTS}
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
