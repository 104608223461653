import React from 'react';

interface Props {
  className?: string;
}
export default function FromAToB({ className = '' }: Props) {
  return (
    <svg className={className} viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.7715 67.2204C26.2706 68.22 30.5376 69.6934 33.1971 70.1036C33.0181 67.5337 32.8428 64.9675 32.6637 62.3975L31.5708 62.4684C30.5153 62.1737 29.0345 61.715 27.535 61.2524"
        fill="#F24949"
      />
      <path
        d="M23.1041 59.9098C22.6416 59.783 11.433 70.7864 11.5151 70.9355C11.8098 71.4839 12.3133 71.883 12.8243 72.2373C17.2965 75.363 23.9918 69.0109 25.4241 67.9777C25.4241 67.9777 26.0097 65.3518 26.7557 63.8598C27.5017 62.3678 28.9937 61.6218 28.9937 61.6218C27.5017 60.8796 24.2529 60.2306 23.1041 59.9098Z"
        fill="#4A5296"
      />
      <path
        d="M86.3488 63.166L82.3018 70.1485C82.7605 70.749 85.2783 73.5875 87.7885 76.0642L93.5178 75.0571L92.7494 69.865L86.3488 63.166Z"
        fill="#F24949"
      />
      <path
        d="M104.57 66.5903C100.117 63.4385 94.186 68.8394 92.7499 69.8652C92.7499 69.8652 91.6533 72.0733 90.9073 73.1923C90.1613 74.3113 87.7891 76.0644 87.7891 76.0644C89.7361 77.9853 91.6757 79.6899 92.638 79.9659C93.1005 80.0965 104.503 68.5 104.615 68.3732C105.316 67.5787 105.077 66.9484 104.57 66.5903Z"
        fill="#4A5296"
      />
      <path
        d="M50.4333 49.2012L47.7962 48.5298C46.8973 52.6029 47.3001 59.093 45.7709 62.6365C45.5918 63.0543 35.5023 61.5958 31.5858 61.2005C31.321 61.1744 31.0412 61.152 30.8025 61.2639C30.4892 61.4131 30.3213 61.7562 30.1908 62.0733C29.1539 64.5537 28.6466 67.2542 28.7175 69.9398C28.7361 70.6559 35.4948 72.4799 37.923 72.7895C41.5896 73.2594 51.6157 75.9189 54.6892 71.29C58.628 65.3556 59.001 58.2836 59.6426 51.2154C55.3792 50.5477 54.637 50.171 50.4333 49.2012Z"
        fill="#DBA9A2"
      />
      <path
        d="M74.7827 51.562C72.832 50.6034 61.6421 48.1528 60.3552 47.985L46.6961 44.7324C46.5991 46.7764 46.1776 47.4404 46.4537 49.4695C47.0094 53.5575 48.7476 55.4635 52.3545 57.4591C55.9651 59.4546 69.8629 60.8794 70.1538 61.1965C75.3683 66.8474 85.0849 74.7773 85.5623 74.4267C87.2893 73.1697 91.6347 69.9321 91.866 67.6754C91.9331 67.0264 77.4795 52.8861 74.7827 51.562Z"
        fill="#DBA9A2"
      />
      <path
        d="M61.8994 43.446C62.5335 44.039 65.9017 46.4747 66.685 46.8477C68.0762 47.5191 69.7137 47.4258 71.2355 47.1424C73.9248 46.6425 76.4798 45.6019 78.9752 44.4866C80.7171 43.7071 82.4441 42.8865 84.1449 42.0211C86.6216 40.7567 89.1356 39.3094 90.7171 37.0192C91.258 36.2322 91.6832 35.3408 91.7764 34.3933C91.81 34.0315 90.2024 32.0845 89.2885 32.3381C86.8976 32.9946 85.6929 35.5198 85.6929 35.5198C81.9629 36.6388 74.13 38.8768 70.4 40.3688C70.2098 40.4471 64.253 35.3669 64.0591 35.5198"
        fill="#565EA3"
      />
      <path
        d="M54.6634 22.5805C52.8059 21.51 50.9148 20.5029 48.9939 19.5555C48.2143 19.1713 47.4198 18.7946 46.5619 18.6641C45.0326 18.4254 43.4959 18.9886 42.1233 19.701C39.6951 20.958 37.5503 22.6962 35.4877 24.4903C34.0479 25.7435 32.6342 27.0266 31.2579 28.3508C29.2512 30.2792 27.2668 32.3941 26.4201 35.0461C26.1292 35.9562 25.9837 36.9334 26.1702 37.8659C26.2411 38.224 28.9378 39.7533 29.7361 39.246C31.8323 37.9219 32.347 34.7701 32.347 34.7701C35.1296 32.2523 41.575 27.9629 44.3575 25.4451C44.5104 25.3071 47.1587 27.0229 48.6992 28.2725C49.4116 28.8506 50.0905 29.522 50.9633 29.8129C51.1498 29.8763 51.3549 29.9211 51.5451 29.8614C51.7801 29.7905 51.9405 29.5779 52.0785 29.3765C53.3542 27.5302 54.3016 25.4601 54.8648 23.2892"
        fill="#565EA3"
      />
      <path
        d="M60.698 31.04C63.2642 33.278 65.4201 35.9263 67.9901 38.1643C67.2851 40.7118 65.9684 43.0915 64.1855 45.0386C64.0773 45.1579 63.9542 45.281 63.7939 45.3034C63.626 45.3258 63.4694 45.2325 63.3276 45.143C61.388 43.9196 59.4485 42.6924 57.5089 41.469C57.1732 41.2564 56.83 41.0401 56.5913 40.723C56.2071 40.2157 56.1474 39.5332 56.1661 38.8953C56.2407 36.0345 57.5947 33.2295 59.7916 31.3907"
        fill="#F24949"
      />
      <path
        d="M63.4734 27.2277C60.4447 24.3967 54.2007 20.9689 50.3365 19.462C50.1015 19.3724 45.7225 25.2098 45.0325 27.6828C44.9691 27.9066 49.5644 31.092 50.6274 32.1588C47.9866 36.575 45.4838 40.3945 45.0176 45.5195C50.1202 48.1603 56.0172 49.2457 61.7241 48.6004C62.0971 48.5594 62.4925 48.4997 62.7759 48.2535C63.0035 48.0596 63.1303 47.7724 63.2422 47.4926C65.1743 42.7742 66.2896 31.0398 65.6555 29.9916C65.178 29.2046 64.3574 28.6862 63.7122 28.0334C63.5182 27.8395 63.4212 27.3844 63.6972 27.3993"
        fill="#F24949"
      />
      <path
        d="M72.6344 20.5959C73.6005 21.3158 74.007 21.741 74.9731 22.4609C75.1484 22.5915 75.3386 22.7519 75.3461 22.9682C75.3536 23.2666 75.0104 23.4344 74.7157 23.4717C73.7683 23.5911 73.7012 23.606 73.7683 23.5911L72.7911 25.8962C72.4181 25.9186 71.3998 25.5419 70.4412 24.1767C70.0234 23.5799 69.505 23.8783 69.2774 24.3259C69.132 24.6057 69.1506 25.1577 69.2774 25.4449C69.7586 26.5266 70.609 27.0861 71.6684 27.6195C70.3964 29.5479 69.6504 29.5479 68.5315 29.5479C68.1808 29.5479 67.3864 29.4919 67.0395 29.5479C66.5695 29.6262 65.9205 31.0398 64.8686 30.9093C62.8545 30.6631 61.687 27.1383 61.8175 26.9369C62.7985 25.43 63.6825 25.4449 65.1745 23.9529C64.3427 23.9044 64.0033 22.8041 64.4285 22.0879C64.8537 21.3718 66.2301 21.0062 66.6665 21.7149C68.0876 20.4393 68.5315 18.358 71.9779 20.0365"
        fill="#565EA3"
      />
      <path
        d="M71.7011 15.8739C71.4848 15.1652 71.1789 14.4715 70.709 13.8933C69.8772 12.8713 68.5978 12.3006 67.2998 12.0619C66.6992 11.95 65.9794 11.9463 65.6064 12.4274C65.3005 12.8228 65.3677 13.4047 65.6176 13.8374C65.8675 14.2663 66.2703 14.5871 66.647 14.9116C67.1506 15.348 67.6206 15.8217 68.057 16.3253C67.2028 15.7098 66.233 15.251 65.2147 14.9862C64.3531 14.7624 63.4243 14.6766 62.5814 14.9638C62.0927 15.1317 61.6079 15.4897 61.5482 16.0045C61.4922 16.4894 61.8316 16.9183 62.1673 17.2764C62.8052 17.9552 63.5027 18.5856 64.2412 19.1526C63.2938 18.5222 62.0219 18.4178 60.9849 18.884C60.7537 18.9884 60.5224 19.1302 60.4143 19.3577C60.3322 19.5293 60.3285 19.7307 60.3471 19.9247C60.3919 20.3835 60.5485 20.8348 60.7947 21.2264C61.0782 21.674 61.4736 22.0358 61.772 22.4722C62.3837 23.3562 63.1409 25.486 63.305 25.4487C63.954 25.2958 65.1252 24.4752 65.543 23.9568C65.543 23.9568 64.3233 22.6587 65.5803 22.0582C66.289 21.7188 66.5501 22.2932 66.662 22.4648C66.7739 22.6363 67.0349 22.4648 68.5269 20.9728C70.0189 19.4808 72.2569 20.2268 72.2569 20.2268C72.7157 18.8616 72.1226 17.2503 71.7011 15.8739Z"
        fill="#9DA6D9"
      />
      <path
        d="M21.2199 94.1322C21.0371 94.1322 20.8768 93.9979 20.8506 93.8114C20.8208 93.6063 20.9625 93.4198 21.164 93.3899C24.0286 92.9722 26.7328 92.5768 30.9215 93.3377C31.123 93.375 31.2572 93.569 31.2237 93.7704C31.1864 93.9718 30.9961 94.1098 30.791 94.0688C26.7216 93.3302 24.0771 93.7144 21.2759 94.1247C21.2535 94.1322 21.2386 94.1322 21.2199 94.1322Z"
        fill="#565EA3"
      />
      <path
        d="M43.1636 99.1378C43.0815 99.1378 42.9957 99.108 42.9249 99.052C41.224 97.6458 39.2098 96.5567 37.1024 95.9002C36.9047 95.8405 36.7965 95.6316 36.8562 95.4339C36.9159 95.2363 37.1248 95.1281 37.3225 95.1878C39.5232 95.8741 41.6231 97.0117 43.3986 98.4776C43.559 98.6081 43.5814 98.8431 43.4471 99.0035C43.3762 99.093 43.2718 99.1378 43.1636 99.1378Z"
        fill="#565EA3"
      />
      <path
        d="M55.379 107.061C55.3193 107.061 55.2596 107.046 55.2037 107.016C53.1671 105.942 51.2089 104.685 49.3849 103.282C49.2208 103.155 49.191 102.924 49.3178 102.76C49.4446 102.596 49.6759 102.566 49.84 102.693C51.6304 104.069 53.5513 105.304 55.5506 106.356C55.7333 106.453 55.8042 106.676 55.7072 106.859C55.6438 106.99 55.5133 107.061 55.379 107.061Z"
        fill="#565EA3"
      />
      <path
        d="M63.9734 107.877C63.7831 107.877 63.619 107.732 63.6041 107.538C63.5855 107.333 63.7347 107.15 63.9398 107.131C65.4243 106.989 66.9051 106.698 68.3337 106.266C68.4493 106.229 68.5799 106.195 68.7142 106.158C69.393 105.967 70.236 105.732 71.1237 105.143C71.2953 105.027 71.5266 105.076 71.6422 105.248C71.7578 105.419 71.7093 105.65 71.5377 105.766C70.553 106.422 69.6056 106.687 68.9156 106.878C68.785 106.915 68.6619 106.948 68.55 106.982C67.073 107.43 65.5474 107.728 64.0107 107.877C63.9995 107.877 63.9846 107.877 63.9734 107.877Z"
        fill="#565EA3"
      />
      <path
        d="M77.8257 102.819C77.7138 102.819 77.6019 102.767 77.5273 102.67C77.4042 102.506 77.4378 102.271 77.6019 102.148C78.5605 101.432 79.4221 100.828 80.2577 100.242C81.5594 99.3318 82.7866 98.4702 84.1443 97.3363C84.3047 97.2058 84.5397 97.2244 84.6702 97.3848C84.8008 97.5415 84.7821 97.7802 84.6217 97.9107C83.2379 99.0633 81.9996 99.9324 80.6866 100.854C79.8548 101.436 78.9969 102.036 78.0495 102.748C77.9786 102.793 77.9003 102.819 77.8257 102.819Z"
        fill="#565EA3"
      />
      <path
        d="M91.0298 95.0683C90.8657 95.0683 90.7165 94.9601 90.6717 94.7923C90.6195 94.5946 90.7351 94.3894 90.9328 94.3335C93.0663 93.7553 95.2596 93.3525 97.4602 93.1324C97.6617 93.1138 97.8482 93.263 97.8668 93.4681C97.8855 93.6733 97.7363 93.856 97.5311 93.8747C95.3715 94.091 93.2193 94.4864 91.1268 95.0534C91.0932 95.0646 91.0634 95.0683 91.0298 95.0683Z"
        fill="#565EA3"
      />
      <path
        d="M119.631 98.9254C119.586 98.9254 119.542 98.9179 119.497 98.8993C118.579 98.5412 117.49 98.1197 116.315 97.6684C111.119 95.6579 104.652 93.1588 103.424 92.5434C103.242 92.4501 103.167 92.2264 103.257 92.0436C103.35 91.8608 103.574 91.7862 103.756 91.8757C104.95 92.4725 111.671 95.0723 116.58 96.9709C117.751 97.4259 118.844 97.8437 119.762 98.2018C119.952 98.2764 120.049 98.4927 119.974 98.6829C119.918 98.8358 119.78 98.9254 119.631 98.9254Z"
        fill="#F24949"
      />
      <path
        d="M103.593 99.6712C103.47 99.6712 103.347 99.6078 103.276 99.4959C103.168 99.3206 103.22 99.0893 103.395 98.9812C105.376 97.7428 109.315 95.6876 113.127 93.7033C115.059 92.6962 117.058 91.6518 118.711 90.764C118.89 90.6671 119.117 90.7342 119.214 90.917C119.311 91.0997 119.244 91.3235 119.061 91.4205C117.405 92.312 115.406 93.3564 113.47 94.3635C109.673 96.3441 105.745 98.3918 103.791 99.6152C103.727 99.6563 103.66 99.6712 103.593 99.6712Z"
        fill="#F24949"
      />
      <path
        d="M72.0742 22.8339C72.1772 22.8339 72.2607 22.7504 72.2607 22.6474C72.2607 22.5444 72.1772 22.4609 72.0742 22.4609C71.9712 22.4609 71.8877 22.5444 71.8877 22.6474C71.8877 22.7504 71.9712 22.8339 72.0742 22.8339Z"
        fill="#9DA6D9"
      />
      <path
        d="M16.4124 98.3995C16.3676 98.3995 16.3229 98.392 16.2781 98.3734C15.3605 98.0153 14.2677 97.5938 13.0964 97.1425C7.90061 95.132 1.43285 92.633 0.205692 92.0175C0.022924 91.9243 -0.0516753 91.7005 0.0378438 91.5177C0.127363 91.3349 0.354891 91.2603 0.537659 91.3499C1.73125 91.9466 8.45637 94.5464 13.3613 96.445C14.5325 96.9 15.6254 97.3178 16.5429 97.6759C16.7332 97.7505 16.8301 97.9668 16.7555 98.157C16.7033 98.31 16.5616 98.3995 16.4124 98.3995Z"
        fill="#565EA3"
      />
      <path
        d="M0.373282 99.1453C0.250193 99.1453 0.127104 99.0819 0.056235 98.97C-0.051934 98.7947 0.000285519 98.5634 0.175594 98.4553C2.1562 97.2169 6.09505 95.1617 9.90707 93.1774C11.8392 92.1703 13.8384 91.1259 15.4908 90.2382C15.6736 90.1412 15.8974 90.2083 15.9944 90.3911C16.0913 90.5739 16.0242 90.7977 15.8414 90.8946C14.1853 91.7861 12.1861 92.8305 10.2502 93.8376C6.45312 95.8182 2.52547 97.8659 0.57097 99.0894C0.51129 99.1267 0.440421 99.1453 0.373282 99.1453Z"
        fill="#565EA3"
      />
    </svg>
  );
}
