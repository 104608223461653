import React from 'react';
import cx from 'classnames';

import Clock from '../../Clock';
import MetroIcon from '../../Icons/MetroIcon';
import BusCircleIcon from '../../Icons/BusIcon';
import TramIcon from '../../Icons/TramIcon';
import BoatIcon from '../../Icons/BoatIcon';
import ArrowIcon from '../../Icons/ArrowUpIcon';
import UArrowIcon from '../../Icons/UArrowIcon';

import { getHiddenTableColumns, getTrackColumnLabels } from '~/utils/columns';

import './styles.scss';

const customDirectionIcons = Object.freeze({
  u180deg: 'u180deg',
});

export const getStopPlaceTypeIcon = (stopPlaceType: DPI.TransportType, className: string) => {
  const iconClassName = stopPlaceType + ' ' + className;
  switch (stopPlaceType) {
    case 'metro':
      return <MetroIcon className={iconClassName} />;
    case 'bus':
      return <BusCircleIcon className={iconClassName} />;
    case 'tram':
      return <TramIcon className={iconClassName} />;
    case 'water':
      return <BoatIcon className={iconClassName} />;
    default:
      return null;
  }
};

const getSvgHolder = (stopPlaceType: DPI.TransportType, reverseHeaderOrder: boolean, className = '') => {
  const supportedModalities = ['metro', 'bus', 'tram', 'water'];
  if (stopPlaceType && supportedModalities.includes(stopPlaceType)) {
    const svgHolderClassNames = cx('svg-holder', {
      '-reverse': reverseHeaderOrder,
      '-margin': stopPlaceType !== 'metro',
    });
    const icon = getStopPlaceTypeIcon(stopPlaceType, className);
    return <div className={svgHolderClassNames}>{icon}</div>;
  }
  return null;
};

type TrackColumnProps = {
  transportMode: DPI.TransportType;
  showTrackArrows?: boolean;
  hidden?: boolean;
};
const TrackColumn = ({ transportMode, showTrackArrows, hidden }: TrackColumnProps) => {
  const [norwegianLabel, englishLabel] = getTrackColumnLabels(transportMode);
  const trackClasses = cx('track-header', {
    '-hidden': hidden === true,
    '-flex-adjusted': showTrackArrows === true,
  });

  return (
    <div className={trackClasses}>
      <div className="tracki18n">
        <span className="norwegian">{norwegianLabel}</span>
        <span className="english">{englishLabel}</span>
      </div>
    </div>
  );
};

interface Props {
  screenConfig?: DPI.ScreenConfig;
  departures: DPI.EstimatedCall[];
  index: number;
  hideTrack?: boolean;
  hasTopHeader?: boolean;
  isFallBackHeader?: boolean;
  showPlannedTime?: boolean;
}
const DepartureHeader = ({
  screenConfig,
  departures = [],
  index,
  hideTrack,
  hasTopHeader = false,
  isFallBackHeader = false,
  showPlannedTime,
}: Props) => {
  if (!screenConfig || !Array.isArray(screenConfig.boards) || !screenConfig.boards.length) {
    return null;
  }

  const displayTableColumns = isFallBackHeader ? false : screenConfig.boards[index];
  const { header } = screenConfig.boards[index];

  const hiddenTableColumns = [
    ...(screenConfig.boards[index].hiddenTableColumns || []),
    ...(hideTrack ? ['track'] : []),
  ];

  const headerLabel = header?.label;
  const arrowAngle = header?.arrow?.angle;
  const reverseHeaderOrder =
    (typeof arrowAngle === 'number' && isNumberBetween(arrowAngle, 0, 180)) ||
    header?.arrow?.customDirectionIcon === customDirectionIcons.u180deg;
  const transportMode = departures.length ? departures[0].transportMode : 'bus';
  const topHeader = screenConfig.header;
  const isNotFirstOfManyBoards = index > 0;
  const { hideDepartureColumn } = getHiddenTableColumns(hiddenTableColumns);

  const arrow =
    header && typeof header.arrow === 'object' ? (
      <div className="svg-holder">
        {header.arrow.customDirectionIcon === customDirectionIcons.u180deg ? (
          <UArrowIcon className="u-arrow" />
        ) : (
          <ArrowIcon
            className="arrow-up"
            style={{
              transform: `rotate(${arrowAngle}deg)`,
            }}
          />
        )}
      </div>
    ) : null;

  const stopPlaceTypeIcon = header?.stopPlaceTypeIcon ?? '';
  const stopPlaceHeaderIcon = getSvgHolder(stopPlaceTypeIcon, reverseHeaderOrder);

  const departureColumnClasses = cx('departure', {
    '-hidden': hideDepartureColumn,
  });

  const leftHolderClasses = cx('left-holder', {
    '-reverse': reverseHeaderOrder,
    '-reduce-icon-space': arrow && stopPlaceHeaderIcon,
  });

  return (
    <div className={cx('departure-header', { 'no-top-header': !hasTopHeader })}>
      {topHeader || isNotFirstOfManyBoards ? null : <Clock className="header-clock" sizeVariant={'small'} />}
      <div className={leftHolderClasses}>
        {arrow}
        {stopPlaceHeaderIcon}
        <div className="departure-title-holder">
          <span
            className={cx('label', {
              norwegian: headerLabel?.en,
              onlyNorwegian: !headerLabel?.en,
              '-adjust-line': !headerLabel?.en,
            })}
          >
            {headerLabel?.no ?? ''}
          </span>
          <span className="label english">{headerLabel?.en ?? ''}</span>
        </div>
      </div>

      {displayTableColumns && (
        <>
          {showPlannedTime && (
            <div
              className={cx('plannedTitles', {
                '-hidden': hideDepartureColumn,
              })}
            >
              <span className="norwegian">Rutetid</span>
              <span className="english">Schedule</span>
            </div>
          )}
          <div className={departureColumnClasses}>
            <span className="norwegian">Forventet</span>
            <span className="english">Expected</span>
          </div>
          <TrackColumn transportMode={transportMode} hidden={hideTrack} />
          <div className="situation-header"> </div>
        </>
      )}
    </div>
  );
};

export const isNumberBetween = (number: number, from: number, to: number) => {
  if (![number, from, to].every((n) => typeof n === 'number')) {
    return false;
  }
  return number > from && number < to;
};

export default DepartureHeader;
