import { useState } from 'react';

import { useEffectOnce } from './useEffectOnce';
import { fetchMockConfig } from '~/mockData';
import { config } from '~/config';

import { getConfigFromURL, getQueryParam } from '~/utils/url';
import { fillBoardsWithGlobal } from '~/utils/config';
import { getIsOutOfServiceEnabled } from '~/utils/outOfService';
import { useStore } from './useStore';

export const resizeHtmlSize = (htmlFontSize = 16) => {
  document.querySelector('html')!.style.fontSize = htmlFontSize + 'px';
};

type StoredConfig = {
  id: string;
  data: DPI.ScreenConfig;
  mqtt: DPI.MQTTConfig;
  colors: DPI.PTAColor[];
  enturAuthority: DPI.EnturAuthority;
};
export const getScreenConfig = async (id: string): Promise<StoredConfig> => {
  return fetch(`${config.configUrl}config/${id}`).then((res) => res.json());
};
export const getPtaConfig = async (ptaId: string): Promise<Pick<StoredConfig, 'mqtt' | 'colors' | 'enturAuthority'>> => {
  return fetch(`${config.configUrl}pta/${ptaId}`).then((res) => res.json());
};

export default function useConfig() {
  const { screenConfig, mqtt, setScreenConfig, isSnapshotTest, setIsSnapshotTest, setMqtt, setPtaColors, setEnturAuthority } = useStore();
  const [enableConfigReload, setEnableConfigReload] = useState<boolean>(false);

  const outOfService = getIsOutOfServiceEnabled(screenConfig);

  const fetchConfig = async (screenConfigId: string) : Promise<[DPI.ScreenConfig, DPI.MQTTConfig, DPI.PTAColor[], DPI.EnturAuthority]> => {
    try {
      const screenConfigResponse = await getScreenConfig(screenConfigId);

      if (screenConfigResponse && screenConfigResponse.data) {
        const { data, mqtt, colors, enturAuthority } = screenConfigResponse;

        const screenConfigAligned = fillBoardsWithGlobal(data, 'situationsInfo');

        return [screenConfigAligned, mqtt, colors, enturAuthority];
      }
      return Promise.reject(null);
    } catch (e) {
      console.error('Unable to fetch config with screen config id', screenConfigId);
      return Promise.reject(null);
    }
  };

  const fetchPtaConfig = async (ptaId: string) : Promise<[DPI.MQTTConfig, DPI.PTAColor[], DPI.EnturAuthority]> => {
    try {
      const screenConfigResponse = await getPtaConfig(ptaId);

      if (screenConfigResponse) {
        const { mqtt, colors, enturAuthority } = screenConfigResponse;

        return [mqtt, colors, enturAuthority];
      }
      return Promise.reject(null);
    } catch (e) {
      console.error('Unable to fetch PTA config with id', ptaId);
      return Promise.reject(null);
    }
  };

  useEffectOnce(() => {
    // Run setup once at the start
    const screenConfigId = getQueryParam('id');
    const urlScreenConfigString = getQueryParam('config');
    const runAsSnapShotTest = getQueryParam('runAsTest');
    const pta = getQueryParam('pta');

    console.log('runAsSnapShotTest', runAsSnapShotTest, screenConfigId);

    // Used for snapshot testing
    if (runAsSnapShotTest && screenConfigId) {
      fetchMockConfig(screenConfigId).then(([screenConfig, colors, enturAuth]) => {
        setIsSnapshotTest(true);
        resizeHtmlSize(screenConfig.htmlFontSize);
        setScreenConfig(screenConfig);
        setPtaColors(colors);
        setEnturAuthority(enturAuth);
      });
      return;
    }
    if (screenConfigId) {
      fetchConfig(screenConfigId).then(([screenConfig, mqtt, colors, enturAuthority]) => {
        resizeHtmlSize(screenConfig.htmlFontSize);
        setScreenConfig(screenConfig);
        setMqtt(mqtt);
        setPtaColors(colors);
        setEnturAuthority(enturAuthority);

        setEnableConfigReload(true);
      });
      return;
    }
    if (urlScreenConfigString && pta) {
      fetchPtaConfig(pta).then(([mqtt, colors, enturAuthority]) => {
        const screenConfigFromURL = getConfigFromURL(urlScreenConfigString);

        resizeHtmlSize(screenConfigFromURL.htmlFontSize);
        setScreenConfig(screenConfigFromURL);
        setMqtt(mqtt);
        setPtaColors(colors);
        setEnturAuthority(enturAuthority);
      });
      return;
    }
    if (urlScreenConfigString) {
      const screenConfigFromURL = getConfigFromURL(urlScreenConfigString);

      resizeHtmlSize(screenConfigFromURL.htmlFontSize);
      setScreenConfig(screenConfigFromURL);
      return;
    }
  }, []);

  useEffectOnce(
    () => {
      let configInterval: NodeJS.Timeout;

      if (screenConfig) {
        configInterval = setInterval(() => {
          fetchConfig(screenConfig.id).then(([screenConfig, mqtt, colors, enturAuthority]) => {
            resizeHtmlSize(screenConfig.htmlFontSize);
            setScreenConfig(screenConfig);
            setMqtt(mqtt);
            setPtaColors(colors);
            setEnturAuthority(enturAuthority);
          });
        }, 5 * 60 * 1000); // 5 min interval
      }

      return () => {
        if (configInterval !== undefined) {
          clearInterval(configInterval);
        }
      };
    },
    [enableConfigReload, screenConfig],
    () => enableConfigReload
  );

  return {
    screenConfig,
    mqttConfig: mqtt,
    outOfService,
    isSnapshotTest,
  };
}
