import React from 'react';
import { createRoot } from 'react-dom/client';
import { initSentry } from './sentry';

import App from './App';
import ErrorBoundary from './components/ErrorBoundary';

import './index.scss';

window._LAST_LOADED_ = new Date().toISOString();

initSentry();

const rootEl = document.getElementById('root');

const root = createRoot(rootEl!);

root.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>
);
