import React from 'react';

interface Props {
  className?: string;
}
export default function Micromobility({ className = '' }: Props) {
  return (
    <svg className={className} viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.23 51.5655C116.415 51.5278 116.596 51.6478 116.634 51.8334C116.758 52.4435 116.934 54.1567 117.141 56.4688C117.348 58.793 117.59 61.7507 117.842 64.8717C117.867 65.1685 117.891 65.4667 117.915 65.766C118.401 71.7658 118.921 78.1942 119.328 81.8206C119.467 83.0634 119.571 84.2029 119.671 85.3308C119.68 85.4316 119.689 85.5322 119.698 85.6329C119.789 86.6595 119.879 87.6831 119.995 88.7776L119.654 88.8137L119.995 88.7776C119.995 88.7776 119.995 88.7776 119.995 88.7776C120.053 89.3213 119.65 89.8123 119.098 89.851L119.098 89.851C116.579 90.0263 113.571 90.0263 110.732 90.0262C110.484 90.0262 110.236 90.0262 109.99 90.0263C105.84 90.0289 102.032 90.0767 98.2737 90.1238C92.2823 90.1988 86.4177 90.2723 79.4976 90.1592C79.3082 90.1561 79.1571 90 79.1602 89.8105C79.1633 89.6211 79.3194 89.47 79.5088 89.4731C86.4227 89.5862 92.2668 89.5129 98.2484 89.4379C102.008 89.3907 105.822 89.3428 109.99 89.3402H109.99C110.235 89.3401 110.481 89.3401 110.728 89.3401C113.573 89.3401 116.556 89.3401 119.05 89.1665L119.074 89.5088L119.05 89.1665C119.05 89.1665 119.05 89.1665 119.05 89.1665C119.209 89.1554 119.33 89.0123 119.313 88.8498L119.313 88.8498C119.196 87.749 119.105 86.7197 119.014 85.6937C119.005 85.593 118.996 85.4923 118.987 85.3916C118.887 84.264 118.784 83.1314 118.646 81.8972L118.646 81.897C118.238 78.2598 117.717 71.8226 117.232 65.8296C117.207 65.5275 117.183 65.2266 117.159 64.9272C116.906 61.8055 116.665 58.8508 116.457 56.5299C116.249 54.197 116.076 52.5327 115.962 51.97C115.924 51.7843 116.044 51.6032 116.23 51.5655Z"
        fill="#F24949"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.7199 90.5904C85.3214 90.4284 86.0115 90.6781 86.6476 91.0955L86.6478 91.0957C87.1501 91.4258 87.5864 91.9932 87.5971 92.6671C87.6077 93.2835 87.2504 93.8435 86.778 94.1673C86.3042 94.492 85.7268 94.5995 85.192 94.5703C84.9322 94.5565 84.6336 94.508 84.3629 94.3544C83.9758 94.1348 83.7559 93.7702 83.6455 93.3933C83.5357 93.0185 83.5263 92.609 83.5746 92.2456C83.6913 91.362 84.0716 90.765 84.7199 90.5904ZM84.8983 91.2529C84.6343 91.324 84.3542 91.5827 84.2548 92.3355L84.2548 92.3358C84.2158 92.6288 84.2272 92.9385 84.304 93.2005C84.3802 93.4606 84.5126 93.6505 84.7015 93.7576L84.7015 93.7577C84.8404 93.8365 85.018 93.874 85.2287 93.8851L85.2294 93.8852C85.6553 93.9085 86.0719 93.8194 86.3901 93.6014C86.7095 93.3824 86.9171 93.0232 86.9111 92.6787L86.9111 92.6782C86.9051 92.3 86.6492 91.9177 86.2711 91.6691C85.6834 91.2835 85.2092 91.1691 84.8983 91.2529Z"
        fill="#F24949"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M119.6 91.3508C120.03 91.9702 120.161 92.8201 119.688 93.5664L119.688 93.5668C119.335 94.123 118.715 94.4726 118.075 94.533C117.435 94.5934 116.795 94.3751 116.307 93.9842L116.307 93.9842C115.554 93.381 115.451 92.5043 115.749 91.7819C116.04 91.0734 116.725 90.4845 117.587 90.3916L117.587 90.3916C118.422 90.3021 119.173 90.7353 119.6 91.3508ZM117.66 91.0738C118.208 91.0152 118.729 91.2992 119.036 91.7419C119.341 92.1806 119.406 92.7296 119.109 93.199C118.878 93.5622 118.456 93.8078 118.011 93.8499C117.565 93.892 117.098 93.7392 116.736 93.4488C116.255 93.0634 116.184 92.5265 116.383 92.0432C116.588 91.5461 117.071 91.1374 117.66 91.0738Z"
        fill="#F24949"
      />
      <path
        d="M70.5536 81.6953L63.1895 92.2224C64.1268 92.5422 65.1028 92.7804 66.0928 92.7693C68.2277 92.7444 70.2117 91.5426 71.6516 89.9669C73.0914 88.3912 75.0533 85.4379 76.0169 83.5327C76.1318 83.3028 71.9492 82.3087 70.5536 81.6953Z"
        fill="#F24949"
      />
      <path
        d="M96.2207 81.2358C94.8237 83.8319 94.2506 86.8642 94.6036 89.7912C99.6432 89.7621 104.683 89.7344 109.722 89.7053C110.135 89.1238 109.877 88.2654 109.35 87.7849C108.822 87.3045 108.104 87.1092 107.413 86.9292C106.573 86.7105 105.734 86.4903 104.893 86.2715C104.086 86.0611 103.257 85.8396 102.594 85.3342C102.137 84.9853 101.785 84.5214 101.402 84.0936C100.043 82.5802 98.2144 81.4933 96.2359 81.0239"
        fill="#F24949"
      />
      <path
        d="M85.7915 52.669C79.8935 62.3584 74.181 72.1613 68.6582 82.0709C70.4442 84.0412 72.7868 85.5033 75.3426 86.2413C81.3223 77.5973 87.5747 69.262 93.0546 60.2926L101.837 68.4008C99.8304 72.1683 97.737 76.79 95.7059 80.5451C97.7093 82.0391 99.8179 83.389 102.014 84.5826C102.295 84.7362 102.61 84.8913 102.923 84.8193C103.174 84.7612 103.4 84.595 103.544 84.3832C106.356 80.2128 111.856 69.0238 111.427 67.1546C111.034 65.4405 109.905 63.9936 108.711 62.7045C106.434 60.2469 103.804 57.5801 101.196 55.4797C100.031 54.5423 98.8654 54.1533 97.5113 53.5219C95.4969 52.5831 93.2069 52.3962 90.9889 52.5416C89.2389 52.6565 87.5027 52.9667 85.8233 53.4679"
        fill="#4A5296"
      />
      <path
        d="M104.349 30.9438C104.532 31.085 104.907 31.2498 105.128 31.3162C105.611 31.4616 107.007 30.3996 107.205 30.8316C107.458 31.3855 106.467 31.985 105.659 32.5167C105.659 32.5167 105.51 36.8103 104.979 37.5705C104.494 38.2628 102.53 40.4823 101.044 42.5093C100.685 42.9981 97.8402 40.2289 97.058 39.2832C96.9555 39.1586 96.2245 36.2274 96.4778 34.6545C96.7312 33.0802 97.3833 31.5198 98.574 30.4592C100.525 28.7201 102.563 29.5703 104.349 30.9438Z"
        fill="#9DA6D9"
      />
      <path
        d="M101.492 41.7386C99.8282 41.4091 98.2332 40.3415 97.0093 39.166C97.0093 39.166 88.8255 42.5666 83.473 51.9708C83.3706 52.1522 89.2395 55.7576 91.78 56.5012C95.5639 57.6088 101.985 56.7615 101.982 56.5012C101.933 52.4955 101.771 45.7982 101.492 41.7386Z"
        fill="#DBA9A2"
      />
      <path
        d="M114.302 48.6004C115.07 48.5921 116.386 48.7389 117.024 49.1653C117.662 49.5932 118.27 50.2384 117.889 51.3115C117.67 51.9276 116.951 51.9152 116.297 51.8999C115.606 51.8833 114.595 51.5482 112.778 52.1464C111.851 52.451 110.605 53.0464 109.824 53.6307C109.553 53.8342 109.369 53.2707 109.081 53.0921C108.979 53.0284 108.915 52.9204 108.855 52.8165C108.596 52.3665 107.75 51.3793 107.906 50.8407C108.063 50.2883 109.352 49.8189 109.82 49.6056C111.224 48.966 112.76 48.6157 114.302 48.6004Z"
        fill="#9DA6D9"
      />
      <path
        d="M108.509 49.7539C107.241 50.2289 104.605 52.1008 102.487 54.0379C99.9255 56.3806 97.3365 59.5417 96.4255 59.4461C94.5813 59.2537 98.242 46.2398 96.8104 44.2155C95.6571 42.5651 92.877 42.5097 91.8691 45.4326C89.8685 51.2299 88.0672 57.7832 90.054 63.4614C90.5663 64.925 91.4011 66.304 92.7275 67.1071C94.076 67.924 96.5349 67.5017 97.9997 66.9188C99.4645 66.3345 101.55 63.7148 102.695 62.6307C105.843 59.6497 108.707 57.0591 111.449 53.7C111.902 53.1462 109.617 49.3386 108.509 49.7539Z"
        fill="#F24949"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.378 34.0691C102.534 34.0091 102.709 34.0869 102.769 34.243C102.894 34.567 103.313 34.8875 103.88 35.0928C104.44 35.2953 105.023 35.3411 105.37 35.2293C105.529 35.1781 105.7 35.2655 105.751 35.4246C105.802 35.5837 105.715 35.7543 105.556 35.8055C105.03 35.9749 104.298 35.8877 103.674 35.6621C103.058 35.4392 102.425 35.0362 102.204 34.4603C102.144 34.3042 102.222 34.1291 102.378 34.0691Z"
        fill="#4A5296"
      />
      <path
        d="M103.139 32.4086C103.312 32.4086 103.452 32.2685 103.452 32.0956C103.452 31.9228 103.312 31.7827 103.139 31.7827C102.966 31.7827 102.826 31.9228 102.826 32.0956C102.826 32.2685 102.966 32.4086 103.139 32.4086Z"
        fill="#4A5296"
      />
      <path
        d="M103.363 29.1693C104.292 28.4341 105.499 26.9983 104.869 25.9944C104.239 24.992 103.081 26.1066 102.865 26.3544C102.104 27.2267 101.353 27.6089 100.386 28.2458C101.282 27.1894 101.501 26.3447 101.656 24.9685C101.693 24.6389 101.558 24.0228 101.318 23.7943C101.171 23.6545 100.957 23.6074 100.753 23.606C100.276 23.6019 99.802 23.822 99.4947 24.1875C99.0558 24.7137 98.9935 25.4461 98.7969 26.1024C98.5947 26.7754 98.2486 27.415 97.7488 27.9093C95.7011 29.9309 96.727 32.1697 97.411 32.0742C99.0253 31.8471 102.434 29.9045 103.363 29.1693Z"
        fill="#E2D1CA"
      />
      <path
        d="M12.1144 96.5555C18.8051 96.5555 24.2289 90.5573 24.2289 83.1581C24.2289 75.759 18.8051 69.7607 12.1144 69.7607C5.42382 69.7607 0 75.759 0 83.1581C0 90.5573 5.42382 96.5555 12.1144 96.5555Z"
        fill="#E2D1CA"
      />
      <path
        d="M53.1291 96.3494C59.8197 96.3494 65.2435 90.3512 65.2435 82.9521C65.2435 75.5529 59.8197 69.5547 53.1291 69.5547C46.4385 69.5547 41.0146 75.5529 41.0146 82.9521C41.0146 90.3512 46.4385 96.3494 53.1291 96.3494Z"
        fill="#E2D1CA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.4886 65.664C24.6495 65.7639 24.699 65.9754 24.599 66.1364C22.9794 68.7443 20.4086 72.5926 18.1065 76.0387C17.8773 76.3817 17.6509 76.7207 17.4283 77.0541C16.1974 78.8976 15.0847 80.5697 14.2904 81.801C13.8929 82.4173 13.5775 82.9193 13.3676 83.2759C13.262 83.4551 13.1868 83.5913 13.1415 83.6841C13.1281 83.7116 13.1189 83.732 13.1129 83.7464C13.1052 83.8621 13.039 83.9711 12.9286 84.0284C12.7604 84.1157 12.5533 84.0502 12.466 83.882C12.4132 83.7802 12.4249 83.6838 12.4284 83.6562C12.4338 83.6151 12.4444 83.5786 12.453 83.5526C12.4708 83.4991 12.4969 83.4406 12.5249 83.3832C12.5822 83.2658 12.6683 83.1112 12.7764 82.9277C12.9938 82.5586 13.3153 82.0469 13.7138 81.4291C14.5116 80.1925 15.6274 78.5157 16.8576 76.6731C17.0799 76.3402 17.306 76.0018 17.5346 75.6596C19.8389 72.2102 22.403 68.3719 24.0162 65.7744C24.1161 65.6135 24.3276 65.564 24.4886 65.664ZM13.1037 83.7703C13.1037 83.7703 13.1038 83.7699 13.1041 83.7691C13.1039 83.7699 13.1037 83.7703 13.1037 83.7703Z"
        fill="#F24949"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.5211 66.6668C53.5305 66.856 53.3848 67.017 53.1956 67.0265C51.6386 67.1044 50.0832 67.109 48.5083 67.1138C48.407 67.1141 48.3055 67.1144 48.204 67.1147C46.5223 67.1201 44.8144 67.1308 43.0374 67.234L43.0374 67.234C38.684 67.4867 34.4341 67.5255 30.0898 67.5651C27.9508 67.5846 25.789 67.6044 23.5806 67.6499C23.3912 67.6538 23.2345 67.5034 23.2305 67.314C23.2266 67.1246 23.377 66.9679 23.5664 66.964C25.7923 66.918 27.9627 66.8983 30.1055 66.8787C34.4396 66.8393 38.6605 66.8008 42.9977 66.5491C44.7955 66.4446 46.5205 66.4339 48.2018 66.4286C48.3021 66.4283 48.4022 66.428 48.5022 66.4277C50.0808 66.4229 51.621 66.4183 53.1613 66.3413C53.3506 66.3318 53.5116 66.4775 53.5211 66.6668Z"
        fill="#F24949"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.7519 58.4789C56.9955 58.361 56.2609 58.5625 55.6344 58.9402L57.7519 58.4789ZM57.6464 59.1569C57.0804 59.0686 56.5071 59.2152 55.9887 59.5278C55.4476 59.854 54.9812 60.3532 54.673 60.9145C54.0552 62.0397 53.9147 63.3714 53.7891 64.7434C53.2817 70.2831 53.3536 77.1382 53.5294 83.3108L55.8725 82.8507L60.6121 72.0877L60.3059 62.0833C60.1875 61.1595 59.8223 60.406 59.1516 59.885C58.6833 59.5211 58.1795 59.2397 57.6464 59.1569ZM57.7519 58.4789C58.4483 58.5872 59.0607 58.9455 59.5725 59.3431C60.4237 60.0044 60.8557 60.9483 60.9887 62.014L60.9907 62.0299L61.3027 72.222L56.356 83.455L52.8672 84.14L52.8553 83.7355C52.6712 77.459 52.5832 70.3874 53.1058 64.6809C53.2294 63.3307 53.3742 61.8545 54.0716 60.5843C54.4343 59.9237 54.9833 59.3328 55.6344 58.9402"
        fill="#F24949"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.8185 83.5297C29.8257 83.7191 29.6781 83.8784 29.4887 83.8856C23.9803 84.0948 18.4663 84.1835 12.9523 84.1503C12.7629 84.1491 12.6102 83.9946 12.6113 83.8051C12.6125 83.6157 12.767 83.463 12.9565 83.4642C18.4604 83.4974 23.9643 83.4088 29.4627 83.2C29.652 83.1928 29.8113 83.3404 29.8185 83.5297Z"
        fill="#F24949"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.2322 74.4405C53.3124 74.6121 53.2384 74.8163 53.0668 74.8966C46.2216 78.0984 39.31 81.1588 32.3388 84.0752C32.164 84.1484 31.9631 84.0659 31.8899 83.8912C31.8168 83.7164 31.8992 83.5154 32.074 83.4423C39.0365 80.5295 45.9395 77.4729 52.7761 74.2751C52.9477 74.1948 53.1519 74.2688 53.2322 74.4405Z"
        fill="#F24949"
      />
      <path
        d="M46.7786 30.7331C47.2798 31.0627 48.7086 30.2319 48.908 30.6639C49.1613 31.2177 48.8678 31.604 48.0607 32.1343C48.0607 32.1343 48.0759 33.8346 47.8364 34.7304C46.8908 38.2501 45.0881 40.4807 43.6025 42.5077C43.244 42.9965 40.3988 40.2273 39.6166 39.2816C39.5141 39.157 38.7831 36.2258 39.0364 34.6529C39.2898 33.0786 39.9419 31.5182 41.1326 30.4576C43.0806 28.7185 44.8929 29.4981 46.7786 30.7331Z"
        fill="#DBA9A2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.5221 33.7505C43.6056 33.6057 43.7908 33.5561 43.9356 33.6397C44.4864 33.9578 45.9101 34.5319 47.8033 34.1144C47.9666 34.0784 48.1281 34.1816 48.1641 34.3448C48.2001 34.5081 48.0969 34.6696 47.9337 34.7056C45.8589 35.1632 44.281 34.5383 43.6328 34.164C43.4881 34.0804 43.4385 33.8953 43.5221 33.7505Z"
        fill="#4A5296"
      />
      <path
        d="M45.7162 32.305C45.889 32.305 46.0291 32.1649 46.0291 31.9921C46.0291 31.8193 45.889 31.6792 45.7162 31.6792C45.5434 31.6792 45.4033 31.8193 45.4033 31.9921C45.4033 32.1649 45.5434 32.305 45.7162 32.305Z"
        fill="#4A5296"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.9605 27.8292C50.019 27.9858 49.9394 28.1602 49.7828 28.2187C49.2033 28.4351 48.6246 28.6523 48.0452 28.8698C47.8872 28.9291 47.7291 28.9885 47.5709 29.0478C47.5709 29.0479 47.5709 29.0478 47.5709 29.0478L47.535 29.0613C46.3998 29.4877 45.2692 29.9124 44.1555 30.3759C43.0279 30.8456 41.9181 31.3552 40.8028 31.8674C40.456 32.0266 40.1088 32.1861 39.7603 32.3447C38.2943 33.0118 36.8113 33.661 35.2797 34.1967C35.1219 34.2519 34.9492 34.1688 34.894 34.011C34.8388 33.8532 34.922 33.6805 35.0798 33.6253C36.5844 33.099 38.0469 32.4592 39.5095 31.7936C39.8537 31.637 40.1982 31.4788 40.5433 31.3203C41.6608 30.8072 42.7842 30.2913 43.9228 29.817C45.0463 29.3494 46.1855 28.9215 47.3176 28.4963L47.358 28.4811C47.516 28.4218 47.6741 28.3625 47.8321 28.3032C48.4116 28.0856 48.9909 27.8681 49.571 27.6515C49.7276 27.5931 49.902 27.6726 49.9605 27.8292Z"
        fill="#4A5296"
      />
      <path
        d="M26.5458 86.8626C30.5318 89.0101 35.2377 92.0756 39.4909 94.0625C39.7761 94.1954 40.9447 93.6471 41.108 92.6682C41.2908 91.5688 36.3758 86.8626 35.5451 86.1703C35.5451 86.1703 38.1673 82.5731 38.0372 82.3626C37.9015 82.1439 32.5102 79.2431 31.4608 79.8703C30.907 80.2013 26.4406 86.8058 26.5458 86.8626Z"
        fill="#DBA9A2"
      />
      <path
        d="M16.6257 56.0453C16.1577 58.7841 16.4277 61.844 18.2428 63.9472C19.6924 65.6268 22.1831 67.0294 24.3997 67.1083C32.0602 67.3852 36.7675 65.2626 39.8439 64.4637C36.3286 70.3718 33.6759 73.4304 30.1454 80.0764C30.0402 80.2758 36.9641 85.3642 37.1372 85.1994C38.2725 84.1236 37.9346 84.2607 38.8969 83.027C42.1975 78.7901 45.1175 74.257 47.6151 69.5022C48.6853 67.4627 49.6905 65.3429 50.0408 63.0666C50.1668 62.2511 51.1442 58.1084 43.8756 57.2084C41.3004 56.89 33.1692 57.6002 33.1692 57.6002C32.7192 57.5504 28.5643 51.3225 25.8977 48.8011C25.002 47.9538 23.2159 45.7799 21.8564 46.3282C20.5342 46.8627 19.3324 49.1431 18.6692 50.3186C17.6696 52.0937 16.969 54.0377 16.6257 56.0453Z"
        fill="#4A5296"
      />
      <path
        d="M43.5989 42.5094C41.9832 48.855 36.3663 54.6468 31.5897 59.3544C25.7748 57.7621 21.7486 52.4591 19.5556 47.5396C19.46 47.3264 27.0472 41.7936 32.7084 40.1556C36.2154 39.1407 39.786 39.1933 39.6129 39.2833C39.6116 39.2833 41.1525 41.3546 43.5989 42.5094Z"
        fill="#9DA6D9"
      />
      <path
        d="M53.2561 54.1815C54.851 54.3352 56.3975 54.9624 57.6505 55.9634C58.034 56.2708 58.3995 56.6239 58.6003 57.0725C58.801 57.5211 58.8052 58.0847 58.5047 58.4723C58.2181 58.8434 57.71 58.9874 57.2421 58.957C56.7741 58.9265 56.3297 58.7479 55.8908 58.5803C53.8943 57.8147 51.7289 57.211 49.6328 57.636C49.3074 57.7025 49.1828 54.6827 49.6965 54.3241C50.3929 53.8381 52.46 54.1039 53.2561 54.1815Z"
        fill="#DBA9A2"
      />
      <path
        d="M33.9164 41.6987C30.4842 43.6898 26.9399 46.6362 24.1404 49.4469C17.4145 56.1982 18.9153 60.4365 21.8145 62.9925C24.139 65.0403 30.3707 64.6138 33.3557 63.7872C36.817 62.8277 39.7023 62.2406 42.5862 61.1108C45.6972 59.891 47.1329 59.6833 50.363 58.8262C50.6025 58.7625 50.3436 53.6533 49.9629 53.6257C45.2182 53.2754 36.9208 55.6929 31.9754 56.9376C29.7892 57.4887 28.6816 57.8071 28.0849 57.065C27.6308 56.5015 29.0347 55.085 32.1124 52.3574C35.3729 49.4677 36.6245 48.6065 39.0848 45.3527C40.9248 42.913 37.3639 39.698 33.9164 41.6987Z"
        fill="#F24949"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.4863 86.7217C26.5635 86.5734 26.7462 86.5157 26.8945 86.5928C31.0378 88.7471 35.6471 91.4062 39.6466 93.803C39.79 93.8889 39.8366 94.0748 39.7506 94.2182C39.6647 94.3616 39.4788 94.4082 39.3354 94.3223C35.3407 91.9284 30.7431 89.2762 26.6153 87.1299C26.4669 87.0528 26.4092 86.87 26.4863 86.7217Z"
        fill="#4A5296"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7269 84.7897C33.8139 84.647 34.0002 84.6018 34.1429 84.6888C34.6857 85.0198 35.2283 85.3506 35.7722 85.6814C35.915 85.7683 35.9604 85.9545 35.8735 86.0973C35.7867 86.2401 35.6005 86.2855 35.4576 86.1986C34.9134 85.8676 34.3705 85.5366 33.8278 85.2057C33.685 85.1187 33.6399 84.9324 33.7269 84.7897Z"
        fill="#4A5296"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.6867 86.8536C36.7307 87.0149 36.6355 87.1813 36.4742 87.2252C35.8583 87.3929 35.2461 87.5745 34.6362 87.7673C34.4768 87.8177 34.3067 87.7293 34.2563 87.5699C34.206 87.4105 34.2943 87.2404 34.4537 87.19C35.0705 86.9951 35.6905 86.8112 36.3151 86.6411C36.4764 86.5972 36.6428 86.6923 36.6867 86.8536Z"
        fill="#4A5296"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.8138 87.8611C37.8978 88.0056 37.8488 88.1909 37.7043 88.2749C37.3269 88.4943 36.9796 88.762 36.6715 89.0714C36.5535 89.1899 36.3619 89.1903 36.2434 89.0723C36.125 88.9544 36.1245 88.7627 36.2425 88.6442C36.5879 88.2973 36.9772 87.9973 37.4 87.7515C37.5445 87.6675 37.7298 87.7165 37.8138 87.8611Z"
        fill="#4A5296"
      />
      <path
        d="M36.4227 22.6861C36.6595 21.6725 39.7732 20.7642 40.6109 21.055C41.8002 21.469 45.0565 28.2729 45.4456 29.4706C45.4456 29.4706 39.5254 32.1872 38.8235 32.5402C38.4289 32.7382 36.186 23.6996 36.4227 22.6861Z"
        fill="#4A5296"
      />
      <path
        d="M43.3411 98.2021C43.3398 98.4776 43.3342 98.7919 43.5419 98.9733C43.679 99.0924 43.8728 99.1201 44.0542 99.1395C45.0053 99.2419 45.962 99.2696 46.9187 99.2973C51.6454 99.433 56.3721 99.5701 61.0975 99.7058C61.2968 99.7113 61.7149 99.6504 61.7343 99.2004C61.7897 97.8823 62.6149 95.6877 51.0542 92.9877C51.0542 92.9877 52.8818 89.7381 53.4771 89.1801C52.5038 88.3728 48.8626 85.9207 47.5237 86.4109C46.6252 86.7376 43.3702 93.0556 43.3411 98.2021Z"
        fill="#4A5296"
      />
      <path
        d="M91.3416 98.3891C93.7991 98.5303 109.686 98.1468 109.686 98.1468C109.479 96.4853 107.779 93.9169 107.294 93.5043C105.987 92.3938 104.22 92.0241 102.526 91.7555C100.831 91.4869 97.086 91.0618 96.5792 90.5537C96.0407 90.0151 95.1989 87.4729 94.6409 86.9537C94.1619 86.5093 94.2422 87.4314 94.1356 87.4148C94.0566 87.4023 90.7588 88.3854 89.1887 88.8534C88.7872 88.9725 88.3719 89.0999 88.0617 89.3809C87.4802 89.9057 87.7571 90.3391 88.0742 90.8721C88.4729 91.5437 91.2004 98.3808 91.3416 98.3891Z"
        fill="#4A5296"
      />
      <path
        d="M95.6336 74.3313C97.295 75.3006 98.5604 75.1787 98.8456 76.2559C99.1308 77.3331 97.8751 77.815 97.079 77.9728C94.9731 78.3882 92.4713 76.8831 90.9498 75.3698C90.857 75.2784 91.7957 74.5584 92.2277 74.1513C92.535 73.8633 93.129 72.7183 93.5873 72.739C93.5873 72.7404 94.5578 73.7041 95.6336 74.3313Z"
        fill="#F24949"
      />
      <path
        d="M94.5037 72.3931C93.7644 74.1003 90.328 77.1145 90.0719 76.9622C89.0432 76.3502 88.1239 74.7981 87.4164 73.833C82.5665 67.2133 77.7193 59.9774 77.3303 51.7806C77.1267 47.4772 79.8251 52.6086 80.4814 53.8104C81.7593 56.1545 83.0815 58.4765 84.52 60.7278C85.1887 61.7746 94.0413 71.4695 94.5037 72.3931Z"
        fill="#9DA6D9"
      />
      <path
        d="M58.9205 69.6004C56.0961 73.7569 48.5824 81.8776 45.7677 86.7237C45.4936 87.1958 52.3981 91.9284 52.6446 91.7082C58.321 86.6309 65.2353 80.3282 68.1372 73.6725C70.2597 68.8056 70.7678 63.4126 71.2496 58.1262C74.6513 65.2306 78.0544 72.3363 81.4562 79.4407C83.3183 83.3286 85.186 87.229 87.4995 90.8664C90.3779 90.0979 93.1788 89.0429 95.8495 87.7233C91.7735 76.8473 87.0316 66.2219 81.6583 55.9261C81.2623 55.1673 80.8428 54.385 80.1644 53.8616C79.0679 53.0156 77.5574 53.0281 76.1743 53.078C72.2672 53.2192 68.3476 53.359 64.4752 53.935C63.1364 54.1344 61.4777 53.1832 60.9502 54.4625C60.3729 55.8679 60.0005 68.0122 58.9205 69.6004Z"
        fill="#DBA9A2"
      />
      <path
        d="M76.6023 30.5287C76.785 30.67 79.021 30.4401 79.7479 31.3747C80.1217 31.8552 78.686 32.1944 77.8788 32.7247C77.8788 32.7247 77.7736 33.4212 77.5327 34.317C76.587 37.8367 74.7844 40.0672 73.2988 42.0943C72.9403 42.5831 70.0951 39.8139 69.3128 38.8682C69.2104 38.7436 68.4794 35.8124 68.7327 34.2395C68.9861 32.6652 69.6382 31.1047 70.8289 30.0441C72.7783 28.3051 74.8163 29.1552 76.6023 30.5287Z"
        fill="#F24949"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.3961 33.854C74.4797 33.7093 74.6648 33.6597 74.8096 33.7432C74.8127 33.745 74.8158 33.7468 74.8189 33.7486C75.1165 33.9205 75.4018 34.0852 75.7848 34.2129C76.1702 34.3413 76.666 34.4354 77.3714 34.4407C77.5385 34.442 77.673 34.5785 77.6718 34.7457C77.6705 34.9128 77.5339 35.0473 77.3668 35.0461C76.6074 35.0403 76.0475 34.9385 75.5935 34.7872C75.1517 34.64 74.8212 34.4491 74.5279 34.2796C74.5208 34.2756 74.5138 34.2715 74.5069 34.2675C74.3621 34.1839 74.3125 33.9988 74.3961 33.854Z"
        fill="#4A5296"
      />
      <path
        d="M75.4145 31.889C75.5873 31.889 75.7274 31.7489 75.7274 31.5761C75.7274 31.4033 75.5873 31.2632 75.4145 31.2632C75.2417 31.2632 75.1016 31.4033 75.1016 31.5761C75.1016 31.7489 75.2417 31.889 75.4145 31.889Z"
        fill="#4A5296"
      />
      <path
        d="M60.398 55.2931C65.1856 56.7068 67.1821 57.7854 71.2428 57.647C73.9398 57.5556 78.7191 56.9547 81.9035 54.4624C78.0269 46.8014 73.296 42.0938 73.296 42.0938C71.5197 41.1246 70.4813 40.1318 69.31 38.8677C69.31 38.8677 65.6037 39.5614 62.3363 46.087C60.2803 50.1909 59.3111 54.9719 60.398 55.2931Z"
        fill="#4A5296"
      />
      <path
        d="M72.489 62.7937C72.6635 63.8155 72.5818 66.5321 71.0588 68.2393C70.883 68.4373 70.008 69.3483 68.9378 68.4788C67.736 67.5013 69.0305 65.6695 69.1205 64.6864C69.2133 63.6715 68.2441 61.0407 68.2441 61.0407C68.438 59.8251 70.1174 59.1895 70.7362 59.4249C71.7663 59.8168 72.3437 61.9366 72.489 62.7937Z"
        fill="#F24949"
      />
      <path
        d="M56.6946 47.2971C56.6946 47.2971 66.6589 46.9149 68.2455 46.2808C70.3417 45.4431 70.9412 44.0363 70.8484 42.6656C70.7473 41.1813 69.7311 39.769 66.0885 39.7676C62.0277 39.7648 50.0545 39.9088 48.0732 44.1125"
        fill="#9DA6D9"
      />
      <path
        d="M73.8033 55.2244C73.2494 54.4615 71.2876 52.8941 70.4403 52.2282C70.2575 52.0842 71.9909 49.3565 71.6185 48.3374C71.4828 47.9664 71.2128 47.6507 70.893 47.4236C68.9118 46.0155 67.8042 45.4616 65.6125 44.5395C65.2165 44.372 64.2668 44.0452 63.8431 44.1158C63.3115 44.203 61.8065 45.8798 61.1364 46.7548C61.1364 46.7548 57.4121 45.2609 57.1296 45.9338C57.0175 46.1996 56.4277 48.3859 56.5038 48.6628C56.5717 48.9065 68.6432 56.8292 71.912 58.5461C72.2138 58.7067 74.3571 55.986 73.8033 55.2244ZM61.9006 47.1301C62.2329 46.6787 62.5984 46.2509 62.9916 45.8521C63.2588 45.5821 63.7351 44.9659 64.1131 44.8704C64.5201 44.7679 67.2947 46.3824 67.3141 46.4821C67.3307 46.5679 67.2822 46.651 67.2351 46.7258C66.6883 47.5911 66.1234 48.4468 65.5405 49.2887C64.3277 48.5687 63.1149 47.8501 61.9006 47.1301ZM69.478 51.7491C69.4019 51.7532 68.3414 51.036 68.0645 50.8629C67.6893 50.6289 66.4944 49.8093 66.5069 49.7027C66.5138 49.6514 68.1365 46.9335 68.3234 47.0027C68.981 47.245 70.5469 48.379 70.6604 48.4911C70.8252 48.6545 70.8556 48.894 70.8155 49.045C70.5912 49.9103 70.2257 50.7162 69.7993 51.468C69.7273 51.5982 69.6262 51.7394 69.478 51.7491Z"
        fill="#E2D1CA"
      />
      <path
        d="M48.0742 44.1123C46.8032 46.8081 48.6903 49.3641 49.3743 50.0038C53.5458 53.9042 56.5017 55.7845 60.8823 58.4775C63.3869 60.0172 67.7356 63.6517 67.8505 63.6241C69.6158 63.1921 71.8656 60.7164 72.6036 59.5159C72.6714 59.4052 69.5369 55.7222 65.7738 53.0083C62.0107 50.2945 56.6831 47.5586 56.6942 47.2969"
        fill="#9DA6D9"
      />
      <path
        d="M61.9014 47.1291C62.2336 46.6777 62.5992 46.2499 62.9924 45.8511C63.2596 45.5811 63.7358 44.965 64.1138 44.8694C64.5209 44.767 67.2954 46.3814 67.3148 46.4811C67.3314 46.5669 67.2829 46.65 67.2359 46.7248C66.689 47.5902 66.1241 48.4458 65.5412 49.2877C64.3284 48.5677 63.1156 47.8491 61.9014 47.1291Z"
        fill="#4A5296"
      />
      <path
        d="M74.8834 29.0496C75.6629 28.63 76.369 28.0277 76.7608 27.2344C77.4545 25.8276 76.9242 23.9362 75.5992 23.0958C75.2573 22.8784 74.8502 22.7247 74.4501 22.7926C74.1773 22.8396 73.9309 22.9864 73.7163 23.1623C72.9368 23.8033 72.5589 24.7975 72.1837 25.7362C72.0494 26.0741 71.8832 26.4382 71.5565 26.5961C71.0442 26.8411 70.4752 26.4576 69.9684 26.2001C69.1627 25.7902 68.1312 25.7071 67.3877 26.2194C66.8035 26.6224 66.4864 27.3299 66.3964 28.0333C66.3064 28.738 66.413 29.4497 66.5196 30.1517C65.664 30.1212 64.8292 29.7405 63.9791 29.8374C63.2757 29.9177 62.6195 30.3442 62.2623 30.9562C61.9037 31.5668 61.8525 32.3477 62.1266 33.0012C61.4108 32.6121 60.5192 32.5055 59.7729 32.8351C59.0267 33.1646 58.4853 33.969 58.574 34.779C58.6875 35.805 59.4047 36.7673 60.4223 36.9473C61.9909 37.2242 63.5997 36.6538 64.6215 36.5084C66.6498 36.2204 70.3118 38.6365 71.9178 37.0844C73.229 35.8161 71.3474 33.663 71.2685 33.6437C70.9293 33.5578 70.5707 33.5218 70.2633 33.3543C69.956 33.1867 69.7109 32.8295 69.8148 32.4958C69.8992 32.2231 70.1927 32.0569 70.4779 32.0334C70.7632 32.0098 71.0442 32.0971 71.317 32.1843C71.5385 31.564 71.8528 30.2583 72.4744 29.9371C73.2165 29.5508 74.122 29.4594 74.8834 29.0496Z"
        fill="#DBA9A2"
      />
    </svg>
  );
}
