import React from 'react';
import './styles.scss';

const NoConfigFound = () => {
  return (
    <div className="no-config">
      <span className="message appear">No configuration for this screen found</span>
    </div>
  );
};

export default NoConfigFound;
