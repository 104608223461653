import React from 'react';

interface Props {
  className?: string;
  style?: any;
}

const ArrowUpIcon = ({ className = '', style }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={className} style={style}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM15.6464 11.5607C15.4512 11.7559 15.1346 11.7559 14.9393 11.5607L13 9.62132V17.7071C13 17.9832 12.7761 18.2071 12.5 18.2071H11.5C11.2239 18.2071 11 17.9832 11 17.7071V9.62132L9.06066 11.5607C8.8654 11.7559 8.54881 11.7559 8.35355 11.5607L7.64645 10.8536C7.45118 10.6583 7.45118 10.3417 7.64645 10.1464L11.6464 6.14645C11.8417 5.95118 12.1583 5.95118 12.3536 6.14645L16.3536 10.1464C16.5488 10.3417 16.5488 10.6583 16.3536 10.8536L15.6464 11.5607Z"
        fill="#e5e8ff"
      />
    </svg>
  );
};

export default ArrowUpIcon;
