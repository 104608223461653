export const getIsOutOfServiceEnabled = (screenConfig: DPI.ScreenConfig | null) => {
  if (screenConfig?.outOfService !== null && typeof screenConfig?.outOfService === 'object') {
    const { startDate, endDate, enabled } = screenConfig?.outOfService;
    const isEnabled = typeof enabled === 'boolean' && enabled;
    const now = new Date();
    if (startDate && endDate) {
      return isEnabled && now > new Date(startDate) && now < new Date(endDate);
    }
    if (startDate && !endDate) {
      return isEnabled && now > new Date(startDate);
    }
    return isEnabled;
  }
  return false;
};
