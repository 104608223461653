import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';

dayjs.extend(isToday);
dayjs.extend(isTomorrow);

type TextLanguage = 'en' | 'no';

export const UNABLE_TO_FETCH_DEPARTURES_TEXTS: DPI.MessageText = {
  no: {
    title: 'Ai, beklager!',
    body: 'Vi får dessverre ikke tak i informasjonen som skal vises her.\nSe Ruter-appen eller rutetabellene for avganger.',
  },
  en: {
    title: 'Oops... Something went wrong!',
    body: 'Unfortunately, it seems like one our services is not responding.\nPlease refer to the Ruter app or timetables for departures.',
  },
};

export const NO_DEPARTURES_TEXTS: DPI.MessageText = {
  no: {
    title: 'Vi har ingen avganger å vise deg akkurat nå.',
    body: 'Se Ruter-appen for mer informasjon.',
  },
  en: {
    title: 'Looks like there are no departures coming up.',
    body: 'Please refer to the Ruter app for more information.',
  },
};

export const dayStrings = {
  no: {
    today: 'I dag',
    tomorrow: 'I morgen',
    weekdaysList: ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'],
  },
  en: {
    today: 'Today',
    tomorrow: 'Tomorrow',
    weekdaysList: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  },
};

const getDepartureDay = (timestamp: string, locale: TextLanguage) => {
  if (dayjs(timestamp).isToday()) {
    return dayStrings[locale].today;
  } else if (dayjs(timestamp).isTomorrow()) {
    return dayStrings[locale].tomorrow;
  } else {
    return dayStrings[locale].weekdaysList[new Date(timestamp).getDay()];
  }
};

const getTimestamp = (timestamp: string, locale: TextLanguage) => {
  if (locale === 'no') {
    return `kl. ${dayjs(timestamp).format('HH:mm')}`;
  }
  return dayjs(timestamp).format('HH:mm');
};

export const NO_DEPARTURE_SOON = {
  no: {
    nextDeparture: 'Neste avgang',
    moreInfo: 'Se Ruter-appen eller rutetabellene for mer informasjon.',
    departureDay: (timestamp: string) => {
      return getDepartureDay(timestamp, 'no');
    },
    getDepartureTime: (timestamp: string) => {
      return getTimestamp(timestamp, 'no');
    },
  },
  en: {
    nextDeparture: 'Next departure',
    moreInfo: 'See the Ruter app or timetables for more information.',
    departureDay: (timestamp: string) => {
      return getDepartureDay(timestamp, 'en');
    },
    getDepartureTime: (timestamp: string) => {
      return getTimestamp(timestamp, 'en');
    },
  },
};
