import { getLegalTimeRange } from '../../utils/timeRange';

const getSituationsQuery = (retrieveSituations: boolean) => {
  if (retrieveSituations) {
    return `
  situations {
    severity
    summary {
      value
      language
    }
    description {
      value
      language
    }
    situationNumber
    reportType
  }`;
  }
  return '';
};

const getEstimatedCallFragment = (retrieveSituations: boolean) => `
  fragment EstimatedCallParts on EstimatedCall {
    realtime
    ${getSituationsQuery(retrieveSituations)}
    destinationDisplay {
      frontText
    }
    quay {
      publicCode
      id
    }
    cancellation
    expectedDepartureTime
    actualDepartureTime
    aimedDepartureTime
    serviceJourney {
      id
      line {
        id
        publicCode
        transportMode
        authority {
          id
        }
      }        
    }
  }
`;

const getFirstDepartureFragment = () => `
  fragment FirstDepartureParts on EstimatedCall {
      destinationDisplay {
        frontText
      }
      expectedDepartureTime
      actualDepartureTime
      aimedDepartureTime
      cancellation
      serviceJourney {
        id
        line {
          id
          publicCode
          transportMode
          authority {
            id
          }
        }        
      }
  }
`;

const rawStopPlaceDepartureQuery = (
  id: string,
  stopPlaceId: string,
  numberOfDepartures: number,
  timeRange: number
) => `${id}: stopPlace(id: "${stopPlaceId}") {
    name
    tariffZones {
      id
      name
    }
    estimatedCalls(numberOfDepartures: ${numberOfDepartures}, arrivalDeparture: departures, includeCancelledTrips: true, timeRange: ${timeRange}) {
      ...EstimatedCallParts
     }
     firstDeparture: estimatedCalls(numberOfDepartures: 1, numberOfDeparturesPerLineAndDestinationDisplay: 1, arrivalDeparture: departures, includeCancelledTrips: false) {
      ...FirstDepartureParts
    }
  }`;

const rawQuaysDepartureQuery = (id: string, quayIds: string[], numberOfDepartures: number, timeRange: number) => {
  const quayIdsString = quayIds.map((quayId) => `"${quayId}"`).join(',');
  return `${id}: quays(ids: [${quayIdsString}]) {
    name
    tariffZones {
      id
      name
    }
    stopPlace {
      id
    }
    estimatedCalls(numberOfDepartures: ${numberOfDepartures}, arrivalDeparture: departures, includeCancelledTrips: true, timeRange: ${timeRange}) {
      ...EstimatedCallParts
    }
    firstDeparture: estimatedCalls(numberOfDepartures: 1, numberOfDeparturesPerLineAndDestinationDisplay: 1, arrivalDeparture: departures, includeCancelledTrips: false) {
      ...FirstDepartureParts
    }
  }`;
};

type BoardWithId = DPI.BoardConfig & { id: string };

export const buildDepartureQuery = (screenConfig: DPI.ScreenConfig) => {
  if (screenConfig.boards.length === 0) {
    throw new Error('Empty query');
  }

  const { timeRange: requestedTimeRange } = screenConfig;
  const timeRange = getLegalTimeRange(requestedTimeRange);

  const boardsWithId = screenConfig.boards.reduce<BoardWithId[]>((sum, item, index) => {
    return [
      ...sum,
      {
        ...item,
        id: `board_${index}`,
      },
    ];
  }, []);

  const retrieveSituations = boardsWithId.find((item) => item.situationsInfo);
  const startString = `${getEstimatedCallFragment(
    retrieveSituations !== undefined
  )} ${getFirstDepartureFragment()} query {`;
  const endString = ` }`;
  let graphqlString = '';

  boardsWithId.forEach((item) => {
    const { numberOfDepartures, stopPlaceId, quayIds, id } = item;

    if (Array.isArray(quayIds) && quayIds.length) {
      graphqlString += rawQuaysDepartureQuery(id, quayIds, numberOfDepartures, timeRange);
    } else if (stopPlaceId) {
      graphqlString += rawStopPlaceDepartureQuery(id, stopPlaceId, numberOfDepartures, timeRange);
    } else {
      console.warn('No stopPlace or quayIds specified, omitting query');
    }
  });

  return startString + graphqlString + endString;
};
