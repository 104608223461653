export const fillBoardsWithGlobal = (screenConfig: DPI.ScreenConfig, key: keyof DPI.BoardConfig) => {
  if (Array.isArray(screenConfig.boards) && screenConfig.hasOwnProperty(key)) {
    // [key]) {
    return {
      ...screenConfig,
      boards: screenConfig.boards.map((board) => ({
        ...board,
        [key]: typeof board[key] === 'undefined' ? screenConfig[key as keyof DPI.ScreenConfig] : board[key],
      })),
    };
  }
  return screenConfig;
};

export const getBoardByIndex = (screenConfig: DPI.ScreenConfig, index: number): DPI.BoardConfig | null => {
  if (screenConfig && Array.isArray(screenConfig.boards) && screenConfig.boards[index]) {
    return screenConfig.boards[index];
  }
  return null;
};

export const getSituationSeveritiesForBoard = (screenConfig: DPI.ScreenConfig, boardIndex: number) => {
  const boardConfig = (screenConfig.boards || [])[boardIndex];
  if (boardConfig && boardConfig.situationsInfo && Array.isArray(boardConfig.situationsInfo.showOnlySeverities)) {
    return boardConfig.situationsInfo.showOnlySeverities;
  }
  return screenConfig?.situationsInfo?.showOnlySeverities ?? [];
};
