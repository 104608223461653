import React from 'react';

interface Props {
  className?: string;
}
export default function CantFindAnything({ className = '' }: Props) {
  return (
    <svg className={className} viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.6113 80.4098C26.1631 80.1713 25.6862 79.9246 25.1764 79.937C24.6296 79.9534 24.1444 80.2618 23.6839 80.5537C18.6474 83.7853 13.2985 86.5399 7.73981 88.756C6.35837 89.3069 4.66035 90.3677 5.05916 91.8026C5.39629 93.0154 5.84444 93.8172 8.09339 93.8172C16.6411 93.8172 26.932 94.142 35.4468 93.406C35.7222 93.3814 34.8835 84.0566 34.8753 83.2755C31.6683 82.5642 29.6167 82.0174 26.6113 80.4098Z"
        fill="#4A5296"
      />
      <path
        d="M66.7677 42.9053C62.2739 49.9194 64.638 71.9279 65.5466 79.0202C54.6472 78.831 30.324 80.2248 30.0773 80.4756C29.8511 80.7017 29.6415 89.6934 30.2582 94.1173C43.0941 94.3394 74.3286 96.7034 80.9151 93.4801C85.191 91.3873 84.932 69.5598 84.3975 60.457C84.1262 55.8481 84.5537 52.559 84.5003 47.9418C84.4715 45.6477 84.5003 43.3494 84.6195 41.0552C84.6935 39.645 89.4628 35.0402 88.1019 34.8181C74.6411 32.6391 68.6466 39.978 66.7677 42.9053Z"
        fill="#9DA6D9"
      />
      <path
        d="M102.912 73.5642C109.435 73.5642 114.724 62.6928 114.724 49.2821C114.724 35.8715 109.435 25 102.912 25C96.3881 25 91.0996 35.8715 91.0996 49.2821C91.0996 62.6928 96.3881 73.5642 102.912 73.5642Z"
        fill="#4A5296"
      />
      <path
        d="M109.815 68.9923C108.54 70.9946 106.307 73.1284 104.038 73.4244C103.771 72.302 99.3016 68.5031 101.082 68.059C105.629 66.9243 109.782 69.0458 109.815 68.9923Z"
        fill="#DBA9A2"
      />
      <path
        d="M114.271 43.5712C114.271 43.5712 109.564 38.3826 97.4966 34.9413C93.118 33.6914 88.9819 33.7367 84.4141 33.5928C83.119 40.278 83.6658 51.054 84.3935 60.572C95.1901 59.1042 101.123 60.535 112.729 62.9196C113.005 62.9772 116.475 51.7365 114.271 43.5712Z"
        fill="#F24949"
      />
      <path
        d="M109.046 47.0291C98.8945 44.3608 87.0084 45.3229 80.2574 47.3004C71.2821 49.9277 68.0341 52.1355 66.8212 58.6233C66.8911 59.2894 65.7317 65.1728 73.0295 69.3336C81.6347 74.2386 98.8822 74.6538 104.383 73.4697C104.276 71.6566 103.417 67.9316 101.098 67.1833C99.6305 66.7105 78.4031 62.081 78.3497 59.3058C78.3908 58.4465 79.3076 57.8134 81.7581 57.3282C91.4528 55.4123 97.5336 56.4196 107.635 55.622C111.286 55.3342 112.951 53.6896 112.951 50.7911C112.947 49.0601 111.862 47.7692 109.046 47.0291Z"
        fill="#E2D1CA"
      />
    </svg>
  );
}
