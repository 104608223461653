import React from 'react';

interface Props {
  className?: string;
}
export default function Garden({ className = '' }: Props) {
  return (
    <svg className={className} viewBox="0 0 240 240" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M33.8571 213.216C28.2353 213.973 22.5466 214.876 17.4012 217.254C14.7889 218.462 12.0636 220.514 12.001 223.39C11.9383 226.504 14.9895 228.694 17.8025 230.032C25.2885 233.593 33.5227 235.306 41.736 236.431C54.7518 238.216 67.914 238.6 81.0469 238.981C114.46 239.954 147.949 240.924 181.278 238.358C184.798 238.086 188.543 237.689 191.293 235.474C195.736 231.896 195.657 224.606 192.271 220.013C187.009 212.865 178.695 212.548 170.712 212.05C159.593 211.356 148.475 210.654 137.34 210.153C115.288 209.158 93.185 208.798 71.1241 209.877C58.6683 210.491 46.2251 211.557 33.8571 213.216Z"
        fill="#E2D1CA"
      />
      <path
        d="M190.892 27.124C193.082 25.7279 195.565 24.5618 198.16 24.6579C200.756 24.754 203.439 26.4468 203.937 28.9965C204.179 30.2296 203.899 31.5127 203.435 32.6789C202.19 35.8054 199.64 38.2924 196.718 39.9559C193.793 41.6195 190.499 42.5265 187.197 43.166C182.812 44.0145 177.659 45.5694 173.236 44.9382C176.998 36.7208 185.153 30.7896 190.892 27.124Z"
        fill="#8D91AF"
      />
      <path
        d="M124.883 66.4259C124.364 66.3381 123.834 66.2461 123.324 66.3673C121.714 66.7561 121.305 68.871 121.535 70.5137C122.35 76.2902 127.094 81.0426 132.636 82.865C138.179 84.6874 144.323 83.9016 149.744 81.7532C154.889 79.7134 160.022 76.4406 163.847 72.4406C159.32 71.0111 153.33 71.3706 148.348 70.5053C140.523 69.1427 132.703 67.7843 124.883 66.4259Z"
        fill="#4A5296"
      />
      <path
        d="M220.903 102.595C221.655 102.281 222.47 101.964 223.268 102.127C224.138 102.302 224.794 103.004 225.346 103.698C227.009 105.792 228.338 108.421 227.87 111.055C227.385 113.772 225.137 115.795 222.863 117.362C217.86 120.815 212.05 123.051 206.052 124C201.391 124.739 191.264 125.809 187.209 122.683C184.003 120.217 186.553 119.799 189.905 117.847C193.316 115.862 196.806 113.993 200.313 112.183C207.042 108.702 213.914 105.504 220.903 102.595Z"
        fill="#4A5296"
      />
      <path
        d="M128.997 167.967C123.534 167.992 118.965 168.247 112.85 169.622C111.145 170.006 109.218 170.922 108.95 172.652C108.846 173.338 109.034 174.031 109.272 174.683C110.806 178.884 114.355 182.182 118.472 183.933C122.589 185.685 127.22 185.986 131.647 185.359C135.693 184.79 139.592 183.482 143.467 182.178C153.164 178.918 162.861 175.657 172.563 172.397C173.328 172.138 173.938 171.712 174.293 171.005C169.052 168.217 139.425 167.921 128.997 167.967Z"
        fill="#4A5296"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M174.689 43.3209C175.02 43.4617 175.173 43.8436 175.032 44.1738L165.001 67.6846C165.001 67.6848 165.001 67.685 165.001 67.6851C164.55 68.7434 164.145 69.7101 164.055 70.7146L164.055 70.716C163.947 71.9061 164.271 73.065 164.835 74.2288C165.358 75.3093 166.064 76.3481 166.775 77.3949C166.835 77.4836 166.895 77.5723 166.955 77.6611L166.956 77.6616C173.655 87.5643 177.566 99.0222 181.374 110.235C183.259 115.789 185.194 121.501 185.661 127.455C186.161 133.812 184.984 140 183.178 146.056C182.001 150.002 180.544 153.932 179.102 157.819C178.339 159.876 177.58 161.922 176.87 163.951C170.311 182.688 167.174 200.824 167.782 220.65C167.793 221.008 167.511 221.308 167.152 221.319C166.793 221.33 166.493 221.048 166.482 220.689C165.87 200.689 169.036 182.394 175.643 163.522C176.371 161.443 177.138 159.374 177.905 157.307C179.337 153.448 180.767 149.594 181.932 145.685C183.715 139.705 184.848 133.69 184.365 127.557C183.913 121.792 182.037 116.234 180.143 110.653C176.326 99.4125 172.464 88.124 165.879 78.39C165.817 78.2985 165.754 78.2063 165.691 78.1134C164.99 77.0822 164.232 75.9661 163.665 74.7955C163.043 73.5112 162.623 72.1081 162.761 70.5984C162.87 69.37 163.357 68.227 163.788 67.2135L163.805 67.1754L173.836 43.6636C173.977 43.3335 174.359 43.18 174.689 43.3209Z"
        fill="#4A5296"
      />
      <path
        d="M176.258 202.484C180.685 195.847 186.453 189.916 193.634 186.438C195.598 185.485 197.705 184.716 199.891 184.699C202.073 184.687 204.351 185.535 205.63 187.303C207.481 189.861 206.783 193.464 205.416 196.311C201.889 203.659 194.775 208.629 187.46 212.223C183.895 213.975 168.918 221.916 166.762 221.147C168.776 214.694 172.513 208.098 176.258 202.484Z"
        fill="#8D91AF"
      />
      <path
        d="M146.703 105.658C145.905 105.29 144.839 104.989 144.254 105.641C143.986 105.942 143.911 106.369 143.861 106.77C143.046 113.407 145.562 120.179 149.763 125.382C153.963 130.586 159.719 134.348 165.796 137.14C170.014 139.08 175.527 141.688 180.233 141.926C185.96 142.219 180.572 135.932 178.887 133.37C173.847 125.713 167.426 118.966 160.045 113.533C155.878 110.461 151.405 107.811 146.703 105.658Z"
        fill="#8D91AF"
      />
      <path
        d="M164.11 5.34453C163.307 3.80219 162.417 2.22641 160.988 1.2358C159.09 -0.085014 156.591 -0.135171 154.292 0.123975C151.491 0.43746 148.348 1.45315 147.282 4.06134C146.618 5.68727 146.948 7.53892 147.387 9.24427C149.84 18.8536 155.37 27.6437 162.91 34.0806C165.209 36.0451 171.868 42.9125 173.74 38.9709C175.525 35.2133 172.131 26.5444 171.048 22.7701C169.322 16.747 166.998 10.8995 164.11 5.34453Z"
        fill="#4A5296"
      />
      <path
        d="M24.5572 215.206C24.436 217.029 24.3566 218.889 24.7955 220.665C24.9292 221.213 25.1466 221.794 25.6189 222.103C26.0034 222.354 26.4841 222.379 26.9397 222.4C33.3264 222.659 39.7215 222.529 46.1124 222.4C52.1815 222.278 58.2505 222.153 64.3238 222.032C64.5745 222.028 64.8379 222.019 65.0678 221.915C65.4816 221.727 65.6989 221.267 65.8034 220.824C66.1169 219.465 66.1629 216.41 65.1555 215.44C62.1252 212.514 50.3633 210.851 48.9087 210.333C47.4541 209.814 46.0581 208.911 45.335 207.549C44.1563 205.338 45.0089 202.638 45.8742 200.289C41.1385 198.846 32.7287 194.763 27.993 193.321C27.993 193.321 26.2835 200.539 26.1205 201.551C25.3765 206.073 24.8582 210.633 24.5572 215.206Z"
        fill="#F24949"
      />
      <path
        d="M79.8834 128.501C80.1551 129.048 99.6413 138.378 99.9464 138.01C104.858 132.054 108.665 124.948 111.453 118.077C107.633 116.363 88.8491 108.028 88.8491 108.028C86.6254 108.292 79.5658 127.861 79.8834 128.501Z"
        fill="#9DA6D9"
      />
      <path
        d="M130.146 126.878C130.823 127.764 130.355 128.918 129.832 129.336C120.532 128.604 105 127.534 105 127.534L107.157 124.061C107.157 124.057 130.045 126.749 130.146 126.878Z"
        fill="#9DA6D9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M99.504 103.293C101.4 102.949 103.318 102.876 104.986 103.53L99.504 103.293ZM105.46 102.32C103.459 101.535 101.255 101.654 99.2723 102.013L99.2721 102.013C97.1427 102.399 95.3203 102.896 93.5555 103.893C91.7905 104.891 90.1251 106.367 88.2631 108.655C88.0365 108.933 88.0785 109.342 88.3569 109.569C88.6353 109.796 89.0447 109.754 89.2713 109.475C91.0666 107.269 92.6134 105.919 94.1954 105.025C95.7775 104.131 97.4368 103.667 99.5038 103.293M109.832 109.616C109.116 106.942 107.425 104.483 104.986 103.53L104.986 103.53M109.832 109.617C110.545 112.298 110.41 115.141 110.258 118.051C110.24 118.41 110.515 118.715 110.874 118.734C111.232 118.753 111.538 118.477 111.557 118.119C111.706 115.244 111.863 112.194 111.088 109.282L111.088 109.281C110.308 106.364 108.413 103.473 105.46 102.32"
        fill="#9DA6D9"
      />
      <path
        d="M96.6052 95.4676C99.1465 95.756 101.696 96.3746 103.899 97.6787C106.102 98.9828 107.937 101.035 108.597 103.509C108.844 104.429 108.911 105.466 108.417 106.281C107.473 107.844 105.174 107.71 103.431 107.171C99.4015 105.921 95.6271 103.514 91.4097 103.418C88.9186 103.359 86.4734 104.12 83.9823 104.182C83.907 102.782 83.836 101.386 83.7607 99.9859C83.5935 96.7884 84.9645 96.4749 87.7315 95.8688C90.6365 95.2251 93.6501 95.1332 96.6052 95.4676Z"
        fill="#F24949"
      />
      <path
        d="M78.4217 60.0562C79.3288 61.1722 79.7175 61.6446 79.5377 62.2841C79.2744 63.2078 77.8616 62.6268 77.0299 62.1461C74.8856 60.9047 74.4969 60.4199 72.5867 58.8399C66.0914 62.702 61.9157 63.864 56.9669 65.699C54.2458 63.3959 49.8529 61.5317 47.1318 59.2286C48.8372 52.215 54.4297 46.2797 61.3306 44.1605C63.8217 43.3956 71.9263 42.7101 74.313 43.7551C77.6568 45.218 75.0486 52.0352 75.358 54.5515C75.7049 57.4104 76.591 57.8033 78.4217 60.0562Z"
        fill="#F24949"
      />
      <path
        d="M34.9522 149.504C35.5081 150.34 23.8632 194.96 24.9207 196.389C28.1266 200.707 41.3807 203.942 47.3495 203.52C52.9253 188.377 61.9787 158.215 61.9787 146.65C61.9787 138.269 47.1781 115.36 41.0798 104.296C40.0599 102.444 40.9627 102.306 39.5458 100.735C37.5771 98.5612 35.0024 97.0481 32.4694 95.5685C26.5258 92.0993 23.1025 87.1421 16.7701 84.7178C15.4744 86.862 11.2904 100.768 12.7116 110.286C15.0982 126.307 34.367 148.623 34.9522 149.504Z"
        fill="#DBA9A2"
      />
      <path
        d="M12.631 86.8668C20.4681 94.7833 30.4536 100.551 41.2291 103.39C41.9898 103.59 42.8049 103.774 43.5572 103.532C44.1884 103.323 44.6858 102.838 45.1414 102.353C52.6775 94.3236 54.0443 87.1636 56.4143 75.8782C57.0203 72.9857 56.9702 65.7087 56.9702 65.7087C56.9702 65.7087 49.8645 60.275 48.6106 55.6772C48.6106 55.6772 46.0693 56.3084 43.8874 57.3701C39.4736 59.5226 35.2896 62.135 31.4066 65.1403C27.5236 68.1456 23.9457 71.5437 20.7439 75.2679C19.8996 76.2418 11.8535 86.081 12.631 86.8668Z"
        fill="#9DA6D9"
      />
      <path
        d="M43.3108 64.7255C36.6231 64.5876 35.9627 70.4435 35.248 72.9305C33.4465 79.2002 32.9366 85.7624 32.69 92.2787C32.4057 99.7731 32.9867 108.429 38.9805 112.939C44.6191 117.182 54.1448 116.254 61.0039 114.607C67.9716 112.935 79.5454 109.031 85.2466 106.248C87.8423 104.981 86.9185 96.6341 84.8287 95.3801C81.4848 94.2641 54.7342 104.296 49.5805 100.676C47.3192 99.0876 50.1907 78.661 49.9985 75.0413C49.7728 70.8029 50.5586 64.876 43.3108 64.7255Z"
        fill="#4A5296"
      />
      <path
        d="M78.6733 43.337C78.0004 41.5648 76.3744 40.2941 74.6189 39.5836C72.8634 38.873 70.9532 38.6473 69.0765 38.3756C65.5571 37.8699 62.1631 36.4905 58.6103 36.3568C52.3992 36.1227 50.1462 38.2377 48.9132 39.5668C47.1201 41.5063 46.1169 44.1604 46.1838 46.8021C46.2507 49.3726 47.6133 52.1647 50.0961 52.8419C52.1567 53.4019 54.3177 52.3612 56.0272 51.078C57.7367 49.7948 59.2791 48.2107 61.2687 47.429C64.8173 46.033 68.7588 47.4959 72.4496 48.4614C74.017 48.8711 75.7809 49.1636 77.1769 48.3486C78.8196 47.3914 79.3504 45.1092 78.6733 43.337Z"
        fill="#4A5296"
      />
      <path
        d="M65.6108 38.4445C67.8261 38.3191 70.0957 37.7799 71.8178 36.3797C73.5399 34.9836 74.5681 32.5844 73.8868 30.4736C73.473 29.1946 72.5033 28.1664 71.4332 27.3597C69.544 25.9344 67.1991 25.0524 64.8375 25.1528C62.476 25.2489 60.1227 26.3983 58.7936 28.3545C57.4644 30.3106 57.3014 33.0651 58.6013 35.0421C59.3871 36.2417 60.6285 37.0944 61.9577 37.6378C63.6338 38.3233 65.4938 38.5615 67.2869 38.3149"
        fill="#4A5296"
      />
      <path
        d="M130.46 153.892C130.857 153.052 137.47 146.523 140.596 143.442C143.33 146.247 149.992 154.067 150.105 154.728C150.197 155.263 149.449 165.971 148.851 170.924C148.826 171.129 134.937 170.707 129.415 170.402C128.558 170.356 130.034 154.799 130.46 153.892Z"
        fill="#F24949"
      />
      <path
        d="M190.126 99.1384C189.871 98.0767 190.54 83.7944 191.196 83.6648C191.618 83.5812 203.585 90.5865 204.024 91.0923C204.985 92.1958 205.925 94.503 206.979 96.9565C208.697 100.94 210.41 104.923 212.128 108.902C208.672 110.57 197.892 115.858 197.545 115.369C196.471 113.864 190.556 100.919 190.126 99.1384Z"
        fill="#F24949"
      />
      <path
        d="M138.465 34.4177C141.324 34.961 145.085 35.5462 147.944 36.0896C146.494 47.3834 142.899 59.7723 141.537 71.4798C141.508 71.7432 133.767 70.5478 131.923 70.0169C131.543 69.9082 131.313 69.5195 131.405 69.1308C131.869 67.1203 133.01 60.1443 133.336 58.4138C133.721 56.3908 138.093 34.3466 138.465 34.4177Z"
        fill="#9DA6D9"
      />
      <path
        d="M206.87 103.03C206.803 102.164 208.872 94.6365 209.064 94.5655C209.24 94.5028 216.103 97.3952 216.772 98.507C217.399 99.5519 221.048 106.921 220.922 107.188C220.809 107.431 211.656 112.083 211.024 111.987C210.306 111.882 206.937 103.899 206.87 103.03Z"
        fill="#DBA9A2"
      />
      <path
        d="M147.998 72.5288C150.828 73.0931 153.687 73.5027 156.562 73.7452L158.824 59.8014C158.974 58.8735 151.292 57.0636 150.385 57.4816C149.841 57.7282 148.437 69.185 147.998 72.5288Z"
        fill="#E2D1CA"
      />
      <path
        d="M139.728 72.3872C142.373 73.0141 146.031 73.6411 148.593 73.8501C149.479 66.6943 150.641 58.9993 151.711 52.1862C149.989 51.8393 146.298 50.3722 144.559 50.8571C144.045 50.9992 140.003 72.3955 139.728 72.3872Z"
        fill="#F24949"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M136.207 128.551C136.368 128.23 136.758 128.101 137.079 128.262C138.054 128.75 139.03 129.237 140.006 129.723C140.331 129.885 140.657 130.047 140.982 130.209C141.303 130.369 141.434 130.759 141.274 131.08C141.113 131.402 140.723 131.532 140.402 131.372C140.078 131.21 139.753 131.049 139.428 130.887C138.451 130.401 137.473 129.913 136.496 129.424C136.176 129.263 136.046 128.872 136.207 128.551Z"
        fill="#4A5296"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M132.191 131.336C132.495 131.146 132.897 131.237 133.087 131.541C133.612 132.377 134.136 133.212 134.661 134.047C135.185 134.882 135.71 135.717 136.235 136.553C136.426 136.857 136.334 137.258 136.03 137.449C135.726 137.64 135.324 137.548 135.134 137.244C134.609 136.408 134.085 135.574 133.56 134.739L133.56 134.738C133.035 133.903 132.511 133.068 131.986 132.232C131.795 131.928 131.887 131.527 132.191 131.336Z"
        fill="#4A5296"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M140.632 137.093C140.861 136.817 141.271 136.779 141.547 137.008C142.74 138 143.842 139.102 144.832 140.29C145.062 140.565 145.025 140.975 144.749 141.205C144.474 141.435 144.064 141.398 143.834 141.122C142.894 139.995 141.847 138.948 140.716 138.008C140.44 137.779 140.402 137.369 140.632 137.093Z"
        fill="#4A5296"
      />
      <path
        d="M137.098 161.788C137.14 161.6 137.194 161.395 137.345 161.274C137.483 161.161 137.675 161.148 137.855 161.144C139.268 161.085 140.689 161.14 142.093 161.299C142.231 161.315 142.382 161.336 142.474 161.445C142.557 161.541 142.565 161.679 142.57 161.804C142.678 164.897 142.837 167.807 142.766 170.9C142.766 170.908 135.999 170.666 135.999 170.666C136.183 169.09 136.756 163.33 137.098 161.788Z"
        fill="#DBA9A2"
      />
      <path
        d="M195.886 104.458C195.924 104.329 199.422 102.531 199.531 102.657C200.576 103.84 203.686 110.673 204.601 112.55C202.804 113.42 200.333 114.431 200.333 114.431C200.333 114.431 195.844 104.609 195.886 104.458Z"
        fill="#E2D1CA"
      />
      <path
        d="M202.144 101.173C202.257 101.186 204.551 99.9152 205.304 99.5265C204.576 97.9215 203.719 96.5129 203.005 94.9287C202.95 94.8117 199.736 96.1283 199.774 96.4544C199.836 96.9935 201.977 101.157 202.144 101.173Z"
        fill="#E2D1CA"
      />
      <path
        d="M138.674 40.5369C138.766 40.6581 140.258 40.7835 140.823 40.9465C141.032 39.7594 141.291 38.5807 141.596 37.4187C140.852 37.2557 140.1 37.1136 139.347 36.9966C139.122 38.0332 138.599 40.4365 138.674 40.5369Z"
        fill="#E2D1CA"
      />
      <path
        d="M137.879 45.6105C138.539 45.7401 139.2 45.8697 139.86 45.9951C139.91 46.0034 139.965 46.016 140.011 45.9951C140.098 45.9616 140.123 45.8571 140.14 45.7652C140.32 44.8038 140.495 43.8425 140.675 42.8811C140.684 42.8226 140.696 42.7641 140.671 42.7097C140.633 42.6303 140.533 42.6094 140.445 42.5969C139.777 42.5007 139.078 42.3419 138.41 42.3252C138.326 42.5927 137.854 45.6063 137.879 45.6105Z"
        fill="#E2D1CA"
      />
      <path
        d="M137.072 51.1866C137.122 51.2243 137.185 51.2326 137.248 51.241C137.816 51.3037 138.385 51.3706 138.953 51.4333C139.037 51.4416 139.133 51.4458 139.183 51.3831C139.212 51.3455 139.221 51.2995 139.225 51.2535C139.354 50.2462 139.559 49.0341 139.689 48.0267C139.697 47.9724 137.603 47.5168 137.566 47.6965C137.411 48.4572 136.976 51.1156 137.072 51.1866Z"
        fill="#E2D1CA"
      />
      <path
        d="M212.66 106.915C212.798 106.969 216.368 105.084 216.426 104.955C216.489 104.813 214.767 101.126 214.675 101.088C214.549 101.038 210.75 102.785 210.75 103.216C210.746 103.534 212.572 106.877 212.66 106.915Z"
        fill="#F24949"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.8028 53.8492C66.1564 53.7873 66.4932 54.0239 66.555 54.3775C66.9785 56.7983 67.8427 58.6584 69.4944 60.4527C69.7375 60.7168 69.7205 61.128 69.4564 61.3711C69.1923 61.6143 68.7811 61.5972 68.5379 61.3331C66.7036 59.3405 65.7371 57.2465 65.2745 54.6015C65.2126 54.2478 65.4491 53.911 65.8028 53.8492Z"
        fill="#4A5296"
      />
      <path
        d="M72.2237 53.3687C72.2237 53.7156 71.9436 53.9956 71.5967 53.9956C71.2498 53.9956 70.9697 53.7156 70.9697 53.3687C70.9697 53.0217 71.2498 52.7417 71.5967 52.7417C71.9436 52.7417 72.2237 53.0217 72.2237 53.3687Z"
        fill="#4A5296"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.4125 221.864C24.4247 221.505 24.7255 221.224 25.0843 221.236L25.0621 221.886L25.04 222.535C24.6812 222.523 24.4003 222.222 24.4125 221.864ZM65.8234 221.886C65.8403 222.536 65.8402 222.536 65.8401 222.536L65.1368 222.554C64.6851 222.565 64.0334 222.581 63.2333 222.601C61.6332 222.64 59.4394 222.692 57.0649 222.745C52.3188 222.849 46.841 222.954 43.9422 222.954C40.6223 222.954 35.8945 222.849 32.0019 222.745C30.0545 222.692 28.3142 222.64 27.0608 222.601C26.434 222.581 25.9289 222.565 25.5805 222.553C25.4063 222.548 25.2712 222.543 25.1797 222.54L25.0406 222.535C25.0405 222.535 25.04 222.535 25.0621 221.886C25.0843 221.236 25.0842 221.236 25.0843 221.236L25.2232 221.241C25.3144 221.244 25.4492 221.248 25.6231 221.254C25.971 221.266 26.4754 221.282 27.1014 221.301C28.3535 221.341 30.0918 221.393 32.0368 221.445C35.9291 221.55 40.6414 221.654 43.9422 221.654C46.824 221.654 52.2869 221.55 57.0363 221.445C59.4094 221.393 61.6021 221.341 63.2015 221.301C64.0011 221.282 64.6525 221.265 65.1039 221.254L65.8064 221.236C65.8065 221.236 65.8066 221.236 65.8234 221.886ZM65.8234 221.886L65.8401 222.536C66.199 222.526 66.4825 222.228 66.4732 221.869C66.4639 221.51 66.1652 221.227 65.8064 221.236L65.8234 221.886Z"
        fill="#9DA6D9"
      />
    </svg>
  );
}
