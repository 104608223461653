import React from 'react';
import cx from 'classnames';
import { getSituationText, getSituationTextSize } from '~/utils/situations';
import DeviationIcon from '../../Icons/DeviationIcon';

import './styles.scss';

interface Props {
  situation: DPI.Situation;
  numberOfSituations: number;
  index: number;
  includeBody?: boolean;
}
const SituationLineEntry = ({ situation, numberOfSituations, index, includeBody = false }: Props) => {
  const [title = '', body] = getSituationText(situation) || [];
  const textSizeVariant = getSituationTextSize(title, includeBody ? body : '');
  const situationArray = [...Array(numberOfSituations)];

  return (
    <div className={cx('situationLineEntry', { '-fullSize': includeBody })}>
      <div className="iconWrapper">
        <DeviationIcon className="-deviation" />
      </div>
      <div className={cx('text', `-${textSizeVariant}`)}>
        <span className="title">{title}</span>
        {includeBody && <span className="body">{body}</span>}
      </div>
      {numberOfSituations > 1 && (
        <div className="dot-dot-dot">
          {situationArray.map((_, dotIndex) => {
            return (
              <div
                key={`dot-${dotIndex}-${index}`}
                className={cx('dot', {
                  active: dotIndex === index,
                  '-fiveAndAbove': numberOfSituations >= 5,
                })}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SituationLineEntry;
