import React from 'react';

interface Props {
  className?: string;
}
export default function RushHour({ className = '' }: Props) {
  return (
    <svg className={className} viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M77.3424 34.6368C77.043 38.3976 76.4801 42.1343 75.6657 45.8152C70.9987 45.5398 66.3477 45.0128 61.7406 44.2463C59.8922 43.9389 57.8561 43.4678 56.8101 41.9108C56.347 41.2201 56.1394 40.3937 55.9997 39.5753C55.345 35.7267 56.7103 31.3392 61.1417 31.7305C62.543 31.8582 77.3863 34.1019 77.3424 34.6368Z"
        fill="#E2D1CA"
      />
      <path
        d="M74.0043 47.0969C75.4734 47.4722 76.9386 47.8515 78.4078 48.2268C79.1862 43.4121 80.2242 38.6373 81.5137 33.9304C81.4179 34.2777 76.6751 32.8485 75.8367 33.6749C74.4434 35.0442 73.5931 44.8892 74.0043 47.0969Z"
        fill="#9DA6D9"
      />
      <path
        d="M77.4345 49.197C78.5842 49.9795 101.145 52.7182 113.597 53.6324C114.195 47.2408 113.964 41.2803 113.597 34.9805C113.553 34.2339 83.1714 31.4992 80.3928 32.3296C78.0892 33.0163 76.4643 45.5481 77.4345 49.197Z"
        fill="#F24949"
      />
      <path
        d="M43.2519 52.4946C43.0363 52.5345 42.8087 52.5744 42.645 52.7221C42.4414 52.9058 42.3935 53.1972 42.3576 53.4647C42.0103 56.0277 41.8146 60.3554 41.6709 62.9384C41.6549 63.1859 52.1586 62.8985 57.9794 60.6428C59.8398 59.9202 62.9817 55.2972 62.4867 53.3928C61.9238 51.221 60.8099 50.698 58.7938 50.5623C54.2586 50.2509 49.2004 51.3648 43.2519 52.4946Z"
        fill="#DBA9A2"
      />
      <path
        d="M45.6438 64.6989C35.6312 65.8606 21.9017 68.1362 11.929 66.6631C11.7294 66.6351 12.0169 55.349 12.5319 50.7778C20.3288 50.4944 45.56 50.0153 45.7636 51.177C46.3265 54.4108 46.3066 61.541 45.6438 64.6989Z"
        fill="#4A5296"
      />
      <path
        d="M58.0514 120C57.0573 120 56.2549 119.198 56.2549 118.203V1.79653C56.2549 0.802449 57.0573 0 58.0514 0C59.0455 0 59.8479 0.802449 59.8479 1.79653V118.203C59.8479 119.194 59.0415 120 58.0514 120Z"
        fill="#4A5296"
      />
      <path
        d="M57.209 99.0607C55.8755 98.941 54.4503 99.5797 53.6598 100.662C53.1408 101.368 52.9652 102.263 52.8374 103.129C52.6897 104.131 52.5939 105.141 52.55 106.155C52.4941 107.46 52.5859 108.922 53.5321 109.824C54.2147 110.471 55.1968 110.678 56.127 110.834C61.3729 111.716 66.7266 111.94 72.0243 111.489C72.3157 107.544 76.0845 105.664 76.3759 101.72C76.3839 101.608 72.9146 99.42 72.8427 99.3362C72.7709 99.2524 71.1221 100.211 71.0143 100.199C65.8403 99.6037 63.1934 99.5997 57.209 99.0607Z"
        fill="#DBA9A2"
      />
      <path
        d="M74.4756 112.359C78.4479 116.375 87.4545 116.259 91.83 113.676C97.8025 117.381 105.176 118.751 112.079 117.445C112.494 117.365 112.937 117.266 113.237 116.966C113.58 116.627 113.664 116.112 113.728 115.637C114.562 109.429 114.969 103.161 114.945 96.8929C111.121 95.799 107.172 94.9207 103.2 95.1123C99.2277 95.304 95.1875 96.6693 92.4129 99.5198C88.5484 96.2541 81.2545 94.9447 75.5176 98.4738"
        fill="#4A5296"
      />
      <path
        d="M75.5139 98.4737L71.2102 98.4497C70.7551 99.5276 71.0984 112.111 70.791 112.175L74.4759 112.359"
        fill="#F24949"
      />
      <path
        d="M59.8161 14.3365C65.1139 13.6299 62.7545 21.5625 62.5069 22.3011C61.9999 23.7902 60.2393 24.3691 58.7103 24.7204C52.0471 26.2614 44.0546 28.4772 37.1959 28.058C36.2657 24.0657 33.8224 19.4825 37.8266 17.5143C37.8306 17.5143 47.0129 16.0452 59.8161 14.3365Z"
        fill="#F24949"
      />
      <path
        d="M8.24439 36.5893C16.193 36.0584 44.5582 31.2756 45.0692 30.7606C45.712 30.1138 45.8916 16.4483 43.125 15.6618C40.0349 14.7795 9.41812 16.8874 8.59571 17.9573C7.86512 18.9115 8.12063 36.5973 8.24439 36.5893Z"
        fill="#9DA6D9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.9911 17.1331C47.1166 17.4354 47.1685 17.9453 46.8188 18.3704C46.5938 18.6459 46.3112 18.7649 46.1122 18.8186C45.9257 18.8689 45.7494 18.8779 45.6699 18.882L45.6687 18.882C32.18 19.5989 18.7231 20.9888 5.3737 23.0437C4.82889 23.1276 4.31926 22.754 4.23539 22.2091C4.15153 21.6643 4.52519 21.1547 5.06999 21.0708C18.3117 19.0324 31.6587 17.6452 45.0379 16.9169C45.1662 16.6936 45.3804 16.5198 45.6492 16.4491C46.4154 16.2474 46.8663 16.8329 46.9911 17.1331Z"
        fill="#DBA9A2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.5987 20.0443C50.663 20.5918 50.2713 21.0877 49.7239 21.152C34.952 22.8868 20.22 24.9686 5.54794 27.3973C5.00412 27.4874 4.49029 27.1195 4.40027 26.5757C4.31025 26.0319 4.67813 25.518 5.22195 25.428C19.9251 22.9941 34.6881 20.908 49.491 19.1695C50.0385 19.1052 50.5344 19.4969 50.5987 20.0443Z"
        fill="#DBA9A2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.3442 23.5691C50.4088 24.1166 50.0174 24.6127 49.47 24.6773C34.8517 26.403 20.2969 28.6906 5.84954 31.5243C5.30863 31.6304 4.78412 31.2779 4.67803 30.737C4.57194 30.1961 4.92443 29.6716 5.46534 29.5655C19.962 26.7221 34.5669 24.4266 49.236 22.695C49.7834 22.6303 50.2795 23.0217 50.3442 23.5691Z"
        fill="#DBA9A2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.4166 27.8137C49.4851 28.3606 49.0972 28.8595 48.5503 28.928C34.0276 30.7458 19.5607 33.03 5.18557 35.7767C4.64414 35.8802 4.12137 35.5251 4.01792 34.9837C3.91447 34.4423 4.26952 33.9195 4.81094 33.816C19.2282 31.0613 33.7373 28.7704 48.3023 26.9473C48.8493 26.8788 49.3482 27.2667 49.4166 27.8137Z"
        fill="#DBA9A2"
      />
      <path
        d="M53.0056 37.2439C52.8499 39.1283 53.5525 41.1524 55.0935 42.2503C56.2992 43.1046 57.8402 43.3082 59.3174 43.3801C61.3734 43.4799 63.4374 43.3761 65.4735 43.0687C66.1082 42.9729 66.7749 42.8411 67.25 42.41C67.8928 41.8271 68.0086 40.8689 68.0285 40.0026C68.0605 38.7131 67.9646 37.3517 67.282 36.2578C66.4117 34.8685 64.7788 34.1819 63.2338 33.6349C61.1937 32.9083 58.1716 31.7106 55.9519 32.2696C53.9597 32.7726 53.1533 35.4354 53.0056 37.2439Z"
        fill="#E2D1CA"
      />
      <path
        d="M59.776 82.5006C60.1313 82.5046 60.5106 82.5205 60.798 82.7321C61.0455 82.9158 61.1773 83.2192 61.2811 83.5106C61.8041 84.9718 61.9717 86.5567 61.7601 88.0938C61.6683 88.7565 61.4887 89.4471 61.0176 89.9222C60.6223 90.3175 60.0794 90.5091 59.5524 90.6887C56.1031 91.8465 52.5699 92.7687 48.9928 93.4394C49.0846 93.4234 47.368 85.3431 49.1326 84.0416C51.6397 82.1812 56.8337 82.4726 59.776 82.5006Z"
        fill="#F24949"
      />
      <path
        d="M50.4058 93.3954C50.4019 93.7188 50.3859 94.0621 50.2062 94.3296C49.9946 94.645 49.6074 94.7808 49.2481 94.8965C36.2293 99.1164 23.0228 102.765 9.68457 105.831C8.53879 100.278 7.56068 88.3133 8.24735 82.6841C14.9384 81.3148 48.4456 82.2849 49.2361 82.768C50.1024 83.299 50.0944 86.8681 50.1903 87.8901C50.3659 89.7225 50.4378 91.559 50.4058 93.3954Z"
        fill="#E2D1CA"
      />
      <path
        d="M47.4482 51.9076C48.8296 51.7679 51.7719 50.5542 52.9336 49.7877C54.0954 49.0212 55.6324 48.1828 56.902 47.6159C58.2274 47.0251 58.4749 47.3365 58.8901 47.6239C59.3133 47.9153 58.9979 48.598 58.8103 49.0771C58.2713 50.4584 56.4149 50.9854 55.4767 52.1392"
        fill="#DBA9A2"
      />
      <path
        d="M53.9954 67.6253C52.5382 68.783 52.2628 70.5915 52.1869 72.2523C52.0991 74.1686 52.4105 76.4442 54.4585 77.3784C55.4526 77.8296 56.6423 77.8495 57.7801 77.8575C63.8723 77.8934 69.9685 77.9334 76.0608 77.9693C76.5837 74.5679 79.1388 70.5756 79.2905 67.1462C74.6515 66.5194 67.7808 66.5034 63.1098 66.0643C60.2713 65.7968 56.1752 65.8926 53.9954 67.6253Z"
        fill="#9DA6D9"
      />
      <path
        d="M75.4938 81.3669C87.5784 82.1374 99.675 82.7043 111.78 83.0636C111.752 77.1151 111.724 71.1627 111.692 65.2142C104.278 64.4995 99.7109 64.3558 92.2693 64.0843C88.6364 63.9526 75.8451 63.6013 75.4459 64.767C72.799 72.54 75.2662 81.351 75.4938 81.3669Z"
        fill="#E2D1CA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81.0367 62.035C81.2469 62.1018 81.363 62.3263 81.2962 62.5364C80.842 63.9644 80.7195 65.4876 80.6317 67.0409C80.4382 70.5209 80.413 74.0057 80.3877 77.5014C80.3802 78.5374 80.3727 79.5743 80.3608 80.6124C80.3529 81.4604 80.3371 82.3042 80.3213 83.1496L80.3208 83.1748C80.3167 83.3952 80.1347 83.5706 79.9142 83.5664C79.6938 83.5623 79.5184 83.3803 79.5225 83.1598L79.5229 83.1364C79.5388 82.2897 79.5545 81.4489 79.5624 80.6045L79.5624 80.6037C79.5742 79.5731 79.5816 78.5411 79.5891 77.508C79.6144 74.0086 79.6398 70.4977 79.8344 66.9963C79.9223 65.4437 80.0473 63.8287 80.5353 62.2944C80.6021 62.0843 80.8266 61.9681 81.0367 62.035Z"
        fill="#F24949"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.9915 61.9697C86.2112 61.988 86.3744 62.181 86.3561 62.4008C85.7702 69.4111 85.5032 76.4454 85.555 83.4758C85.5566 83.6962 85.3792 83.8763 85.1587 83.8779C84.9382 83.8795 84.7582 83.7021 84.7566 83.4816C84.7046 76.4272 84.9725 69.3687 85.5604 62.3343C85.5787 62.1145 85.7717 61.9513 85.9915 61.9697Z"
        fill="#F24949"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.6977 61.9768C90.9179 61.9883 91.0871 62.176 91.0756 62.3962C90.7171 69.2924 90.733 76.2126 91.1235 83.1088C91.1359 83.3289 90.9676 83.5175 90.7474 83.53C90.5273 83.5424 90.3387 83.3741 90.3263 83.1539C89.9342 76.2289 89.9182 69.2798 90.2783 62.3548C90.2897 62.1346 90.4775 61.9654 90.6977 61.9768Z"
        fill="#F24949"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M96.1518 62.5083C96.3717 62.5246 96.5368 62.716 96.5205 62.9359C96.0102 69.8448 95.7511 76.7738 95.7391 83.7029C95.7387 83.9234 95.5597 84.1019 95.3392 84.1015C95.1187 84.1011 94.9403 83.922 94.9407 83.7016C94.9527 76.7535 95.2125 69.8053 95.7242 62.877C95.7405 62.6572 95.9319 62.4921 96.1518 62.5083Z"
        fill="#F24949"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.181 62.6391C101.401 62.6347 101.584 62.8099 101.588 63.0303L102.007 84.2254C102.011 84.4458 101.836 84.628 101.616 84.6324C101.395 84.6368 101.213 84.4616 101.209 84.2411L100.79 63.0461C100.785 62.8257 100.96 62.6434 101.181 62.6391Z"
        fill="#F24949"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M107.756 62.639C107.977 62.6415 108.154 62.8222 108.151 63.0427C108.067 70.4781 107.711 77.9135 107.084 85.3249C107.065 85.5446 106.872 85.7076 106.652 85.689C106.433 85.6704 106.27 85.4772 106.288 85.2575C106.914 77.8655 107.269 70.4496 107.353 63.0337C107.355 62.8132 107.536 62.6365 107.756 62.639Z"
        fill="#F24949"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M113.689 67.9765C100.291 67.6095 86.8767 67.6294 73.4784 68.0404C73.258 68.0471 73.0739 67.874 73.0671 67.6536C73.0604 67.4332 73.2336 67.2491 73.4539 67.2423C86.7989 66.833 100.16 66.8111 113.505 67.1727C113.527 67.129 113.556 67.0916 113.586 67.0618C113.707 66.942 113.86 66.9251 113.927 66.9229C114.01 66.9203 114.134 66.9342 114.249 67.011C114.312 67.0529 114.379 67.1194 114.421 67.2172C114.463 67.3173 114.465 67.4191 114.445 67.5067L114.444 67.5092C114.384 67.7643 114.183 67.8807 114.05 67.927C113.918 67.973 113.782 67.9792 113.689 67.9765ZM113.695 67.7619L113.689 67.9765C113.689 67.9765 113.689 67.9765 113.689 67.9765"
        fill="#F24949"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M113.967 73.5928C100.23 72.6155 86.4479 72.2366 72.6741 72.4559C72.4536 72.4595 72.2721 72.2836 72.2685 72.0631C72.265 71.8427 72.4409 71.6611 72.6614 71.6576C86.4583 71.4378 100.263 71.8174 114.024 72.7963C114.244 72.812 114.41 73.003 114.394 73.2229C114.378 73.4428 114.187 73.6084 113.967 73.5928Z"
        fill="#F24949"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.1329 77.1945C72.1375 76.974 72.3199 76.7991 72.5404 76.8037C86.8726 77.1033 101.193 77.8303 115.481 78.9887C115.701 79.0065 115.865 79.1991 115.847 79.4189C115.829 79.6386 115.637 79.8023 115.417 79.7845C101.144 78.6274 86.8401 77.9012 72.5237 77.602C72.3033 77.5974 72.1283 77.4149 72.1329 77.1945Z"
        fill="#F24949"
      />
    </svg>
  );
}
