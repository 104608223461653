import React from 'react';

interface Props {
  className?: string;
}
export default function BusIcon({ className }: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 8C23 7.44772 22.5523 7 22 7H2C1.44772 7 1 7.44772 1 8V16.5C1 16.7761 1.22386 17 1.5 17H2.5C2.77614 17 2.99563 16.7745 3.04113 16.5021C3.2783 15.0822 4.51277 14 6 14C7.48723 14 8.7217 15.0822 8.95887 16.5021C9.00437 16.7745 9.22386 17 9.5 17H14.5C14.7761 17 14.9956 16.7745 15.0411 16.5021C15.2783 15.0822 16.5128 14 18 14C19.4872 14 20.7217 15.0822 20.9589 16.5021C21.0044 16.7745 21.2239 17 21.5 17H22.5C22.7761 17 23 16.7761 23 16.5V8ZM9 9.5C9 9.22386 9.22386 9 9.5 9H14.5C14.7761 9 15 9.22386 15 9.5V11.5C15 11.7761 14.7761 12 14.5 12H9.5C9.22386 12 9 11.7761 9 11.5V9.5ZM8 9.5C8 9.22386 7.77614 9 7.5 9H3.5C3.22386 9 3 9.22386 3 9.5V11.5C3 11.7761 3.22386 12 3.5 12H7.5C7.77614 12 8 11.7761 8 11.5V9.5ZM16 9.5C16 9.22386 16.2239 9 16.5 9H20.5C20.7761 9 21 9.22386 21 9.5V11.5C21 11.7761 20.7761 12 20.5 12H16.5C16.2239 12 16 11.7761 16 11.5V9.5Z"
        fill="#e5e8ff"
      />
      <path
        d="M18 19C19.1046 19 20 18.1046 20 17C20 15.8954 19.1046 15 18 15C16.8954 15 16 15.8954 16 17C16 18.1046 16.8954 19 18 19Z"
        fill="#e5e8ff"
      />
      <path
        d="M8 17C8 18.1046 7.10457 19 6 19C4.89543 19 4 18.1046 4 17C4 15.8954 4.89543 15 6 15C7.10457 15 8 15.8954 8 17Z"
        fill="#e5e8ff"
      />
    </svg>
  );
}
