const mapHiddenArray = (arr: [string, string][] = [], isHidden: boolean) =>
  arr.reduce<Record<string, boolean>>((obj, [key, value]) => ({ ...obj, [value]: isHidden }), {});

export const TABLE_COLUMNS = Object.freeze({
  stopPlaceIcon: 'hideStopPlaceTypeIcon',
  departure: 'hideDepartureColumn',
  destination: 'hideDestinationColumn',
  track: 'hideTrackColumn',
});

export const getHiddenTableColumns = (hiddenTableColumns: string[] = []) => {
  if (!Array.isArray(hiddenTableColumns) || (Array.isArray(hiddenTableColumns) && !hiddenTableColumns.length)) {
    return mapHiddenArray(Object.entries(TABLE_COLUMNS), false);
  }

  const toShow = mapHiddenArray(
    Object.entries(TABLE_COLUMNS).filter(([key, value]) => !hiddenTableColumns.includes(key)),
    false
  );
  const toHide = mapHiddenArray(
    Object.entries(TABLE_COLUMNS).filter(([key, value]) => hiddenTableColumns.includes(key)),
    true
  );
  return {
    ...toShow,
    ...toHide,
  };
};

export const getTrackColumnLabels = (transportMode: DPI.TransportType) => {
  const i18nMap: Record<DPI.TransportType, any> = {
    metro: {
      no: 'Spor',
      en: 'Track',
    },
    bus: {
      no: 'Plf.',
      en: 'Plf.',
    },
    tram: {
      no: 'Plf.',
      en: 'Plf.',
    },
    water: {
      no: 'Brygge',
      en: 'Pier',
    },
    coach: {
      no: 'Plf.',
      en: 'Plf.',
    },
    '': {
      no: 'Plf.',
      en: 'Plf.',
    },
  };

  return [
    i18nMap[transportMode] ? i18nMap[transportMode].no : 'Spor',
    i18nMap[transportMode] ? i18nMap[transportMode].en : 'Track',
  ];
};
