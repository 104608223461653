export const getMilisecondsOffset = () => {
  return (60 - new Date().getSeconds()) * 1000;
};

export const getClock = () => {
  const isDebug = window && window.location && window.location.href.indexOf('&_DEBUG_') > -1;
  const now = isDebug ? new Date(Date.UTC(2019, 1, 1, 10, 0, 0, 0)) : new Date();
  return [now.getHours(), now.getMinutes()].map((d) => {
    return (d < 10 ? '0' + d : d) + '';
  });
};
