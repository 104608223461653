import { useEffect, useRef } from 'react';

/**
 * Just like useEffect, but runs only once when the given condition is true.
 *
 * Remember that both f() and condition() cannot change, as they are excluded
 * from the dependencies array.
 */
export function useEffectOnce(f: () => void, dependencies: React.DependencyList, condition?: () => boolean) {
  const hasRun = useRef(false);
  useEffect(() => {
    if (!hasRun.current && (!condition || condition())) {
      f();
      hasRun.current = true;
    }
    /**
     * Not quite sure what the effect of leaving both f() and condition()
     * out of the dependencies array means. However, after some testing
     * it seems to work as intended.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
}
