import React from 'react';

interface Props {
  className?: string;
}
export default function Home({ className = '' }: Props) {
  const clipId = `clip_${Math.random()}`;
  return (
    <svg className={className} viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath={`url(#${clipId})`}>
        <path
          d="M23.9679 49.744C23.8875 58.6964 23.91 67.6507 24.0372 76.605C24.14 83.8481 24.3196 91.16 26.0534 98.1947C26.2349 98.9331 26.4612 99.7106 27.0298 100.218C27.5479 100.682 28.2642 100.842 28.9469 100.983C45.0622 104.327 60.4144 104.709 76.9936 104.764C76.9936 104.764 98.8997 104.913 111.815 104.889C112.843 86.3815 112.146 46.6433 112.361 45.1962C112.482 44.3703 70.1068 6.51635 67.8455 7.00369C66.6541 7.26038 23.9773 48.7173 23.9679 49.744Z"
          fill="#DBA9A2"
        />
        <path
          d="M91.0384 10.2665C95.187 9.91121 99.3542 9.79775 103.516 9.92609C104.268 12.0856 104.303 33.6827 104.243 42.1739C100.547 41.7851 97.0368 39.8823 94.7063 37.0048C91.9287 33.5786 90.5222 33.3777 90.4455 29.2335C90.2585 19.0032 90.6513 13.1998 91.0384 10.2665Z"
          fill="#DBA9A2"
        />
        <path
          d="M69.2986 77.9555C75.7215 78.4893 82.1744 77.6951 88.5674 76.8804C94.9604 76.0657 101.402 75.2268 107.832 75.6732C108.178 75.6974 108.545 75.7327 108.82 75.9429C109.074 76.1382 109.203 76.447 109.319 76.7446C109.892 78.2177 110.371 79.7281 110.752 81.2608C110.907 81.8858 111.046 82.5442 110.872 83.1636C110.511 84.4471 109.029 85.0069 107.737 85.3622C92.2764 89.6124 76.0937 91.2679 60.0888 90.2039C57.7957 90.0514 55.3585 89.7873 53.5686 88.355C52.2649 87.3134 51.4681 85.7714 50.8883 84.2127C50.0092 81.8504 40.3318 69.7192 45.5577 72.5725C50.5049 75.2751 63.6163 77.483 69.2986 77.9555Z"
          fill="#E2D1CA"
        />
        <path
          d="M89.8207 68.263C89.7272 70.4951 86.0781 74.643 84.1721 77.0815C73.5483 90.6766 69.1229 105.023 70.2433 105.31C70.2433 105.31 111.719 104.86 111.906 104.86C113.543 95.7304 114.011 94.4284 112.701 85.7791C111.732 79.3843 107.666 73.579 106.08 67.3088C104.443 60.8451 103.987 53.6355 100.706 47.6871C99.573 45.6298 97.8148 44.8337 95.4319 44.8672C91.7023 44.9193 90.0377 47.8117 87.1161 49.5006C83.5362 51.5709 79.0323 50.3376 74.9062 50.678C74.519 50.7097 74.1225 50.7599 73.7802 50.9422C73.0078 51.3551 72.7197 52.3725 72.9217 53.2207C73.1162 54.0429 73.0526 57.0004 79.7412 58.8325C81.7762 58.5219 83.4464 57.7183 84.4508 57.2273C85.6815 56.6246 86.83 55.8713 88.0513 55.2482C88.3338 55.105 88.663 54.9655 88.9566 55.0826C89.1867 55.1756 89.3307 55.4044 89.4242 55.6332C89.802 56.5521 89.6206 57.6272 89.1455 58.4996C88.6704 59.3719 87.9316 60.0713 87.1685 60.7093C86.8711 60.9567 86.5662 61.1966 86.2576 61.4291C88.3674 63.4752 89.8656 67.2065 89.8207 68.263Z"
          fill="#4A5296"
        />
        <path
          d="M82.6012 40.9909C82.5133 39.78 82.6087 38.5282 83.1231 37.4289C83.6374 36.3277 84.6194 35.3995 85.8164 35.167C87.6176 34.8173 89.4076 36.1343 90.21 37.7748C91.0124 39.4154 91.0554 41.3089 91.0834 43.1337C90.8889 41.4931 91.0816 39.7335 92.0355 38.3812C92.9894 37.029 94.8355 36.2236 96.3842 36.8188C97.5419 37.2652 98.335 38.3961 98.6342 39.594C98.9335 40.7918 98.8044 42.0567 98.565 43.2694C98.8549 42.1794 99.3992 41.1248 100.273 40.4068C101.148 39.6888 102.386 39.3577 103.454 39.7353C104.926 40.2543 105.699 41.9711 105.573 43.5187C105.448 45.0662 104.619 46.465 103.722 47.7354C102.787 49.0579 101.666 50.373 100.123 50.8882C98.9391 51.2825 97.6523 51.1542 96.4103 51.0221C93.5954 50.7227 88.8203 51.143 86.5515 49.3034C84.206 47.4025 82.8144 43.9074 82.6012 40.9909Z"
          fill="#E2D1CA"
        />
        <path
          d="M99.4616 62.4276C99.744 63.2683 100.167 64.1742 101.04 64.5295C101.577 64.7471 102.189 64.7136 102.772 64.6615C103.786 64.5704 104.796 64.4346 105.797 64.2542C106.231 64.1761 106.704 64.0644 106.956 63.7296C107.104 63.5325 107.153 63.2869 107.181 63.0489C107.403 61.239 106.818 59.4404 106.369 57.6677C105.959 56.0458 105.654 54.3885 104.964 52.8521C104.272 51.3138 103.129 49.8722 101.491 49.171C100.687 48.8269 99.3662 48.6111 98.6012 49.1729C97.9054 49.6825 98.2084 51.0608 98.2009 51.8086C98.1766 54.0983 98.2253 56.3955 98.5694 58.6666C98.7639 59.9407 99.0501 61.2018 99.4616 62.4276Z"
          fill="#E2D1CA"
        />
        <path
          d="M89.8375 105.123C89.8375 105.123 84.1477 105.929 76.6512 108.704C76.4716 108.771 77.8258 103.773 77.6967 103.606C77.5714 103.442 76.9598 99.7143 76.9318 99.4967C76.8177 98.6169 83.8204 97.3986 89.8375 98.8457C92.7366 99.5432 98.4974 103.018 98.5816 105.03L89.8375 105.123Z"
          fill="#F24949"
        />
        <path
          d="M42.5004 66.2484L28.2854 64.6357C18.6716 71.1385 16.2663 91.8818 13.3223 99.6047L21.739 102.395C28.0797 106.355 54.2203 99.0281 65.4445 98.4273C58.8981 90.9239 44.5691 67.6435 42.5004 66.2484Z"
          fill="#9DA6D9"
        />
        <path
          d="M79.597 105.185C79.597 103.321 78.9143 98.509 76.3238 98.0551C68.6309 96.7066 59.4454 97.2051 49.9513 97.5901C40.7864 97.9621 34.1128 98.5536 26.8482 98.0551C29.2703 91.5319 34.6141 78.3385 33.2113 73.8743C31.8085 69.4102 28.3164 67.5185 24.1081 68.9136C17.8984 70.9727 5.20587 102.132 7.2128 108.099C8.64365 112.353 13.1064 113.213 17.6028 113.562C23.5095 114.024 40.8574 112.835 46.7716 112.471C60.8613 111.602 78.8994 110.298 79.0359 109.866C79.7841 107.51 79.597 108.161 79.597 105.185Z"
          fill="#E2D1CA"
        />
        <path
          d="M51.3546 39.4003C47.2753 40.7972 46.8338 39.7258 43.0631 37.8509C40.3903 36.5228 40.0574 36.0596 36.9432 36.8576C34.1226 37.5793 31.2497 38.5912 29.0913 40.6149C26.1211 43.4031 21.1608 45.5124 21.3666 49.5692C21.4432 51.0944 25.5263 54.7997 25.8705 56.2858C26.4409 58.7504 27.3163 62.1841 28.1617 64.5742C28.3543 65.1211 42.4122 66.6501 42.689 66.1869C44.479 63.1848 47.5278 57.454 48.5621 54.117C46.1268 54.0947 43.6299 53.8436 41.5163 52.6625C39.2756 51.4106 37.5885 48.8642 38.0785 46.355C38.1421 46.0314 38.2506 45.6947 38.5012 45.4771C38.8042 45.2148 39.2457 45.1851 39.644 45.2297C40.9795 45.3804 42.1242 46.1988 43.325 46.7977C45.5152 47.8914 47.4436 47.7891 49.6993 47.9119C49.7573 46.6489 49.7012 45.3785 49.5066 44.1099C49.4524 43.7491 53.1614 42.8116 53.9095 41.5096C54.6577 40.2076 55.1571 38.0983 51.3546 39.4003Z"
          fill="#F24949"
        />
        <path
          d="M103.296 78.8503C102.338 79.2837 101.321 79.652 100.552 80.3662C99.7832 81.0805 99.31 82.2282 99.714 83.1935C100.008 83.8966 100.692 84.3579 101.384 84.6872C104.276 86.0655 107.611 85.4758 110.459 84.0101C113.046 82.6764 114.327 78.5769 113.327 76.7094C112.362 74.9107 110.592 73.7407 108.488 73.9732C108.488 73.9751 109.444 76.0621 103.296 78.8503Z"
          fill="#F24949"
        />
        <path
          d="M43.2273 21.6497C41.37 20.0909 38.3662 20.5076 36.5762 22.1407C34.7862 23.7738 34.068 26.3072 34.0661 28.7234C32.134 25.1373 28.2604 22.6653 24.1774 22.4104C23.0645 22.3416 21.9123 22.4327 20.9042 22.9108C18.7139 23.9487 17.7002 26.6588 18.0537 29.0471C18.4072 31.4354 19.8642 33.5205 21.5251 35.282C19.2395 34.8542 16.8305 34.4338 14.6309 35.1871C12.4332 35.9404 10.6114 38.3176 11.3334 40.5162C11.8515 42.0916 13.4394 43.0533 14.9919 43.6559C16.7519 44.3404 18.6223 44.7422 20.5095 44.8427C18.2613 46.3307 15.7943 48.1591 15.4969 50.8283C15.2743 52.8167 16.4377 54.8107 18.1379 55.8802C19.838 56.9497 23.8426 56.667 25.8196 56.295C25.8196 56.295 23.4479 49.3868 25.8196 47.4598C28.0322 45.6611 28.4737 50.2405 29.2499 53.1311C30.3272 52.932 31.2138 52.6679 32.3977 52.1713C31.7749 49.0706 31.2138 46.632 30.5124 43.6913C29.8428 40.8863 32.5623 40.3525 33.7706 39.8335C38.8207 37.6629 39.9822 36.8965 41.4392 35.5554C43.2666 33.8758 43.9792 32.3878 44.7442 30.0999C45.6981 27.2466 45.5391 23.5897 43.2273 21.6497Z"
          fill="#4A5296"
        />
        <path
          d="M89.2771 69.8754C91.617 70.413 94.9987 70.3274 98.068 70.2697C101.016 70.2158 103.913 69.9052 106.5 69.6578C106.676 69.6411 106.881 69.0142 106.78 68.3241C106.676 67.6099 106.251 67.13 105.907 67.0835C101.854 67.5169 98.9545 67.5485 96.6409 67.6192C95.3914 67.6564 89.7971 67.4611 89.4866 67.6229C88.7927 67.9838 88.6692 69.7359 89.2771 69.8754Z"
          fill="#9DA6D9"
        />
        <path
          d="M72.9579 51.4609C72.739 52.5155 72.8288 53.3749 72.9242 53.3451C73.2702 53.2409 74.1194 52.8987 74.5945 52.4225C75.1294 51.8868 75.2772 51.1949 75.3894 50.8099C75.481 50.4899 73.1449 50.5606 72.9579 51.4609Z"
          fill="#9DA6D9"
        />
        <path
          d="M89.3536 58.0639C91.7234 57.5059 93.403 59.1892 93.9678 59.7696C94.7272 60.5489 95.3257 61.107 95.3313 62.528C95.3388 64.2021 93.5937 64.7601 92.7839 63.9547C92.1367 63.3111 91.9572 62.6024 91.2558 61.6296C89.9876 59.8719 87.8292 60.1118 87.8292 60.1118C87.7338 59.3659 88.4165 58.2834 89.3536 58.0639Z"
          fill="#F24949"
        />
        <path
          d="M41.2919 42.492C41.6834 42.492 42.0008 42.1763 42.0008 41.787C42.0008 41.3977 41.6834 41.082 41.2919 41.082C40.9004 41.082 40.583 41.3977 40.583 41.787C40.583 42.1763 40.9004 42.492 41.2919 42.492Z"
          fill="#4A5296"
        />
        <path
          d="M44.5803 95.2984C42.3639 96.6451 40.9293 99.0278 40.1269 101.511C39.8164 102.473 39.5863 103.492 39.7491 104.491C39.9118 105.49 40.544 106.461 41.496 106.769C42.8446 107.208 44.2455 106.219 45.132 105.103C46.5573 103.311 48.7961 98.241 54.7814 97.311C54.7814 97.311 53.9715 94.5823 50.919 94.0949C47.3765 93.5276 46.1196 94.3628 44.5803 95.2984Z"
          fill="#4A5296"
        />
        <path
          d="M93.8782 54.8673C94.2143 54.8331 94.4573 54.5197 94.4211 54.1671C94.3849 53.8146 94.0831 53.5564 93.7471 53.5905C93.4111 53.6247 93.168 53.9381 93.2042 54.2907C93.2404 54.6433 93.5422 54.9014 93.8782 54.8673Z"
          fill="#9DA6D9"
        />
        <path
          d="M45.9306 75.2302C46.3221 75.2302 46.6394 74.9146 46.6394 74.5253C46.6394 74.1359 46.3221 73.8203 45.9306 73.8203C45.5391 73.8203 45.2217 74.1359 45.2217 74.5253C45.2217 74.9146 45.5391 75.2302 45.9306 75.2302Z"
          fill="#4A5296"
        />
        <path
          d="M49.8583 81.4617C50.2498 81.4617 50.5672 81.1461 50.5672 80.7567C50.5672 80.3674 50.2498 80.0518 49.8583 80.0518C49.4668 80.0518 49.1494 80.3674 49.1494 80.7567C49.1494 81.1461 49.4668 81.4617 49.8583 81.4617Z"
          fill="#4A5296"
        />
        <path
          d="M53.9735 87.1346C54.365 87.1346 54.6824 86.819 54.6824 86.4296C54.6824 86.0402 54.365 85.7246 53.9735 85.7246C53.582 85.7246 53.2646 86.0402 53.2646 86.4296C53.2646 86.819 53.582 87.1346 53.9735 87.1346Z"
          fill="#4A5296"
        />
      </g>
      <defs>
        <clipPath id={clipId}>
          <rect width="106.667" height="106.667" fill="white" transform="translate(7 7)" />
        </clipPath>
      </defs>
    </svg>
  );
}
