export const getDestinationAndViaText = (frontText: string) => {
  if (frontText) {
    const indexOfVia = frontText.indexOf(' via ');
    if (indexOfVia > 0) {
      const destination = frontText.slice(0, indexOfVia);
      const viaText = frontText.slice(indexOfVia + 1);
      return {
        destination,
        viaText,
      };
    }
    return {
      destination: frontText,
      viaText: null,
    };
  }
  return {
    destination: null,
    viaText: null,
  };
};
