import { generateShortUID } from './utils/client';

const defaultConfigUrl = 'https://signs-manager.dev.transhub.io/api/';
const configUrl = window && window._env_ ? window._env_.SERVER_BASE_URL || defaultConfigUrl : defaultConfigUrl;

const environment = window && window._env_ ? window._env_.ENVIRONMENT || 'development' : 'development';

export const getMqttHost = () => {
  const host = window && window._env_ ? window._env_.MQTT_HOST || 'localhost' : 'localhost';
  if (host === 'localhost') {
    return `ws://${host}/mqtt`;
  }
  return `wss://${host}/mqtt`;
};

export const mqttOptions = (configId: string) => {
  const clientId = configId || `${generateShortUID()}-${generateShortUID()}`;

  return {
    port: 9883,
    clientId: clientId,
    username: window && window._env_ ? window._env_.MQTT_USERNAME || 'dpi_stopmonitor' : 'dpi_stopmonitor',
    password: window && window._env_ ? window._env_.MQTT_PASSWORD || '' : '',
    clean: false,
    reconnect: true,
    reconnectPeriod: 1000,
    protocolVersion: 5,
  };
};

export const config = {
  configUrl: configUrl,
  environment: environment,
};
