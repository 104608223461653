import React from 'react';
import cx from 'classnames';
import AffectedBySituationIcon from './affectedBySituationIcon';

import './styles.scss';

interface Props {
  off: boolean;
  keyRef: string;
  className?: string;
  hideArrow?: boolean;
  active?: boolean;
}

const AffectedBySituation = ({ off, keyRef, className, hideArrow = false, active = false }: Props) => {
  if (off) {
    return <div className="situationAffected" />;
  }

  return (
    <div className="situationAffected">
      <AffectedBySituationIcon
        className={cx('affectedBySituation', className)}
        key={keyRef}
        hideArrow={hideArrow}
        active={active}
      />
    </div>
  );
};

export default AffectedBySituation;
