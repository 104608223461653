import React from 'react';
import cx from 'classnames';

interface Props {
  className?: string;
  hideArrow?: boolean;
  active?: boolean;
}
const AffectedBySituationIcon = ({ className, hideArrow, active }: Props) => {
  const color = active ? '#FFE589' : '#6D7196';
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 24 24"
      enableBackground="new 0 0 40 40"
      xmlSpace="preserve"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 19.7C12.9389 19.7 13.7 18.9389 13.7 18C13.7 17.0612 12.9389 16.3 12 16.3C11.0611 16.3 10.3 17.0612 10.3 18C10.3 18.9389 11.0611 19.7 12 19.7Z"
        fill={color}
      />
      <path
        d="M10.5 5C10.5 4.72386 10.7239 4.5 11 4.5H13C13.2761 4.5 13.5 4.72386 13.5 5V14C13.5 14.2761 13.2761 14.5 13 14.5H11C10.7239 14.5 10.5 14.2761 10.5 14V5Z"
        fill={color}
      />
      <g className={cx('blink', { '-hidden': hideArrow || !active })}>
        <path
          d="M15.2929 12L9.14645 5.8536C8.95118 5.65834 8.95118 5.34176 9.14645 5.14649L9.93934 4.3536C10.1346 4.15834 10.4512 4.15834 10.6464 4.3536L17.5858 11.2929C17.9763 11.6835 17.9763 12.3166 17.5858 12.7072L10.6464 19.6465C10.4512 19.8418 10.1346 19.8418 9.93934 19.6465L9.14645 18.8536C8.95118 18.6583 8.95118 18.3418 9.14645 18.1465L15.2929 12Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default AffectedBySituationIcon;
