import React, { useEffect, useState } from 'react';
import InfoIcon from '../Icons/InfoIcon';

import './styles.scss';

interface Props {
  config?: DPI.OutOfServiceConfig;
}

function OutOfService({ config }: Props) {
  const [showEnglishText, setShowEnglishText] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowEnglishText(!showEnglishText);
    }, 10 * 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [showEnglishText]);

  const norwegianTitle = config?.title?.no ?? '';
  const englishTitle = config?.title?.en ?? '';

  const activeTitle = showEnglishText ? englishTitle || norwegianTitle : norwegianTitle || englishTitle;

  return (
    <div className="outOfService">
      <div className="contentWrapper">
        <div className="content">
          <InfoIcon className="outOfServiceIcon" />
          <span className="text">{activeTitle}</span>
        </div>
      </div>
    </div>
  );
}

export default OutOfService;
