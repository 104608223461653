import React from 'react';
import { getETAText } from '~/utils/eta';

interface DepartureTimeProps {
  eta: number | null;
  departureTime: string;
  realtime: boolean;
  cancellation: boolean;
}
function DepartureTime({ eta, departureTime, realtime, cancellation }: DepartureTimeProps) {
  const etaText = getETAText(eta, departureTime, realtime);
  const etaHasTimestamp = etaText.indexOf(':') > -1;
  const etaSplit = etaText.split(':');

  if (cancellation) {
    return <span className="eta">Innstilt</span>;
  }

  if (etaHasTimestamp) {
    return (
      <span className={'eta'}>
        <span>{etaSplit[0]}</span>
        <span className="clock-colon">:</span>
        <span>{etaSplit[1]}</span>
      </span>
    );
  }

  return <span className={'eta'}>{etaText}</span>;
}

export default DepartureTime;
