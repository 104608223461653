export const getAngleForPublicCode = (
  publicCodeArrowAngles: DPI.PublicCodeArrowAngle[] = [],
  quayPublicCode: string
) => {
  if (typeof quayPublicCode === 'undefined' || quayPublicCode === null) {
    return null;
  }

  for (let i = 0; i < publicCodeArrowAngles.length; i++) {
    const current = publicCodeArrowAngles[i];
    if (current && String(current.publicCode) === String(quayPublicCode)) {
      return current.angle;
    }
  }

  return null;
};
