import React from 'react';
import { isLegalMediaSrc, isLegalMediaType, MEDIA_SOURCES_MAP } from '~/utils/mediaContent';

interface MultimediaContentProps {
  config: DPI.MultimediaConfig;
}
const MultimediaContent = ({ config }: MultimediaContentProps) => {
  const { type, src, dimensions, padding } = config;
  if (!isLegalMediaType(type) || !isLegalMediaSrc(src)) {
    return null;
  }
  const height = dimensions ? dimensions.height : '100px';
  const width = dimensions ? dimensions.width : '100px';
  const contentPadding = padding || 0;
  const mediaSrc = MEDIA_SOURCES_MAP[src as keyof typeof MEDIA_SOURCES_MAP]; // TODO: Recheck
  return (
    <img
      src={mediaSrc}
      style={{
        height,
        width,
        padding: contentPadding,
      }}
      alt={src}
    />
  );
};

function isMultimediaContentConfig(value?: DPI.MultimediaConfig[]): value is DPI.MultimediaConfig[] {
  return value !== undefined && value !== null && Array.isArray(value) && value.length > 0;
}

interface Props {
  screenConfig: DPI.ScreenConfig;
  placementFilter: string;
}
export const MultiMediaContentWrapper = ({ screenConfig, placementFilter }: Props) => {
  const multimediaContent = screenConfig.multimediaContent;

  if (!isMultimediaContentConfig(multimediaContent)) {
    return null;
  }

  const contentMatchingPlacement = multimediaContent.filter((mc) => mc.placement === placementFilter);
  if (contentMatchingPlacement.length) {
    return (
      <div className="multimediaContentWrapper">
        {multimediaContent.map((item, index) => (
          <MultimediaContent config={item} key={`multimedia-content-${index}`} />
        ))}
      </div>
    );
  }
  return null;
};

export default MultiMediaContentWrapper;
