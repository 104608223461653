import React from 'react';

interface Props {
  className?: string;
}
export default function OpenDoor({ className = '' }: Props) {
  return (
    <svg className={className} viewBox="0 0 241 240" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.02445 214.64C1.04167 215.136 1.08473 215.682 1.44641 216.023C1.78226 216.341 2.28604 216.376 2.74245 216.397C17.2486 217.026 31.7762 217.189 46.291 216.888C46.8981 216.875 47.5397 216.854 48.0564 216.531C49.4557 215.652 48.8745 213.435 47.7808 212.195C44.1252 208.044 37.7699 208.109 32.5771 206.201C23.8149 202.985 16.818 191.549 8.59399 187.462C8.59399 187.462 5.35605 192.393 3.80597 196.789C1.79518 202.502 0.834994 208.587 1.02445 214.64Z"
        fill="#DBA9A2"
      />
      <path
        d="M133.189 211.993C133.775 212.225 173.138 190.322 173.336 188.449C173.534 186.576 170.413 184.13 166.847 184.212C162.154 184.32 157.655 185.969 153.086 187.054C148.518 188.139 143.506 188.621 139.303 186.528C138.589 186.171 137.882 185.741 137.086 185.659C136.229 185.573 135.394 185.913 134.606 186.261C131.329 187.717 122.989 190.593 123.592 195.062C123.592 195.067 132.69 211.795 133.189 211.993Z"
        fill="#DBA9A2"
      />
      <path
        d="M122.486 196.22C129.375 194.067 134.883 190.037 139.619 185.051C140.213 184.427 129.965 162.39 115.201 139.281C104.398 122.372 90.292 104.985 80.6729 94.5223C76.9484 98.0229 50.5412 91.2198 49.7188 93.1703C49.7188 93.1703 50.7952 106.088 85.2413 144.409C96.8884 157.365 121.466 196.539 122.486 196.22Z"
        fill="#4A5296"
      />
      <path
        d="M4.50764 190.907C4.16318 191.742 19.5477 201.004 26.2518 202.533C27.0914 202.722 48.7839 173.628 61.1285 149.141C70.162 131.225 78.1363 110.867 82.2268 97.2565C77.3182 95.84 65.8175 94.6085 60.9089 93.1919C58.8723 92.6063 54.4029 89.6483 52.3017 89.9367C52.3017 89.9367 39.8149 128.852 35.9398 136.654C28.1894 152.263 11.1773 174.812 4.50764 190.907Z"
        fill="#565EA3"
      />
      <path
        d="M158.137 40.9105C160.699 39.369 166.568 34.9212 168.652 38.2022C172.208 43.7954 159.003 50.0387 152.768 52.7514C152.419 52.9021 152.07 53.1389 151.704 53.0442C151.459 52.9796 151.27 52.7987 151.097 52.6136C149.633 51.0334 146.103 48.4757 147.579 46.6157C147.584 46.62 155.924 42.2453 158.137 40.9105Z"
        fill="#F24949"
      />
      <path
        d="M67.7592 76.6833C68.5644 80.3991 72.1941 83.9729 77.8347 82.9611C116.634 75.9987 157.117 54.6377 157.341 53.6173C157.526 52.7949 154.228 46.1769 152.363 42.8916C132.583 49.4708 128.031 52.5279 108.246 59.1071C102.352 61.0662 81.3353 66.1514 75.3029 67.3699C70.6914 68.2999 66.644 71.5293 67.7592 76.6833Z"
        fill="#8D91AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.568 90.8626C48.8891 91.1193 48.9413 91.5876 48.6847 91.9087C47.4017 93.5139 46.0615 95.6016 44.7365 97.6655C44.0844 98.6813 43.4361 99.6912 42.8001 100.635C42.5704 100.976 42.1078 101.066 41.7669 100.836C41.426 100.607 41.3359 100.144 41.5656 99.8033C42.1582 98.9238 42.7827 97.9514 43.4225 96.9553C44.7729 94.8526 46.1914 92.6439 47.5219 90.9793C47.7785 90.6582 48.2469 90.606 48.568 90.8626Z"
        fill="#DBA9A2"
      />
      <path
        d="M45.5361 92.2101C51.5556 97.024 58.9228 98.9702 66.5095 100.344C74.0963 101.717 80.2191 101.769 87.8963 101.063C88.6153 87.5728 88.8608 77.536 83.302 66.5822C83.0049 66.0009 82.0835 64.3432 81.5797 63.9255C81.0803 63.5165 73.4332 60.3216 71.5688 61.2C67.9089 62.9223 62.4277 67.9815 59.5084 71.0688C53.6783 77.2389 48.9119 84.421 45.5361 92.2101Z"
        fill="#9DA6D9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81.3127 62.369C81.6559 62.1428 82.1175 62.2376 82.3437 62.5809C84.0122 65.1122 83.947 68.0012 83.2885 70.583C82.6306 73.1624 81.3536 75.5459 80.4525 77.165C77.2614 82.9063 72.0379 90.8692 67.6503 97.3233C65.4541 100.554 63.463 103.413 62.0335 105.436C61.3189 106.447 60.7437 107.251 60.3528 107.788C60.1581 108.056 60.0063 108.262 59.9052 108.395C59.8572 108.458 59.8114 108.517 59.7756 108.559C59.7661 108.57 59.7525 108.586 59.7367 108.603L59.7358 108.604C59.7273 108.613 59.6856 108.657 59.6266 108.7L59.626 108.701C59.6154 108.709 59.4844 108.81 59.2888 108.838C59.1592 108.845 58.8591 108.771 58.7069 108.672C58.5904 108.55 58.4665 108.3 58.4447 108.19C58.4245 108.023 58.4686 107.891 58.4744 107.873L58.4749 107.872C58.4878 107.832 58.5014 107.801 58.5081 107.786C58.5498 107.694 58.6079 107.615 58.6774 107.55C58.6889 107.535 58.7032 107.516 58.7206 107.493C58.8115 107.374 58.9555 107.179 59.1488 106.913C59.5343 106.383 60.1053 105.585 60.8178 104.577C62.2423 102.561 64.2284 99.7092 66.4192 96.4864C70.8057 90.034 75.9957 82.1193 79.1513 76.4418L79.1516 76.4414C80.0502 74.8268 81.2403 72.5905 81.8461 70.2151C82.4513 67.8421 82.4464 65.4415 81.1008 63.4001C80.8746 63.0569 80.9695 62.5952 81.3127 62.369Z"
        fill="#DBA9A2"
      />
      <path
        d="M159.947 108.71C162.896 109.205 170.28 109.636 169.703 113.481C168.933 118.587 154.323 115.323 148.175 114.225C147.804 114.161 147.391 114.066 147.176 113.756C147.029 113.549 147.003 113.287 146.995 113.033C146.909 110.884 145.884 106.643 148.218 106.191C148.213 106.191 157.398 108.284 159.947 108.71Z"
        fill="#F24949"
      />
      <path
        d="M88.952 40.8069C90.2567 40.8543 91.5183 40.4538 92.2503 40.3548C92.9822 40.2558 93.8649 40.6777 93.9123 41.414C93.9338 41.7843 93.7358 42.1374 93.4774 42.4044C92.9091 42.977 89.6453 44.1869 88.9219 44.5443C88.9219 44.5443 89.1157 46.1547 89.2879 48.3937C87.5053 48.7941 85.6065 48.6434 83.9143 47.9631C82.9154 47.5627 81.9638 46.9771 80.896 46.8608C79.8281 46.7446 78.5622 47.3129 78.3986 48.3765C78.2953 49.0568 78.6656 49.7199 79.1005 50.2581C81.1156 52.7425 84.4827 53.5778 87.6086 54.2711C86.5451 56.1225 85.4773 57.9697 84.4138 59.8212C83.4364 61.5177 81.5892 63.9246 81.5892 63.9246C80.2243 63.9461 73.4211 62.8352 72.405 62.0128C71.9572 61.6511 71.7204 61.0914 71.5051 60.5532C71.0228 59.3476 70.5363 58.1419 70.054 56.9363C68.9518 54.1936 67.8279 51.2613 68.3791 48.3592C68.7666 46.314 69.9679 44.497 71.4276 43.0115C73.5546 40.8543 78.2694 37.5948 81.5504 38.1675C84.6592 38.71 84.5602 40.6476 88.952 40.8069Z"
        fill="#F24949"
      />
      <path
        d="M64.177 71.7446C62.0327 74.9782 62.2308 80.0676 67.458 83.2754C79.1955 90.4704 91.4928 97.7643 103.751 102.858C129.018 113.351 151.743 117.976 152.376 117.588C153.134 117.127 155.477 110.079 156.463 106.419C135.868 97.3596 130.804 97.7084 110.21 88.649C104.079 85.9536 82.8038 73.6434 77.1158 70.3151C72.7713 67.7704 67.148 67.2666 64.177 71.7446Z"
        fill="#8D91AF"
      />
      <path
        d="M190.119 22C190.119 22 214.628 26.4263 240.501 33.5179C240.501 33.66 240.893 34.0346 240.893 34.1767V208.698L190.632 190.687L190.119 22Z"
        fill="#565EA3"
      />
      <path
        d="M205.69 58.1854C205.259 58.2198 204.846 58.4007 204.506 58.6719C202.792 60.0498 203.317 62.6677 203.774 64.8163C205.767 74.2588 212.764 79.7831 212.764 79.7831C213.229 79.4042 214.508 78.5 214.853 78.0091C215.516 77.0705 217.531 74.3062 217.285 73.1824C217.285 73.1824 213.789 69.4493 212.226 66.4352C211.115 64.2953 209.569 57.8581 205.69 58.1854Z"
        fill="#DBA9A2"
      />
      <path
        d="M36.1597 113.408C36.1468 114.859 36.3578 116.331 36.9951 117.636C38.4289 120.56 41.9036 122.131 45.1502 121.894C48.3967 121.662 51.3763 119.879 53.6842 117.58C55.9921 115.281 60.117 108.434 59.8372 107.832C59.6865 107.509 43.6518 98.4838 42.6055 98.6087C41.9639 98.6862 39.0102 103.569 38.2696 105.136C37.051 107.733 36.1813 110.523 36.1597 113.408Z"
        fill="#DBA9A2"
      />
      <path
        d="M210.895 80.7513C221.875 92.0883 233.789 100.127 240.889 104.17L241.001 85.1991C235.726 81.6469 230.486 78.6931 225.1 75.4121C223.192 74.2495 217.858 71.2743 216.927 71.1322C216.079 71.0073 210.012 79.8384 210.895 80.7513Z"
        fill="#F24949"
      />
      <path
        d="M83.5655 43.7785C83.8509 43.7785 84.0822 43.5472 84.0822 43.2618C84.0822 42.9764 83.8509 42.7451 83.5655 42.7451C83.2802 42.7451 83.0488 42.9764 83.0488 43.2618C83.0488 43.5472 83.2802 43.7785 83.5655 43.7785Z"
        fill="#4A5296"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M171.518 186.255C171.747 186.596 171.657 187.059 171.316 187.288C158.754 195.74 145.541 203.217 131.826 209.631C131.454 209.806 131.011 209.645 130.837 209.273C130.663 208.9 130.823 208.457 131.196 208.283C144.84 201.901 157.987 194.462 170.485 186.053C170.826 185.824 171.288 185.914 171.518 186.255Z"
        fill="#4A5296"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.319299 213.014C0.339765 212.604 0.689171 212.288 1.09972 212.308C16.7108 213.086 32.3607 213.134 47.9763 212.459C48.387 212.441 48.7343 212.759 48.7521 213.17C48.7698 213.581 48.4513 213.928 48.0406 213.946C32.3791 214.623 16.683 214.575 1.02561 213.795C0.61506 213.774 0.298834 213.425 0.319299 213.014Z"
        fill="#4A5296"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.4465 203.037C27.7739 203.285 27.8379 203.752 27.5894 204.08C27.1766 204.623 26.7648 205.169 26.3525 205.714L26.3523 205.715C25.9401 206.26 25.5274 206.807 25.1136 207.352C24.8651 207.679 24.3982 207.743 24.0708 207.495C23.7433 207.246 23.6793 206.779 23.9278 206.452C24.3406 205.908 24.7524 205.363 25.1647 204.817L25.165 204.817C25.5771 204.271 25.9898 203.725 26.4036 203.18C26.6521 202.852 27.119 202.788 27.4465 203.037Z"
        fill="#4A5296"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.4448 205.087C31.8148 205.266 31.9696 205.711 31.7905 206.081C31.5624 206.553 31.3344 207.025 31.1061 207.497C30.878 207.97 30.6496 208.443 30.4212 208.915C30.2421 209.285 29.797 209.439 29.427 209.26C29.057 209.081 28.9023 208.636 29.0814 208.266C29.3094 207.795 29.5375 207.323 29.7657 206.85L29.7658 206.85C29.994 206.377 30.2223 205.905 30.4506 205.433C30.6297 205.063 31.0748 204.908 31.4448 205.087Z"
        fill="#4A5296"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.0364 206.471C36.4287 206.594 36.6473 207.011 36.5246 207.404C36.3822 207.859 36.2389 208.314 36.0958 208.768L36.0957 208.768C35.9525 209.222 35.8095 209.676 35.6677 210.129C35.545 210.522 35.1275 210.74 34.7352 210.617C34.3429 210.495 34.1243 210.077 34.247 209.685C34.3894 209.23 34.5327 208.775 34.6758 208.321L34.6759 208.321C34.8191 207.866 34.9621 207.413 35.1039 206.959C35.2266 206.567 35.6441 206.348 36.0364 206.471Z"
        fill="#4A5296"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M140.989 186.872C141.364 187.04 141.533 187.48 141.365 187.855C141.245 188.124 141.125 188.393 141.005 188.661C140.617 189.528 140.23 190.392 139.846 191.259C139.679 191.635 139.24 191.804 138.864 191.638C138.488 191.471 138.318 191.032 138.485 190.656C138.87 189.786 139.258 188.919 139.647 188.051C139.766 187.783 139.886 187.516 140.006 187.248C140.174 186.873 140.614 186.704 140.989 186.872Z"
        fill="#4A5296"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M145.05 187.082C145.45 187.176 145.698 187.577 145.603 187.977C145.314 189.203 145.148 190.458 145.106 191.719C145.093 192.129 144.749 192.451 144.338 192.438C143.927 192.424 143.605 192.08 143.619 191.67C143.663 190.312 143.842 188.958 144.155 187.635C144.249 187.235 144.65 186.987 145.05 187.082Z"
        fill="#4A5296"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M149.492 187.244C149.894 187.156 150.29 187.411 150.378 187.812C150.477 188.268 150.575 188.722 150.673 189.176L150.673 189.177C150.771 189.631 150.869 190.085 150.968 190.538C151.055 190.94 150.801 191.336 150.399 191.424C149.997 191.511 149.601 191.257 149.513 190.855C149.414 190.4 149.316 189.945 149.218 189.491L149.218 189.491C149.12 189.036 149.022 188.583 148.923 188.13C148.836 187.728 149.091 187.331 149.492 187.244Z"
        fill="#4A5296"
      />
      <path
        d="M83.7761 32.5526C83.0915 29.1597 79.3498 26.9767 75.6985 26.0035C73.8513 25.5084 71.918 25.2156 70.0019 25.3577C68.0084 25.5041 66.088 26.1198 64.2968 26.9293C59.259 29.2113 55.104 33.1899 53.2094 37.9951C51.3149 42.8004 51.7971 48.4022 54.8026 52.7208C57.808 57.0395 65.8684 60.7597 71.496 60.553C71.7027 60.5444 72.3615 54.5766 72.9988 49.8188C73.1495 48.7036 70.0967 48.0276 70.8416 45.048C71.2205 43.5237 73.903 44.1351 74.0709 44.0404C76.4735 42.6669 78.9881 41.3881 80.9558 39.5323C82.9192 37.6765 84.2885 35.0844 83.7761 32.5526Z"
        fill="#4A5296"
      />
    </svg>
  );
}
