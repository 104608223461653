import React from 'react';

interface Props {
  className?: string;
}
export default function OtherPlaces({ className = '' }: Props) {
  return (
    <svg className={className} viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.90029 108.891C5.42979 111.63 11.2011 116.133 14.2974 118.207C15.5689 119.059 31.5042 118.514 32.138 118.27C34.0011 117.554 32.4252 110.037 25.9147 109.366C23.9291 109.161 19.8504 110.1 18.3971 105.659L22.3723 98.8998C22.1463 98.5322 19.0577 95.8859 17.3937 94.8155C15.6569 93.6991 12.4477 94.1702 11.362 95.6772C10.7607 96.5082 2.60731 108.576 2.90029 108.891Z"
        fill="#4A5296"
      />
      <path
        d="M96.3781 116.453C103.942 110.9 110.138 106.375 116.618 100.686C115.764 98.2179 112.848 97.0268 110.238 97.1245C107.628 97.2202 105.165 98.2734 102.634 98.911C100.102 99.5487 97.2187 99.7172 95.0741 98.2274C94.1397 97.5783 93.2301 96.5922 92.1023 96.7473C91.4512 96.8373 85.0978 99.9278 84.0293 101.421C84.0274 101.421 93.8046 114.218 96.3781 116.453Z"
        fill="#4A5296"
      />
      <path
        d="M10.4439 94.9245C10.1337 95.4396 9.81391 95.993 9.83497 96.5942C9.86178 97.3046 10.3577 97.9002 10.846 98.4133C13.3027 100.995 17.4771 103.501 20.74 104.931C21.1057 105.092 33.4277 89.5055 37.5906 82.974C42.3221 75.5502 46.7818 69.5701 51.6321 62.805C63.4792 77.2888 73.1511 91.6635 85.475 105.743C85.8082 106.124 95.9281 98.6776 95.9913 96.1883C96.0008 95.8417 79.5237 73.3634 79.3188 73.0838C66.9795 56.3443 63.709 51.6127 62.7362 51.6989C58.1043 52.1106 48.0264 53.5831 43.3964 53.9929C41.5256 54.1595 38.414 53.8359 36.7117 54.5961C35.1434 55.295 34.2013 57.5717 33.2573 58.9868C28.1906 66.5715 23.235 74.2347 18.3981 81.9668C15.7461 86.2101 12.6823 90.4476 10.4439 94.9245Z"
        fill="#F24949"
      />
      <path
        d="M37.0166 52.5072C36.9707 52.8462 36.9324 53.1947 37.0166 53.524C37.1717 54.1368 37.706 54.5753 38.246 54.9046C39.527 55.6878 40.9918 56.1148 42.4548 56.4518C49.7695 58.1311 57.9765 56.2795 65.0403 53.7423C63.7995 46.9484 61.6568 41.6329 59.4547 35.086C59.1828 34.278 58.8956 33.4546 58.3709 32.7805C57.6682 31.8767 56.594 31.3234 55.4891 31.0304C50.0069 29.5789 47.0236 33.8529 44.1839 37.6366C40.8712 42.0541 37.7864 46.9331 37.0166 52.5072Z"
        fill="#DBA9A2"
      />
      <path
        d="M17.9046 9.38098C15.6125 8.67823 12.8667 10.6754 12.2099 13.0862C11.2008 16.7837 14.0845 19.1869 17.2019 20.7781C22.0177 23.2348 29.0394 26.9362 29.2347 26.5647C29.407 26.2354 25.1599 17.0537 23.6644 14.911C22.3183 12.9809 20.1967 10.0856 17.9046 9.38098ZM19.1837 13.2509C19.492 13.7583 20.0971 15.9106 18.2416 16.6976C16.0644 17.6186 14.8696 14.8747 15.2909 13.6051C15.9649 11.5677 18.3661 11.9009 19.1837 13.2509Z"
        fill="#DBA9A2"
      />
      <path
        d="M51.1839 21.9917C51.9441 19.7169 50.012 16.9251 47.6185 16.207C43.9458 15.1079 41.4738 17.9323 39.8079 21.0094C37.2343 25.764 33.3625 32.6938 33.7282 32.8967C34.0538 33.0767 43.3369 29.0537 45.516 27.6137C47.4768 26.3154 50.4237 24.2666 51.1839 21.9917ZM47.2853 23.1751C46.7702 23.47 44.6045 24.0234 43.8635 22.1488C42.9961 19.9505 45.7668 18.8227 47.0268 19.2746C49.045 19.9984 48.6525 22.3919 47.2853 23.1751Z"
        fill="#DBA9A2"
      />
      <path
        d="M44.6778 2.94889C43.4925 0.865547 40.1243 0.436622 37.9912 1.73871C34.7207 3.73781 35.1706 7.46408 36.3483 10.7595C38.1674 15.8511 40.739 23.3611 41.1354 23.2232C41.4858 23.1026 44.6931 13.5054 45.0761 10.9223C45.4227 8.59575 45.8631 5.03224 44.6778 2.94889ZM42.9545 6.6407C42.83 7.2209 41.7941 9.20276 39.9041 8.50193C37.6887 7.68046 38.6997 4.86182 39.8773 4.22801C41.7673 3.20931 43.2857 5.09926 42.9545 6.6407Z"
        fill="#F24949"
      />
      <path
        d="M42.2854 13.1973C42.5133 10.8114 40.0029 8.52313 37.5098 8.3642C33.684 8.12102 31.9108 11.4279 30.9802 14.8019C29.5422 20.0141 27.3305 27.6371 27.7326 27.7539C28.0888 27.8573 36.2288 21.8466 38.0287 19.9528C39.6487 18.2448 42.0576 15.5851 42.2854 13.1973ZM38.7525 15.2289C38.316 15.6329 36.3303 16.6593 35.1871 14.9991C33.8467 13.0517 36.2939 11.3284 37.6228 11.4854C39.754 11.7362 39.911 14.1566 38.7525 15.2289Z"
        fill="#DBA9A2"
      />
      <path
        d="M27.1919 4.59552C25.7118 2.7094 22.3167 2.78408 20.4 4.38872C17.4607 6.84929 18.4583 10.4683 20.1109 13.5551C22.6633 18.3211 26.3188 25.3677 26.6902 25.1743C27.0196 25.002 28.7717 15.0371 28.7678 12.4253C28.7621 10.07 28.6721 6.48164 27.1919 4.59552ZM26.0335 8.50179C25.9971 9.09539 25.2656 11.2075 23.2933 10.7939C20.9802 10.3094 21.5642 7.37204 22.6346 6.56972C24.3503 5.28486 26.133 6.92779 26.0335 8.50179Z"
        fill="#F24949"
      />
      <path
        d="M37.9757 22.9264C36.7904 20.8431 33.4222 20.4142 31.289 21.7163C28.0185 23.7153 28.4685 27.4416 29.6461 30.7371C31.4652 35.8286 34.0368 43.3386 34.4332 43.2008C34.7836 43.0801 37.991 33.4829 38.374 30.8998C38.7205 28.5733 39.161 25.0117 37.9757 22.9264ZM36.2523 26.6202C36.1279 27.2004 35.0919 29.1822 33.202 28.4814C30.9865 27.6599 31.9975 24.8413 33.1752 24.2075C35.0651 23.1868 36.5836 25.0768 36.2523 26.6202Z"
        fill="#F24949"
      />
      <path
        d="M48.3164 29.3276C47.2441 36.8166 41.9017 52.8802 36.6359 59.1991C29.5088 67.7508 15.9556 63.6033 12.8823 57.0488C12.0149 55.1971 11.7774 53.1119 11.72 51.0687C11.5764 45.9389 12.5644 40.8454 13.742 35.8496C14.5884 32.2554 16.6813 26.2026 18.1577 22.8172C18.757 21.4462 26.7093 22.1929 34.6253 23.8703C41.4728 25.3199 48.3853 28.8547 48.3164 29.3276Z"
        fill="#9DA6D9"
      />
      <path
        d="M67.799 16.0673C68.5649 16.1152 70.8398 15.8529 70.7785 16.8045C70.7536 17.1932 70.0451 17.5092 69.666 17.5992C68.6856 17.8347 68.2298 17.9592 66.9048 18.1219C66.9258 19.0813 66.8626 19.7879 66.7209 20.7357C66.6845 20.9789 66.5773 21.5246 66.4509 21.6567C66.3016 21.8118 64.854 21.6223 64.111 21.335C60.693 20.0119 60.042 18.4149 59.3967 19.5236C58.8931 20.617 60.0822 22.0014 61.1009 22.7673C62.1196 23.5333 63.3546 23.9411 64.5648 24.3337C63.1153 26.4994 61.6657 28.667 60.2143 30.8327C60.019 31.1256 59.8103 31.4282 59.4962 31.589C59.1784 31.7518 58.8031 31.7403 58.4488 31.7154C56.6891 31.5948 54.9427 31.2539 53.2673 30.7024C52.8211 30.555 52.3232 30.3367 52.1873 29.8886C52.1318 29.7048 52.1471 29.5076 52.1643 29.3161C52.5052 25.3371 52.8441 21.358 53.1849 17.3771C53.2768 16.3067 53.3726 15.219 53.7747 14.2233C54.4315 12.6015 55.927 11.3472 57.6369 10.9834C59.5556 10.5756 61.6562 11.374 63.1249 12.6149C64.5744 13.8308 65.5165 15.9237 67.799 16.0673Z"
        fill="#9DA6D9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.8137 62.4538C33.9081 62.5385 33.916 62.6838 33.8312 62.7783C30.4542 66.5413 27.7694 70.9557 25.099 75.3463C24.1667 76.8791 23.2362 78.4091 22.2786 79.9074L22.0975 79.7917L22.2786 79.9074C22.0122 80.3243 21.754 80.7285 21.5031 81.1212C17.3373 87.642 15.1938 90.9973 10.8752 96.7247C10.7988 96.8261 10.6547 96.8463 10.5534 96.7699C10.452 96.6935 10.4318 96.5494 10.5082 96.4481C14.816 90.7351 16.9509 87.3931 21.1174 80.8712C21.3678 80.4794 21.6255 80.076 21.8914 79.6599L22.085 79.7837L21.8914 79.6599C22.8365 78.1812 23.7594 76.6636 24.6872 75.1381C27.3639 70.737 30.0812 66.2689 33.4892 62.4713C33.574 62.3769 33.7192 62.369 33.8137 62.4538Z"
        fill="#DBA9A2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.9799 62.8357C37.0897 62.8994 37.1271 63.04 37.0634 63.1498C29.6857 75.8739 21.9656 87.154 13.2192 98.973C13.1437 99.075 12.9998 99.0965 12.8978 99.021C12.7958 98.9455 12.7743 98.8016 12.8498 98.6996C21.5896 86.8895 29.2993 75.6242 36.6659 62.9192C36.7295 62.8095 36.8701 62.7721 36.9799 62.8357Z"
        fill="#DBA9A2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.8968 62.651C42.9999 62.7249 43.0236 62.8685 42.9496 62.9716C38.6521 68.9653 34.6493 75.1499 30.6438 81.3389C30.3955 81.7225 30.1471 82.1062 29.8987 82.4899C25.6444 89.0605 21.3666 95.6224 16.7146 101.941C16.6394 102.043 16.4956 102.065 16.3934 101.99C16.2912 101.914 16.2693 101.771 16.3446 101.668C20.9873 95.3625 25.2582 88.8115 29.513 82.2401C29.7618 81.8558 30.0106 81.4713 30.2595 81.0869C34.2636 74.9001 38.2719 68.7068 42.5762 62.7038C42.6501 62.6007 42.7937 62.577 42.8968 62.651Z"
        fill="#DBA9A2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.1361 66.1934C46.239 66.2676 46.2622 66.4112 46.1879 66.5142C42.413 71.7459 38.827 76.9257 35.2437 82.1016C30.2809 89.2702 25.3231 96.4314 19.8757 103.713C19.7997 103.815 19.6557 103.835 19.5541 103.759C19.4524 103.683 19.4317 103.539 19.5077 103.438C24.9546 96.1568 29.8998 89.0134 34.8557 81.8546C38.4387 76.6788 42.0274 71.495 45.8153 66.2453C45.8895 66.1423 46.0331 66.1191 46.1361 66.1934Z"
        fill="#DBA9A2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.5936 60.8272C52.6991 60.7568 52.8418 60.7853 52.9122 60.8909C54.8173 63.7485 58.6518 68.2415 62.5184 72.7316C62.7882 73.0449 63.0582 73.3582 63.3276 73.6709C66.9109 77.8294 70.4042 81.8834 72.254 84.4896L72.0755 84.6163L72.254 84.4896C74.7724 88.0381 77.2341 91.2776 79.6544 94.4579L79.8028 94.6529C82.1714 97.7651 84.5008 100.826 86.801 104.065C86.8745 104.168 86.8502 104.312 86.7467 104.385C86.6433 104.459 86.4998 104.435 86.4263 104.331C84.1306 101.098 81.8052 98.0427 79.4354 94.9288L79.2887 94.7362C76.8688 91.5563 74.4027 88.3112 71.8793 84.7556L72.0666 84.6226L71.8793 84.7556C70.0427 82.1681 66.5631 78.1298 62.9715 73.9616C62.7048 73.6521 62.4375 73.3419 62.1702 73.0315C58.3119 68.551 54.4544 64.0327 52.5298 61.1458C52.4594 61.0402 52.488 60.8976 52.5936 60.8272Z"
        fill="#DBA9A2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.649 57.481C55.7519 57.4067 55.8955 57.4299 55.9698 57.5328C62.6345 66.7608 73.5028 80.753 82.4444 92.2646C85.1879 95.7968 87.7501 99.0954 89.9538 101.951C90.0314 102.052 90.0128 102.196 89.9123 102.273C89.8118 102.351 89.6675 102.332 89.59 102.232C87.3887 99.3793 84.8284 96.0831 82.0862 92.5527C73.1439 81.0401 62.2672 67.0372 55.5972 57.8018C55.5229 57.699 55.5461 57.5553 55.649 57.481Z"
        fill="#DBA9A2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.0123 55.3667C59.1195 55.2988 59.2615 55.3308 59.3293 55.4381C64.8257 64.1298 73.4067 75.0068 81.3401 85.0629C85.4939 90.3282 89.4702 95.3684 92.7334 99.7522C92.8091 99.854 92.788 99.998 92.6862 100.074C92.5844 100.15 92.4405 100.128 92.3647 100.027C89.1188 95.666 85.1532 90.639 81.005 85.3807C73.0615 75.3114 64.4489 64.3939 58.9409 55.6837C58.873 55.5764 58.905 55.4345 59.0123 55.3667Z"
        fill="#DBA9A2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.8849 54.026C61.9924 53.9585 62.1342 53.9908 62.2017 54.0983C66.2912 60.6079 72.2186 68.1134 78.2803 75.6397C79.0534 76.5995 79.8287 77.5596 80.6025 78.5181C85.8922 85.0695 91.1165 91.54 95.1306 97.2683C95.2034 97.3722 95.1782 97.5155 95.0743 97.5884C94.9703 97.6612 94.827 97.636 94.7542 97.532C90.7499 91.8176 85.5368 85.361 80.2457 78.8077C79.4717 77.8492 78.6961 76.8886 77.9224 75.9279C71.8633 68.4049 65.9187 60.8789 61.8126 54.3428C61.7451 54.2353 61.7775 54.0935 61.8849 54.026Z"
        fill="#DBA9A2"
      />
      <path
        d="M62.7484 17.2526C62.9546 17.2526 63.1218 17.0855 63.1218 16.8793C63.1218 16.673 62.9546 16.5059 62.7484 16.5059C62.5422 16.5059 62.375 16.673 62.375 16.8793C62.375 17.0855 62.5422 17.2526 62.7484 17.2526Z"
        fill="#4A5296"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.4309 38.3331C56.802 34.5283 50.4367 30.6757 47.5177 29.3119L47.7122 28.8955C50.666 30.2756 57.0564 34.1456 62.6883 37.9523C65.5058 39.8568 68.1404 41.7501 70.0657 43.3132C71.0276 44.0941 71.8181 44.7971 72.3667 45.381C72.6406 45.6725 72.8598 45.9401 73.0101 46.1774C73.1553 46.4065 73.2588 46.6417 73.2487 46.8592L73.2487 46.8593C73.2336 47.1795 73.0409 47.4538 72.764 47.6849C72.4856 47.9174 72.0942 48.1303 71.6164 48.3282C70.6591 48.7246 69.3023 49.0814 67.6643 49.4032C64.3848 50.0474 59.9302 50.56 55.1742 50.9562C45.6604 51.7488 34.91 52.078 29.8711 52.0569L29.873 51.5973C34.8985 51.6183 45.6355 51.2897 55.136 50.4983C59.8873 50.1025 64.3216 49.5915 67.5757 48.9522C69.2046 48.6323 70.5258 48.2824 71.4406 47.9036C71.8988 47.7138 72.242 47.522 72.4695 47.3321C72.6986 47.1409 72.7831 46.9754 72.7896 46.8377C72.7928 46.7677 72.7555 46.6342 72.6219 46.4234C72.4935 46.2206 72.296 45.9769 72.0318 45.6957C71.504 45.134 70.7319 44.446 69.776 43.6699C67.8657 42.1191 65.2437 40.2343 62.4309 38.3331Z"
        fill="#9DA6D9"
      />
      <path
        d="M72.7505 43.1968C73.9721 43.059 75.3604 42.99 76.2661 43.823C77.0531 44.5487 77.2005 45.7206 77.2982 46.7872C77.3307 47.1299 77.3595 47.488 77.2369 47.8097C76.9171 48.658 75.8084 48.8111 74.9027 48.8724C71.255 49.1194 67.6589 50.096 64.3884 51.7275C63.946 51.9477 62.4812 48.4109 62.3739 48.0376C61.9412 46.5153 63.1475 46.0136 64.4018 45.3395C66.9581 43.9704 69.9031 43.5185 72.7505 43.1968Z"
        fill="#4A5296"
      />
      <path
        d="M38.761 43.7385C35.8754 47.214 33.815 51.3385 32.5531 55.6756C31.9902 57.6096 31.5823 59.67 32.061 61.627C32.8863 65.0086 36.3253 67.3274 39.795 67.5994C43.2647 67.8713 46.6808 66.4256 49.509 64.3958C52.3372 62.3661 65.473 53.7819 69.1246 51.2524C68.487 49.5922 65.362 44.4202 64.4658 44.8051C57.1818 47.932 48.8465 52.7957 41.9339 56.6771C44.45 52.6942 46.5027 48.9163 49.0188 44.9334C50.0356 43.323 50.7192 40.6997 49.7733 39.0472C47.2361 34.6086 40.6146 41.5097 38.761 43.7385Z"
        fill="#E2D1CA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M110.338 105.787C112.341 104.144 114.274 102.559 116.583 100.762L116.866 101.124C114.559 102.919 112.635 104.497 110.637 106.136C109.42 107.134 108.176 108.154 106.8 109.26C105.018 110.694 102.801 112.229 100.611 113.747C99.1932 114.729 97.7862 115.704 96.5154 116.638L96.2432 116.268C97.5383 115.316 98.9551 114.335 100.376 113.35C102.552 111.843 104.738 110.329 106.512 108.902L106.512 108.902C107.88 107.802 109.122 106.784 110.338 105.787Z"
        fill="#9DA6D9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.79601 115.228C7.19154 113.26 4.24849 110.902 2.61523 109.272L2.93991 108.947C4.54848 110.552 7.46867 112.893 10.073 114.861C11.3735 115.843 12.5916 116.73 13.5229 117.383C13.9887 117.709 14.3813 117.976 14.676 118.166C14.8235 118.261 14.9447 118.336 15.0372 118.39C15.0835 118.416 15.1209 118.437 15.1498 118.451C15.1811 118.467 15.1939 118.471 15.1938 118.471L15.1939 118.471C15.2153 118.477 15.2797 118.488 15.4038 118.5C15.5211 118.511 15.6755 118.52 15.8643 118.529C16.2416 118.547 16.7484 118.56 17.3548 118.57C18.5671 118.589 20.1707 118.594 21.9183 118.59C24.8804 118.584 28.2526 118.554 30.8281 118.53C31.2915 118.526 31.7291 118.522 32.1339 118.519L32.1379 118.978C31.7334 118.982 31.296 118.986 30.8328 118.99C28.2573 119.013 24.8832 119.043 21.9193 119.05C20.1709 119.053 18.564 119.049 17.3475 119.029C16.7394 119.02 16.2272 119.006 15.8429 118.988C15.6509 118.979 15.4888 118.969 15.3617 118.958C15.2415 118.946 15.1337 118.933 15.0627 118.911L15.1283 118.691L15.0628 118.911C15.0627 118.911 15.0627 118.911 15.0627 118.911C14.9937 118.891 14.9014 118.842 14.8075 118.788C14.7055 118.729 14.5772 118.649 14.4267 118.552C14.1252 118.357 13.7273 118.087 13.2592 117.759C12.3227 117.103 11.1 116.213 9.79601 115.228Z"
        fill="#9DA6D9"
      />
      <path
        d="M49.1566 30.2548C49.1164 30.4789 49.0877 30.7163 49.17 30.9289C49.2734 31.195 49.53 31.3654 49.7751 31.5148C52.1878 32.9796 54.8552 34.0232 57.6202 34.5881C58.3019 34.7279 59.0544 34.8256 59.6519 34.4694C59.9812 34.2741 60.2225 33.96 60.4235 33.6364C61.0401 32.6465 62.9128 29.5923 60.8352 29.2074C58.1506 28.7096 55.0677 27.3404 52.4329 26.2624C50.0834 25.3031 49.5358 28.1217 49.1566 30.2548Z"
        fill="#4A5296"
      />
      <path
        d="M44.9094 29.9197C42.3837 29.7225 39.9442 28.945 37.4606 28.4472C34.9771 27.9493 32.3289 27.7483 29.9698 28.6712C29.8185 28.7306 29.6596 28.7995 29.5657 28.9336C29.4834 29.0484 29.4585 29.194 29.4394 29.3357C29.2 31.0629 29.223 32.8264 29.5064 34.546C29.5274 34.6762 29.56 34.8179 29.6672 34.8925C29.7611 34.9576 29.8855 34.95 30.0004 34.9404C30.4715 34.8983 30.9444 34.8562 31.4174 34.8313C36.2122 34.5766 40.9284 36.0951 45.727 36.2904C47.4944 36.3631 49.3039 36.2482 50.9564 35.6144C52.6089 34.9806 54.1006 33.7762 54.7765 32.1409C54.8474 31.9686 54.9106 31.7809 54.8665 31.599C54.8301 31.4458 54.7229 31.3214 54.6176 31.2065C53.7846 30.3027 52.7564 29.5808 51.6266 29.1001C51.1441 28.8953 50.4375 28.6176 49.9186 28.8416C49.4724 29.0331 49.145 29.3893 48.6529 29.5578C47.4542 29.9637 46.1597 30.0174 44.9094 29.9197Z"
        fill="#4A5296"
      />
      <path
        d="M55.2267 16.711C58.4111 15.2711 60.9846 14.6756 64.4007 13.946C64.4485 13.9364 65.9957 13.2126 65.2949 11.7018C64.9943 11.0565 63.7554 11.1389 63.7554 11.1389C62.5835 9.6338 60.7606 7.94491 59.3666 7.42216C58.4168 7.066 57.3483 6.84387 56.4005 7.20578C55.7207 7.4662 55.1807 7.99852 54.7154 8.55957C53.7331 9.74103 53.1012 14.3539 53.1012 14.3539C50.6445 15.767 51.7149 17.5708 53.1338 18.017C53.1357 18.0131 53.9303 17.297 55.2267 16.711Z"
        fill="#4A5296"
      />
      <path
        d="M58.5149 5.62014C58.5915 4.93846 58.3023 4.22422 57.7681 3.79147C57.387 3.48127 56.9083 3.31659 56.4258 3.22276C55.9911 3.13659 55.5316 3.10213 55.1084 3.23808C54.645 3.38552 54.2563 3.7302 54.0054 4.14763C53.8312 4.43869 53.7182 4.76612 53.6761 5.10314C53.5593 6.04524 54.0571 7.03521 54.8805 7.50626C56.3549 8.34496 58.3253 7.30329 58.5149 5.62014Z"
        fill="#4A5296"
      />
    </svg>
  );
}
