import { useEffect, useState } from 'react';

import { getAggregatedDepartures } from '../graphql';
import { fetchMockData } from '../mockData';
import { useEffectOnce } from './useEffectOnce';
import { useStore } from './useStore';
import { getSituationsForAllBoards } from '~/utils/situations';

const FETCH_DEPARTURES_INTERVAL = 20 * 1000;

export type DeparturesData = {
  firstDepartures: DPI.FirstEstimatedCall[];
  departures: DPI.BoardDeparture[];
  unableToFetch: boolean;
  fetchDate: Date;
};

export default function useDepartures() {
  const { setDepartures, isSnapshotTest, screenConfig, setSituations, setOfflineMode, tarrifZones, setTarrifZones } = useStore();

  const fetchDepartures = async (screenConfig: DPI.ScreenConfig) => {
    try {
      const [departures, firstDepartures] = await getAggregatedDepartures(screenConfig);

      // Get TarrifZones from all boards
      const zones = departures.reduce<string[]>((sum, departure) => {
        return [...sum, ...(departure.zones || []).map((zone) => zone.id)];
      }, []);

      setDepartures({
        departures,
        firstDepartures,
        fetchDate: new Date(),
      });

      setTarrifZones(zones);
      setSituations(getSituationsForAllBoards(departures));
      setOfflineMode(false);
    } catch (error) {
      console.log(error);
      setOfflineMode(true);

      return Promise.reject(`Unable to fetch departures`);
    }
  };

  useEffectOnce(
    () => {
      if (isSnapshotTest) {
        fetchMockData(screenConfig!.id).then(({ departures, firstDepartures }) => {
          setDepartures({
            fetchDate: new Date(),
            departures,
            firstDepartures,
          });
          setSituations(getSituationsForAllBoards(departures));
        });
        return;
      }

      fetchDepartures(screenConfig!);

      const interval = setInterval(() => {
        fetchDepartures(screenConfig!);
      }, FETCH_DEPARTURES_INTERVAL);

      return () => {
        console.log('Cleared interval');
        clearInterval(interval);
      };
    },
    [screenConfig, isSnapshotTest],
    () => screenConfig !== null && screenConfig !== undefined
  );

  return { tarrifZones }
}
