import React, { useEffect } from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import * as Sentry from '@sentry/browser';

import { isDevelopment } from '~/sentry';
import { isMinutesBefore } from '~/utils/dates';

import './styles.scss';

const RELOAD_TRIAL_KEY = 'DPI:stopMonitor:reloadTrial';
const RELOAD_INTERVAL_MINUTES = 1;

const ErrorContent = () => {
  // Only reload once 1 minute if state reaches a failing state in order to recover from failing state.
  useEffect(() => {
    const previouslyReloaded: string | null = window.localStorage.getItem(RELOAD_TRIAL_KEY);
    const previouslyReloadedDate = previouslyReloaded ? new Date(previouslyReloaded) : new Date();
    const shouldAttemptToReload = isMinutesBefore(previouslyReloadedDate, RELOAD_INTERVAL_MINUTES);
    if (shouldAttemptToReload) {
      window.localStorage.setItem(RELOAD_TRIAL_KEY, new Date().toISOString());
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  }, []);

  return (
    <div className="error-boundary">
      <img className="ruter-logo" src="./img/ruter-logo.png" alt="ruter-logo" />
    </div>
  );
};

const defaultOnErrorHandler = (error: Error, stack: React.ErrorInfo) => {
  console.error(error, stack);
  if (!isDevelopment()) {
    Sentry.captureException(error);
  }
};

const ErrorBoundary = ({ children }: React.PropsWithChildren) => (
  <ReactErrorBoundary onError={defaultOnErrorHandler} FallbackComponent={ErrorContent}>
    {children}
  </ReactErrorBoundary>
);
export default ErrorBoundary;
