import React, { useMemo } from 'react';

import DepartureLine from '../../Departures/Line';
import { getDepartureKey } from '~/components/Metro/DepartureBoard';

import { CampaignContent } from '~/types/Campaign';

import './styles.scss';

interface Props {
  departures: DPI.EstimatedCall[];
  boardConfig: DPI.BoardConfig;
  activeSituation?: DPI.Situation | CampaignContent;
  allDeparturesTramOrMetro?: boolean;
}

function BusShelterDepartures(props: Props) {
  const { departures = [], boardConfig, activeSituation, allDeparturesTramOrMetro } = props;
  const hasDelayOrCancelation = departures.some((departure) => departure.delayed || departure.cancellation);
  const showPlannedTime = boardConfig.hideDelay !== true && hasDelayOrCancelation;
  const publicCodeArrowAngles = boardConfig && boardConfig.publicCodeArrowAngles ? boardConfig.publicCodeArrowAngles : [];

  const departuresString = useMemo(() => {
    return departures
      .map((d) => d.serviceJourneyId)
      .sort((a, b) => a.localeCompare(b))
      .join(',');
  }, [departures]);

  return (
    <div className="busShelterDepartures">
      {departures.map((departure, i) => {
        return (
          <DepartureLine
            key={getDepartureKey(departure, i)}
            showPlannedTime={showPlannedTime}
            departure={departure}
            publicCodeArrowAngles={publicCodeArrowAngles}
            hideTrack={boardConfig.hideTracks}
            allDeparturesTramOrMetro={allDeparturesTramOrMetro}
            blinkKey={departuresString}
            activeSituation={activeSituation}
          />
        );
      })}
    </div>
  );
}

export default BusShelterDepartures;
