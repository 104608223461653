import { decompressConfig } from './zstring';

export const getQueryParam = (key: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(key);
};

export const getConfigFromURL = (string: string): DPI.ScreenConfig => {
  // Let it crash and get picked up by error boundry
  return decompressConfig(string);
};
