import React from 'react';

interface Props {
  className?: string;
}
export default function EasyTravel({ className = '' }: Props) {
  return (
    <svg className={className} viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_1246_29507"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="120"
        height="120"
      >
        <rect width="120" height="120" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0_1246_29507)">
        <path
          d="M71.8548 118.504C71.8833 118.76 76.2179 118.864 77.8547 118.504C78.281 113.658 77.8661 113.56 78.046 109.589C82.9244 114.562 87.7819 119.622 94.0204 119.831C95.9149 119.893 96.9588 119.213 96.4037 117.921C95.2689 115.278 85.8135 104.68 83.6954 99.4497C83.5723 99.1446 82.267 99.5103 82.0017 99.5463C79.1941 99.9138 71.6843 100.2 71.5062 104.328C71.3888 107.007 71.3869 114.414 71.8548 118.504Z"
          fill="#4A5296"
        />
        <path
          d="M46.658 118.631C46.6466 118.864 41.9539 119.336 40.3436 119.01C39.9249 114.619 40.3038 114.136 40.1257 110.538C35.3232 115.045 32.2617 118.701 26.1349 119.137C25.559 119.179 21.5048 119.258 22.0504 118.087C23.1681 115.693 32.4777 106.09 34.5635 101.35C34.6848 101.073 35.9692 101.405 36.2326 101.437C38.9966 101.77 46.3927 102.028 46.567 105.769C46.6807 108.196 46.8474 114.778 46.658 118.631Z"
          fill="#4A5296"
        />
        <path
          d="M64.7904 40.366C67.3214 41.6372 69.5323 43.4597 71.6428 45.3485C73.5752 47.0801 75.4602 48.8988 76.9114 51.049C77.8567 52.4491 78.6221 54.031 78.7168 55.7171C78.8116 57.4032 76.1877 60.512 74.7592 61.4138C72.7397 62.6888 71.9838 60.512 69.9889 59.2011C64.0819 55.3155 61.1378 48.3096 58.8682 41.6145C58.567 40.7241 57.8054 39.7616 57.7334 38.858C59.6582 38.032 63.0456 39.4907 64.7904 40.366Z"
          fill="#4A5296"
        />
        <path
          d="M32.8471 41.7338C30.8389 45.7179 28.8213 49.7248 27.4838 53.9817C26.4702 57.2081 25.9227 60.9478 27.749 63.7952C28.2283 64.5416 29.6378 65.8394 29.6378 65.8394C29.6378 65.8394 29.1793 68.7455 28.7834 70.0565C27.0064 75.9276 24.6098 92.9723 24.8713 92.9951C58.5933 95.7743 69.7272 90.5531 86.9482 81.9445C88.4638 81.1867 83.4737 74.9576 82.0187 73.1161C75.9033 65.3733 71.0856 56.7041 66.2963 48.0785C64.1119 44.1455 61.9295 40.2125 59.7451 36.2796C58.9134 34.7829 58.0704 33.2711 57.1099 31.8521L57.8999 27.7013C55.5033 25.3048 43.354 24.3992 40.4383 26.8639L40.8646 30.3214C39.6692 31.8161 37.0945 33.9115 36.1037 35.6127C34.9367 37.6057 33.8891 39.6688 32.8471 41.7338Z"
          fill="#DBA9A2"
        />
        <path
          d="M78.6962 18.7932C78.8932 18.5052 80.5793 16.2053 80.8843 16.0348C81.411 15.7412 85.0276 19.64 84.9101 20.2254C82.4757 32.3464 82.2824 41.8909 77.0006 47.1746C72.935 51.2421 62.6384 51.4543 62.6384 51.4543L57.0137 31.6417C62.5077 35.3359 69.8962 37.8935 70.8435 36.5673C72.5751 34.1443 77.0101 21.2503 78.6962 18.7932Z"
          fill="#DBA9A2"
        />
        <path
          d="M61.2452 34.0422C62.8404 36.4918 64.7595 40.6786 66.1558 43.2456C67.624 45.9453 68.4765 47.603 70.1816 50.9183C70.227 51.0055 68.2132 51.534 67.9707 51.0604C64.8599 44.9507 62.3194 40.7032 59.7012 35.3892C59.4265 34.8322 57.9943 32.5266 57.8674 32.2102C57.695 31.7839 60.3605 32.6838 61.2452 34.0422Z"
          fill="#4A5296"
        />
        <path
          d="M34.8832 65.2276C33.1403 78.7088 29.5919 90.2444 31.3121 103.727C32.7178 104.391 34.8131 104.934 37.1699 105.29C39.2974 105.611 44.511 105.669 47.2107 105.48C47.4816 105.461 47.5422 97.0494 47.637 89.0925C47.7222 81.9162 49.4273 63.0394 50.6359 61.1014C54.9023 71.6651 55.1202 71.5211 60.709 82.5092C62.6603 86.3455 68.6848 105.01 69.1395 105.054C72.5496 105.385 81.2169 104.485 85.3696 100.696C85.4946 100.583 77.7234 82.5357 72.2351 71.648C70.4884 68.183 71.0321 69.8956 69.7097 67.2585C67.4913 62.8367 61.211 52.1252 60.1425 52.292C55.304 53.0479 46.038 54.3835 41.1995 55.1413C39.8619 55.3497 38.4884 55.5713 37.3214 56.2552C34.4494 57.9338 35.2526 62.3839 34.8832 65.2276Z"
          fill="#F24949"
        />
        <path
          d="M37.5926 75.6223C37.7991 75.7929 38.0814 75.827 38.3466 75.8554C40.8758 76.1168 43.4068 76.3783 45.9359 76.6416C48.287 76.8841 50.657 77.1285 53.01 76.9087C55.3421 76.6908 62.2172 73.9741 64.4016 73.133C68.2531 71.6496 72.2751 71.7841 72.2315 71.6496C70.6534 66.597 65.3432 59.4301 63.7158 55.6392C62.7799 53.4587 61.7588 51.0091 59.5744 50.0789C58.4756 49.6109 57.2404 49.6147 56.0469 49.6526C53.1028 49.7473 50.1645 50.0069 47.2507 50.435C44.6913 50.8102 45.6139 49.7966 43.4655 51.3482C43.4655 51.3482 38.8373 59.7882 38.824 63.7571C38.8164 67.027 37.3349 75.4083 37.5926 75.6223Z"
          fill="#4A5296"
        />
        <path
          d="M41.4788 60.438C41.1586 70.4731 41.7629 82.3668 41.4314 93.0233C41.4219 93.3226 34.1565 93.855 30.8222 93.4496C30.0568 93.3567 27.128 92.3602 27.128 92.3602C25.7999 91.4888 27.5504 90.3066 27.4822 88.719C27.181 81.6393 28.9902 74.66 30.7843 67.8057C31.2826 65.9017 31.7827 63.9902 32.5121 62.162C33.393 59.9473 34.7059 56.1129 37.0456 55.0292C41.2855 53.0646 41.5564 57.9998 41.4788 60.438Z"
          fill="#DBA9A2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M91.5405 -0.0834961L105.525 0.153537C105.94 0.160556 106.27 0.501985 106.263 0.916139C106.256 1.33029 105.914 1.66034 105.5 1.65332L91.5412 1.41673L78.2834 1.65348L58.6186 1.51132C58.2044 1.50833 57.871 1.17012 57.874 0.755921C57.877 0.341718 58.2152 0.00836748 58.6294 0.0113617L78.2754 0.153381L91.5405 -0.0834961Z"
          fill="#F24949"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M82.3724 9.37898C82.7022 9.12836 83.1727 9.19253 83.4233 9.52232C84.1008 10.4138 85.0653 11.5442 86.0698 12.7216C86.1664 12.8348 86.2633 12.9484 86.3604 13.0623C87.4547 14.3458 88.569 15.6639 89.3242 16.7095C89.4729 16.9154 89.5073 17.1826 89.4156 17.4194C89.3239 17.6563 89.1186 17.8306 88.87 17.8827L88.7162 17.1487C88.87 17.8827 88.8701 17.8827 88.87 17.8827L88.8689 17.883L88.8673 17.8833L88.8624 17.8843L88.8463 17.8876C88.8328 17.8903 88.8137 17.894 88.7893 17.8987C88.7404 17.9079 88.6702 17.9208 88.5805 17.936C88.4011 17.9663 88.1434 18.0059 87.8221 18.0448C87.1804 18.1224 86.2807 18.1975 85.2429 18.1878C84.8287 18.1839 84.4961 17.845 84.5 17.4308C84.5039 17.0166 84.8428 16.684 85.257 16.6878C86.0658 16.6954 86.7851 16.6472 87.3458 16.5889C86.7231 15.8057 85.9787 14.9266 85.2189 14.0355C85.1203 13.9197 85.0214 13.8038 84.9225 13.6879C83.9272 12.5215 82.9339 11.3574 82.2291 10.4299C81.9785 10.1001 82.0426 9.62961 82.3724 9.37898Z"
          fill="#F24949"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M83.0761 9.26979C83.4663 9.40882 83.6699 9.83783 83.5309 10.228C83.4799 10.3712 83.3579 10.5845 83.2524 10.7651C83.1281 10.978 82.9631 11.2497 82.7733 11.5563C82.3933 12.1706 81.9047 12.9405 81.4235 13.6921C80.9418 14.4442 80.4659 15.1806 80.1105 15.7288C79.9446 15.9847 79.8049 16.1996 79.703 16.3562C79.8188 16.3739 79.948 16.392 80.0898 16.41C80.8102 16.5016 81.7974 16.5855 82.8561 16.6276C83.27 16.6441 83.5922 16.9929 83.5757 17.4068C83.5592 17.8207 83.2104 18.1429 82.7965 18.1264C81.7006 18.0828 80.6697 17.9959 79.9005 17.898C79.5185 17.8494 79.1864 17.7964 78.9383 17.7413C78.8181 17.7146 78.6922 17.682 78.5829 17.6407C78.5309 17.621 78.4515 17.5879 78.3707 17.5355L78.3694 17.5347C78.3227 17.5044 78.0987 17.3593 78.0229 17.0587C77.8837 16.509 78.0974 16.1174 78.2277 15.8788L78.2472 15.843L78.2633 15.8183L78.8914 16.2281C78.2633 15.8183 78.2633 15.8183 78.2633 15.8183L78.3053 15.7539C78.3328 15.7117 78.3731 15.6498 78.4246 15.5707C78.5274 15.4126 78.6746 15.1861 78.8519 14.9128C79.2064 14.366 79.6806 13.6322 80.1602 12.8832C80.6402 12.1336 81.1238 11.3714 81.4977 10.7671C81.685 10.4645 81.8423 10.2053 81.9572 10.0086C82.0421 9.86323 82.0868 9.78004 82.1063 9.7437C82.1175 9.72283 82.1204 9.71741 82.1179 9.72454C82.2569 9.33436 82.6859 9.13076 83.0761 9.26979Z"
          fill="#F24949"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M82.4628 0.153987C82.8767 0.136682 83.2262 0.458147 83.2435 0.871999L83.6239 9.96856C83.6412 10.3824 83.3197 10.7319 82.9059 10.7492C82.492 10.7665 82.1425 10.4451 82.1252 10.0312L81.7448 0.934666C81.7275 0.520814 82.049 0.171293 82.4628 0.153987Z"
          fill="#F24949"
        />
        <path
          d="M80.5293 16.3435C83.2289 16.3207 81.4822 20.1571 82.2571 21.1744C82.9429 22.0743 84.5077 22.8075 85.7619 21.0797C86.3245 20.3029 86.8361 18.8688 86.0461 16.6996C85.5724 15.3962 84.7919 14.5683 83.1096 14.6877C81.9236 14.7691 80.5293 16.3435 80.5293 16.3435Z"
          fill="#9DA6D9"
        />
        <path
          d="M47.6379 14.3732C46.954 14.754 46.2777 15.1651 45.734 15.7259C44.5916 16.9043 44.1577 18.6396 44.2582 20.2784C44.3586 21.9171 44.9345 23.4896 45.5805 24.9976C46.0314 26.0509 46.5315 27.1061 47.3026 27.9549C48.0737 28.8036 49.1611 29.4307 50.3073 29.4023C51.2754 29.3795 52.1866 28.8964 52.9046 28.2466C53.6226 27.5968 54.172 26.786 54.7101 25.9808C55.2386 25.1889 55.7653 24.3857 56.1272 23.5047C56.7751 21.9285 56.8565 20.1268 56.3545 18.4976C55.8525 16.8683 54.7707 15.4266 53.3479 14.4869C51.3834 13.1949 49.6101 13.2763 47.6379 14.3732Z"
          fill="#9DA6D9"
        />
        <path
          d="M50.5421 16.6427C53.1318 16.7374 54.0317 17.3853 55.1154 17.9044C54.8179 16.5499 53.9351 15.3336 52.7378 14.6345C51.5405 13.9336 50.0495 13.7593 48.7214 14.1628C47.6814 14.4792 46.7682 15.1271 45.9744 15.8716C45.4137 16.3983 43.2824 18.5372 45.1731 18.2379C45.8304 18.1337 48.4392 16.5669 50.5421 16.6427Z"
          fill="#E2D1CA"
        />
        <path
          d="M54.4672 30.8307C54.7135 29.4458 55.3103 27.6233 55.8768 26.3369C56.5398 24.8327 56.8467 23.9461 57.0134 22.3111C57.1574 20.8903 57.3507 19.2989 56.968 17.9216C55.5168 12.7117 49.6836 9.78284 45.2316 13.6552C43.5701 15.1007 42.2875 17.9803 42.5528 20.3693C42.6153 20.9376 42.8691 25.6113 44.7636 28.3887L42.0488 36.5198C44.4169 38.6985 51.2845 44.7609 51.2845 44.7609C51.2845 44.7609 58.6844 43.5067 59.3361 42.5822C59.3342 42.5822 54.1262 32.7555 54.4672 30.8307ZM48.9145 28.2788C46.4516 27.3941 45.1255 24.3004 45.0781 22.4059C45.0516 21.3127 45.0497 19.6494 45.4097 18.6169C46.8021 14.6308 52.7129 13.4866 54.8405 17.6014C55.4922 18.8613 55.621 20.3333 55.5111 21.7485C55.3974 23.2072 55.0261 24.6736 54.2172 25.8917C53.4063 27.1099 51.9495 29.3681 48.9145 28.2788Z"
          fill="#4A5296"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.9436 19.143C51.1637 19.1126 51.3667 19.2664 51.397 19.4865C51.4323 19.7424 51.539 20.0945 51.6923 20.5024C51.8435 20.9045 52.0304 21.3366 52.2132 21.7464C52.2744 21.8835 52.3356 22.0193 52.3947 22.1504C52.5098 22.4056 52.6171 22.6436 52.7012 22.8411C52.765 22.991 52.8206 23.1292 52.8582 23.2424C52.8769 23.2985 52.8944 23.3582 52.9056 23.4155C52.9134 23.4554 52.9332 23.561 52.9019 23.6743C52.8338 23.9215 52.6572 24.1037 52.4182 24.1868C52.1973 24.2636 51.9506 24.2482 51.7126 24.185C51.2352 24.0583 50.6535 23.7034 50.0155 23.0998C49.8541 22.9471 49.847 22.6925 49.9997 22.5311C50.1524 22.3698 50.4069 22.3627 50.5683 22.5154C51.1579 23.0731 51.6239 23.3291 51.919 23.4075C51.985 23.425 52.0356 23.4319 52.0728 23.4336C52.0811 23.4563 52.0885 23.4772 52.0948 23.4962C52.1078 23.5353 52.1136 23.5581 52.1157 23.5681C52.1168 23.578 52.1179 23.5795 52.116 23.5696L52.1157 23.5681C52.1139 23.5515 52.1124 23.5112 52.1263 23.4607L52.1266 23.4598C52.1266 23.4598 52.128 23.4562 52.1297 23.4531C52.1323 23.4483 52.1361 23.443 52.1408 23.438C52.149 23.4294 52.1554 23.4264 52.1554 23.4264L52.1539 23.427C52.145 23.43 52.1193 23.4358 52.0728 23.4336C52.0458 23.36 52.0084 23.2674 51.9611 23.1563C51.8801 22.9663 51.779 22.742 51.6665 22.4926C51.6066 22.3597 51.5435 22.2198 51.4785 22.0741C51.2942 21.6611 51.0992 21.211 50.9393 20.7854C50.7815 20.3657 50.6481 19.9448 50.6001 19.5964C50.5697 19.3763 50.7235 19.1733 50.9436 19.143Z"
          fill="#4A5296"
        />
        <path
          d="M47.0675 29.6522C47.7306 30.0785 45.173 35.6199 43.5627 42.9137C43.252 41.8225 41.7667 33.9092 41.7118 32.7744C41.672 31.9427 41.5185 26.0337 42.1418 26.621C43.7995 28.184 45.7944 28.8338 47.0675 29.6522Z"
          fill="#DBA9A2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M48.4626 23.9566C48.6181 23.798 48.8728 23.7954 49.0315 23.9509C49.6213 24.529 50.4512 24.859 51.2774 24.8439C51.4995 24.8398 51.6829 25.0166 51.6869 25.2387C51.691 25.4608 51.5142 25.6442 51.2921 25.6482C50.2504 25.6673 49.2123 25.2546 48.4683 24.5255C48.3097 24.37 48.3071 24.1153 48.4626 23.9566Z"
          fill="#4A5296"
        />
        <path
          d="M48.7967 21.3638C48.9923 21.3638 49.1509 21.2052 49.1509 21.0095C49.1509 20.8139 48.9923 20.6553 48.7967 20.6553C48.601 20.6553 48.4424 20.8139 48.4424 21.0095C48.4424 21.2052 48.601 21.3638 48.7967 21.3638Z"
          fill="#4A5296"
        />
        <path
          d="M53.3914 21.7657C53.587 21.7657 53.7456 21.6071 53.7456 21.4114C53.7456 21.2157 53.587 21.0571 53.3914 21.0571C53.1957 21.0571 53.0371 21.2157 53.0371 21.4114C53.0371 21.6071 53.1957 21.7657 53.3914 21.7657Z"
          fill="#4A5296"
        />
        <path
          d="M56.4453 41.1143C58.9555 40.0723 61.3445 39.6006 63.2655 39.9776C63.3754 39.9984 62.8847 41.711 62.8336 42.4253C62.8222 42.5806 57.277 42.9841 56.7447 43.0656C55.7311 43.2228 54.7327 43.4464 53.7608 43.776C53.5183 43.8575 52.4574 44.7195 52.4328 44.2402C52.4195 43.9655 52.304 43.7078 52.4953 43.4691C52.4991 43.4634 52.4991 43.4634 52.4934 43.4729C52.1391 43.9465 51.567 44.1909 51.029 44.3898C50.741 44.4978 50.4436 44.6001 50.1367 44.6058C49.7085 44.6134 49.2993 44.4334 48.9318 44.2156C48.3994 43.9011 47.9201 43.5051 47.4427 43.1111C45.5899 41.5784 43.6935 39.9927 42.5416 37.8823C42.4886 37.7856 42.4488 37.3783 42.4772 37.2836C42.6818 36.6035 43.3752 34.9193 43.5552 34.8795C43.7427 34.8378 47.3802 37.0714 50.0003 39.7559C50.6444 40.4171 52.465 43.3744 52.465 43.3744C52.878 43.1471 55.5928 41.4685 56.4453 41.1143Z"
          fill="#E5E8FF"
        />
        <path
          d="M34.1733 49.7209C34.0956 49.8989 34.0179 50.0903 34.0558 50.2797C34.1013 50.5146 34.3059 50.6795 34.4972 50.8234C38.057 53.4928 41.6167 56.1602 45.1765 58.8296C45.4171 59.0096 45.6653 59.1933 45.9589 59.2577C46.1806 59.3051 46.4098 59.2824 46.6352 59.2577C49.8748 58.9054 53.1163 58.553 56.3559 58.1987C56.6268 58.1684 56.9072 58.1362 57.1383 57.9922C57.4301 57.8085 57.5911 57.4807 57.7351 57.1681C60.1619 51.945 62.3539 46.6101 64.3014 41.1899C63.0624 41.0232 61.812 41.3112 60.5939 41.5973C58.2068 42.158 52.7905 43.4273 52.4684 43.3781C52.0895 43.3194 41.702 34.5554 41.4311 35.0537C40.6922 36.4196 38.7598 40.0002 38.0627 41.387C36.6873 44.1264 35.3895 46.9056 34.1733 49.7209Z"
          fill="#E2D1CA"
        />
        <path
          d="M51.2483 46.6555C51.5703 46.2482 51.9947 45.8257 52.5119 45.856C53.0083 45.8863 53.3796 46.3316 53.5728 46.7881C54.1942 48.2526 53.5899 49.9387 52.834 51.3406C52.2505 52.4224 51.5703 53.4586 50.7462 54.3699C50.642 54.4855 50.5303 54.6029 50.3863 54.6654C49.943 54.8606 49.475 54.4646 49.1511 54.1047C48.6566 53.5572 46.6996 52.0832 47.1296 51.5964C48.2322 50.3498 50.2177 47.957 51.2483 46.6555Z"
          fill="#9DA6D9"
        />
        <path
          d="M35.7475 56.0332C41.2358 55.8248 42.365 53.4264 47.0671 50.5865C47.4593 50.3497 52.0818 54.2505 51.9928 54.4229C50.4393 57.4105 48.2076 60.1499 45.6197 62.3021C43.0318 64.4542 39.8282 65.9452 36.4693 66.1782C34.4195 66.3203 32.3393 65.9926 30.4315 65.2272C29.7666 64.9601 29.1111 64.6323 28.6052 64.1246C28.1506 63.668 27.8418 63.0883 27.5614 62.5067C27.0177 61.3795 26.8206 60.311 26.7013 59.0663C26.616 58.1853 28.3324 55.5084 30.8067 55.8608C31.5853 55.9707 34.9632 56.0635 35.7475 56.0332Z"
          fill="#DBA9A2"
        />
      </g>
    </svg>
  );
}
