import React from 'react';
import cx from 'classnames';
import { getClock, getMilisecondsOffset } from '~/utils/clock';

import './styles.scss';

interface Props {
  sizeVariant: string;
  className?: string;
}

class Clock extends React.Component<Props> {
  state = {
    clock: getClock(),
  };

  private clockInterval?: NodeJS.Timeout;
  private clockOffsetTimeout?: NodeJS.Timeout;

  componentDidMount() {
    const offsetInMS = getMilisecondsOffset();
    this.clockOffsetTimeout = setTimeout(() => {
      this.setState({ clock: getClock() });
      this.clockInterval = setInterval(() => {
        this.setState({ clock: getClock() });
      }, 60 * 1000);
    }, offsetInMS);
  }

  componentWillUnmount() {
    if (this.clockOffsetTimeout) {
      clearTimeout(this.clockOffsetTimeout);
    }
    if (this.clockInterval) {
      clearInterval(this.clockInterval);
    }
  }

  render() {
    const { sizeVariant, className } = this.props; // xsmall, small, medium, large
    const { clock } = this.state;

    return (
      <div className={cx('clock', `-${sizeVariant}`, className)}>
        <span>{clock[0]}</span>
        <span className={cx('clock-colon', `-${sizeVariant}`)}> : </span>
        <span>{clock[1]}</span>
      </div>
    );
  }
}
export default Clock;
