import React from 'react';
import Clock from '../../Clock';

import './styles.scss';

interface Props {
  screenConfig: DPI.ScreenConfig;
}

const TopHeader = ({ screenConfig }: Props) => {
  if (!screenConfig || !screenConfig.header || !screenConfig.header.label) {
    return null;
  }
  const { label } = screenConfig.header;
  return (
    <div className="top-header">
      <div className={`title-holder`}>
        <span>{label.no}</span>
        <span>{label.en}</span>
      </div>
      <div className="right-holder">
        <Clock sizeVariant={'small'} />
      </div>
    </div>
  );
};

export default TopHeader;
