import React from 'react';
import cx from 'classnames';
import ArrowUpIcon from '../../Icons/ArrowUpIcon';

import './styles.scss';

interface Props {
  publicCode: string;
  trackArrowAngle?: number | null;
  transportMode: DPI.TransportType;
}

function getTrackText(publicCode: string, transportMode: DPI.TransportType) {
  if (isNaN(Number(publicCode)) && publicCode.length === 2 && transportMode === 'water') {
    return `${publicCode[0]}/${publicCode[1]}`;
  }
  return publicCode;
}

function BusShelterTrack(props: Props) {
  const { publicCode, trackArrowAngle, transportMode } = props;
  const trackText = getTrackText(publicCode, transportMode);

  const showTrackArrowAngle = typeof trackArrowAngle === 'number';

  return (
    <span
      className={'line-track'}
    >
      <span className="text">{trackText}</span>
      {showTrackArrowAngle ? (
        <div className="track-arrow">
          <ArrowUpIcon
            className="arrowUpIcon"
            style={{
              transform: `rotate(${trackArrowAngle || 0}deg)`,
            }}
          />
        </div>
      ) : null}
    </span>
  );
}

export default BusShelterTrack;
