import React from 'react';

interface Props {
  className?: string;
  style?: any;
}
export default function HeartIcon({ className, style }: Props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} style={style}>
      <path
        d="M10.5355 5.46447C8.58291 3.51184 5.41709 3.51184 3.46447 5.46447C1.51184 7.41709 1.51184 10.5829 3.46447 12.5355L10.5858 19.6569C11.3668 20.4379 12.6332 20.4379 13.4142 19.6569L20.5356 12.5355C22.4882 10.5829 22.4882 7.41709 20.5356 5.46447C18.5829 3.51184 15.4171 3.51184 13.4645 5.46447L12.3536 6.57539C12.1583 6.77065 11.8417 6.77065 11.6465 6.57539L10.5355 5.46447Z"
        fill="#FFFFFF"
      />
    </svg>
  );
}
