import { create } from 'zustand';
import { CampaignContent } from '../types/Campaign';

type Store = {
  screenConfig: DPI.ScreenConfig | null;
  isSnapshotTest: boolean;
  campaigns: CampaignContent[];
  situations: DPI.Situation[];
  activePlaylistIndex: number;
  departureDate: DPI.DeparturesData;
  tarrifZones: string[];
  offlineMode: boolean;
  mqtt: DPI.MQTTConfig | null;
  enturAuthority: string | null;
  colors: DPI.PTAColor[];
};
type StoreActions = {
  setScreenConfig: (config: DPI.ScreenConfig) => void;
  setIsSnapshotTest: (isTest: boolean) => void;
  setCampaigns: (campaigns: CampaignContent[]) => void;
  setSituations: (situations: DPI.Situation[]) => void;
  setActivePlaylistIndex: (activePlaylistIndex: number) => void;
  setNextActivePlaylistIndex: () => number;
  setDepartures: (data: DPI.DeparturesData) => void;
  setOfflineMode: (offline: boolean) => void;
  setPtaColors: (colors: DPI.PTAColor[]) => void;
  setTarrifZones: (zones: string[]) => void;
  setMqtt: (config: DPI.MQTTConfig) => void;
  setEnturAuthority: (authority: string) => void;
};

export const useStore = create<Store & StoreActions>((set, get, store) => ({
  screenConfig: null,
  isSnapshotTest: false,
  campaigns: [],
  situations: [],
  activePlaylistIndex: 0,
  departureDate: {
    firstDepartures: [],
    departures: [],
    fetchDate: new Date(),
  },
  tarrifZones: [],
  offlineMode: false,
  mqtt: null,
  enturAuthority: null,
  colors: [],
  setScreenConfig: (config: DPI.ScreenConfig) => set({ screenConfig: config }),
  setIsSnapshotTest: (isTest: boolean) => set({ isSnapshotTest: isTest }),
  setCampaigns: (campaigns: CampaignContent[]) => set({ campaigns }),
  setSituations: (situations: DPI.Situation[]) => set({ situations }),
  setActivePlaylistIndex: (activePlaylistIndex: number) => set({ activePlaylistIndex }),
  setNextActivePlaylistIndex: () => {
    const state = get();
    const playlist = getPlaylist(state);
    const nextIndex = state.activePlaylistIndex < playlist.length - 1 ? state.activePlaylistIndex + 1 : 0;
    set({ activePlaylistIndex: nextIndex });
    return nextIndex;
  },
  setDepartures: (data: DPI.DeparturesData) => set({ departureDate: data }),
  setOfflineMode: (offline: boolean) => set({ offlineMode: offline }),
  setPtaColors: (colors: DPI.PTAColor[]) => set({ colors }),
  setTarrifZones: (zones: string[]) => {
    // Only set zones if there is a diff
    const currentZones = get().tarrifZones;
    const diff = [...zones.filter((x) => !currentZones.includes(x)), ...currentZones.filter((x) => !zones.includes(x))];
    if (diff.length) {
      set({ tarrifZones: zones });
    }
  },
  setEnturAuthority: (authority: string) => set({ enturAuthority: authority }),
  setMqtt: (config: DPI.MQTTConfig) => set({ mqtt: config }),
}));

export const useTemplate = () => useStore((state) => state.screenConfig?.template || 'METRO_DEFAULT');

export const useColors = () => useStore((state) => state.colors);

export const useTarrifZones = () =>
  useStore((state) =>
    state.departureDate?.departures?.reduce<string[]>((sum, departure) => {
      return [...sum, ...(departure.zones || []).map((zone) => zone.id)];
    }, [])
  );

export const useIgnoreCampaigns = () =>
  useStore((state) => state.screenConfig?.ignoreCampaigns || state.screenConfig?.template !== 'BUS_SHELTER_DEFAULT');

export const usePlaylist = () => useStore((state) => getPlaylist(state));

export const useActivePlalistItem = () =>
  useStore((state) => {
    const playlist = getPlaylist(state);
    return playlist[state.activePlaylistIndex];
  });

export function getPlaylist(state: Store) {
  if (
    state.screenConfig?.ignoreCampaigns ||
    state.screenConfig?.template !== 'BUS_SHELTER_DEFAULT' ||
    state.campaigns.length === 0
  ) {
    return state.situations;
  }
  return state.campaigns.reduce<(CampaignContent | DPI.Situation)[]>((sum, campaign) => {
    return [...sum, ...state.situations, campaign];
  }, []);
}

export const isOfflineMode = () => useStore((state) => state.offlineMode);

export const useEnturAuthority = () => useStore((state) => state.enturAuthority);
