import React from 'react';

interface Props {
  className?: string;
}
export default function TaDa({ className = '' }: Props) {
  return (
    <svg className={className} viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.2447 77.9641C22.3657 78.4809 22.9425 80.4915 24.6675 80.7357C25.7329 80.8861 27.4542 80.95 29.9138 80.9537C31.0319 80.9556 32.3247 80.8616 33.8899 80.9556C31.6971 81.1116 28.5872 81.2638 27.9089 81.2995C25.468 81.4329 22.8354 81.4874 23.0703 83.0846C23.3052 84.6818 24.5266 84.3605 25.7949 84.3529C28.3166 84.3379 29.6076 84.3642 33.7659 84.4319C30.4776 84.6198 29.5061 84.6818 26.4526 84.9167C24.8423 85.0407 23.7393 85.6645 23.8689 86.7957C24.0117 88.034 25.9584 88.1787 27.2042 88.205C32.4806 88.314 36.4924 89.464 38.4673 90.4092C40.1735 91.2247 41.4363 92.7223 42.6633 94.1598C51.042 103.991 60.3452 113.202 71.3302 119.997C71.7361 120.249 81.8567 106.019 81.2742 105.351C74.1187 97.1531 59.727 89.7233 52.2747 81.1435C52.0135 80.8429 55.8994 80.0499 57.6281 79.2475C58.1674 78.9976 58.7743 78.7176 58.9773 78.1596C59.2779 77.3309 58.4098 76.4646 57.5323 76.3838C56.6529 76.3012 24.2447 77.9641 24.2447 77.9641Z"
        fill="#DBA9A2"
      />
      <path
        d="M41.8134 71.4045C35.9263 71.8724 12.4681 73.3174 12.5 76.322C12.6879 79.6104 33.6056 79.5859 43.6924 79.1725C52.5128 78.8099 74.8849 77.4494 74.4865 74.2625C74.0393 70.2132 46.4641 71.0343 41.8134 71.4045Z"
        fill="#4A5296"
      />
      <path
        d="M70.9163 53.3013C71.6134 53.6602 72.4515 53.1886 72.5097 52.4069C73.0697 44.9526 74.0449 30.0554 57.9771 21.2464C40.5864 11.712 25.5915 22.0525 21.435 25.416C20.8506 25.8877 20.9051 26.7934 21.5402 27.1955C24.6331 29.1535 33.2468 34.4731 44.6395 40.4015C55.0307 45.8095 66.1209 50.8379 70.9163 53.3013Z"
        fill="#4A5296"
      />
      <path
        d="M68.6801 2.83353C67.8082 0.199083 67.4625 -0.43416 66.055 0.262972C64.7247 1.99547 66.5098 6.67808 67.7406 9.71277C66.7578 8.25086 65.5402 7.35455 63.9674 5.03766C61.8027 1.84514 60.711 -0.428523 59.0386 0.456515C58.1573 0.975136 59.2115 4.21088 61.1357 6.48642C62.7761 8.42373 64.4766 10.3516 64.4766 10.3516C61.5829 9.03443 58.971 9.55117 57.7571 10.3967C56.765 11.0901 55.9194 11.9977 55.6075 13.7903C55.4045 14.9497 55.7277 16.9284 56.8833 17.1576C58.0108 17.3812 58.3471 13.4521 59.7151 12.8095C62.3589 11.5674 65.8427 14.2394 66.0832 15.8761C65.807 19.1832 60.0326 16.5601 58.6647 17.898C58.0465 19.5083 59.8917 20.7278 61.9098 20.7861C63.9279 20.8443 66.0156 20.3652 67.9266 21.0153C69.6234 21.5922 70.9105 22.9733 72.0267 24.377C81.5686 36.3654 83.5096 42.2882 93.7637 55.9752C94.3687 56.7832 108.512 45.5746 107.699 44.047C102.988 35.2173 96.1689 29.9108 88.6489 23.4017C84.9152 20.1697 81.0105 17.1407 77.0588 14.1774C75.533 13.0331 73.3853 11.9601 72.2071 10.4719C70.6118 8.45943 69.9071 6.54091 68.6801 2.83353Z"
        fill="#DBA9A2"
      />
      <path
        d="M57.3872 16.9923C57.5882 16.733 57.8607 16.4869 58.1895 16.4737C58.5165 16.4605 58.8002 16.6823 59.0464 16.8965C59.1103 16.9529 59.1761 17.0111 59.2136 17.09C59.2794 17.231 59.2493 17.402 59.1836 17.5448C57.2481 21.7802 56.7389 22.8719 57.2481 21.7802C56.7445 21.41 55.2188 20.6039 55.2188 20.6039C55.6528 19.608 56.7088 17.8642 57.3872 16.9923Z"
        fill="#4A5296"
      />
      <path
        d="M37.003 47.5421C34.5809 47.606 32.5854 49.6673 31.9953 52.0237C31.4072 54.38 31.9935 56.928 32.9931 59.1923C34.1224 61.7497 35.7629 64.0553 37.486 66.2632C39.0418 68.2569 44.7993 75.0083 45.5997 75.2244C45.5997 75.2244 46.8042 68.7511 44.6677 57.8901C44.2355 55.6972 43.5516 52.9256 42.4035 50.9883C41.2554 49.0491 39.2053 47.4839 37.003 47.5421ZM39.3218 52.2022C39.7446 52.9857 39.6901 53.9948 39.2166 54.7483C38.6265 55.6841 37.3901 56.1689 36.3303 55.8532C35.2705 55.5375 34.4964 54.427 34.5997 53.3259C34.8271 50.8511 38.0741 49.8872 39.3218 52.2022Z"
        fill="#F24949"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.0386 41.4769C29.741 41.6598 29.6482 42.0497 29.8311 42.3473L29.832 42.3488C30.3406 43.1765 30.8501 44.0057 31.3613 44.8325C31.545 45.1296 31.9347 45.2216 32.2319 45.0379C32.529 44.8542 32.621 44.4644 32.4373 44.1673C31.9268 43.3415 31.4181 42.5137 30.9089 41.685C30.726 41.3874 30.3362 41.294 30.0386 41.4769Z"
        fill="#4A5296"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.755 42.7255C42.9517 42.4368 42.8772 42.0434 42.5885 41.8466C42.2999 41.6499 41.9064 41.7244 41.7097 42.0131C41.2296 42.7175 40.8988 43.5236 40.7457 44.3622C40.683 44.7058 40.9108 45.0352 41.2544 45.0979C41.5981 45.1607 41.9275 44.9329 41.9902 44.5893C42.1115 43.9246 42.3744 43.2838 42.755 42.7255Z"
        fill="#4A5296"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.8073 52.0111C24.458 52.0111 24.1748 52.2943 24.1748 52.6436C24.1748 52.993 24.458 53.2761 24.8073 53.2761H27.344C27.6934 53.2761 27.9765 52.993 27.9765 52.6436C27.9765 52.2943 27.6934 52.0111 27.344 52.0111H24.8073Z"
        fill="#4A5296"
      />
    </svg>
  );
}
