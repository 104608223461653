import React from 'react';
import cx from 'classnames';
import Lottie from 'lottie-react';

import {
  ContentTypes,
  CampaignContent,
  isImageCampaign,
  isInternalTextCampaign,
  isVideoCampaign,
} from '~/types/Campaign';
import { getSituationText, getSituationIncludingBodyTextSize } from '~/utils/situations';

import SurveyArrowIcon from '~/components/Icons/SurveyArrowIcon';
import HeartIcon from '~/components/Icons/HeartIcon';
import { Illustration, isSupportedIllustration } from '~/components/Illustrations';
import TravelCompanionMoving from '~/components/Illustrations/TravelCompanion_Dark.json';

import './styles.scss';

interface DotDotDotProps {
  size: number;
  activeIndex: number;
  fullHeight: boolean;
}
function DotDotDot(props: DotDotDotProps) {
  const { size, activeIndex, fullHeight } = props;
  if (size > 1) {
    return (
      <div
        className={cx('dot-dot-dot', {
          '-full': fullHeight,
        })}
      >
        {new Array(size).fill(0).map((_, dotIndex) => (
          <div key={`dot-${dotIndex}-${dotIndex}`} className={cx('dot', { active: dotIndex === activeIndex })} />
        ))}
      </div>
    );
  }
  return null;
}

interface RenderSituationProps {
  current?: DPI.Situation;
  size: number;
  activeIndex: number;
  fullHeight: boolean;
  largeHeader: boolean;
}
export function RenderSituation({ current, size, activeIndex, fullHeight, largeHeader }: RenderSituationProps) {
  const [title, body] = getSituationText(current) || [];
  const isGeneralInfoType = current?.reportType === 'general';
  return (
    <div
      className={cx(
        'activeSituation',
        `-size-${getSituationIncludingBodyTextSize(title || '', body || '', fullHeight)}`,
        {
          '-general-situation': isGeneralInfoType,
          '-full': fullHeight,
          '-large': largeHeader,
        }
      )}
    >
      <div className="title">{title}</div>
      <div className="body">{body}</div>
      <div className="bottom">
        <DotDotDot size={size} activeIndex={activeIndex} fullHeight={fullHeight} />
      </div>
    </div>
  );
}

interface BusShelterSurveyProps {
  size: number;
  activeIndex?: number;
  fullHeight: boolean;
}
function BusShelterSurvey({ size, activeIndex = 0, fullHeight }: BusShelterSurveyProps) {
  return (
    <div className={cx('survey', { '-full': fullHeight })}>
      <div className="left">
        <img className="surveyQrCode" src="./img/survey-qr-code2.gif" alt="ruter-logo" />
      </div>
      <div className="right">
        <span className="caption">Hjelp oss å forbedre skjermen</span>
        <SurveyArrowIcon className="surveyArrowIcon" />
      </div>
      <div className="bottomRight">
        <DotDotDot size={size} activeIndex={activeIndex} fullHeight={fullHeight} />
      </div>
    </div>
  );
}

function RenderCampaign(data: CampaignContent, playlistCount = 1) {
  if (isImageCampaign(data)) {
    return (
      <div className="image-content">
        <img src={data.src} alt="Ruter Info" />
      </div>
    );
  }
  if (isVideoCampaign(data)) {
    return (
      <div className="image-content">
        <video src={data.src} autoPlay muted loop={playlistCount === 1} />
      </div>
    );
  }
  if (isInternalTextCampaign(data)) {
    return (
      <div className={cx('happening', { '--right-achor': data.iconAnchor === 'RIGHT' })}>
          {isSupportedIllustration(data.icon as DPI.SupportedIllustrations) ? (
            <Illustration className="illustration" name={data.icon as DPI.SupportedIllustrations} />
          ) : (
            ''
          )}
        <div className="text">
          <h2>{data.message.title ?? ''}</h2>
          <p>{data.message.paragraphs?.join('') || ''}</p>
        </div>
      </div>
    );
  }

  return null;
}

interface ActiveContentProps {
  activeIndex?: number;
  activeSituationIndex: number;
  fullHeight: boolean;
  playlistCount: number;
  situationCount: number;
  largeHeader: boolean;
  current?: DPI.Situation | CampaignContent;
}
function ActiveContent(props: ActiveContentProps) {
  const { activeIndex, activeSituationIndex, fullHeight, largeHeader, current, playlistCount, situationCount } = props;

  if (!current) {
    return (
      <div className="noContent">
        <div className="illustration">
          <Lottie style={{ height: '21.66rem', width: '21.66rem' }} animationData={TravelCompanionMoving} />
        </div>
        <div className="text">
          Takk for at du
          <span>
            reiser med oss <HeartIcon className="icon" />
          </span>
        </div>
      </div>
    );
  }

  if (current.type === ContentTypes.situation) {
    return (
      <RenderSituation
        current={current as DPI.Situation}
        activeIndex={activeSituationIndex}
        fullHeight={fullHeight}
        size={situationCount}
        largeHeader={largeHeader}
      />
    );
  }
  if (current.type === ContentTypes.survey) {
    return <BusShelterSurvey fullHeight={fullHeight} activeIndex={activeIndex} size={playlistCount} />;
  }

  return RenderCampaign(current as CampaignContent, playlistCount);
}

interface BusShelterCarouselProps {
  currentPlaylistItem?: DPI.Situation | CampaignContent;
  activeIndex?: number;
  activeSituationIndex: number;
  fullHeight?: boolean;
  largeHeader?: boolean;
  playlistCount: number;
  situationCount: number;
}
function BusShelterCarousel(props: BusShelterCarouselProps) {
  const {
    currentPlaylistItem,
    activeIndex,
    activeSituationIndex,
    fullHeight = false,
    largeHeader = false,
    playlistCount = 0,
    situationCount = 0,
  } = props;
  return (
    <div className={cx('busShelterCarousel', { '-full': fullHeight })}>
      <ActiveContent
        current={currentPlaylistItem}
        playlistCount={playlistCount}
        situationCount={situationCount}
        activeIndex={activeIndex}
        fullHeight={fullHeight}
        largeHeader={largeHeader}
        activeSituationIndex={activeSituationIndex}
      />
    </div>
  );
}

export default BusShelterCarousel;
